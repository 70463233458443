import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  selectTrendTimeFilterList,
  selectRevenueIsDisplayTT,
  selectTrendTimeTabularVisuals,
} from 'modules/dashboard/components/tab-container/trend-tabular-tab/selectors';
import styles from 'modules/dashboard/components/tab-container/trend-tabular-tab/tabular-tab.module.scss';
import { selectEmbedToken } from 'modules/dashboard/selectors';
import VisualGrid from 'modules/dashboard/components/tab-container/visual-grid';
import VisualCard from 'modules/dashboard/components/tab-container/visual-card';
import { trendTabularTabActions } from '../../../../slice';
/**
 * Trend Time tabular visual report with filters
 */
const TrendTimeTabularWidget = ({ setIsTabularViewLoaded }) => {
  const dispatch = useDispatch();
  // States are obtained from redux store. Refer each selector function for details.
  const filterList = useSelector(selectTrendTimeFilterList);
  const tokenDetails = useSelector(selectEmbedToken);
  const visuals = useSelector(selectTrendTimeTabularVisuals);
  const revenueIsDisplay = useSelector(selectRevenueIsDisplayTT);
  //
  const [isInitialVisualLoaded, setIsInitialVisualLoaded] = useState(false);
  //
  useEffect(() => {
    setIsTabularViewLoaded(isInitialVisualLoaded);
  }, [isInitialVisualLoaded]);
  // To get visual details
  const getVisual = (index) => visuals?.find((visual) => visual?.order === index);

  // To get visuals based on the configuration
  const getWidget = (
    index,
    cssClassName,
    setPBIReport = () => {},
    setIsLoaded = () => {},
    filter = filterList
  ) => {
    const found = getVisual(index);
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={tokenDetails?.accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filter}
          setPBIReport={(r) => setPBIReport(r)}
          setIsLoaded={(d) => setIsLoaded(d)}
        />
      )
    );
  };
  // To get visual names based on visual order
  const getVisualName = (order) => getVisual(order)?.name;
  //
  return (
    <VisualGrid
      isDisplay={revenueIsDisplay}
      visualName={getVisualName(0)}
      visual={getWidget(0, styles.tabularVisual, () => {}, setIsInitialVisualLoaded)}
      onClick={() => {
        dispatch(trendTabularTabActions.setRevenueIsDisplayTT(!revenueIsDisplay));
      }}
    />
  );
};
//
export default TrendTimeTabularWidget;
