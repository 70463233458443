import { Autocomplete, FormControl, TextField, IconButton, Tooltip, Grid } from '@mui/material';
import { CustomStack, CustomToggleSwitch } from 'modules/common/components';
import { AddHotelGroup } from 'modules/common/components/add-hotel-group';
import styles from 'modules/dashboard/components/tab-container/inner-filter/inner-filter.module.scss';
import { useState } from 'react';
import { PlusCircle } from 'react-feather';
/**
 * Compare hotels window component common for the dashboard component
 * @param {*} selectedHotelName - selected hotel
 * @param {Array} hotelList - hotel list
 * @param {*} focusOn - selected compared hotels
 * @param {String} focusOnLabel - label for dropdown
 * @param {Function} handleCompareHotelsChange - Function to set compare hotels value
 * @param {Boolean} isTrendHotelAggregate - state of isTrendHotelAggregate
 * @param {Boolean} aggregateDisabled - state of aggregate toggle enabled/disabled
 * @param {Function} handleTrendHotelAggregate - Function to set trend hotel aggregate value
 * @returns
 */
const CompareHotelsWindow = ({
  // selectedHotelName,
  hotelList,
  focusOn,
  focusOnLabel,
  handleCompareHotelsChange,
  normalizedViewToggle,
  handleTrendHotelNormalize,
  enableSecondaryHotelGroups,
  hotelGroupList,
  hotelGroupFocusOn,
  groupFocusOnLabel,
  handleCompareGroupHotelsChange,
  handleEnableSecondaryHotelGroups,
  isTrendPercentageChange = false,
}) => {
  const [open, setOpen] = useState(false);
  //
  return (
    <CustomStack cssClassName={styles.innerFilter}>
      <FormControl>
        <Grid container direction="row" justifyContent="space-between" alignContent="center">
          <CustomToggleSwitch
            label="Hotel Sets"
            onChange={handleEnableSecondaryHotelGroups}
            checked={enableSecondaryHotelGroups}
            sx={{ pt: 3 }}
          />
          <Tooltip title="Add Hotel Set">
            <span>
              <IconButton
                color="primary"
                variant="contained"
                onClick={() => setOpen(true)}
                disabled={!enableSecondaryHotelGroups}
                sx={{
                  '&.Mui-disabled': {
                    background: 'gray',
                    color: 'white',
                  },
                }}
              >
                <PlusCircle size="15px" />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </FormControl>
      {!enableSecondaryHotelGroups ? (
        <FormControl>
          <Autocomplete
            size="small"
            id="tags-outlined"
            options={hotelList ?? []}
            getOptionLabel={(option) => (option.label ? option.label : '')}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterSelectedOptions
            onChange={handleCompareHotelsChange}
            disableClearable
            value={focusOn}
            renderInput={(params) => (
              <TextField {...params} label={focusOnLabel} size="small" color="primary" />
            )}
            fullWidth
            componentsProps={{
              paper: {
                sx: {
                  width: '100%',
                  whiteSpace: 'unset',
                  wordBreak: 'break-all',
                },
              },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                padding: 0,
                height: 'fit-content',
              },
              marginY: 2,
            }}
          />
        </FormControl>
      ) : (
        <FormControl>
          <Autocomplete
            size="small"
            id="tags-outlined"
            options={hotelGroupList ?? []}
            getOptionLabel={(option) => (option.label ? option.label : '')}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterSelectedOptions
            onChange={handleCompareGroupHotelsChange}
            disableClearable
            value={hotelGroupFocusOn}
            renderInput={(params) => (
              <TextField {...params} label={groupFocusOnLabel} size="small" color="primary" />
            )}
            fullWidth
            componentsProps={{
              paper: {
                sx: {
                  width: '100%',
                  whiteSpace: 'unset',
                  wordBreak: 'break-all',
                },
              },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                padding: 0,
                height: 'fit-content',
              },
              marginY: 2,
            }}
          />
        </FormControl>
      )}
      <FormControl>
        <CustomToggleSwitch
          label="Normalized - PAR"
          title="Change ‘Total Occupied Rooms’ and ‘Total Revenue’ graphs to display ‘per room’ metrics for the hotels."
          onChange={handleTrendHotelNormalize}
          checked={normalizedViewToggle ?? true}
          disabled={isTrendPercentageChange}
        />
      </FormControl>
      <AddHotelGroup open={open} onClose={() => setOpen(false)} hotelList={hotelList ?? []} />
    </CustomStack>
  );
};
//
export default CompareHotelsWindow;
