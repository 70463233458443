import React from 'react';
import * as Sentry from '@sentry/react';
import { ENVIRONMENT } from 'config';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

export default () => {
  console.log('Sentry: Check', ENVIRONMENT.ENABLE_SENTRY === 'true');
  console.log('Sentry: Env', ENVIRONMENT.ENABLE_SENTRY);
  if (ENVIRONMENT.ENABLE_SENTRY === 'true') {
    console.log('Sentry: Log', 'Sentry Start Initializing');
    Sentry.init({
      // default to 250 but increase up to 1200 to allocated space for filter logs to ship without truncating
      maxValueLength: 1200,
      transport: Sentry.makeFetchTransport,
      transportOptions: {
        // defaults to 30 and changed to 500
        // which solves the issues droping events on queue overflow
        bufferSize: 500,
        maxQueueSize: 500,
      },
      environment: process.env.NODE_ENV,
      release: ENVIRONMENT?.APP_VERSION || 'undefined',
      autoSessionTracking: true,
      dsn: ENVIRONMENT.SENTRY_DSN,
      maxBreadcrumbs: 50,
      debug: false,
      sampleRate: 1.0,
      integrations: [
        new Sentry.Integrations.Breadcrumbs({
          console: true,
        }),
        new Sentry.BrowserTracing({
          // See docs for support of different versions of variation of react router
          // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
        }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/dev-amicacorp\.surge\.tools/],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }
  // log the integrations and the sentry host name
  // console.log("Sentry: Host",Sentry.getClient().getDsn()?.host)
  // console.log("Sentry: Integrations",Sentry.getClient().getOptions().integrations)
};
