/* eslint-disable */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { withTheme } from '@emotion/react';
import { Button, Grid, Toolbar, Typography, Menu, Fade } from '@mui/material';
import { ExpandMore, Menu as MenuIcon } from '@mui/icons-material';
import { selectAuthUser } from 'modules/common/auth/selectors';
import { ADMIN_ROLE, SUPER_ADMIN_ROLE } from 'modules/common/constants/roles';
import ROUTES from 'modules/common/constants/route';
import { ENVIRONMENT } from 'config';
import { isEmpty } from 'modules/common/helpers/object';
import NavbarUserDropdown from './navbar-user-dropdown';
import NavbarHotelDropdown from './navbar-hotel-dropdown';
import { AppBar, IconButton, LargeAvatar, MenuItemDropdownItem, SmallAvatar } from './style';
/**
 * Navigation bar common component that uses
 * across modules for navigate among pages
 */
const Navbar = () => {
  const navigate = useNavigate();
  // get current path location
  const location = useLocation();
  //
  const currentUser = useSelector(selectAuthUser);
  //
  const [anchorEl, setAnchorEl] = useState(null);
  //
  const [open, setOpen] = useState(false);
  // set initial selected path state
  const [selected, setSelected] = useState({
    home: location.pathname === ROUTES.DASHBOARD,
    rateCalendar: location.pathname === ROUTES.RATE_CALENDAR,
    forecast: location.pathname === ROUTES.FORECAST_UPLOAD,
    configure:
      location.pathname === ROUTES.CONFIGURE_HOTELS ||
      location.pathname === ROUTES.EVENT_CATEGORIES ||
      location.pathname === ROUTES.USERS,
  });
  // set selected menu item based on changed location
  useEffect(() => {
    setSelected({
      home: location.pathname === ROUTES.DASHBOARD,
      rateCalendar: location.pathname === ROUTES.RATE_CALENDAR,
      forecast: location.pathname === ROUTES.FORECAST_UPLOAD,
      configure:
        location.pathname === ROUTES.CONFIGURE_HOTELS ||
        location.pathname === ROUTES.EVENT_CATEGORIES ||
        location.pathname === ROUTES.USERS,
    });
  }, [location]);
  //
  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{ height: 75, maxHeight: 75, padding: 0, margin: 0 }}
    >
      <Toolbar>
        <Grid container alignItems="center" direction="row">
          <Grid
            item
            xs={1}
            md={2}
            lg={3}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid
              item
              sx={{
                display: {
                  xs: 'block',
                  md: 'none',
                },
              }}
            >
              <IconButton
                color="inherit"
                aria-label="Menu Items"
                size="large"
                id="menu"
                aria-controls={open ? 'menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={() => setOpen(!open)}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            {ENVIRONMENT.APP_LOGO_LIGHT && (
              <>
                <Grid
                  item
                  sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'none', xl: 'block' } }}
                >
                  <LargeAvatar
                    variant="square"
                    src={ENVIRONMENT.APP_LOGO_LIGHT}
                    sx={{ '& .MuiAvatar-img': { objectFit: 'contain !important' } }}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    display: { xs: 'block', sm: 'block', md: 'block', lg: 'block', xl: 'none' },
                  }}
                >
                  <SmallAvatar
                    variant="square"
                    src={ENVIRONMENT.APP_LOGO_LIGHT}
                    sx={{ '& .MuiAvatar-img': { objectFit: 'contain !important' } }}
                  />
                </Grid>
              </>
            )}
            <Grid item>
              <Typography
                sx={{
                  color: '#e5e5e5',
                  display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'none',
                    lg: 'block',
                  },
                  fontSize: 20,
                  pl: 2,
                }}
              >
                Revenue Intelligence
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={3}
            md={3}
            lg={4}
            container
            justifyContent="flex-end"
            alignItems="center"
            wrap="nowrap"
          >
            <Button
              onClick={() => {
                setSelected({
                  home: true,
                  rateCalendar: false,
                  forecast: false,
                  configure: false,
                });
                navigate(ROUTES.DASHBOARD);
              }}
              variant="text"
              sx={{
                color: selected.home ? '#ffffff' : '#9e9e9e',
                fontSize: { md: 13, lg: 15 },
              }}
            >
              Home
            </Button>
            {/* Rate Calendar is hidden as per client's request */}
            {false && (
              <Button
                onClick={() => {
                  setSelected({
                    home: false,
                    rateCalendar: true,
                    forecast: false,
                    configure: false,
                  });
                  navigate(ROUTES.RATE_CALENDAR);
                }}
                variant="text"
                sx={{
                  color: selected.rateCalendar ? '#ffffff' : '#9e9e9e',
                  fontSize: { md: 13, lg: 15 },
                }}
              >
                Rate Calendar
              </Button>
            )}
            {ENVIRONMENT.ENABLE_BULK_UPLOAD.toLowerCase() === 'true' && (
              <>
                {false && (
                  <Button
                    id="forecast"
                    variant="text"
                    endIcon={<ExpandMore />}
                    sx={{
                      color: selected.forecast ? '#ffffff' : '#9e9e9e',
                      fontSize: { md: 13, lg: 15 },
                    }}
                    onClick={(event) => {
                      setSelected({
                        home: false,
                        rateCalendar: false,
                        forecast: true,
                        configure: false,
                      });
                      setAnchorEl(event?.currentTarget);
                    }}
                    aria-controls={anchorEl?.id === 'forecast' ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={anchorEl?.id === 'forecast' ? 'true' : undefined}
                  >
                    Forecast
                  </Button>
                )}
                <Menu
                  anchorEl={anchorEl}
                  MenuListProps={{
                    style: {
                      backgroundColor: '#233044',
                    },
                  }}
                  open={anchorEl?.id === 'forecast'}
                  onClose={() => setAnchorEl(null)}
                  TransitionComponent={Fade}
                >
                  <MenuItemDropdownItem
                    onClick={() => {
                      navigate(ROUTES.FORECAST_UPLOAD);
                      setAnchorEl(null);
                    }}
                  >
                    Bulk Monthly Input
                  </MenuItemDropdownItem>
                </Menu>
              </>
            )}
            {!isEmpty(currentUser) &&
              (currentUser?.roles[0]?.name === ADMIN_ROLE ||
                currentUser?.roles[0]?.name === SUPER_ADMIN_ROLE) && (
                <>
                  <Button
                    id="configuration"
                    variant="text"
                    endIcon={<ExpandMore />}
                    sx={{
                      color: selected.configure ? '#ffffff' : '#9e9e9e',
                      fontSize: { md: 13, lg: 15 },
                    }}
                    onClick={(event) => {
                      setSelected({
                        home: false,
                        rateCalendar: false,
                        forecast: false,
                        configure: true,
                      });
                      setAnchorEl(event?.currentTarget);
                    }}
                    aria-controls={anchorEl?.id === 'configuration' ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={anchorEl?.id === 'configuration' ? 'true' : undefined}
                  >
                    Configuration
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    MenuListProps={{
                      style: {
                        backgroundColor: '#233044',
                      },
                    }}
                    open={anchorEl?.id === 'configuration'}
                    onClose={() => setAnchorEl(null)}
                    TransitionComponent={Fade}
                  >
                    <MenuItemDropdownItem
                      onClick={() => {
                        navigate(ROUTES.CONFIGURE_HOTELS);
                        setAnchorEl(null);
                      }}
                    >
                      Configure Hotels
                    </MenuItemDropdownItem>
                    <MenuItemDropdownItem
                      onClick={() => {
                        navigate(ROUTES.EVENT_CATEGORIES);
                        setAnchorEl(null);
                      }}
                    >
                      Configure Event Categories
                    </MenuItemDropdownItem>
                    <MenuItemDropdownItem
                      onClick={() => {
                        navigate(ROUTES.USERS);
                        setAnchorEl(null);
                      }}
                    >
                      Configure Users
                    </MenuItemDropdownItem>
                  </Menu>
                </>
              )}
          </Grid>
          <Grid
            item
            xs={8}
            md={7}
            lg={5}
            container
            justifyContent="flex-end"
            direction="row"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item mr={2}>
              <NavbarHotelDropdown />
            </Grid>
            <Grid item>
              <Typography noWrap sx={{ color: '#e5e5e5', fontSize: { md: 13, lg: 15 }, ml: 2 }}>
                {currentUser?.username}
              </Typography>
            </Grid>
            <Grid item>
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
//
export default withTheme(Navbar);
