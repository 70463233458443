import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { isEmpty } from 'modules/common/helpers/object';
import { useEffect, useState } from 'react';
import { ENVIRONMENT } from 'config';
import * as Sentry from '@sentry/react';
import { selectAuthUser,selectSessionId } from 'modules/common/auth/selectors';
import { useSelector } from 'react-redux';
/**
 * Power BI visual component
 * @param {String} cssClassName - Class name for the visual
 * @param {String} accessToken - Power bi access token
 * @param {String} embedUrl - Power bi embed url
 * @param {String} id - Power bi report id
 * @param {String} pageName - Power bi page name
 * @param {Array} filters - List of filters to be applied for the visual
 * @param {Function} setPBIReport - Function to set loaded report object
 * @param {Function} setIsLoaded - Function to set report loaded state
 * @returns
 */

const VisualCard = (props) => {
  const {
    cssClassName,
    accessToken,
    embedUrl,
    visualName,
    id,
    pageName,
    filters,
    reportName,
    pageDisplayName,
    setPBIReport = () => {},
    setIsLoaded = () => {},
  } = props;
  // global selectors
  const user = useSelector(selectAuthUser);
  const sessionId = useSelector(selectSessionId);
  //
  const [reportInstance, setReportInstance] = useState();
  let visualRenderedCount = new Map();

  const handleLogging = (message, level, visualtype = null) => {
    if(ENVIRONMENT.PBI_EMBED_DEBUG_MODE?.toLowerCase() === 'true'){
      console.log(message)
    }
    if(ENVIRONMENT.ENABLE_SENTRY?.toLowerCase() === 'true'){
      Sentry.captureEvent({
        message,
        level,
        user: { username: user?.username || 'undefined' },
        tags:{
          sessionId,
          visualtype
        }
      });
    }
  };

  // Triggered when the filters value changes
  useEffect(() => {
    // Update visual filters on filter change
    if (reportInstance) {
      reportInstance?.updateFilters(models.FiltersOperations.Replace, filters).catch((error) => {
        // eslint-disable-next-line
        console.error(error);
      });
    }
  }, [filters, reportInstance]);
  //
  return (
    <PowerBIEmbed
      embedConfig={{
        type: 'report',
        tokenType: models.TokenType.Embed,
        accessToken,
        embedUrl,
        id,
        pageName,
        ...(isEmpty(reportInstance?.config?.filter) && { filters }),
        settings: {
          displayOption: models.DisplayOption.FitToPage,
          visualRenderedEvents: true,
          panes: {
            filters: {
              expanded: false,
              visible: false,
            },
            pageNavigation: {
              visible: false,
            },
          },
          background: models.BackgroundType.Transparent,
        },
      }}
      cssClassName={cssClassName}
      eventHandlers={
        new Map([
          [
            'error',
            (event) => {
              const logMessage = `${new Date().toISOString()} - Event : error | Visual : ${visualName} | Report : ${reportName} | Page : ${pageDisplayName} | Message : ${
                event?.detail?.detailedMessage
              }`;
              handleLogging(logMessage, 'error');
            },
          ],
          [
            'loaded',
            function loaded() {
              const logMessage = `${new Date().toISOString()} - Event : loaded | Visual : ${visualName} | Report : ${reportName} | Page : ${pageDisplayName}`;
              handleLogging(logMessage, 'info',"loaded");
              setIsLoaded(true);
            },
          ],
          [
            'rendered',
            (event) => {
              const logMessage = `${new Date().toISOString()} - Event : rendered | Visual : ${visualName} | Report : ${reportName} | Page : ${pageDisplayName} | Visual Rendered Count : ${visualRenderedCount.get(
                  `${visualName}_${reportName}_${pageDisplayName}`
              )}`;
              handleLogging(logMessage, 'info',"rendered");
              if (ENVIRONMENT.PBI_EMBED_DEBUG_MODE?.toLowerCase() === 'true') {
                visualRenderedCount = new Map();
              }
              if (event.target.classList.contains('loader')) {
                event.target.classList.remove('loader');
              }
            },
          ],
          [
            'visualRendered',
            (event) => {
              const logMessage = `${new Date().toISOString()} - Event : visualRendered | Visual : ${visualName} | Report : ${reportName} | Page : ${pageDisplayName}`;
              handleLogging(logMessage, 'info',"visualRendered");
              if (ENVIRONMENT.PBI_EMBED_DEBUG_MODE?.toLowerCase() === 'true') {
                visualRenderedCount.set(
                  `${visualName}_${reportName}_${pageDisplayName}`,
                  visualRenderedCount.get(`${visualName}_${reportName}_${pageDisplayName}`)
                    ? visualRenderedCount.get(`${visualName}_${reportName}_${pageDisplayName}`) + 1
                    : 1
                );
              }
              if (!event.target.classList.contains('loader')) {
                event.target.classList.add('loader');
              }
            },
          ],
        ])
      }
      getEmbeddedComponent={(embeddedReport) => {
        setPBIReport(embeddedReport);
        setReportInstance(embeddedReport);
      }}
    />
  );
};
//
export default VisualCard;
