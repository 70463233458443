import { useState, useEffect } from 'react';
import { Toolbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectTargetDate,
  selectVisualFilters,
  selectIsSignedIn,
  selectPmsSync,
} from 'modules/dashboard/selectors';
import { getComparisonDateRange } from 'modules/dashboard/functions';
import { CustomStack } from 'modules/common/components';
import { capitalizeFirst } from 'modules/common/helpers/strings';
import {
  OTB_VALUE,
  KEYS,
  STAY_DATE,
  RESET_BOOKING_DATE,
  ALIGNED,
} from 'modules/dashboard/constants';
import ActionButton from 'modules/common/components/action-button';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import { ENVIRONMENT } from 'config';
import DataUpdatedLabel from 'modules/dashboard/components/tab-container/data-updated-label';
import BookingWindow from 'modules/dashboard/components/tab-container/inner-filter/components/booking-window';
import VsComparisonWindow from 'modules/dashboard/components/tab-container/inner-filter/components/vs-comparison-window';
import {
  getDateComparisonFilter,
  getSubAlignmentFilter,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import { dashboardActions } from 'modules/dashboard/slice';
import {
  selectAlignment,
  selectDateComparison,
  selectWorkbookCustomDate,
  selectWorkbookEndDate,
  selectWorkbookStartDate,
  selectSymmetricComparisonToggle,
  selectWorkbookAlignmentToggle,
  selectWorkbookOtb,
  selectSymmetricComparisonIsDisplay,
} from '../../selectors';
import { workbookActions } from '../../slice';
import useWorkBookCommandFlow from '../../hooks/use-workbook-command-flow';
import { PmsToggle } from '../../../inner-filter';

/**
 * Filters for Workbook widget include PMS Sync, Booking Dates, Chart Breakdown,
 * Alignment, Date Comparison, Pace Horizon, pace variable and pace forecast
 * @param {Number} tabValue - Pace widget tab value
 * @param {Date} latestDate - Latest booking date of selected hotel
 * @param {Boolean} hotelTimezone - Timezone of selected hotel
 * @param {Number} toggleDisabled - PMS sync toggle disabled state
 * @param {Boolean} isLoaded - Visual is loaded state
 * @param {String} reportType - Report type of active tab
 * @param {Object} visual- Symmetric comparison visual object
 * @returns
 */
const WorkBookFilter = ({
  reportType,
  visual,
  visualName,
  latestDate,
  hotelTimezone,
  isLoaded,
  toggleDisabled,
}) => {
  const dispatch = useDispatch();
  // Selected filter values are obtained from redux store. Refer each selector function for details.
  const alignment = useSelector(selectAlignment);
  const dateComparison = useSelector(selectDateComparison);
  const visualFilters = useSelector(selectVisualFilters);
  const targetDate = useSelector(selectTargetDate);
  const alignmentToggle = useSelector(selectWorkbookAlignmentToggle);
  const symmetricComparisonToggle = useSelector(selectSymmetricComparisonToggle);
  const isSignIn = useSelector(selectIsSignedIn);
  const customBooking = useSelector(selectWorkbookOtb);
  const customDate = useSelector(selectWorkbookCustomDate);
  const startDate = useSelector(selectWorkbookStartDate);
  const endDate = useSelector(selectWorkbookEndDate);
  const symmetricComparisonIsDisplay = useSelector(selectSymmetricComparisonIsDisplay);
  const pmsSyncToggle = useSelector(selectPmsSync);
  //
  const [dateComparisonList, setDateComparisonList] = useState([]);

  // To select otb or custom booking window
  const handleOTBChange = (event) => {
    const { value } = event.target;
    dispatch(workbookActions.setWorkbookOtb(value !== 'false'));
    dispatch(workbookActions.setWorkbookBookingDate(RESET_BOOKING_DATE));
  };

  // Reset button function to set filter panel values to default
  const clearSearch = () => {
    dispatch(workbookActions.setWorkbookOtb(false));
    const subAlignmentFilter = getSubAlignmentFilter(visualFilters, false);
    dispatch(
      workbookActions.setDateComparison(
        subAlignmentFilter?.values?.filter((filter) => filter.isDefault === true)[0].value
      )
    );
    dispatch(workbookActions.setWorkbookBookingDate(RESET_BOOKING_DATE));
    dispatch(workbookActions.setWorkbookAlignmentToggle(subAlignmentFilter?.type === ALIGNED));
    dispatch(workbookActions.setSymmetricComparisonToggle(true));
    dispatch(workbookActions.setWorkbookStartDate(STAY_DATE.startDate));
    dispatch(workbookActions.setWorkbookEndDate(STAY_DATE.endDate));
    dispatch(workbookActions.setWorkbookCustomDate(KEYS.START_DATE));
    dispatch(workbookActions.setWorkbookDateSelected(STAY_DATE.startDate));
  };

  // Triggered when alignment, visualFilters, alignmentToggle changes
  useEffect(() => {
    // Set date comparison list based on alignment
    if (alignment) {
      const dateComparisonFilter = getDateComparisonFilter(visualFilters, alignment?.id);
      setDateComparisonList(dateComparisonFilter);
      // persist value on browser refresh
      const serializedState = JSON.parse(
        localStorage.getItem(`persist:${ENVIRONMENT.PERSIST_KEY}`)
      );
      if (!isSignIn && serializedState) {
        return;
      }
      dispatch(
        workbookActions.setDateComparison(
          dateComparisonFilter?.filter((filter) => filter?.isDefault === true)[0].value
        )
      );
    }
  }, [alignment, visualFilters, alignmentToggle]);

  // Triggered when alignmentToggle or visualFilters value changes
  useEffect(() => {
    // Set alignment list
    const subAlignmentFilter = getSubAlignmentFilter(visualFilters, alignmentToggle);
    dispatch(
      workbookActions.setAlignment({
        id: subAlignmentFilter?.id,
        label: capitalizeFirst(subAlignmentFilter?.type),
      })
    );
  }, [alignmentToggle, visualFilters]);

  // Alignment toggle change handler
  const handleToggleChange = (event) => {
    dispatch(workbookActions.setWorkbookAlignmentToggle(event.target.checked));
    const dateComparisonFilter = getDateComparisonFilter(visualFilters, alignment?.id);
    dispatch(
      workbookActions.setDateComparison(
        dateComparisonFilter?.filter((filter) => filter?.isDefault === true)[0].value
      )
    );
  };
  // Date comparison change handler
  const handleComparisonChange = (event) => {
    dispatch(workbookActions.setDateComparison(event.target.value));
    dispatch(workbookActions.setWorkbookDateSelected(STAY_DATE.startDate));
    dispatch(workbookActions.setWorkbookCustomDate(KEYS.START_DATE));
    //
    const dateRange = getComparisonDateRange(
      STAY_DATE.startDate,
      targetDate.startDate,
      targetDate.endDate,
      KEYS.START_DATE
    );
    //
    dispatch(workbookActions.setWorkbookStartDate(dateRange?.comparisonStartDate.toISOString()));
    dispatch(workbookActions.setWorkbookEndDate(dateRange?.comparisonEndDate.toISOString()));
  };

  // Triggered when customBooking, dateComparison, visualFilters, isLoaded change
  useEffect(() => {
    // Assign default value to date comparison when OTB is selected for booking window
    if (!customBooking && dateComparison === OTB_VALUE && isLoaded) {
      const subAlignmentFilter = getSubAlignmentFilter(visualFilters, true);
      dispatch(
        workbookActions.setDateComparison(
          subAlignmentFilter?.values?.filter((filter) => filter.isDefault === true)[0].value
        )
      );
    }
  }, [customBooking, dateComparison, visualFilters, isLoaded]);
  /**
   * set symmetric comparison value
   * @param {*} event - on change event
   */
  const handleSymmetricComparisonChange = (event) => {
    dispatch(workbookActions.setSymmetricComparisonToggle(event.target.checked));
  };
  /**
   * set custom dates value
   * @param {*} event - on change event
   */
  const handleCustomDates = (event) => {
    dispatch(workbookActions.setWorkbookCustomDate(event.target.value));
  };
  //
  useWorkBookCommandFlow(
    isLoaded,
    customBooking,
    dateComparison,
    dateComparisonList,
    handleComparisonChange,
    handleToggleChange
  );
  //
  return (
    <>
      <PmsToggle
        disabled={toggleDisabled}
        value={pmsSyncToggle}
        onChange={(event) => dispatch(dashboardActions.setPmsSync(event.target.checked))}
      />
      <br />
      <BookingWindow
        vOTB={customBooking}
        customBooking={customBooking}
        handleOTBChange={handleOTBChange}
        reportType={reportType}
        latestDate={latestDate}
        otbValue={false}
      />
      <br />
      <VsComparisonWindow
        customBooking={customBooking}
        alignmentToggle={alignmentToggle}
        handleToggleChange={handleToggleChange}
        symmetricComparisonToggle={symmetricComparisonToggle}
        visual={visual}
        visualName={visualName}
        dateComparison={dateComparison}
        dateComparisonList={dateComparisonList}
        handleComparisonChange={handleComparisonChange}
        handleSymmetricComparisonChange={handleSymmetricComparisonChange}
        isCustomOptionAvailable
        customDate={customDate}
        reportType={reportType}
        startDate={startDate}
        endDate={endDate}
        targetDate={targetDate}
        handleCustomDates={handleCustomDates}
        isDisplay={symmetricComparisonIsDisplay}
        isDisplayOnClickAction={() => {
          dispatch(workbookActions.setSymmetricComparisonIsDisplay(!symmetricComparisonIsDisplay));
        }}
      />
      <br />
      <CustomStack cssClassName={styles.innerFilter}>
        <ActionButton onClick={clearSearch} label="RESET" />
      </CustomStack>
      <Toolbar />
      <DataUpdatedLabel latestDate={latestDate} hotelTimezone={hotelTimezone} />
    </>
  );
};
//
export default WorkBookFilter;
