import { useEffect } from 'react';
import { Button } from '@mui/material';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUserInvite, selectUserInviteToken } from 'modules/verify-callback/selectors';
import signUpFormValidation from 'modules/sign-up/validation/sign-up-form-validation';
import { signUpActions } from 'modules/sign-up/slice';
import { notificationActions } from 'modules/common/notifications/slice';
import { selectNotification } from 'modules/common/notifications/selectors';
import ROUTES from 'modules/common/constants/route';
import { toast } from 'react-toastify';
import TOAST_TYPES from 'modules/common/constants/toast-types';
import ERROR_TYPES from 'modules/common/constants/error-types';
import { ACCEPTED_STATUS } from 'modules/sign-up/constants';
import { Alert, TextField } from './style';
/**
 * Sign up form fields are defined here
 * @returns
 */
const SignUpFormView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //
  const notification = useSelector(selectNotification);
  const userInvite = useSelector(selectUserInvite);
  const userInviteToken = useSelector(selectUserInviteToken);
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, {
        type: TOAST_TYPES.SUCCESS,
      });
      navigate(ROUTES.LOGIN);
    }
    return () => notification?.isEnabled && dispatch(notificationActions.resetNotification());
  }, [notification]);
  //
  const signUpSubmit = (values) => {
    const organizationId = userInvite?.organizationId;
    const valueObj = {
      username: values.username,
      email: userInvite?.email,
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.newPassword,
      token: userInviteToken,
      role: userInvite?.role,
    };
    dispatch(
      signUpActions.signUp({
        ...valueObj,
        organizationId,
      })
    );
  };
  //
  useEffect(() => {
    if (userInvite?.status === ACCEPTED_STATUS) {
      navigate(ROUTES.LOGIN);
    }
  }, [userInvite]);
  //
  return (
    <Formik
      initialValues={{
        username: '',
        email: '',
        firstName: '',
        lastName: '',
        newPassword: '',
        passwordConfirmation: '',
      }}
      validationSchema={signUpFormValidation}
      onSubmit={(values) => signUpSubmit(values)}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          {notification?.isEnabled && (
            <Alert mt={2} mb={3} severity={notification?.type}>
              {notification?.message}
            </Alert>
          )}
          <TextField
            type="text"
            name="username"
            value={values.username}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
            fullWidth
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
            label="Username"
            size="small"
          />
          <TextField
            type="text"
            name="firstName"
            value={values.firstName}
            error={Boolean(touched.firstName && errors.firstName)}
            helperText={touched.firstName && errors.firstName}
            fullWidth
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
            label="First Name"
            size="small"
          />
          <TextField
            type="text"
            name="lastName"
            value={values.lastName}
            error={Boolean(touched.lastName && errors.lastName)}
            helperText={touched.lastName && errors.lastName}
            fullWidth
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
            label="Last Name"
            size="small"
          />

          <TextField
            type="password"
            name="newPassword"
            value={values.newPassword}
            error={Boolean(touched.newPassword && errors.newPassword)}
            helperText={touched.newPassword && errors.newPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            label="Password"
            autoComplete="off"
            my={2}
            size="small"
          />
          <TextField
            type="password"
            name="passwordConfirmation"
            value={values.passwordConfirmation}
            error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
            helperText={touched.passwordConfirmation && errors.passwordConfirmation}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            label="Confirm Password "
            autoComplete="off"
            my={2}
            size="small"
          />
          <Button type="submit" fullWidth variant="contained" color="primary">
            Sign Up
          </Button>
        </form>
      )}
    </Formik>
  );
};
//
export default SignUpFormView;
