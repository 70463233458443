import { Dialog, DialogTitle, DialogContent, Box, IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { notificationActions } from 'modules/common/notifications/slice';
import CustomToggleSwitch from 'modules/common/components/toggle-switch';
import { useEffect, useState } from 'react';
import SaveFilter from 'modules/common/components/add-hotel-group/components/save-filter';
import LoadFilter from 'modules/common/components/add-hotel-group/components/load-filter';
import AggregationTransferList from 'modules/common/components/add-hotel-group/components/add-hotel-group-transfer-list';
import QueryBox from 'modules/common/components/add-hotel-group/components/query-box';
import { dashboardActions } from 'modules/dashboard/slice';
import { X } from 'react-feather';

/**
 * Custom aggregation filter component is implemented
 * @param {Boolean} open - To open/ close dialog box
 * @param {Function} onClose - Function to trigger on close event
 * @returns
 */
const AddHotelGroup = ({ open, onClose, hotelList }) => {
  const dispatch = useDispatch();
  //
  const [groupHotelList, setGroupHotelList] = useState([]);
  const [showLoadFilter, setShowLoadFilter] = useState(false);
  const [isQueryEnabled, setIsQueryEnabled] = useState(false);
  const [loadFilter, setLoadFilter] = useState(false);
  const [detailedBreakdown, setDetailedBreakdown] = useState(hotelList);
  const [temGroupList, setTemGroupList] = useState([]);
  //
  const [checked, setChecked] = useState([]);
  // Triggered once when the component mounted
  useEffect(() => {
    dispatch(notificationActions.resetNotification());
  }, []);
  useEffect(() => {
    // Update global and local states
    if (open) {
      setGroupHotelList([]);
      setTemGroupList([]);
      dispatch(dashboardActions.setHotelGroupQuery(''));
      dispatch(dashboardActions.setSelectedHotelGroupName(''));
    }
  }, [open]);
  //
  const modalAction = (reason, action) => {
    if (reason && reason === 'backdropClick') return;
    onClose(action);
  };
  //
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => modalAction(reason, false)}
      aria-labelledby="form-dialog-title"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
          },
        },
      }}
    >
      <DialogTitle sx={{ paddingBottom: 1 }}>Add Hotel Set</DialogTitle>
      <IconButton
        size="small"
        aria-label="close"
        onClick={() => modalAction(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <X />
      </IconButton>
      <DialogContent>
        <Box
          sx={{
            borderRadius: '8px',
            borderColor: '#8c8787 !important',
            border: 1,
            padding: 4,
          }}
        >
          <AggregationTransferList
            focusOnList={hotelList}
            groupList={groupHotelList}
            setGroupList={(value) => setGroupHotelList(value)}
            isQueryEnabled={isQueryEnabled}
            detailedBreakdown={detailedBreakdown}
            setDetailedBreakdown={setDetailedBreakdown}
            checked={checked}
            setChecked={setChecked}
            temGroupList={temGroupList}
            setTemGroupList={setTemGroupList}
          />
        </Box>
        <Box
          sx={{
            borderRadius: '8px',
            borderColor: '#8c8787 !important',
            border: 1,
            padding: 2,
            marginBottom: 4,
            marginTop: 4,
          }}
        >
          <CustomToggleSwitch
            label="Advanced Querying"
            title=""
            onChange={(event) => setIsQueryEnabled(event.target.checked)}
            checked={isQueryEnabled}
          />

          <QueryBox
            setGroupList={setGroupHotelList}
            onClose={() => {
              modalAction(false);
            }}
            open={open}
            isQueryEnabled={isQueryEnabled}
            loadFilter={loadFilter}
            setLoadFilter={setLoadFilter}
            focusOnList={hotelList}
            setTemGroupList={setTemGroupList}
          />
        </Box>
        <SaveFilter
          groupList={groupHotelList}
          setGroupList={setGroupHotelList}
          setShowLoadFilter={setShowLoadFilter}
          setTemGroupList={setTemGroupList}
          onClose={() => {
            modalAction(false);
          }}
        />
        <LoadFilter
          openLoad={showLoadFilter}
          onClose={() => setShowLoadFilter(false)}
          setLoadFilter={setLoadFilter}
        />
      </DialogContent>
    </Dialog>
  );
};
//
export default AddHotelGroup;
