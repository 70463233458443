import { useDispatch } from 'react-redux';
import { CustomStack, CustomToggleSwitch } from 'modules/common/components';
/**
 * Lag aligned window component common for the dashboard component
 * @param {Boolean} paceLagAligned - State of Lag Aligned toggle
 * @param {*} paceCumulative - State of Cumulative toggle
 * @returns
 */
const LagAlignedWindow = ({
  paceLagAligned,
  paceCumulative,
  changePaceLagAligned,
  changePaceCumulative,
  isPaceForecast,
  IsForecastDisabled,
  setIsPaceForecast,
}) => {
  const dispatch = useDispatch();
  return (
    <CustomStack>
      <CustomToggleSwitch
        label="Lag Aligned"
        title="Display the pace-time and pace-segment statistics considering lead time to stay date as opposed to the absolute booking date"
        onChange={(event) => dispatch(changePaceLagAligned(event.target.checked))}
        checked={paceLagAligned ?? false}
      />
      <CustomToggleSwitch
        label="Cumulative View"
        title="Change ‘Total Occupied Rooms’ and ‘Total Revenue’ graphs to display the cumulated booking pace for each statistic."
        onChange={(event) => dispatch(changePaceCumulative(event.target.checked))}
        checked={paceCumulative ?? false}
      />
      <CustomToggleSwitch
        label="Forecasts"
        title="Enable forecasts on the ‘Total Revenue’ and ‘Total Occupied Rooms’ graphs"
        onChange={(event) => dispatch(setIsPaceForecast(event.target.checked))}
        checked={isPaceForecast ?? false}
        disabled={IsForecastDisabled}
      />
    </CustomStack>
  );
};
//
export default LagAlignedWindow;
