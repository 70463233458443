import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import VisualGrid from 'modules/dashboard/components/tab-container/visual-grid';
import VisualCard from 'modules/dashboard/components/tab-container/visual-card';
import { selectEmbedToken } from 'modules/dashboard/selectors';
import {
  selectAdrIsDisplayPS,
  selectCxlIsDisplayPS,
  selectOccupiedRoomsIsDisplayPS,
  selectPaceCumulativeView,
  selectPaceLagAligned,
  selectPaceSegmentAbsoluteCumulativeVisuals,
  selectPaceSegmentLagAlignedCumulativeVisuals,
  selectPaceSegmentLagAlignedVisuals,
  selectPaceSegmentVisuals,
  selectPaceWidgetSegmentFilterList,
  selectRevenueIsDisplayPS,
} from '../../../../selectors';
import { paceActions } from '../../../../slice';

/**
 * Pace widget report with out filters
 * @param {Boolean} setIsSegmentLoaded - Visual loading state
 * @returns
 */
const PaceSegmentWidget = ({ setIsSegmentLoaded }) => {
  const dispatch = useDispatch();
  const tokenDetails = useSelector(selectEmbedToken);
  // Selected filter values are obtained from redux store. Refer each selector function for details.
  const paceSegmentVisualFilterList = useSelector(selectPaceWidgetSegmentFilterList);
  const paceLagAligned = useSelector(selectPaceLagAligned);
  const paceCumulative = useSelector(selectPaceCumulativeView);
  const revenueIsDisplay = useSelector(selectRevenueIsDisplayPS);
  const occupiedRoomsIsDisplay = useSelector(selectOccupiedRoomsIsDisplayPS);
  const adrIsDisplay = useSelector(selectAdrIsDisplayPS);
  const cxlIsDisplay = useSelector(selectCxlIsDisplayPS);
  //
  const paceSegmentVisuals = useSelector(selectPaceSegmentVisuals);
  const paceLagAlignedVisuals = useSelector(selectPaceSegmentLagAlignedVisuals);
  const paceSegmentAbsoluteCumulativeVisuals = useSelector(
    selectPaceSegmentAbsoluteCumulativeVisuals
  );
  const paceSegmentLagAlignedCumulativeVisuals = useSelector(
    selectPaceSegmentLagAlignedCumulativeVisuals
  );
  //
  const [isPace1VisualsLoaded, setIsPace1VisualLoaded] = useState(false);
  const [isPace2VisualsLoaded, setIsPace2VisualLoaded] = useState(false);
  const [isPace3VisualsLoaded, setIsPace3VisualLoaded] = useState(false);
  const [isPace4VisualsLoaded, setIsPace4VisualLoaded] = useState(false);
  //
  useEffect(() => {
    setIsSegmentLoaded(
      (isPace1VisualsLoaded && isPace2VisualsLoaded && isPace3VisualsLoaded) || isPace4VisualsLoaded
    );
  }, [isPace1VisualsLoaded, isPace2VisualsLoaded, isPace3VisualsLoaded, isPace4VisualsLoaded]);
  //
  const getVisual = (index) => {
    let found;
    if (paceCumulative && !paceLagAligned) {
      found = paceSegmentAbsoluteCumulativeVisuals?.find((visual) => visual?.order === index);
    } else if (paceCumulative && paceLagAligned) {
      found = paceSegmentLagAlignedCumulativeVisuals?.find((visual) => visual?.order === index);
    } else if (paceLagAligned && !paceCumulative) {
      found = paceLagAlignedVisuals?.find((visual) => visual?.order === index);
    } else {
      found = paceSegmentVisuals?.find((visual) => visual?.order === index);
    }
    return found;
  };
  // To get visuals based on the configuration
  const getWidget = (
    index,
    cssClassName,
    setPBIReport = () => {},
    setIsLoaded = () => {},
    filter = paceSegmentVisualFilterList
  ) => {
    const found = getVisual(index);
    //
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={tokenDetails?.accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filter}
          setPBIReport={(report) => setPBIReport(report)}
          setIsLoaded={(loading) => setIsLoaded(loading)}
        />
      )
    );
  };
  //
  const getVisualName = (order) => {
    const found = getVisual(order);
    return found?.name;
  };
  //
  return (
    <>
      <VisualGrid
        visualName={getVisualName(0)}
        isDisplay={revenueIsDisplay}
        visual={getWidget(0, styles.paceVisual, () => {}, setIsPace1VisualLoaded)}
        onClick={() => {
          dispatch(paceActions.setRevenueIsDisplayPS(!revenueIsDisplay));
        }}
        information="Only the Top 10 segments (by value) applicable to the filter selection are displayed from the Pace Segment visuals"
      />
      <VisualGrid
        visualName={getVisualName(1)}
        isDisplay={occupiedRoomsIsDisplay}
        visual={getWidget(1, styles.paceVisual, () => {}, setIsPace2VisualLoaded)}
        onClick={() => {
          dispatch(paceActions.setOccupiedRoomIsDisplayPS(!occupiedRoomsIsDisplay));
        }}
        information="Only the Top 10 segments (by value) applicable to the filter selection are displayed from the Pace Segment visuals"
      />
      <VisualGrid
        visualName={getVisualName(2)}
        isDisplay={adrIsDisplay}
        visual={getWidget(2, styles.paceVisual, () => {}, setIsPace3VisualLoaded)}
        onClick={() => {
          dispatch(paceActions.setAdrIsDisplayPS(!adrIsDisplay));
        }}
        information="Only the Top 10 segments (by value) applicable to the filter selection are displayed from the Pace Segment visuals"
      />
      <VisualGrid
        visualName={getVisualName(3)}
        isDisplay={cxlIsDisplay}
        visual={getWidget(3, styles.paceVisual, () => {}, setIsPace4VisualLoaded)}
        onClick={() => {
          dispatch(paceActions.setCxlIsDisplayPS(!cxlIsDisplay));
        }}
        information="Only the Top 10 segments (by value) applicable to the filter selection are displayed from the Pace Segment visuals"
      />
    </>
  );
};
//
export default PaceSegmentWidget;
