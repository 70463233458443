import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectHotelName,
  selectTargetDate,
  selectVisualFilters,
  selectPmsSync,
  selectPaceHorizon,
  selectEnableHotelGroups,
  selectSelectedHotelGroupName,
  selectReportType,
  selectIsDemoUser,
} from 'modules/dashboard/selectors';
import { PACE_HORIZON_SELECTION, REPORT_TYPE, TAGS } from 'modules/dashboard/constants';
import {
  getBookingDateSchema,
  getGroupASchema,
  getGroupHotelFilterSchema,
  getHotelGroupASchema,
  getHotelGroupOrderSchema,
  getHotelGroupSchema,
  getHotelIdSchema,
  getIsForecastSchema,
  getNormalizedViewSchema,
  getPaceWidgetPaceHorizonSchema,
  getPmsSyncSchema,
  getTargetDateSchema,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import { paceActions } from 'modules/dashboard/components/tab-container/pace-tabs/slice';
import {
  selectEnableSecondaryHotelGroups,
  selectHotelGroupFocusOn,
  selectIsPrimary,
  selectNormalizedView,
  selectPaceBookingDate,
  selectPaceBookingPaceWindow,
  selectPaceWidgetHotelFilterList,
  selectIsForecast,
  selectHotelFocusOn,
} from 'modules/dashboard/components/tab-container/pace-tabs/selectors';
import writeLog from 'modules/common/utils/filter-log';

/**
 * Custom hook to generate summary widget filter schemas
 */
const usePaceHotelWidget = () => {
  const dispatch = useDispatch();
  //
  const targetDate = useSelector(selectTargetDate);
  const hotelId = useSelector(selectHotelName);
  const visualFilters = useSelector(selectVisualFilters);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const paceHorizon = useSelector(selectPaceHorizon);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const hotelGroupId = useSelector(selectSelectedHotelGroupName);
  const paceHotelVisualFilterList = useSelector(selectPaceWidgetHotelFilterList);
  const isSegment = useSelector(selectIsPrimary);
  const reportType = useSelector(selectReportType);
  const bookingDate = useSelector(selectPaceBookingDate);
  const bookingPaceWindow = useSelector(selectPaceBookingPaceWindow);
  const hotelFocusOn = useSelector(selectHotelFocusOn);
  const enableSecondaryHotelGroups = useSelector(selectEnableSecondaryHotelGroups);
  const hotelGroupFocusOn = useSelector(selectHotelGroupFocusOn);
  const isPaceForecast = useSelector(selectIsForecast);
  const isDemoUser = useSelector(selectIsDemoUser);
  const normalizedViewToggle = useSelector(selectNormalizedView);

  // Generate filter list for pace segment visuals
  useEffect(() => {
    if (reportType !== REPORT_TYPE.PACE || !paceHorizon || !hotelId || !visualFilters) return;
    const targetDateFilter = getTargetDateSchema(
      visualFilters,
      targetDate?.startDate,
      targetDate?.endDate
    );
    const bookingDateFilter = getBookingDateSchema(
      visualFilters,
      bookingDate?.startDate,
      bookingDate?.endDate
    );

    const paceHorizonFilter = getPaceWidgetPaceHorizonSchema(
      visualFilters,
      paceHorizon,
      bookingPaceWindow === PACE_HORIZON_SELECTION.BOOKING_PACE_HORIZON
    );

    const pmsSyncFilter = getPmsSyncSchema(visualFilters, pmsSyncToggle, TAGS.PACE_WIDGET);
    const isForecastFilter = getIsForecastSchema(visualFilters, isPaceForecast);
    const hotelGroupOrderFilter = getHotelGroupOrderSchema(visualFilters, isDemoUser);
    const normalizedFilter = getNormalizedViewSchema(visualFilters, normalizedViewToggle);

    const groupFocusOnIds = hotelGroupFocusOn?.hotels?.map((hotel) => hotel?.databricksId) ?? [];
    let hotelGroup1Filter;
    const hotelListIds = hotelGroupId?.hotels?.map((hotel) => hotel?.databricksId) ?? [];
    //
    const groupHotelFilter = getGroupHotelFilterSchema(visualFilters);
    if (enableHotelGroups && hotelListIds?.length > 0) {
      hotelGroup1Filter = getHotelGroupSchema(hotelListIds, groupHotelFilter);
    } else {
      hotelGroup1Filter = getHotelGroupSchema([hotelId?.databricksId], groupHotelFilter);
    }
    //
    const groupAFilter = getGroupASchema(visualFilters);
    let hotelGroupAFilter;
    if (enableSecondaryHotelGroups && groupFocusOnIds?.length > 0) {
      hotelGroupAFilter = getHotelGroupASchema(groupFocusOnIds, groupAFilter);
    } else {
      hotelGroupAFilter = getHotelGroupASchema([hotelFocusOn?.databricksId], groupAFilter);
    }
    //
    let dimHotelIds = [];
    if (enableHotelGroups) {
      if (enableSecondaryHotelGroups) {
        dimHotelIds = new Set([...groupFocusOnIds, ...hotelListIds]);
      } else {
        dimHotelIds = new Set([hotelFocusOn?.databricksId, ...hotelListIds]);
      }
    } else if (enableSecondaryHotelGroups) {
      dimHotelIds = new Set([...groupFocusOnIds, hotelId?.databricksId]);
    } else {
      dimHotelIds = new Set([hotelFocusOn?.databricksId, hotelId?.databricksId]);
    }
    //
    const secondaryHotelIdFilter = getHotelIdSchema(Array.from(dimHotelIds), visualFilters);
    const hotelFilters = [secondaryHotelIdFilter, hotelGroupAFilter, hotelGroup1Filter];

    if (
      targetDateFilter &&
      paceHorizonFilter &&
      pmsSyncFilter &&
      bookingDateFilter &&
      hotelFilters &&
      isForecastFilter &&
      hotelGroupOrderFilter &&
      normalizedFilter
    ) {
      const filterList = [
        targetDateFilter,
        paceHorizonFilter,
        ...pmsSyncFilter,
        bookingDateFilter,
        ...hotelFilters,
        isForecastFilter,
        hotelGroupOrderFilter,
        normalizedFilter,
      ];
      if (
        JSON.stringify(paceHotelVisualFilterList) !== JSON.stringify(filterList)
      ) {
        writeLog('Pace Hotel Filters', filterList);
        dispatch(paceActions.setPaceWidgetHotelFilterList(filterList));
      }
    }
  }, [
    pmsSyncToggle,
    visualFilters,
    targetDate,
    hotelId,
    paceHorizon,
    enableHotelGroups,
    hotelGroupId,
    reportType,
    isSegment,
    bookingDate,
    bookingPaceWindow,
    hotelFocusOn,
    hotelGroupFocusOn,
    enableSecondaryHotelGroups,
    isPaceForecast,
    isDemoUser,
    normalizedViewToggle,
  ]);
};
//
export default usePaceHotelWidget;
