import { createSelector } from '@reduxjs/toolkit';
import { SLICE } from 'modules/dashboard/constants';
import { initialState } from 'modules/dashboard/components/tab-container/pace-segment-tab/slice';
//
const selectDomain = (state) => state[SLICE.PACE_SEGMENT] || initialState;
/**
 * Getting pace cumulative state for pace segment component
 */
export const selectPaceSegmentCumulativeView = createSelector(
  [selectDomain],
  (state) => state?.paceSegmentCumulativeView
);
/**
 * Getting pace widget segment visuals filter list state to pace segment component
 */
export const selectPaceSegmentLagAligned = createSelector(
  [selectDomain],
  (state) => state.paceSegmentLagAligned
);
/**
 * Getting pace widget segment visuals filter list state to pace segment component
 */
export const selectPaceWidgetSegmentFilterList = createSelector(
  [selectDomain],
  (state) => state.paceWidgetSegmentFilterList
);
/**
 * Getting is pace detailed  state for pace segment component
 */
export const selectIsPaceDetailed = createSelector(
  [selectDomain],
  (state) => state?.isPaceDetailed
);
/**
 *  Getting pace widget focus on state to pace segment component
 */
export const selectPaceFocusOn = createSelector([selectDomain], (state) => state.paceFocusOn);
/**
 *  Getting pace widget chart breakdown state to pace segment component
 */
export const selectPaceChartBreakdown = createSelector(
  [selectDomain],
  (state) => state.paceChartBreakdown
);
/**
 *  Getting generated pace segment widget tab url
 */
export const selectPaceSegmentWidgetUrl = createSelector(
  [selectDomain],
  (state) => state.paceSegmentWidgetUrl
);
/**
 *  Getting  revenue is display state to the  component
 */
export const selectRevenueIsDisplay = createSelector(
  [selectDomain],
  (state) => state.revenueIsDisplay
);
/**
 *  Getting  Occupied Rooms is display state to the  component
 */
export const selectOccupiedRoomsIsDisplay = createSelector(
  [selectDomain],
  (state) => state.occupiedRoomsIsDisplay
);
/**
 *  Getting  Adr is display state to the  component
 */
export const selectAdrIsDisplay = createSelector([selectDomain], (state) => state.adrIsDisplay);
/**
 *  Getting  Cxl count is display state to the  component
 */
export const selectCxlIsDisplay = createSelector([selectDomain], (state) => state.cxlIsDisplay);
