import {
  ADMIN_ROLE,
  SUPER_ADMIN_ROLE,
  USER_ROLE,
  DEMO_ROLE,
  PREFERRED_ROLE,
} from 'modules/common/constants/roles';
/**
 * User roles for dropdowns are defined here
 */
const ROLE_NAMES = [
  { label: 'General User', key: USER_ROLE },
  { label: 'Admin User', key: ADMIN_ROLE },
  { label: 'Super Admin User', key: SUPER_ADMIN_ROLE },
  { label: 'Demo User', key: DEMO_ROLE },
  { label: 'Preferred User', key: PREFERRED_ROLE },
];
//
export default ROLE_NAMES;
