/**
 * Define subscribable event names in Ui controller flow
 * events are subscribed and dispatched under following names
 */
const events = {
  STAY_DATE: 'STAY_DATE',
  SET_PREVIOUS_MONTH: 'PREVIOUS_MONTH',
  SET_NEXT_MONTH: 'NEXT_MONTH',
  SET_PREVIOUS_DAY: 'PREVIOUS_DAY',
  SET_NEXT_DAY: 'NEXT_DAY',
  ERROR: 'error',
  SET_HOTEL: 'SET_HOTEL',
  SET_HOTEL_GROUP: 'SET_HOTEL_GROUP',
  SET_PMS_SYNC: 'SET_PMS_SYNC',
  SET_TAB: 'SET_TAB',
  BOOKING_DATE: 'BOOKING_DATE',
  SET_BOOKING_DATE: 'SET_BOOKING_DATE',
  WEEKLY_VIEW: 'WEEKLY_VIEW',
  ALIGNMENT: 'ALIGNMENT',
  SUMMARY_ALIGNMENT: 'SUMMARY_ALIGNMENT',
  TREND_TIME_ALIGNMENT: 'TREND_TIME_ALIGNMENT',
  WEB_CLICK_ALIGNMENT: 'WEB_CLICK_ALIGNMENT',
  IPREFER_ALIGNMENT: 'IPREFER_ALIGNMENT',
  PACE_TIME_ALIGNMENT: 'PACE_TIME_ALIGNMENT',
  PACE_ALIGNMENT: 'PACE_ALIGNMENT',
  TABULAR_ALIGNMENT: 'TABULAR_ALIGNMENT',
  SYMMETRIC_COMPARISON: 'SYMMETRIC_COMPARISON',
  SET_SUMMARY_CUSTOM_BOOKING: 'SET_SUMMARY_CUSTOM_BOOKING',
  SET_WEB_CLICK_CUSTOM_BOOKING: 'SET_WEB_CLICK_CUSTOM_BOOKING',
  SET_TREND_CUSTOM_BOOKING: 'SET_TREND_CUSTOM_BOOKING',
  SET_RELATION_MAP_CUSTOM_BOOKING: 'SET_RELATION_MAP_CUSTOM_BOOKING',
  SET_IPREFER_CUSTOM_BOOKING: 'SET_IPREFER_CUSTOM_BOOKING',
  SET_PACE_CUSTOM_BOOKING: 'SET_PACE_CUSTOM_BOOKING',
  SET_TABULAR_CUSTOM_BOOKING: 'SET_TABULAR_CUSTOM_BOOKING',
  SET_CUMULATIVE_VIEW: 'SET_CUMULATIVE_VIEW',
  SET_SUMMARY_COMPARE_WINDOW: 'SET_SUMMARY_COMPARE_WINDOW',
  SET_TREND_COMPARE_WINDOW: 'SET_TREND_COMPARE_WINDOW',
  SET_WEB_CLICK_COMPARE_WINDOW: 'SET_WEB_CLICK_COMPARE_WINDOW',
  SET_IPREFER_COMPARE_WINDOW: 'SET_IPREFER_COMPARE_WINDOW',
  SET_PACE_TIME_COMPARE_WINDOW: 'SET_PACE_TIME_COMPARE_WINDOW',
  SET_PACE_COMPARE_WINDOW: 'SET_PACE_COMPARE_WINDOW',
  SET_TABULAR_COMPARE_WINDOW: 'SET_TABULAR_COMPARE_WINDOW',
  SET_COMPARE_WINDOW: 'SET_COMPARE_WINDOW',
  SET_SUMMARY_PACE_HORIZON: 'SET_SUMMARY_PACE_HORIZON',
  SET_PACE_TIME_PACE_HORIZON: 'SET_PACE_TIME_PACE_HORIZON',
  SET_PACE_SEGMENT_PACE_HORIZON: 'SET_PACE_SEGMENT_PACE_HORIZON',
  SET_PLOT_PACE_HORIZON: 'SET_PLOT_PACE_HORIZON',
  SET_WEB_CLICK_PACE_HORIZON: 'SET_WEB_CLICK_PACE_HORIZON',
  SET_PACE_PACE_HORIZON: 'SET_PACE_PACE_HORIZON',
  SET_PACE_HORIZON: 'SET_PACE_HORIZON',
  SET_SUMMARY_PACE_VARIABLE: 'SET_SUMMARY_PACE_VARIABLE',
  SET_PACE_TIME_PACE_VARIABLE: 'SET_PACE_TIME_PACE_VARIABLE',
  SET_PLOT_PACE_VARIABLE: 'SET_PLOT_PACE_VARIABLE',
  SET_PACE_VARIABLE: 'SET_PACE_VARIABLE',
  SET_BREAKDOWN: 'SET_BREAKDOWN',
  SET_SUMMARY_BREAKDOWN: 'SET_SUMMARY_BREAKDOWN',
  SET_SEGMENT_BREAKDOWN: 'SET_SEGMENT_BREAKDOWN',
  SET_IPREFER_BREAKDOWN: 'SET_IPREFER_BREAKDOWN',
  SET_PACE_SEGMENT_BREAKDOWN: 'SET_PACE_SEGMENT_BREAKDOWN',
  SET_PACE_BREAKDOWN: 'SET_PACE_BREAKDOWN',
  SET_TABULAR_BREAKDOWN: 'SET_TABULAR_BREAKDOWN',
  SET_REGINAL_MAP_BREAKDOWN: 'SET_REGINAL_MAP_BREAKDOWN',
  SET_COMPARE_HOTELS: 'SET_COMPARE_HOTELS',
  SET_TREND_HOTELS_COMPARE_HOTELS: 'SET_TREND_HOTELS_COMPARE_HOTELS',
  SET_WEB_CLICK_COMPARE_HOTELS: 'SET_WEB_CLICK_COMPARE_HOTELS',
  SET_SUMMARY_COMPARE_HOTELS: 'SET_SUMMARY_COMPARE_HOTELS',
  SET_PACE_COMPARE_HOTELS: 'SET_PACE_COMPARE_HOTELS',
  SET_FOCUS_ON: 'SET_FOCUS_ON',
  SET_REGIONAL_MAP_FOCUS_ON: 'SET_REGIONAL_MAP_FOCUS_ON',
  SET_TREND_SEGMENT_FOCUS_ON: 'SET_TREND_SEGMENT_FOCUS_ON',
  SET_WEB_CLICK_FOCUS_ON: 'SET_WEB_CLICK_FOCUS_ON',
  SET_IPREFER_FOCUS_ON: 'SET_IPREFER_FOCUS_ON',
  SET_PACE_SEGMENT_FOCUS_ON: 'SET_PACE_SEGMENT_FOCUS_ON',
  SET_PACE_FOCUS_ON: 'SET_PACE_FOCUS_ON',
  SET_TABULAR_FOCUS_ON: 'SET_TABULAR_FOCUS_ON',
  SET_NORMALIZED_VIEW: 'SET_NORMALIZED_VIEW',
  SET_TREND_HOTEL_NORMALIZED_VIEW: 'SET_TREND_HOTEL_NORMALIZED_VIEW',
  SET_SUMMARY_NORMALIZED_VIEW: 'SET_SUMMARY_NORMALIZED_VIEW',
  SET_WEB_CLICK_NORMALIZED_VIEW: 'SET_WEB_CLICK_NORMALIZED_VIEW',
  SET_PACE_NORMALIZED_VIEW: 'SET_PACE_NORMALIZED_VIEW',
  SET_WEB_CLICK_STEPS: 'SET_WEB_CLICK_STEPS',
  SET_WEB_CLICK_SCALE: 'SET_WEB_CLICK_SCALE',
  SET_CUSTOM_DATE_RANGE: 'SET_CUSTOM_DATE_RANGE',
  SET_CUSTOM_DATE_RANGE_VALUE: 'SET_CUSTOM_DATE_RANGE_VALUE',
  SET_SUMMARY_CUSTOM_DATE_RANGE: 'SET_SUMMARY_CUSTOM_DATE_RANGE',
  SET_TREND_TIME_CUSTOM_DATE_RANGE: 'SET_TREND_TIME_CUSTOM_DATE_RANGE',
  SET_WEB_CLICK_CUSTOM_DATE_RANGE: 'SET_WEB_CLICK_CUSTOM_DATE_RANGE',
  SET_IPREFER_CUSTOM_DATE_RANGE: 'SET_IPREFER_CUSTOM_DATE_RANGE',
  SET_TABULAR_CUSTOM_DATE_RANGE: 'SET_TABULAR_CUSTOM_DATE_RANGE',
  SET_CUSTOM_AGGREGATION_SET_A: 'SET_CUSTOM_AGGREGATION_SET_A',
  SET_CUSTOM_AGGREGATION_SET_B: 'SET_CUSTOM_AGGREGATION_SET_B',
  SET_CUSTOM_AGGREGATION_A_VALUE: 'SET_CUSTOM_AGGREGATION_A_VALUE',
  SET_CUSTOM_AGGREGATION_B_VALUE: 'SET_CUSTOM_AGGREGATION_B_VALUE',
  SET_CUSTOM_AGGREGATION: 'SET_CUSTOM_AGGREGATION',
  SET_TABULAR_VIEW: 'SET_TABULAR_VIEW',
  SET_TREND_TIME_TABULAR_VIEW: 'SET_TREND_TIME_TABULAR_VIEW',
  SET_TREND_SEGMENT_TABULAR_VIEW: 'SET_TREND_SEGMENT_TABULAR_VIEW',
  SET_TREND_HOTEL_TABULAR_VIEW: 'SET_TREND_HOTELS_TABULAR_VIEW',
  SET_REGIONAL_MAP_TABULAR_VIEW: 'SET_REGIONAL_MAP_TABULAR_VIEW',
  SET_METRIC_SELECTION: 'SET_METRIC_SELECTION',
  SET_IPREFER_METRIC_SELECTION: 'SET_IPREFER_METRIC_SELECTION',
  SET_LAG_ALIGNED: 'SET_LAG_ALIGNED',
  SET_PACE_TIME_LAG_ALIGNED: 'SET_PACE_TIME_LAG_ALIGNED',
  SET_PACE_SEGMENT_LAG_ALIGNED: 'SET_PACE_SEGMENT_LAG_ALIGNED',
  SET_PACE_LAG_ALIGNED: 'SET_PACE_LAG_ALIGNED',
  SET_BOOKING_FORECAST: 'SET_BOOKING_FORECAST',
  SET_PACE_TIME_BOOKING_FORECAST: 'SET_PACE_TIME_BOOKING_FORECAST',
  SET_PERCENTAGE: 'SET_PERCENTAGE',
  SET_PACE_TIME_PERCENTAGE: 'SET_PACE_TIME_PERCENTAGE',
  SET_PACE_PERCENTAGE: 'SET_PACE_PERCENTAGE',
  SET_TIME_FRAME: 'SET_TIME_FRAME',
  SET_IPREFER_TIME_FRAME: 'SET_IPREFER_TIME_FRAME',
  SET_EXTENDING_FORECAST: 'SET_EXTENDING_FORECAST',
  SET_EXTENDING_SUMMARY_FORECAST: 'SET_EXTENDING_SUMMARY_FORECAST',
  SET_SYMMETRIC_COMPARISON_COLLAPSE: 'SET_SYMMETRIC_COMPARISON_COLLAPSE',
  SET_SUMMARY_SYMMETRIC_COMPARISON_COLLAPSE: 'SET_SUMMARY_SYMMETRIC_COMPARISON_COLLAPSE',
  SET_TREND_SYMMETRIC_COMPARISON_COLLAPSE: 'SET_TREND_SYMMETRIC_COMPARISON_COLLAPSE',
  SET_PACE_SYMMETRIC_COMPARISON_COLLAPSE: 'SET_PACE_SYMMETRIC_COMPARISON_COLLAPSE',
  SET_WEB_CLICK_SYMMETRIC_COMPARISON_COLLAPSE: 'SET_WEB_CLICK_SYMMETRIC_COMPARISON_COLLAPSE',
  SET_TABULAR_SYMMETRIC_COMPARISON_COLLAPSE: 'SET_TABULAR_SYMMETRIC_COMPARISON_COLLAPSE',
  SET_VISUAL_COLLAPSE: 'SET_VISUAL_COLLAPSE',
  SET_SUMMARY_VISUAL_COLLAPSE: 'SET_SUMMARY_VISUAL_COLLAPSE',
  SET_TREND_VISUAL_COLLAPSE: 'SET_TREND_VISUAL_COLLAPSE',
  SET_WORKBOOK_VISUAL_COLLAPSE: 'SET_WORKBOOK_VISUAL_COLLAPSE',
  SET_PACE_VISUAL_COLLAPSE: 'SET_PACE_VISUAL_COLLAPSE',
  SET_WEB_CLICK_VISUAL_COLLAPSE: 'SET_WEB_CLICK_VISUAL_COLLAPSE',
  SET_TABULAR_VISUAL_COLLAPSE: 'SET_TABULAR_VISUAL_COLLAPSE',
  SET_FORECAST: 'SET_FORECAST',
  SET_TREND_FORECAST: 'SET_TREND_FORECAST',
  SET_PACE_FORECAST: 'SET_PACE_FORECAST',
  SET_SUMMARY_FORECAST: 'SET_SUMMARY_FORECAST',
  SET_SECONDARY_HOTEL_GROUP: 'SET_SECONDARY_HOTEL_GROUP',
  SET_TREND_HOTEL_GROUP: 'SET_TREND_HOTEL_GROUP',
  SET_SUMMARY_HOTEL_GROUP: 'SET_SUMMARY_HOTEL_GROUP',
  SET_WEB_CLICK_HOTEL_GROUP: 'SET_WEB_CLICK_HOTEL_GROUP',
  SET_PACE_HOTEL_GROUP: 'SET_PACE_HOTEL_GROUP',
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
  SET_TREND_ACTIVE_TAB: 'SET_TREND_ACTIVE_TAB',
  SET_PACE_ACTIVE_TAB: 'SET_PACE_ACTIVE_TAB',
  SET_WORKBOOK_CUSTOM_BOOKING: 'SET_WORKBOOK_CUSTOM_BOOKING',
  SET_WORKBOOK_COMPARE_WINDOW: 'SET_WORKBOOK_COMPARE_WINDOW',
  SET_WORKBOOK_ALIGNMENT: 'SET_WORKBOOK_ALIGNMENT',
  SET_WORKBOOK_SYMMETRIC_COMPARISON_COLLAPSE: 'SET_WORKBOOK_SYMMETRIC_COMPARISON_COLLAPSE',
  SET_WORKBOOK_CUSTOM_DATE_RANGE: 'SET_WORKBOOK_CUSTOM_DATE_RANGE',
  SET_BREAKDOWN_TOGGLE: 'SET_BREAKDOWN_TOGGLE',
  SET_TREND_BREAKDOWN_TOGGLE: 'SET_TREND_BREAKDOWN_TOGGLE',
  SET_PACE_BREAKDOWN_TOGGLE: 'SET_PACE_BREAKDOWN_TOGGLE',
  SET_PERCENTAGE_TOGGLE: 'SET_PERCENTAGE_TOGGLE',
  SET_TREND_PERCENTAGE_TOGGLE: 'SET_TREND_PERCENTAGE_TOGGLE',
  SET_SEGMENT_BREAKDOWN_TOGGLE: 'SET_SEGMENT_BREAKDOWN_TOGGLE',
  SET_TREND_SEGMENT_BREAKDOWN_TOGGLE: 'SET_TREND_SEGMENT_BREAKDOWN_TOGGLE',
  SET_UTM_BREAKDOWN: 'SET_UTM_BREAKDOWN',
  SET_UTM_FOCUS_ON: 'SET_UTM_FOCUS_ON',
  SET_WEB_CLICK_UTM_BREAKDOWN: 'SET_WEB_CLICK_UTM_BREAKDOWN',
  SET_WEB_CLICK_UTM_FOCUS_ON: 'SET_WEB_CLICK_UTM_FOCUS_ON',
  SET_BOOKING_PACE_WINDOW: 'SET_BOOKING_PACE_WINDOW',
  SET_PACE_BOOKING_PACE_WINDOW: 'SET_PACE_BOOKING_PACE_WINDOW',
};
//
export default events;
