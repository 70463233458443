import { createSelector } from '@reduxjs/toolkit';
import { initialState } from 'modules/dashboard/slice';
import { SLICE } from 'modules/dashboard/constants';
//
const selectDomain = (state) => state[SLICE.WEB_CLICK] || initialState;
/**
 * Getting web click booking date state to dashboard component
 */
export const selectWebClickBookingDate = createSelector(
  [selectDomain],
  (state) => state.webClickBookingDate
);
/**
 * Getting web click otb state for dashboard component
 */
export const selectWebClickOtb = createSelector([selectDomain], (state) => state?.webClickOtb);
/**
 *  Getting web click widget alignment toggle state to dashboard component
 */
export const selectWebClickAlignmentToggle = createSelector(
  [selectDomain],
  (state) => state.webClickAlignmentToggle
);
/**
 * Getting web click alignment state to dashboard component
 */
export const selectWebClickAlignment = createSelector(
  [selectDomain],
  (state) => state.webClickAlignment
);
/**
 * Getting web click date comparison state to dashboard component
 */
export const selectWebClickDateComparison = createSelector(
  [selectDomain],
  (state) => state.webClickDateComparison
);
/**
 * Getting web click symmetric comparison toggle state for dashboard component
 */
export const selectWebClickSymmetricComparisonToggle = createSelector(
  [selectDomain],
  (state) => state?.webClickSymmetricComparisonToggle
);
/**
 * Getting web click target date state to dashboard component
 */
export const selectWebClickTargetDate = createSelector(
  [selectDomain],
  (state) => state.webClickTargetDate
);
/**
 *  Getting web click pace horizon state to dashboard component
 */
export const selectWebClickPaceHorizon = createSelector(
  [selectDomain],
  (state) => state.webClickPaceHorizon
);
/**
 *Getting web click normalized view state for dashboard component
 */
export const selectWebClickNormalizedView = createSelector(
  [selectDomain],
  (state) => state?.webClickNormalizedView
);
/**
 *Getting is web click start date state for dashboard component for custom date comparison
 */
export const selectWebClickStartDate = createSelector(
  [selectDomain],
  (state) => state?.webClickStartDate
);
/**
 *Getting is  web click  end date state for dashboard component for custom date comparison
 */
export const selectWebClickEndDate = createSelector(
  [selectDomain],
  (state) => state?.webClickEndDate
);
/**
 *Getting is  web click  date selected state for dashboard component for custom date comparison
 */
export const selectWebClickDateSelected = createSelector(
  [selectDomain],
  (state) => state?.webClickDateSelected
);
/**
 *Getting is web click   custom date state for dashboard component
 */
export const selectWebClickCustomDate = createSelector(
  [selectDomain],
  (state) => state?.webClickCustomDate
);

/**
 * Getting web click chart breakdown state to dashboard component
 */
export const selectWebClickBreakdown = createSelector(
  [selectDomain],
  (state) => state.webClickChartBreakdown
);
/**
 *  Getting web click breakdown focus on state to dashboard component
 */
export const selectWebClickFocusOn = createSelector(
  [selectDomain],
  (state) => state.webClickFocusOn
);
/**
 *  Getting web click hotel focus on state to dashboard component
 */
export const selectWebClickHotelFocusOn = createSelector(
  [selectDomain],
  (state) => state.webClickHotelFocusOn
);
/**
 *  Getting web click scaling factor state to dashboard component
 */
export const selectWebClickScalingFactor = createSelector(
  [selectDomain],
  (state) => state.webClickScalingFactor
);
/**
 *  Getting web click step state to dashboard component
 */
export const selectWebClickStep = createSelector([selectDomain], (state) => state.webClickStep);

/**
 *  Getting web click time pace visual filter list state to dashboard component
 */
export const selectWebClickTimePaceVisualFilter = createSelector(
  [selectDomain],
  (state) => state.webClickTimePaceVisualFilter
);
/**
 *  Getting web click hotel pace visual filter list state to dashboard component
 */
export const selectWebClickHotelPaceVisualFilter = createSelector(
  [selectDomain],
  (state) => state.webClickHotelPaceVisualFilter
);
/**
 *  Getting web click segment pace visual filter list state to dashboard component
 */
export const selectWebClickSegmentPaceVisualFilter = createSelector(
  [selectDomain],
  (state) => state.webClickSegmentPaceVisualFilter
);
/**
 *  Getting web click hotel visual filter list state to dashboard component
 */
export const selectWebClickHotelVisualFilter = createSelector(
  [selectDomain],
  (state) => state.webClickHotelVisualFilter
);
/**
 *  Getting web click time visual filter list state to dashboard component
 */
export const selectWebClickTimeVisualFilter = createSelector(
  [selectDomain],
  (state) => state.webClickTimeVisualFilter
);
/**
 *  Getting web click segment visual filter list state to dashboard component
 */
export const selectWebClickSegmentVisualFilter = createSelector(
  [selectDomain],
  (state) => state.webClickSegmentVisualFilter
);
/**
 * Getting web click weekly view state for dashboard component
 */
export const selectWebClickWeeklyView = createSelector(
  [selectDomain],
  (state) => state.webClickWeeklyView
);
/**
 * Getting web click cumulative state for dashboard component
 */
export const selectWebClickCumulativeView = createSelector(
  [selectDomain],
  (state) => state?.webClickCumulativeView
);
/**
 *  Getting weekly web click start target date state to dashboard component
 */
export const selectWeeklyWebClickStartDate = createSelector(
  [selectDomain],
  (state) => state.weeklyWebClickStartDate
);
/**
 *  Getting weekly web click end target start date state to dashboard component
 */
export const selectWeeklyWebClickEndDate = createSelector(
  [selectDomain],
  (state) => state.weeklyWebClickEndDate
);
/**
 * Getting selected utm breakdown state to dashboard component
 */
export const selectUtmBreakdownSelection = createSelector(
  [selectDomain],
  (state) => state.selectedUtmBreakdown
);
/**
 *  Getting utm breakdown focus on state to dashboard component
 */
export const selectUtmFocusOn = createSelector([selectDomain], (state) => state.utmFocusOn);
/**
 *  Getting utm filter list state to dashboard component
 */
export const selectUtmFilterList = createSelector([selectDomain], (state) => state.utmFilterList);

/**
 *  Getting generated web click tab url
 */
export const selectWebClickUrl = createSelector([selectDomain], (state) => state.webClickUrl);
/**
 *  Getting time trend is display status
 */
export const selectTimeTrendIsDisplay = createSelector(
  [selectDomain],
  (state) => state.timeTrendIsDisplay
);
/**
 *  Getting time pace is display status
 */
export const selectTimePaceIsDisplay = createSelector(
  [selectDomain],
  (state) => state.timePaceIsDisplay
);
/**
 *  Getting segment trend is display status
 */
export const selectSegmentTrendIsDisplay = createSelector(
  [selectDomain],
  (state) => state.segmentTrendIsDisplay
);
/**
 *  Getting segment pace is display status
 */
export const selectSegmentPaceIsDisplay = createSelector(
  [selectDomain],
  (state) => state.segmentPaceIsDisplay
);
/**
 * Getting hotel trend is display status
 */
export const selectHotelTrendIsDisplay = createSelector(
  [selectDomain],
  (state) => state.hotelTrendIsDisplay
);
/**
 * Getting hotel pace is display status
 */
export const selectHotelPaceIsDisplay = createSelector(
  [selectDomain],
  (state) => state.hotelPaceIsDisplay
);
/**
 * Getting utm trend is display status
 */
export const selectUtmTrendIsDisplay = createSelector(
  [selectDomain],
  (state) => state.utmTrendIsDisplay
);
/**
 * Getting utm pace is display status
 */
export const selectUtmPaceIsDisplay = createSelector(
  [selectDomain],
  (state) => state.utmPaceIsDisplay
);
/**
 * Getting symmetric comparison display action to the component
 */
export const selectSymmetricComparisonIsDisplay = createSelector(
  [selectDomain],
  (state) => state.symmetricComparisonIsDisplay
);
/**
 *  Getting selected enable secondary hotel state to web click component
 */
export const selectWebClickEnableSecondaryHotelGroups = createSelector(
  [selectDomain],
  (state) => state.webClickEnableSecondaryHotelGroups
);
/**
 *  Getting  hotel group focus on state to web click  component
 */
export const selectWebClickHotelGroupFocusOn = createSelector(
  [selectDomain],
  (state) => state.webClickHotelGroupFocusOn
);
