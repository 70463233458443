import { CSVLink } from 'react-csv';
import { useState, useEffect, useRef } from 'react';
import { models } from 'powerbi-client';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/common/auth/selectors';
import {
  selectVisualFilters,
  selectHotelName,
  selectTargetDate,
  selectPmsSync,
  selectEnableHotelGroups,
  selectSelectedHotelGroupName,
} from 'modules/dashboard/selectors';
import { isEmpty } from 'modules/common/helpers/object';
import { formatDate, getSelectedBreakdown } from 'modules/dashboard/functions';
import { DATE_PATTERN, TIME_PATTERN } from 'modules/common/constants/date-range';
import { IconButton, Typography } from '@mui/material';
import { Loader } from 'modules/common/components';
import DownloadIcon from '@mui/icons-material/Download';
import NoDataDialog from 'modules/dashboard/components/tab-container/regional-map-tab/components/widget-pane/no-data-dialog';
import {
  selectRegionMapFocusOn,
  selectRegionMapChartBreakdown,
  selectIsRegionDetailed,
  selectRegionMapBookingDate,
} from 'modules/dashboard/components/tab-container/regional-map-tab/selectors';

/**
 * Export Data component to download regional map data in csv format
 * @param {Object} regionalMapReport - Regional map report details
 * @param {Boolean} isLoaded - Regional map visual loaded state
 * @returns
 */
const ExportData = ({ regionalMapReport, isLoaded }) => {
  const csvLink = useRef();
  // States are obtained here from the redux store. Refer each selector function for details
  const currentUser = useSelector(selectAuthUser);
  const selectedHotel = useSelector(selectHotelName);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const focusOn = useSelector(selectRegionMapFocusOn);
  const chartBreakdown = useSelector(selectRegionMapChartBreakdown);
  const bookingDate = useSelector(selectRegionMapBookingDate);
  const targetDate = useSelector(selectTargetDate);
  const visualFilters = useSelector(selectVisualFilters);
  const isDetailed = useSelector(selectIsRegionDetailed);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const hotelGroup = useSelector(selectSelectedHotelGroupName);
  //
  const [visualData, setVisualData] = useState([]);
  const [initiateDownload, setInitiateDownload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showInvite, setShowInvite] = useState(false);

  // Triggered when visualData changes
  useEffect(() => {
    // Initiate download when visual data is set
    if (visualData?.length) {
      setInitiateDownload(true);
    }
  }, [visualData]);

  // Triggered when initiateDownload value changes
  useEffect(() => {
    // Reset initiate download value after download initiation
    if (initiateDownload) {
      setInitiateDownload(false);
    }
  }, [initiateDownload]);

  // Triggered when initiateDownload or visualData values change
  useEffect(() => {
    // Create download link
    if (initiateDownload) {
      csvLink.current.link.click();
      setInitiateDownload(false);
    }
    setLoading(false);
  }, [initiateDownload, visualData]);

  // Obtain visual data and format to export
  const exportVisualData = async () => {
    if (!isEmpty(regionalMapReport) && isLoaded) {
      const selectedBreakdown = getSelectedBreakdown(visualFilters, chartBreakdown, isDetailed);
      // Get visual data from report instance
      const pages = await regionalMapReport.getPages();
      const page = pages.filter((p) => p.isActive)[0];
      const visuals = await page.getVisuals();
      const visual = visuals.filter((v) => v.name === visuals[0]?.name)[0];
      const result = await visual.exportData(models.ExportDataType.Summarized);
      // Format obtained data to download
      if (result?.data?.length > 0) {
        const dataArray = String(result?.data).split('\r\n');
        const header = dataArray[0];
        const mapData = dataArray
          .slice(1)
          .map((data) => String(data).split(','))
          .filter((data) => data[0] !== '');
        if (isEmpty(mapData)) {
          setLoading(false);
          setInitiateDownload(false);
          setShowInvite(true);
        } else {
          const regionalMapData = mapData.join('\r\n');
          const username = `Username : , ${currentUser?.username}\r\n`;
          const reportGenerationDate = `Report Generation Date : ,"${formatDate(
            new Date(),
            DATE_PATTERN
          )}, ${formatDate(new Date(), TIME_PATTERN)}"\r\n`;
          const hotel = enableHotelGroups
            ? `Hotel Set: , "${hotelGroup?.label}"\r\n`
            : `Hotel: , "${selectedHotel?.label}"\r\n`;
          const stayDateRange = `Stay Date Range :,${formatDate(
            targetDate.startDate,
            DATE_PATTERN
          )} to ${formatDate(targetDate.endDate, DATE_PATTERN)}\r\n`;
          const pmsSync = `PMS Sync : , ${pmsSyncToggle ? 'On' : 'Off'}\r\n`;
          const bookingDateRange = `Booking Date Range : ,${formatDate(
            bookingDate.startDate,
            DATE_PATTERN
          )} to ${formatDate(bookingDate.endDate, DATE_PATTERN)}\r\n`;
          const breakdown = `Breakdown : , ${selectedBreakdown}\r\n`;
          const focus = focusOn ? focusOn?.map((item) => item?.label).toString() : '';
          const focusedBreakdown = `Focused Breakdown Segments: , "${focus.toString()}"\r\n`;
          setVisualData(
            username.concat(
              reportGenerationDate,
              hotel,
              stayDateRange,
              pmsSync,
              bookingDateRange,
              breakdown,
              focusedBreakdown,
              '\r\n',
              header,
              '\r\n',
              regionalMapData
            )
          );
        }
      }
    }
  };
  //
  return (
    <Loader loading={loading}>
      <Typography
        sx={{
          mr: 2,
        }}
      >
        Export Data
      </Typography>
      <IconButton
        size="small"
        onClick={() => {
          exportVisualData();
          setLoading(true);
        }}
        sx={{
          '& .MuiSvgIcon-root': {
            width: '1.5rem',
            height: '1.25rem',
            marginRight: 2,
          },
        }}
      >
        <DownloadIcon />
      </IconButton>
      <CSVLink
        asyncOnClick
        filename={`RegionalMap_${formatDate(new Date(), DATE_PATTERN)}`}
        data={visualData}
        target="_self"
        ref={csvLink}
        sx={{ display: 'none' }}
      />
      <NoDataDialog open={showInvite} onClose={() => setShowInvite(false)} />
    </Loader>
  );
};
//
export default ExportData;
