import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectVisualFilters,
  selectPmsSync,
  selectTargetDate,
  selectPaceHorizon,
  selectPaceVariable,
  selectHotelName,
  selectForecastFilter,
  selectSelectedHotelGroupName,
  selectEnableHotelGroups,
  selectReportType,
} from 'modules/dashboard/selectors';
import { PACE_HORIZON_SELECTION, REPORT_TYPE, TAGS } from 'modules/dashboard/constants';
import {
  getAlignmentAndDateComparisonSchema,
  getBookingDateSchema,
  getHotelIdSchema,
  getIsForecastSchema,
  getPaceVariableSchema,
  getPaceWidgetPaceHorizonSchema,
  getPmsForecastSchema,
  getPmsSyncSchema,
  getRevenueForecastSchema,
  getRoomForecastSchema,
  // getSymmetricComparisonSchema,
  getTargetDateSchema,
  percentageSchema,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import {
  selectIsPrimary,
  selectIsForecast,
  selectPaceAlignment,
  selectPaceAlignmentToggle,
  selectPaceDateComparison,
  // selectPaceSymmetricComparisonToggle,
  selectPaceTimeOccupiedRoomFilterList,
  selectPaceTimeRevenueFilterList,
  selectPercentage,
  selectPaceBookingPaceWindow,
  selectPaceBookingDate,
} from 'modules/dashboard/components/tab-container/pace-tabs/selectors';
import { paceActions } from 'modules/dashboard/components/tab-container/pace-tabs/slice';
import writeLog from 'modules/common/utils/filter-log';

/**
 * Custom hook to generate summary widget filter schemas
 */
const usePaceTimeWidget = () => {
  const dispatch = useDispatch();
  //
  const targetDate = useSelector(selectTargetDate);
  const hotelId = useSelector(selectHotelName);
  const visualFilters = useSelector(selectVisualFilters);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const paceHorizon = useSelector(selectPaceHorizon);
  const paceVariable = useSelector(selectPaceVariable);
  const forecastFilter = useSelector(selectForecastFilter);
  const bookingDate = useSelector(selectPaceBookingDate);
  const dateComparison = useSelector(selectPaceDateComparison);
  const percentage = useSelector(selectPercentage);
  // const symmetricComparisonToggle = useSelector(selectPaceSymmetricComparisonToggle);
  const alignment = useSelector(selectPaceAlignment);
  const alignmentToggle = useSelector(selectPaceAlignmentToggle);
  const hotelGroupId = useSelector(selectSelectedHotelGroupName);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const reportType = useSelector(selectReportType);
  const isSegment = useSelector(selectIsPrimary);
  const revenueFilterList = useSelector(selectPaceTimeRevenueFilterList);
  const occupiedRoomsFilterList = useSelector(selectPaceTimeOccupiedRoomFilterList);
  const isPaceForecast = useSelector(selectIsForecast);
  const bookingPaceWindow = useSelector(selectPaceBookingPaceWindow);

  // Generate filter list for pace visual with forecast
  useEffect(() => {
    if (
      reportType !== REPORT_TYPE.PACE ||
      isSegment ||
      !dateComparison ||
      !alignment ||
      !bookingDate ||
      !hotelId ||
      !visualFilters
    )
      return;
    const targetDateFilter = getTargetDateSchema(
      visualFilters,
      targetDate?.startDate,
      targetDate?.endDate
    );
    const bookingDateFilter = getBookingDateSchema(
      visualFilters,
      bookingDate?.startDate,
      bookingDate?.endDate
    );

    let hotelIdFilter = [];
    if (enableHotelGroups) {
      const hotelListIds = hotelGroupId?.hotels?.map((hotel) => hotel?.databricksId);
      hotelIdFilter = getHotelIdSchema(hotelListIds, visualFilters);
    } else {
      hotelIdFilter = getHotelIdSchema(hotelId?.databricksId, visualFilters);
    }
    const pmsSyncForecastFilter = getPmsForecastSchema(visualFilters, pmsSyncToggle);
    const paceHorizonFilter = getPaceWidgetPaceHorizonSchema(
      visualFilters,
      paceHorizon,
      bookingPaceWindow === PACE_HORIZON_SELECTION.BOOKING_PACE_HORIZON
    );
    const paceVariableFilter = getPaceVariableSchema(
      visualFilters,
      paceVariable,
      TAGS.PACE_VARIABLE
    );
    // const isForecastFilter = getIsForecastSchema(visualFilters, isPaceForecast);
    if (
      targetDateFilter &&
      hotelIdFilter &&
      paceHorizonFilter &&
      paceVariableFilter &&
      bookingDateFilter
      // isForecastFilter
    ) {
      dispatch(
        paceActions.setPaceWidgetFilterList([
          targetDateFilter,
          hotelIdFilter,
          paceHorizonFilter,
          paceVariableFilter,
          // isForecastFilter,
          ...pmsSyncForecastFilter,
          bookingDateFilter,
        ])
      );
    }
  }, [
    pmsSyncToggle,
    visualFilters,
    forecastFilter,
    targetDate,
    hotelId,
    paceHorizon,
    paceVariable,
    hotelGroupId,
    enableHotelGroups,
    isSegment,
    reportType,
    isPaceForecast,
    bookingDate,
    bookingPaceWindow
  ]);
  // Generate filter list for pace time with forecast
  useEffect(() => {
    if (
      reportType !== REPORT_TYPE.PACE ||
      isSegment ||
      !dateComparison ||
      !alignment ||
      !paceHorizon ||
      !hotelId ||
      !visualFilters
    )
      return;
    const targetDateFilter = getTargetDateSchema(
      visualFilters,
      targetDate?.startDate,
      targetDate?.endDate
    );
    const bookingDateFilter = getBookingDateSchema(
      visualFilters,
      bookingDate?.startDate,
      bookingDate?.endDate
    );
    const paceTimeRevenueVariableFilter = getPaceVariableSchema(
      visualFilters,
      'Revenue',
      TAGS.SUMMARY_PACE_VARIABLE
    );
    const paceTimeOccupiedRoomsVariableFilter = getPaceVariableSchema(
      visualFilters,
      'Occupied Rooms',
      TAGS.SUMMARY_PACE_VARIABLE
    );
    const roomForecastFilter = getRoomForecastSchema(visualFilters, pmsSyncToggle);
    const revenueForecastFilter = getRevenueForecastSchema(visualFilters, pmsSyncToggle);
    const percentageFilter = percentageSchema(visualFilters, percentage);
    let hotelIdFilter = [];
    if (enableHotelGroups) {
      const hotelListIds = hotelGroupId?.hotels?.map((hotel) => hotel?.databricksId);
      hotelIdFilter = getHotelIdSchema(hotelListIds, visualFilters);
    } else {
      hotelIdFilter = getHotelIdSchema(hotelId?.databricksId, visualFilters);
    }
    const alignmentAndDateComparisonFilter = getAlignmentAndDateComparisonSchema(
      visualFilters,
      alignment?.id,
      dateComparison
    );
    // const symmetricComparisonFilter = getSymmetricComparisonSchema(
    //   visualFilters,
    //   symmetricComparisonToggle
    // );
    const paceHorizonFilter = getPaceWidgetPaceHorizonSchema(
      visualFilters,
      paceHorizon,
      bookingPaceWindow === PACE_HORIZON_SELECTION.BOOKING_PACE_HORIZON
    );
    const paceVariableFilter = getPaceVariableSchema(
      visualFilters,
      paceVariable,
      TAGS.PACE_VARIABLE
    );
    const pmsSyncFilter = getPmsSyncSchema(visualFilters, pmsSyncToggle, TAGS.PACE_WIDGET);
    const isForecastFilter = getIsForecastSchema(visualFilters, isPaceForecast);
    if (
      paceTimeRevenueVariableFilter &&
      roomForecastFilter &&
      revenueForecastFilter &&
      paceTimeOccupiedRoomsVariableFilter &&
      percentageFilter &&
      targetDateFilter &&
      hotelIdFilter &&
      paceHorizonFilter &&
      paceVariableFilter &&
      alignmentAndDateComparisonFilter &&
      // symmetricComparisonFilter &&
      pmsSyncFilter &&
      isForecastFilter &&
      bookingDateFilter
    ) {
      const commonFilters = [
        roomForecastFilter,
        revenueForecastFilter,
        percentageFilter,
        ...alignmentAndDateComparisonFilter,
        targetDateFilter,
        //  symmetricComparisonFilter,
        hotelIdFilter,
        paceHorizonFilter,
        paceVariableFilter,
        ...pmsSyncFilter,
        isForecastFilter,
        bookingDateFilter,
      ];
      //
      const paceTimeRevenueFilterList = [paceTimeRevenueVariableFilter, ...commonFilters];
      const paceTimeOccupiedFilterList = [paceTimeOccupiedRoomsVariableFilter, ...commonFilters];
      if (JSON.stringify(revenueFilterList) !== JSON.stringify(paceTimeRevenueFilterList)) {
        writeLog('Pace Time Revenue Filters', paceTimeRevenueFilterList);
        dispatch(paceActions.setPaceTimeRevenueFilterList(paceTimeRevenueFilterList));
      }
      if (JSON.stringify(occupiedRoomsFilterList) !== JSON.stringify(paceTimeOccupiedFilterList)) {
        writeLog('Pace Time Occupied Rooms Filters', paceTimeOccupiedFilterList);
        dispatch(paceActions.setPaceTimeOccupiedFilterList(paceTimeOccupiedFilterList));
      }
    }
  }, [
    pmsSyncToggle,
    visualFilters,
    forecastFilter,
    targetDate,
    hotelId,
    paceHorizon,
    paceVariable,
    percentage,
    alignment,
    // symmetricComparisonToggle,
    dateComparison,
    alignmentToggle,
    enableHotelGroups,
    hotelGroupId,
    reportType,
    isSegment,
    isPaceForecast,
    bookingDate,
    bookingPaceWindow
  ]);
};
//
export default usePaceTimeWidget;
