/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect, useRef } from 'react';
import { Grid, Tabs, Tab, Fab, Link } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardActions } from 'modules/dashboard/slice';
import {
  selectEmbedToken,
  selectVisuals,
  selectLatestUpdatedHotelsList,
  selectReportType,
  selectIsSignedIn,
  selectHotelName,
  selectTargetDate,
  selectPmsDate,
  selectTrendBookingDate,
  selectTrendTargetDate,
  selectGroupPmsDates,
  selectEnableHotelGroups,
  selectSelectedHotelGroupName,
  selectHotelGroupDropdownList,
  selectNotifications,
} from 'modules/dashboard/selectors';
import { usersActions } from 'modules/users/slice';
import { selectSummaryBookingDate } from 'modules/dashboard/components/tab-container/summary-tab/selectors';
import { MessageSquare } from 'react-feather';
import { selectAuthUser, selectOrganizationId } from 'modules/common/auth/selectors';
import UseTabContainer from 'modules/dashboard/hooks/use-tab-container';
import PAGINATION from 'modules/common/constants/pagination-limits';
import ORDER_BY from 'modules/common/constants/order-by';
import CustomChatView from 'modules/common/components/custom-chat-view';
import { REPORT_TYPE, TAB_VALUE } from 'modules/dashboard/constants';
import { ENVIRONMENT } from 'config';
import { formatDate } from 'modules/dashboard/functions';
import useMainCommandFlow from 'modules/dashboard/hooks/use-main-command-flow';
import useCustomLanding from 'modules/dashboard/hooks/use-custom-landing';
import { YEAR_MONTH_DAY_PATTERN } from 'modules/common/constants/date-range';
import SummaryWidget from 'modules/dashboard/components/tab-container/summary-tab/components/widget-pane';
import TrendWidget from 'modules/dashboard/components/tab-container/trend-tabs/components/widget-pane';
import RegionalMap from 'modules/dashboard/components/tab-container/regional-map-tab/components/widget-pane';
import DashboardHeader from 'modules/dashboard/components/dashboard-header';
import WebClick from 'modules/dashboard/components/tab-container/web-click-tab/components/widget-pane';
import ShareComponent from 'modules/dashboard/components/tab-container/share';
import IPrefer from 'modules/dashboard/components/tab-container/i-prefer-tab/components/widget-pane';
import PaceTimeWidget from 'modules/dashboard/components/tab-container/pace-time-tab/components/widget-pane';
import PaceSegmentWidget from 'modules/dashboard/components/tab-container/pace-segment-tab/components/widget-pane';
// import PlotlyPane from 'modules/dashboard/components/tab-container/3d-pace-tab/components/widget-pane';
import { selectRegionMapBookingDate } from 'modules/dashboard/components/tab-container/regional-map-tab/selectors';
import useFetchNotifications from 'modules/dashboard/hooks/uses-fetch-notifications';
import { toast } from 'react-toastify';
import TOAST_TYPES from 'modules/common/constants/toast-types';
import WorkbookReport from './workbook-report/components/widget-pane';
import PaceWidget from './pace-tabs/components/widget-pane';
import TrendTabularWidget from './trend-tabular-tab/components/widget-pane';

/**
 * All tab containers with report visuals
 * @returns
 */
const TabContainer = () => {
  UseTabContainer();
  useFetchNotifications();
  //
  const dispatch = useDispatch();
  // States are obtained from redux store. Refer each selector function for details.
  const organizationId = useSelector(selectOrganizationId);
  const tokenDetails = useSelector(selectEmbedToken);
  const visualDetails = useSelector(selectVisuals);
  const hotelList = useSelector(selectLatestUpdatedHotelsList);
  const reportType = useSelector(selectReportType);
  const isSignIn = useSelector(selectIsSignedIn);
  const selectedHotel = useSelector(selectHotelName);
  const summaryBookingDate = useSelector(selectSummaryBookingDate);
  const reginalMapBookingDate = useSelector(selectRegionMapBookingDate);
  const trendBookingDate = useSelector(selectTrendBookingDate);
  const otherTargetDate = useSelector(selectTargetDate);
  const trendTargetDate = useSelector(selectTrendTargetDate);
  const pmsDate = useSelector(selectPmsDate);
  const groupPmsDates = useSelector(selectGroupPmsDates);
  const currentUser = useSelector(selectAuthUser);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const selectedHotelGroup = useSelector(selectSelectedHotelGroupName);
  const hotelGroupList = useSelector(selectHotelGroupDropdownList);
  const notificationList = useSelector(selectNotifications);
  const authUser = useSelector(selectAuthUser);
  //
  const accessToken = tokenDetails?.accessToken;
  // Local states to maintain visual objects and tab active state are defined here
  const [tabValue, setTabValue] = useState(0);
  const [regionMapVisual, setRegionMapVisual] = useState([]);
  const [paceTimeVisuals, setPaceTimeVisuals] = useState([]);
  const [paceSegmentVisuals, setPaceSegmentVisuals] = useState([]);
  const [webClickVisuals, setWebClickVisuals] = useState([]);
  const [webClickWeeklyVisuals, setWebClickWeeklyVisuals] = useState([]);
  const [webClickCumulativeVisuals, setWebClickCumulativeVisuals] = useState([]);
  const [paceTimeLagAlignedVisuals, setPaceTimeLagAligned] = useState([]);
  const [paceSegmentLagAlignedVisuals, setPaceSegmentLagAligned] = useState([]);
  const [paceTimeAbsoluteCumulativeVisuals, setPaceTimeAbsoluteCumulativeVisuals] = useState([]);
  const [paceSegmentAbsoluteCumulativeVisuals, setPaceSegmentAbsoluteCumulativeVisuals] = useState(
    []
  );
  const [paceTimeLagAlignedCumulativeVisuals, setPaceTimeLagAlignedCumulativeVisuals] = useState(
    []
  );
  const [paceSegmentLagAlignedCumulativeVisuals, setPaceSegmentLagAlignedCumulativeVisuals] =
    useState([]);
  const [iPreferVisuals, setIPreferVisuals] = useState([]);
  const [activeSummary, setActiveSummary] = useState(true);
  const [activeTrendTime, setActiveTrendTime] = useState(false);
  // const [activePlotly, setActivePlotly] = useState(false);
  const [activeRegionMap, setActiveRegionMap] = useState(false);
  const [activePaceTimeWidget, setActivePaceTimeWidget] = useState(false);
  const [activePaceSegmentWidget, setActivePaceSegmentWidget] = useState(false);
  const [activeWebClick, setActiveWebClick] = useState(false);
  const [activeIPrefer, setActiveIPrefer] = useState(false);
  const [activePace, setActivePace] = useState(false);
  const [activeTrendTabular, setActiveTrendTabular] = useState(false);
  const [latestBookingDate, setLatestBookingDate] = useState(
    hotelList?.find((hotel) => hotel?.id === selectedHotel?.id)?.latestBookingDate
  );
  const [hotelTimezone, setHotelTimezone] = useState(
    hotelList?.find((hotel) => hotel?.id === selectedHotel?.id)?.timezone
  );
  const [isChatOpen, setChatOpen] = useState(false);
  const [toggleDisabled, setToggleDisabled] = useState(true);
  const [bookingDate, setBookingDate] = useState(summaryBookingDate);
  const [targetDate, setTargetDate] = useState(otherTargetDate);
  //
  const [workbookVisuals, setWorkBookReport] = useState([]);
  const [activeWorkBookReport, setActiveWorkBook] = useState(false);
  //
  const toastId = useRef(null);
  //
  useCustomLanding(setTabValue);
  // Set active report type
  const setSelectedReport = (type) => {
    dispatch(dashboardActions.setReportType(type));
  };

  // Triggered when tabValue changes
  useEffect(() => {
    // Set default booking and stay date based on report type
    switch (tabValue) {
      case TAB_VALUE.SUMMARY:
      case TAB_VALUE.WORKBOOK_REPORT:
      case TAB_VALUE.PACE_3D:
        setBookingDate(summaryBookingDate);
        setTargetDate(otherTargetDate);
        break;
      case TAB_VALUE.REGION_MAP:
        setBookingDate(reginalMapBookingDate);
        setTargetDate(otherTargetDate);
        break;
      case TAB_VALUE.PACE_WIDGET:
        setTargetDate(otherTargetDate);
        break;
      case TAB_VALUE.TREND_TIME:
        setTargetDate(trendTargetDate);
        setBookingDate(trendBookingDate);
        break;
      default:
        break;
    }
  }, [tabValue]);
  //
  const ToastComponent = ({ url, notificationId }) => (
    <div>
      <p>Your Workbook PDF is ready</p>
      <Link
        href={`${ENVIRONMENT.AZURE_CDN_URL}/${url}`}
        onClick={() =>
          dispatch(
            dashboardActions.updateNotification({
              organizationId,
              userId: authUser?.id,
              notificationId,
              isOpen: true,
            })
          )
        }
        target="_blank"
        sx={{ mx: 'auto' }}
      >
        Download a PDF here
      </Link>
    </div>
  );

  //
  useEffect(() => {
    if (notificationList?.length) {
      const filteredNotifications = notificationList?.filter(
        (notificationItem) => !notificationItem.isOpen
      );
      if (filteredNotifications?.length && !toast.isActive(toastId.current))
        toastId.current = toast(
          <ToastComponent
            url={filteredNotifications[0]?.payload.fileName}
            notificationId={filteredNotifications[0]?.id}
          />,
          {
            type: TOAST_TYPES.INFO,
            autoClose: false,
          }
        );
    }
  }, [notificationList]);

  // Triggered when organizationId value changes
  useEffect(() => {
    // Dispatch to get embed token, visuals, visual filters, system information and updated hotel list

    // prevent dispatches on browser refresh
    const serializedState = JSON.parse(localStorage.getItem(`persist:${ENVIRONMENT.PERSIST_KEY}`));
    if (!isSignIn && serializedState) {
      return;
    }
    let query = `limit=${PAGINATION.VISUALS_PER_PAGE_COUNT}&sortBy=order:${ORDER_BY.ASCENDING}`;
    dispatch(
      dashboardActions.getEmbedToken({
        organizationId,
      })
    );
    dispatch(
      dashboardActions.getVisuals({
        organizationId,
        query,
      })
    );
    query = `limit=${PAGINATION.HOTELS_PER_PAGE_COUNT}&sortBy=type:${ORDER_BY.ASCENDING}`;
    dispatch(
      dashboardActions.getVisualFilters({
        organizationId,
        query,
      })
    );
    query = `limit=${PAGINATION.SYSTEM_INFORMATION}`;
    dispatch(
      dashboardActions.getSystemInformation({
        query,
      })
    );
    dispatch(dashboardActions.setIsSignedIn(false));
    query = `limit=${PAGINATION.HOTELS_PER_PAGE_COUNT}&sortBy=latestBookingDate:${ORDER_BY.DESCENDING}&forceFetch=true`;
    dispatch(
      dashboardActions.getLatestUpdatedHotelList({
        organizationId,
        query,
      })
    );
  }, [organizationId]);

  // Triggered when organization and user value changes
  useEffect(() => {
    // get saved command filters
    const query = `limit=${PAGINATION.AGGREGATION_FILTER_PER_PAGE}&page=0`;
    dispatch(dashboardActions.getCommandFilter({ organizationId, userId: currentUser?.id, query }));
  }, [organizationId, currentUser]);
  // get landing page
  useEffect(() => {
    if (currentUser) {
      dispatch(usersActions.getLandingPage({ organizationId, userId: currentUser?.id }));
    }
  }, []);
  // Triggered when visualDetails value changes
  useEffect(() => {
    // Set visuals list based on report type
    setPaceTimeVisuals(
      visualDetails?.filter((visual) => visual?.tags?.includes(REPORT_TYPE.PACE_TIME_WIDGET))
    );
    setPaceTimeLagAligned(
      visualDetails?.filter((visual) =>
        visual?.tags?.includes(REPORT_TYPE.PACE_TIME_WIDGET_LAG_ALIGNED)
      )
    );
    setPaceTimeAbsoluteCumulativeVisuals(
      visualDetails?.filter((visual) =>
        visual?.tags?.includes(REPORT_TYPE.PACE_TIME_WIDGET_ABSOLUTE_CUMULATIVE)
      )
    );
    setPaceTimeLagAlignedCumulativeVisuals(
      visualDetails?.filter((visual) =>
        visual?.tags?.includes(REPORT_TYPE.PACE_TIME_WIDGET_LAG_ALIGNED_CUMULATIVE)
      )
    );
    // ==============================
    setPaceSegmentVisuals(
      visualDetails?.filter((visual) => visual?.tags?.includes(REPORT_TYPE.PACE_SEGMENT_WIDGET))
    );
    setPaceSegmentLagAligned(
      visualDetails?.filter((visual) =>
        visual?.tags?.includes(REPORT_TYPE.PACE_SEGMENT_WIDGET_LAG_ALIGNED)
      )
    );
    setPaceSegmentAbsoluteCumulativeVisuals(
      visualDetails?.filter((visual) =>
        visual?.tags?.includes(REPORT_TYPE.PACE_SEGMENT_WIDGET_ABSOLUTE_CUMULATIVE)
      )
    );
    setPaceSegmentLagAlignedCumulativeVisuals(
      visualDetails?.filter((visual) =>
        visual?.tags?.includes(REPORT_TYPE.PACE_SEGMENT_WIDGET_LAG_ALIGNED_CUMULATIVE)
      )
    );
    // ===============================
    setRegionMapVisual(
      visualDetails?.filter((visual) => visual?.tags?.includes(REPORT_TYPE.REGION_MAP))
    );
    // =============================
    setWebClickVisuals(
      visualDetails?.filter((visual) => visual?.tags?.includes(REPORT_TYPE.WEB_CLICK))
    );
    setWebClickWeeklyVisuals(
      visualDetails?.filter((visual) => visual?.tags?.includes(REPORT_TYPE.WEEKLY_WEB_CLICK))
    );
    setWebClickCumulativeVisuals(
      visualDetails?.filter((visual) => visual?.tags?.includes(REPORT_TYPE.CUMULATIVE_WEB_CLICK))
    );
    // =============================
    setIPreferVisuals(
      visualDetails?.filter((visual) => visual?.tags?.includes(REPORT_TYPE.I_PREFER))
    );
    // =============================
    setWorkBookReport(
      visualDetails?.filter((visual) => visual?.tags?.includes(REPORT_TYPE.WORKBOOK_REPORT))
    );
  }, [visualDetails]);

  // Triggered when tabValue changes
  useEffect(() => {
    // Set selected report based on tab value
    switch (tabValue) {
      case TAB_VALUE.SUMMARY:
        setActiveSummary(true);
        // setActivePlotly(false);
        setSelectedReport(REPORT_TYPE.SUMMARY);
        break;
      case TAB_VALUE.PACE_TIME_WIDGET:
        setActivePaceTimeWidget(true);
        // setActivePlotly(false);
        setSelectedReport(REPORT_TYPE.PACE_TIME_WIDGET);
        break;
      case TAB_VALUE.PACE_SEGMENT_WIDGET:
        setActivePaceSegmentWidget(true);
        // setActivePlotly(false);
        setSelectedReport(REPORT_TYPE.PACE_SEGMENT_WIDGET);
        break;
      case TAB_VALUE.REGION_MAP:
        setActiveRegionMap(true);
        // setActivePlotly(false);
        setSelectedReport(REPORT_TYPE.REGION_MAP);
        break;
      case TAB_VALUE.TREND_TIME:
        setActiveTrendTime(true);
        // setActivePlotly(false);
        setSelectedReport(REPORT_TYPE.TREND_TIME);
        break;
      case TAB_VALUE.PACE_3D:
        // setActivePlotly(true);
        setSelectedReport(REPORT_TYPE.PACE_3D);
        break;
      case TAB_VALUE.WEB_CLICK:
        setActiveWebClick(true);
        // setActivePlotly(false);
        setSelectedReport(REPORT_TYPE.WEB_CLICK);
        break;
      case TAB_VALUE.I_PREFER:
        setActiveIPrefer(true);
        // setActivePlotly(false);
        setSelectedReport(REPORT_TYPE.I_PREFER);
        break;
      case TAB_VALUE.WORKBOOK_REPORT:
        setActiveWorkBook(true);
        // setActivePlotly(false);
        setSelectedReport(REPORT_TYPE.WORKBOOK_REPORT);
        break;
      case TAB_VALUE.PACE:
        setActivePace(true);
        setSelectedReport(REPORT_TYPE.PACE);
        break;
      case TAB_VALUE.TREND_TABULAR:
        setActiveTrendTabular(true);
        setSelectedReport(REPORT_TYPE.TREND_TABULAR);
        break;
      default:
        break;
    }
  }, [tabValue]);

  // Triggered when selectedHotel, hotelList value changes
  useEffect(() => {
    // Get breakdown data, set latest booking date and timezone of selected hotel
    let query = '';
    if (enableHotelGroups) {
      const hotelIds = selectedHotelGroup?.hotels?.map((hotel) => `hotelIds=${hotel?.id}`);
      query = `${hotelIds?.join('&')}&limit=${PAGINATION.BREAKDOWN_DATA}`;
      //
      const earliestBookedHotel = hotelList
        ?.filter((hotel) =>
          selectedHotelGroup?.hotels?.some(
            (selectedHotelItem) => selectedHotelItem?.id === hotel?.id
          )
        )
        .reduce((earliest, hotel) => {
          const selectedLatestBookingDate = hotel?.latestBookingDate;
          if (
            !earliest ||
            (selectedLatestBookingDate && selectedLatestBookingDate < earliest.latestBookingDate)
          ) {
            return hotel;
          }
          return earliest;
        }, null);
      setLatestBookingDate(earliestBookedHotel?.latestBookingDate);
      setHotelTimezone(earliestBookedHotel?.timezone);
    } else {
      query = `hotelId=${selectedHotel?.id}&limit=${PAGINATION.BREAKDOWN_DATA}`;
      setLatestBookingDate(
        hotelList?.find((hotel) => hotel?.id === selectedHotel?.id)?.latestBookingDate
      );
      setHotelTimezone(hotelList?.find((hotel) => hotel?.id === selectedHotel?.id)?.timezone);
    }
    dispatch(dashboardActions.getBreakdown({ organizationId, query }));
    query = `hotelId=${selectedHotel?.id}&limit=${PAGINATION.BREAKDOWN_DATA}`;
    dispatch(dashboardActions.getUtmBreakdown({ organizationId, query }));
  }, [selectedHotel, hotelList, selectedHotelGroup, enableHotelGroups, hotelGroupList]);

  // Triggered when pmsDate, bookingDate, targetDate, selectedHotel values change
  useEffect(() => {
    // Set pms toggle state based on hotel, booking date and target date
    if (enableHotelGroups) {
      if (groupPmsDates !== null && groupPmsDates?.length > 0) {
        const conditions = [
          (date) =>
            date &&
            tabValue === TAB_VALUE.PACE_WIDGET &&
            date <= formatDate(targetDate.endDate, YEAR_MONTH_DAY_PATTERN), // Check if the value is odd
          (date) =>
            date &&
            tabValue !== TAB_VALUE.PACE_WIDGET &&
            date <= formatDate(targetDate.endDate, YEAR_MONTH_DAY_PATTERN) &&
            date <= formatDate(bookingDate.endDate, YEAR_MONTH_DAY_PATTERN),
        ];
        const enableStatus = groupPmsDates.every((date) =>
          conditions.some((condition) => condition(date))
        );
        if (enableStatus) {
          setToggleDisabled(false);
        } else {
          setToggleDisabled(true);
          dispatch(dashboardActions.setPmsSync(false));
        }
      } else {
        setToggleDisabled(true);
        dispatch(dashboardActions.setPmsSync(false));
      }
    } else if (pmsDate) {
      if (
        tabValue === TAB_VALUE.PACE_WIDGET &&
        pmsDate <= formatDate(targetDate.endDate, YEAR_MONTH_DAY_PATTERN)
      ) {
        setToggleDisabled(false);
      } else if (
        tabValue !== TAB_VALUE.PACE_WIDGET &&
        pmsDate <= formatDate(targetDate.endDate, YEAR_MONTH_DAY_PATTERN) &&
        pmsDate <= formatDate(bookingDate.endDate, YEAR_MONTH_DAY_PATTERN)
      ) {
        setToggleDisabled(false);
      } else {
        setToggleDisabled(true);
        dispatch(dashboardActions.setPmsSync(false));
      }
    } else {
      setToggleDisabled(true);
      dispatch(dashboardActions.setPmsSync(false));
    }
  }, [
    pmsDate,
    groupPmsDates,
    bookingDate,
    targetDate,
    selectedHotel,
    enableHotelGroups,
    selectedHotelGroup,
  ]);

  /**
   *  This is where add listener functions to all the ui controller commands to set values and emit listener functions in particular tab.
   * As for event driven flow, this is the first step, adding event listener for events
   * Then we emit these events command values
   * commands values are provided as parameters to listener functions
   */
  useMainCommandFlow(tabValue, setTabValue, toggleDisabled);
  //
  return (
    <Grid container justifyContent="flex-start" alignItems="center" px={{ xs: 1, md: 4, sm: 2 }}>
      <Grid
        sx={{
          width: '100%',
          background: '#f7f7f7',
          top: 75,
          position: 'fixed',
          zIndex: 15,
          height: 100,
        }}
      >
        <Grid
          container
          sx={{
            background: '#f7f7f7',
            flexDirection: 'row',
            justifyContent: 'space-between',
            ml: 10,
          }}
        >
          <Grid item xs={10}>
            <Tabs
              value={tabValue}
              onChange={(e, val) => setTabValue(val)}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Summary" />
              <Tab label="Dashboard" />
              {/* Temporarily hidden */}
              {false && (
                <>
                  <Tab label="Pace (Time)" />
                  <Tab label="Pace (Segment)" />
                </>
              )}
              <Tab label="Trend" />
              <Tab label="Tabular" />
              <Tab label="Pace" />
              {/* <Tab label="3D Pace" /> */}
              <Tab label="Regional Map" />
              <Tab label="Web Clicks" />
              {/* {!isEmpty(tabAccess) && tabAccess?.isIPrefer && <Tab label="iPrefer" />} */}
            </Tabs>
          </Grid>
          <Grid item xs={2} alignSelf="center">
            <ShareComponent tabValue={tabValue} />
          </Grid>
        </Grid>
        <DashboardHeader
          reportType={reportType}
          adornment="Stay Dates"
          latestDate={latestBookingDate}
          sx={{ padding: 10 }}
        />
      </Grid>
      <Grid sx={{ height: 100, whiteSpace: 'pre-wrap' }}> </Grid>
      <SummaryWidget
        value={tabValue}
        active={activeSummary}
        accessToken={accessToken}
        latestDate={latestBookingDate}
        hotelTimezone={hotelTimezone}
        reportType={reportType}
        toggleDisabled={toggleDisabled}
        setToggleDisabled={setToggleDisabled}
      />
      {workbookVisuals && (
        <WorkbookReport
          value={tabValue}
          active={activeWorkBookReport}
          accessToken={accessToken}
          visuals={workbookVisuals}
          latestDate={latestBookingDate}
          hotelTimezone={hotelTimezone}
          reportType={reportType}
          toggleDisabled={toggleDisabled}
        />
      )}
      {/* Temporarily hidden */}
      {false && (
        <>
          {paceTimeVisuals && (
            <PaceTimeWidget
              value={tabValue}
              active={activePaceTimeWidget}
              accessToken={accessToken}
              visuals={paceTimeVisuals}
              latestDate={latestBookingDate}
              hotelTimezone={hotelTimezone}
              reportType={reportType}
              paceLagAlignedVisuals={paceTimeLagAlignedVisuals}
              toggleDisabled={toggleDisabled}
              paceAbsoluteCumulativeVisuals={paceTimeAbsoluteCumulativeVisuals}
              paceLagAlignedCumulativeVisuals={paceTimeLagAlignedCumulativeVisuals}
            />
          )}
          {paceSegmentVisuals && (
            <PaceSegmentWidget
              value={tabValue}
              active={activePaceSegmentWidget}
              accessToken={accessToken}
              visuals={paceSegmentVisuals}
              latestDate={latestBookingDate}
              hotelTimezone={hotelTimezone}
              reportType={reportType}
              paceLagAlignedVisuals={paceSegmentLagAlignedVisuals}
              toggleDisabled={toggleDisabled}
              paceAbsoluteCumulativeVisuals={paceSegmentAbsoluteCumulativeVisuals}
              paceLagAlignedCumulativeVisuals={paceSegmentLagAlignedCumulativeVisuals}
            />
          )}
        </>
      )}
      {regionMapVisual && (
        <RegionalMap
          value={tabValue}
          active={activeRegionMap}
          accessToken={accessToken}
          visuals={regionMapVisual}
          latestDate={latestBookingDate}
          hotelTimezone={hotelTimezone}
          reportType={reportType}
          toggleDisabled={toggleDisabled}
        />
      )}
      <TrendWidget
        value={tabValue}
        active={activeTrendTime}
        latestDate={latestBookingDate}
        hotelTimezone={hotelTimezone}
        reportType={reportType}
        toggleDisabled={toggleDisabled}
      />
      {/* <PlotlyPane
        value={tabValue}
        active={activePlotly}
        latestDate={latestBookingDate}
        hotelTimezone={hotelTimezone}
        reportType={reportType}
        toggleDisabled={toggleDisabled}
      /> */}
      {webClickVisuals && (
        <WebClick
          value={tabValue}
          active={activeWebClick}
          accessToken={accessToken}
          visuals={webClickVisuals}
          weeklyVisuals={webClickWeeklyVisuals}
          cumulativeVisuals={webClickCumulativeVisuals}
          latestDate={latestBookingDate}
          hotelTimezone={hotelTimezone}
          reportType={reportType}
        />
      )}
      {iPreferVisuals && (
        <IPrefer
          value={tabValue}
          active={activeIPrefer}
          accessToken={accessToken}
          visuals={iPreferVisuals}
          latestDate={latestBookingDate}
          hotelTimezone={hotelTimezone}
          reportType={reportType}
          toggleDisabled={toggleDisabled}
          setToggleDisabled={setToggleDisabled}
        />
      )}
      <PaceWidget
        active={activePace}
        value={tabValue}
        reportType={reportType}
        hotelTimezone={hotelTimezone}
        latestDate={latestBookingDate}
        toggleDisabled={toggleDisabled}
      />
      <TrendTabularWidget
        value={tabValue}
        active={activeTrendTabular}
        latestDate={latestBookingDate}
        hotelTimezone={hotelTimezone}
        reportType={reportType}
        toggleDisabled={toggleDisabled}
      />
      {ENVIRONMENT.CHAT_ENABLED === '1' && (
        <>
          <Fab
            color="secondary"
            sx={{ position: 'fixed', bottom: 2, right: 0 }}
            onClick={() => setChatOpen(true)}
          >
            <MessageSquare />
          </Fab>
          <CustomChatView isOpen={isChatOpen} setOpen={() => setChatOpen(false)} />
        </>
      )}
    </Grid>
  );
};
//
export default TabContainer;
