import { createSlice } from '@reduxjs/toolkit';
import { signInActions } from 'modules/sign-in/slice';
import { dashboardActions } from 'modules/dashboard/slice';
import { SLICE, TAGS } from 'modules/dashboard/constants';

/**
 * Initial states of Pace Segment widget functions are defined here
 */
export const initialState = {
  paceSegmentCumulativeView: false,
  paceSegmentLagAligned: false,
  paceWidgetSegmentFilterList: [],
  isPaceDetailed: false,
  paceFocusOn: [],
  paceChartBreakdown: null,
  paceSegmentWidgetUrl: '',
  revenueIsDisplay: true,
  occupiedRoomsIsDisplay: true,
  adrIsDisplay: true,
  cxlIsDisplay: false,
};
/**
 * All actions related to Pace Segment widget feature are defined here
 */
export const paceSegmentSlice = createSlice({
  name: SLICE.PACE_SEGMENT,
  initialState,
  reducers: {
    setPaceSegmentCumulativeView(state, action) {
      state.paceSegmentCumulativeView = action?.payload;
    },
    setPaceSegmentLagAligned(state, action) {
      state.paceSegmentLagAligned = action?.payload;
    },
    setPaceWidgetSegmentFilterList(state, action) {
      state.paceWidgetSegmentFilterList = action?.payload;
    },
    setIsPaceDetailed(state, action) {
      state.isPaceDetailed = action?.payload;
    },
    setPaceFocusOn(state, action) {
      state.paceFocusOn = action?.payload;
    },
    setPaceChartBreakdown(state, action) {
      state.paceChartBreakdown = action?.payload;
    },
    setPaceSegmentWidgetUrl(state, action) {
      state.paceSegmentWidgetUrl = action.payload;
    },
    setRevenueIsDisplay(state, action) {
      state.revenueIsDisplay = action?.payload;
    },
    setOccupiedRoomIsDisplay(state, action) {
      state.occupiedRoomsIsDisplay = action?.payload;
    },
    setAdrIsDisplay(state, action) {
      state.adrIsDisplay = action?.payload;
    },
    setCxlIsDisplay(state, action) {
      state.cxlIsDisplay = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInActions.signIn, (state) => ({
        ...state,
        ...initialState,
      }))
      .addCase(dashboardActions.getVisualFiltersSucceeded, (state, action) => ({
        ...state,
        paceChartBreakdown: action?.payload?.results?.find(
          (filter) => filter?.tags?.includes(TAGS.SEGMENT_BREAKDOWN) && filter?.isDefault === true
        )?.id,
      }));
  },
});
//
export const { actions: paceSegmentActions } = paceSegmentSlice;
