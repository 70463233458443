import { COLLAPSE_VISUALS, UiController, commands, events } from 'modules/common/ui-controller';
import { KEYS, RESET_BOOKING_DATE } from 'modules/dashboard/constants';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCompareWindowCommandValue } from 'modules/dashboard/components/tab-container/inner-filter/functions';
import { workbookActions } from '../slice';
import { summaryActions } from '../../summary-tab/slice';

/**
 * Custom hook to execute summary widget filter command flow
 * @param {Boolean} isLoaded
 * @param {Boolean} customBooking
 * @param {Array} paceList
 * @param {Array} chartBreakdownList
 * @param {Array} paceVariableList
 * @param {Array} visualFilters
 * @param {String} dateComparison
 * @param {Boolean} alignment
 * @param {Array} dateComparisonList
 * @param {Function} handleComparisonChange
 * @param {Function} handleToggleChange
 */
const useWorkBookCommandFlow = (
  isLoaded,
  customBooking,
  dateComparison,
  dateComparisonList,
  handleComparisonChange,
  handleToggleChange
) => {
  const dispatch = useDispatch();
  const [compareWindowValueCommand, setCompareWindowValueCommand] = useState(null);
  const [customDatesValueCommand, setCustomDatesValueCommand] = useState(null);
  const [customBookingCommandValue, setCustomBookingCommandValue] = useState(null);
  const [alignmentCommandValue, setAlignmentCommandValue] = useState(null);
  const [symmetricComparisonCollapseCommandValue, setSymmetricComparisonCollapseCommandValue] =
    useState(null);
  const [visualCollapseCommandValue, setVisualCollapseCommandValue] = useState(null);

  // set alignment toggle command value
  const setAlignmentToggleHandler = (value) => {
    setAlignmentCommandValue(value);
  };
  // Triggers when alignmentCommandValue, isLoaded change
  useEffect(() => {
    if (alignmentCommandValue !== null && isLoaded) {
      handleToggleChange({
        target: {
          checked: alignmentCommandValue,
        },
      });
      setAlignmentCommandValue(null);
    }
  }, [alignmentCommandValue, isLoaded]);

  // Triggers when customBookingCommandValue, isLoaded change
  useEffect(() => {
    if (customBookingCommandValue !== null && isLoaded) {
      if (customBookingCommandValue === commands.OTB) {
        dispatch(workbookActions.setWorkbookOtb(false));
        dispatch(workbookActions.setWorkbookBookingDate(RESET_BOOKING_DATE));
        setCustomBookingCommandValue(null);
      } else {
        dispatch(workbookActions.setWorkbookOtb(true));
      }
    }
  }, [customBookingCommandValue, isLoaded]);
  // Triggers when customBookingCommandValue,customBooking, isLoaded change
  useEffect(() => {
    if (
      customBookingCommandValue !== null &&
      customBookingCommandValue !== commands.OTB &&
      customBooking &&
      isLoaded
    ) {
      // emit set custom booking date range event
      UiController.setBookingDateRange(customBookingCommandValue);
      setCustomBookingCommandValue(null);
    }
  }, [customBookingCommandValue, customBooking, isLoaded]);

  // Triggers when compareWindowValueCommand, customBooking,dateComparison values change
  useEffect(() => {
    if (
      dateComparison &&
      dateComparisonList?.length > 0 &&
      compareWindowValueCommand !== null &&
      isLoaded &&
      alignmentCommandValue === null
    ) {
      setCompareWindowCommandValue(
        compareWindowValueCommand,
        customBooking,
        handleComparisonChange
      );
      setCompareWindowValueCommand(null);
    }
  }, [
    compareWindowValueCommand,
    customBooking,
    dateComparison,
    dateComparisonList?.length,
    isLoaded,
    alignmentCommandValue,
  ]);
  // Triggers when customDatesValueCommand and dateComparison values change
  useEffect(() => {
    if (customDatesValueCommand !== null && isLoaded) {
      const datesExtracted = customDatesValueCommand?.split('-');
      // emit event to set custom date range
      if (dateComparison && dateComparison === KEYS.CUSTOM) {
        dispatch(workbookActions.setWorkbookCustomDate(datesExtracted[0]));
        UiController.setCustomDateRange(customDatesValueCommand);
        setCustomDatesValueCommand(null);
      }
    }
  }, [customDatesValueCommand, dateComparison, isLoaded]);
  // Triggers when symmetricComparisonCollapseCommandValue, isLoaded change
  useEffect(() => {
    if (symmetricComparisonCollapseCommandValue !== null && isLoaded) {
      dispatch(
        summaryActions.setSymmetricComparisonIsDisplay(
          symmetricComparisonCollapseCommandValue === 1
        )
      );
      setSymmetricComparisonCollapseCommandValue(null);
    }
  }, [symmetricComparisonCollapseCommandValue, isLoaded]);
  // Triggers when visualCollapseCommandValue change
  useEffect(() => {
    if (visualCollapseCommandValue !== null && isLoaded) {
      const visuals = visualCollapseCommandValue?.split(',');
      visuals?.forEach((visual) => {
        // get the visual collapse enable/disable status
        const status = parseInt(visual?.substring(2), 10);
        // get the type of visual view
        const visualName = visual?.substring(0, 2);
        if (visualName && status !== undefined) {
          switch (visualName) {
            case COLLAPSE_VISUALS.D1:
              dispatch(workbookActions.setD1SetIsDisplay(status === 1));
              break;
            case COLLAPSE_VISUALS.D2:
              dispatch(workbookActions.setD2SetIsDisplay(status === 1));
              break;
            case COLLAPSE_VISUALS.D3:
              dispatch(workbookActions.setD3SetIsDisplay(status === 1));
              break;
            default:
              break;
          }
        }
      });
      setVisualCollapseCommandValue(null);
    }
  }, [visualCollapseCommandValue, isLoaded]);
  // Listener function to set compare window value
  const setCompareWindowValue = (data) => {
    setCompareWindowValueCommand(data);
  };
  // Listener function to set custom date range value
  const setCustomDateRangeValue = (data) => {
    setCustomDatesValueCommand(data);
  };
  // Listener function to set custom booking date range command
  const setCustomBookingHandler = (value) => {
    setCustomBookingCommandValue(value);
  };
  // Listener function to set symmetric comparison collapse value
  const setSymmetricComparisonCollapseValue = (data) => {
    setSymmetricComparisonCollapseCommandValue(data);
  };
  // Listener function to set visual collapse value
  const setVisualCollapseValue = (data) => {
    setVisualCollapseCommandValue(data);
  };
  // Add Listener function to set custom booking command value
  useEffect(() => {
    UiController.subscribe(events.SET_WEB_CLICK_CUSTOM_BOOKING, setCustomBookingHandler);
    return () => {
      UiController.unsubscribe(events.SET_WEB_CLICK_CUSTOM_BOOKING, setCustomBookingHandler);
    };
  }, []);
  // Add Listener function to set alignment command value
  useEffect(() => {
    UiController.subscribe(events.SET_WORKBOOK_ALIGNMENT, setAlignmentToggleHandler);
    return () => {
      UiController.unsubscribe(events.SET_WORKBOOK_ALIGNMENT, setAlignmentToggleHandler);
    };
  }, []);

  // Add Listener function to set compare window command value
  useEffect(() => {
    UiController.subscribe(events.SET_WORKBOOK_COMPARE_WINDOW, setCompareWindowValue);
    return () => {
      UiController.unsubscribe(events.SET_WORKBOOK_COMPARE_WINDOW, setCompareWindowValue);
    };
  }, []);
  // Add Listener function to set custom booking date range command value
  useEffect(() => {
    UiController.subscribe(events.SET_WORKBOOK_CUSTOM_DATE_RANGE, setCustomDateRangeValue);
    return () => {
      UiController.unsubscribe(events.SET_WORKBOOK_CUSTOM_DATE_RANGE, setCustomDateRangeValue);
    };
  }, []);
  // Add Listener function to set symmetric comparison collapse
  useEffect(() => {
    UiController.subscribe(
      events.SET_WORKBOOK_SYMMETRIC_COMPARISON_COLLAPSE,
      setSymmetricComparisonCollapseValue
    );
    return () => {
      UiController.unsubscribe(
        events.SET_WORKBOOK_SYMMETRIC_COMPARISON_COLLAPSE,
        setSymmetricComparisonCollapseValue
      );
    };
  }, []);
  // Add Listener function to set visual collapse
  useEffect(() => {
    UiController.subscribe(events.SET_WORKBOOK_VISUAL_COLLAPSE, setVisualCollapseValue);
    return () => {
      UiController.unsubscribe(events.SET_WORKBOOK_VISUAL_COLLAPSE, setVisualCollapseValue);
    };
  }, []);
};

export default useWorkBookCommandFlow;
