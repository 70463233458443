import { CSVLink } from 'react-csv';
import { useState, useEffect, useRef } from 'react';
import { models } from 'powerbi-client';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/common/auth/selectors';
import {
  selectHotelName,
  selectPmsSync,
  selectTrendBookingDate,
  selectTrendTargetDate,
  selectWeeklyTrendStartDate,
  selectWeeklyTrendEndDate,
  selectSelectedHotelGroupName,
  selectEnableHotelGroups,
} from 'modules/dashboard/selectors';
import { isEmpty } from 'modules/common/helpers/object';
import { formatDate } from 'modules/dashboard/functions';
import { DATE_PATTERN, TIME_PATTERN } from 'modules/common/constants/date-range';
import { Grid, IconButton, Typography } from '@mui/material';
import { Loader } from 'modules/common/components';
import DownloadIcon from '@mui/icons-material/Download';
import NoDataDialog from 'modules/dashboard/components/tab-container/export-visual-data/components/no-data';
/**
 * Export Data component to download visual data in csv format
 * @returns
 */
const ExportVisualData = ({
  report,
  isLoaded,
  fileName,
  weeklyViewToggle,
  isCumulative,
  otherFilters = '',
}) => {
  const csvLink = useRef();
  //
  const currentUser = useSelector(selectAuthUser);
  const selectedHotel = useSelector(selectHotelName);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const bookingDate = useSelector(selectTrendBookingDate);
  const targetDate = useSelector(selectTrendTargetDate);
  const startDate = useSelector(selectWeeklyTrendStartDate);
  const endDate = useSelector(selectWeeklyTrendEndDate);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const hotelGroup = useSelector(selectSelectedHotelGroupName);
  //
  const [visualData, setVisualData] = useState([]);
  const [initiateDownload, setInitiateDownload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isDialog, setIsDialog] = useState(false);
  //
  useEffect(() => {
    if (visualData?.length) {
      setInitiateDownload(true);
    }
  }, [visualData]);
  //
  useEffect(() => {
    if (initiateDownload) {
      csvLink.current.link.click();
      setInitiateDownload(false);
    }
    setLoading(false);
  }, [visualData, initiateDownload]);
  //
  const resetInitData = () => {
    setInitiateDownload(false);
    setLoading(false);
    setIsDialog(true);
  };
  //
  const exportPbiData = async () => {
    setLoading(true);
    if (!isEmpty(report) && isLoaded) {
      //
      try {
        const pages = await report.getPages();
        const currentPage = pages.filter((page) => page.isActive)[0];
        const visuals = await currentPage.getVisuals();
        //
        const visual = visuals.filter((v) => v.name === visuals[0]?.name)[0];
        const result = await visual.exportData(models.ExportDataType.Summarized);
        if (result?.data?.length > 0) {
          const dataArray = String(result.data).split('\r\n');
          const mapData = dataArray
            .map((data) => String(data).split(','))
            .filter((data) => data[0] !== '');

          if (isEmpty(mapData)) {
            setLoading(false);
            setInitiateDownload(false);
            setMessage('No data available to export');
            setIsDialog(true);
          } else {
            const reportData = mapData.join('\r\n');
            const username = `Username : , ${currentUser?.username}\r\n`;
            const reportGenerationDate = `Report Generation Date : ,"${formatDate(
              new Date(),
              DATE_PATTERN
            )}, ${formatDate(new Date(), TIME_PATTERN)}"\r\n`;
            const hotel = enableHotelGroups
              ? `Hotel Set: , "${hotelGroup?.label}"\r\n`
              : `Hotel: , "${selectedHotel?.label}"\r\n`;
            const stayDateRange = `Stay Date Range :,${formatDate(
              weeklyViewToggle && !isCumulative ? startDate : targetDate.startDate,
              DATE_PATTERN
            )} to ${formatDate(
              weeklyViewToggle && !isCumulative ? endDate : targetDate.endDate,
              DATE_PATTERN
            )}\r\n`;
            const pmsSync = `PMS Sync : , ${pmsSyncToggle ? 'On' : 'Off'}\r\n`;
            const bookingDateRange = `Booking Date Range : ,${formatDate(
              bookingDate.startDate,
              DATE_PATTERN
            )} to ${formatDate(bookingDate.endDate, DATE_PATTERN)}\r\n`;
            setVisualData(
              username.concat(
                reportGenerationDate,
                hotel,
                stayDateRange,
                pmsSync,
                bookingDateRange,
                otherFilters,
                '\r\n',
                reportData
              )
            );
          }
        } else {
          setMessage('No data available to export', resetInitData());
        }
      } catch (error) {
        setMessage('Something went wrong', resetInitData());
      }
    } else {
      setMessage('Visual is still loading', resetInitData());
    }
  };
  //
  return (
    <Loader loading={loading}>
      <Grid container direction="row" alignItems="center">
        <Typography
          sx={{
            mr: 1,
          }}
        >
          Export Data
        </Typography>
        <IconButton
          size="small"
          onClick={() => exportPbiData()}
          sx={{
            '& .MuiSvgIcon-root': {
              width: '1.25rem',
              height: '1.25rem',
            },
          }}
        >
          <DownloadIcon />
        </IconButton>
      </Grid>
      <CSVLink
        asyncOnClick
        filename={`${fileName}_${formatDate(new Date(), DATE_PATTERN)}`}
        data={visualData}
        target="_self"
        ref={csvLink}
        sx={{ display: 'none' }}
      />
      <NoDataDialog open={isDialog} onClose={() => setIsDialog(false)} message={message} />
    </Loader>
  );
};
//
export default ExportVisualData;
