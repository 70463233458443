/**
 * Custom aggregation feature related constant are defined here
 */
export const OPERATORS = {
  EQUAL: '=',
  NOT_EQUAL: '!=',
  AND: 'and',
  OR: ' or ',
  LIKE: 'like',
  SPACE: ' ',
};
//
export const LABELS = {
  GROUP_A: 'Group A',
  GROUP_B: 'Group B',
};
