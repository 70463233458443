/* eslint-disable import/no-cycle */
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'modules/common/utils/request';
import PAGINATION from 'modules/common/constants/pagination-limits';
import { dashboardActions } from 'modules/dashboard/slice';
import { API } from 'modules/dashboard/constants';
/**
 * Embed token generator function for Power BI embedding
 * @param {*} param0
 */
export function* getEmbedTokenGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_EMBED_TOKEN, payload);
    yield put(dashboardActions.getEmbedTokenSucceeded(response));
  } catch (error) {
    yield put(dashboardActions.getEmbedTokenFailed(error?.message));
  }
}
/**
 * Power BI visual generator function to obtain registered visuals
 * @param {*} param0
 */
export function* getVisualsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_VISUALS, payload);
    yield put(dashboardActions.getVisualsSucceeded(response));
  } catch (error) {
    yield put(dashboardActions.getVisualsFailed(error?.message));
  }
}
/**
 * Power BI visual filter generator function to obtain registered visual filters
 * @param {*} param0
 */
export function* getVisualFiltersGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_VISUAL_FILTERS, payload);
    yield put(dashboardActions.getVisualFiltersSucceeded(response));
  } catch (error) {
    yield put(dashboardActions.getVisualFiltersFailed(error?.message));
  }
}
/**
 * All hotels generator function to obtain the list of all registered hotels
 * @param {*} param0
 */
export function* getAllHotelsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ALL_HOTELS, payload);
    yield put(dashboardActions.getHotelListSucceeded(response));
  } catch (error) {
    yield put(dashboardActions.getHotelListFailed(error?.message));
  }
}
/**
 * Get latest updated hotels generator function to obtain the list of latest updated hotel list
 * @param {*} param0
 */
export function* getLatestUpdatedAHotelsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ALL_HOTELS, payload);
    yield put(dashboardActions.getLatestUpdatedHotelListSucceeded(response));
  } catch (error) {
    yield put(dashboardActions.getLatestUpdatedHotelListFailed(error?.message));
  }
}

/**
 * Breakdown data generator function to obtain data for breakdown dropdown
 * @param {*} param0
 */
export function* getBreakdownGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ALL_BREAKDOWNS, payload);
    yield put(dashboardActions.getBreakdownSucceeded(response));
  } catch (error) {
    yield put(dashboardActions.getBreakdownFailed(error?.message));
  }
}
/**
 * Forecast filter generator function to obtain data for forecast dropdown
 * @param {*} param0
 */
export function* getForecastFilterGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_FORECAST_FILTERS, payload);
    yield put(dashboardActions.getForecastFiltersSucceeded(response));
  } catch (error) {
    yield put(dashboardActions.getForecastFiltersFailed(error?.message));
  }
}
/**
 * Ping sql warehouse generator function to ping sql warehouse
 * @param {*} param0
 */
export function* pingSqlWarehouseGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_SQL_WAREHOUSE_PING, payload);
    yield put(dashboardActions.pingSqlWareHouseSucceeded(response));
  } catch (error) {
    yield put(dashboardActions.pingSqlWareHouseFailed(error?.message));
  }
}
/**
 * Get system information generator function to obtain general system information
 * @param {*} param0
 */
export function* getSystemInformationGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_SYSTEM_INFORMATION, payload);
    yield put(dashboardActions.getSystemInformationSucceeded(response));
  } catch (error) {
    yield put(dashboardActions.getSystemInformationFailed(error?.message));
  }
}

/**
 * Get Utm Breakdown data generator function to obtain data for utm breakdown dropdown
 * @param {*} param0
 */
export function* getUtmBreakdownGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ALL_UTM_BREAKDOWNS, payload);
    yield put(dashboardActions.getUtmBreakdownSucceeded(response));
  } catch (error) {
    yield put(dashboardActions.getUtmBreakdownFailed(error?.message));
  }
}
/**
 * Save segment filter generator function to save segment filter combination
 * @param {*} param0
 */
export function* saveCommandUrlFilterGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_COMMAND_FILTER, payload);
    yield put(dashboardActions.saveCommandUrlSucceeded(response));
    if (response) {
      // TODO:  Need to remove this after change the response form BE
      const query = `limit=${PAGINATION.HOTELS_PER_PAGE_COUNT}&page=0`;
      yield put(
        dashboardActions.getCommandFilterDropDownList({
          organizationId: payload?.organizationId,
          userId: payload?.userId,
          query,
        })
      );
    }
  } catch (error) {
    yield put(dashboardActions.saveCommandUrlFailed(error?.message));
  }
}
/**
 * Get command filter generator function to obtain saved command filter combination
 * @param {*} param0
 */
export function* getCommandFilterGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_COMMAND_FILTER, payload);
    yield put(dashboardActions.getCommandFilterSucceeded(response));
  } catch (error) {
    yield put(dashboardActions.getCommandFilterFailed(error?.message));
  }
}
/**
 * Get command filter generator function to obtain saved command filter combination
 * @param {*} param0
 */
export function* getCommandFilterDropdownListGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_COMMAND_FILTER, payload);
    yield put(dashboardActions.getCommandFilterDropDownListSucceeded(response));
  } catch (error) {
    yield put(dashboardActions.getCommandFilterDropDownListFailed(error?.message));
  }
}
/**
 * delete command filter generator function to obtain saved command filter combination
 * @param {*} param0
 */
export function* deleteCommandFilterGenerator({ payload }) {
  try {
    const response = yield call(request, API.DELETE_COMMAND_FILTER, payload);
    yield put(
      dashboardActions.deleteCommandFilterSucceeded({
        response,
        data: payload,
      })
    );
    // TODO:  Need to remove this after change the response form BE
    const query = `limit=${PAGINATION.AGGREGATION_FILTER_PER_PAGE}&page=0`;
    yield put(
      dashboardActions.getCommandFilter({
        organizationId: payload.organizationId,
        userId: payload.userId,
        query,
      })
    );
  } catch (error) {
    yield put(dashboardActions.deleteCommandFilterFailed(error?.message));
  }
}
/**
 * Get command scroll filter generator function to obtain saved command filter combination
 * @param {*} param0
 */
export function* getCommandScrollFilterGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_COMMAND_FILTER, payload);
    yield put(dashboardActions.getScrollCommandFilterSucceeded(response));
  } catch (error) {
    yield put(dashboardActions.getScrollCommandFilterFailed(error?.message));
  }
}
/**
 * Get hotel groups generator function to get all hotel groups
 * @param {*} param0
 */
export function* getAllHotelGroupsGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ALL_HOTEL_GROUPS, payload);
    yield put(dashboardActions.getHotelGroupListSucceeded(response));
  } catch (error) {
    yield put(dashboardActions.getHotelGroupListFailed(error?.message));
  }
}
/**
 * Create hotel group generator function to obtain registered visuals
 * @param {*} param0
 */
export function* createHotelGroupGenerator({ payload }) {
  try {
    const response = yield call(request, API.POST_HOTEL_GROUP, payload);
    yield put(dashboardActions.createHotelGroupSucceeded(response));
    // TODO:  Need to remove this after change the response form BE
    if (payload?.organizationId && payload?.userId) {
      const query = `limit=${PAGINATION.HOTELS_PER_PAGE_COUNT}&page=0`;
      yield put(
        dashboardActions.getHotelGroupList({
          organizationId: payload?.organizationId,
          userId: payload?.userId,
          query,
        })
      );
    }
  } catch (error) {
    yield put(dashboardActions.createHotelGroupFailed(error?.message));
  }
}
/**
 * delete hotel group generator function to delete hotel group
 * @param {*} param0
 */
export function* deleteHotelGroupGenerator({ payload }) {
  try {
    const response = yield call(request, API.DELETE_HOTEL_GROUP, payload);
    yield put(
      dashboardActions.deleteHotelGroupSucceeded({
        response,
        data: payload,
      })
    );
    // TODO:  Need to remove this after change the response form BE
    if (payload?.organizationId && payload?.userId) {
      const query = `limit=${PAGINATION.AGGREGATION_FILTER_PER_PAGE}&page=0`;
      yield put(
        dashboardActions.getLoadHotelGroupList({
          organizationId: payload?.organizationId,
          userId: payload?.userId,
          query,
        })
      );
      const query2 = `limit=${PAGINATION.HOTELS_PER_PAGE_COUNT}&page=0`;
      yield put(
        dashboardActions.getHotelGroupList({
          organizationId: payload?.organizationId,
          userId: payload?.userId,
          query: query2,
        })
      );
    }
  } catch (error) {
    yield put(dashboardActions.deleteHotelGroupFailed(error?.message));
  }
}
/**
 * validate hotel group name generator function to check group  name already exist or not
 * @param {*} param0
 */
export function* validateHotelGroupNameGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ALL_HOTEL_GROUPS, payload);
    yield put(dashboardActions.getHotelGroupNameValidationSucceeded(response));
  } catch (error) {
    yield put(dashboardActions.getHotelGroupNameValidationFailed(error?.message));
  }
}
/**
 * get hotel groups list generator function to load hotel groups
 * @param {*} param0
 */
export function* getHotelGroupsListGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_ALL_HOTEL_GROUPS, payload);
    yield put(dashboardActions.getLoadHotelGroupListSucceeded(response));
  } catch (error) {
    yield put(dashboardActions.getLoadHotelGroupListFailed(error?.message));
  }
}
/**
 * get notifications list based on the user
 */
export function* getNotificationsListGenerator({ payload }) {
  try {
    const response = yield call(request, API.GET_NOTIFICATIONS, payload);
    yield put(dashboardActions.getNotificationsListSucceeded(response));
  } catch (error) {
    yield put(dashboardActions.getNotificationsListFailed(error?.message));
  }
}
/**
 * update notification by id
 */
export function* updateNotificationGenerator({ payload }) {
  try {
    const response = yield call(request, API.UPDATE_NOTIFICATION, payload);
    yield put(dashboardActions.updateNotificationSucceeded(response));
  } catch (error) {
    yield put(dashboardActions.updateNotificationFailed(error?.message));
  }
}
//
export function* dashboardSaga() {
  yield takeLatest(dashboardActions.getEmbedToken.type, getEmbedTokenGenerator);
  yield takeLatest(dashboardActions.getVisuals.type, getVisualsGenerator);
  yield takeLatest(dashboardActions.getVisualFilters.type, getVisualFiltersGenerator);
  yield takeLatest(dashboardActions.getHotelList.type, getAllHotelsGenerator);
  yield takeLatest(dashboardActions.getBreakdown.type, getBreakdownGenerator);
  yield takeLatest(dashboardActions.getForecastFilters.type, getForecastFilterGenerator);
  yield takeLatest(
    dashboardActions.getLatestUpdatedHotelList.type,
    getLatestUpdatedAHotelsGenerator
  );
  yield takeLatest(dashboardActions.pingSqlWareHouse.type, pingSqlWarehouseGenerator);
  yield takeLatest(dashboardActions.getSystemInformation.type, getSystemInformationGenerator);
  yield takeLatest(dashboardActions.getUtmBreakdown.type, getUtmBreakdownGenerator);
  yield takeLatest(dashboardActions.saveCommandUrlFilter.type, saveCommandUrlFilterGenerator);
  yield takeLatest(dashboardActions.getCommandFilter.type, getCommandFilterGenerator);
  yield takeLatest(dashboardActions.deleteCommandFilter.type, deleteCommandFilterGenerator);
  yield takeLatest(dashboardActions.getScrollCommandFilter.type, getCommandScrollFilterGenerator);
  yield takeLatest(dashboardActions.getHotelGroupList.type, getAllHotelGroupsGenerator);
  yield takeLatest(dashboardActions.getLoadHotelGroupList.type, getHotelGroupsListGenerator);
  yield takeLatest(dashboardActions.createHotelGroup.type, createHotelGroupGenerator);
  yield takeLatest(dashboardActions.deleteHotelGroup.type, deleteHotelGroupGenerator);
  yield takeLatest(
    dashboardActions.getHotelGroupNameValidation.type,
    validateHotelGroupNameGenerator
  );
  yield takeLatest(
    dashboardActions.getCommandFilterDropDownList.type,
    getCommandFilterDropdownListGenerator
  );
  yield takeLatest(dashboardActions.getNotificationsList.type, getNotificationsListGenerator);
  yield takeLatest(dashboardActions.updateNotification.type, updateNotificationGenerator);
}
//
export default dashboardSaga;
