import format from 'date-fns/format';
import { ENVIRONMENT } from 'config';
import { DATE_PATTERN, YEAR_MONTH_DAY_PATTERN } from 'modules/common/constants/date-range';
import useKeyboardShortcut from 'use-keyboard-shortcut';
import {
  differenceInCalendarDays,
  addDays,
  subDays,
  differenceInDays,
  getDaysInMonth,
  subMonths,
  endOfMonth,
  isFirstDayOfMonth,
  startOfMonth,
  isSameMonth,
  isLastDayOfMonth,
  addMonths,
  sub,
} from 'date-fns';
import COLUMN_NAMES from 'modules/common/constants/power-bi';
import {
  DEFAULT_CUMULATIVE_VISUAL_ORDER,
  DEFAULT_VISUALS_PER_PAGE,
  KEYS,
  OPERATORS,
  REPORT_TYPE,
  TAGS,
} from 'modules/dashboard/constants';
import { getGroupSetValues } from 'modules/common/components/add-hotel-group/functions';
//
const basicSchema = ENVIRONMENT.PBI_BASIC_SCHEMA;
const advancedSchema = ENVIRONMENT.PBI_ADVANCED_SCHEMA;
/**
 * Schema for date filter
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} tableName
 * @returns
 */
export const dateFilter = (
  startDate,
  endDate,
  tableName,
  columnName = COLUMN_NAMES.BOOKING_AND_TARGET_DATE
) => ({
  $schema: advancedSchema,
  target: {
    table: tableName,
    column: columnName,
  },
  logicalOperator: OPERATORS.AND,
  conditions: [
    {
      operator: OPERATORS.GREATER_OR_EQUAL,
      value: `${format(new Date(startDate), YEAR_MONTH_DAY_PATTERN)}T00:00:00.000`,
    },
    {
      operator: OPERATORS.LESS_OR_EQUAL,
      value: `${format(new Date(endDate), YEAR_MONTH_DAY_PATTERN)}T00:00:00.000`,
    },
  ],
});
/**
 * Filter schema to obtain only the selected values from Power BI
 * @param {*} tableName
 * @param {*} columnName
 * @param {*} values
 * @param {*} operator
 * @returns
 */
export const nameFilter = (tableName, columnName, values, operator = OPERATORS.IN) => ({
  $schema: basicSchema,
  target: {
    table: tableName,
    column: columnName,
  },
  operator,
  values,
  filterType: 1,
});
/**
 * Filter schema to select all values from Power BI
 * @param {*} tableName
 * @param {*} columnName
 * @returns
 */
export const allFilter = (tableName, columnName) => ({
  $schema: basicSchema,
  target: {
    table: tableName,
    column: columnName,
  },
  operator: OPERATORS.ALL,
  values: [],
});
/**
 * Date label formatted and arranged as 'fromDate to toDate'
 * @param {*} item
 * @param {*} pattern
 * @returns
 */
export const formatDateLabel = (item, pattern = DATE_PATTERN) =>
  `${format(new Date(item.startDate), pattern)} to ${format(new Date(item.endDate), pattern)}`;

/**
 * Formatted date according to given pattern
 * @param {*} item
 * @param {*} pattern
 * @returns
 */
export const formatDate = (item, pattern = DATE_PATTERN) => format(new Date(item), pattern);
/**
 * Date string without timestamp
 * @param {*} item
 * @returns
 */
export const toDate = (item) => new Date(item).toISOString().split('T')[0];
/**
 * Short cut function for filter operations
 * @param {*} value - tab that triggered the function
 * @param {*} active - active tab
 * @param {*} key - short cut key
 * @param {*} callback - callback function
 */
export const shortcut = (value, active, key, callback) => {
  if (value === active) {
    useKeyboardShortcut(key, callback, {
      overrideSystem: true,
      ignoreInputFields: true,
      repeatOnHold: false,
    });
  } else {
    useKeyboardShortcut(key, () => {});
  }
};
/**
 * Time out function to set delay
 * @param {*} delay
 * @returns
 */
export const timeout = async (delay) => (res) => setTimeout(res, delay);
/**
 * get comparison date function to calculate comparison dates based on selected stay date gap
 * @param {Date} selectedDate
 * @param {Date} startDate
 * @param {Date} endDate
 * @param {String} customDate
 * @returns { comparisonStartDate, comparisonEndDate }
 */
export const getComparisonDateRange = (selectedDate, startDate, endDate, customDate) => {
  const noOfDays = differenceInCalendarDays(new Date(endDate), new Date(startDate));
  //
  let comparisonStartDate;
  let comparisonEndDate;
  if (customDate === KEYS.START_DATE) {
    comparisonStartDate = new Date(selectedDate);
    comparisonEndDate = addDays(new Date(selectedDate), noOfDays);
  } else if (customDate === KEYS.END_DATE) {
    comparisonStartDate = subDays(new Date(selectedDate), noOfDays);
    comparisonEndDate = new Date(selectedDate);
  }
  return { comparisonStartDate, comparisonEndDate };
};
/**
 * Get previous month date range for scroll navigation
 * @param {*} startDate
 * @param {*} endDate
 * @returns { newStartDate, newEndDate }
 */
export const getPreviousMonthStayDate = (startDate, endDate) => {
  let newEndDate;
  let newStartDate;
  const range = differenceInDays(new Date(endDate), new Date(startDate));
  const startDay = new Date(startDate).getDate();
  const endDay = new Date(endDate).getDate();
  const daysInStartMonth = getDaysInMonth(subMonths(new Date(startDate), 1));
  const daysInEndMonth = getDaysInMonth(subMonths(new Date(endDate), 1));
  newStartDate = subDays(new Date(startDate), daysInStartMonth);
  newEndDate = subDays(new Date(endDate), daysInEndMonth);
  //
  if (
    isSameMonth(new Date(startDate), new Date(endDate)) &&
    isFirstDayOfMonth(new Date(startDate)) &&
    isLastDayOfMonth(new Date(endDate))
  ) {
    newStartDate = startOfMonth(subMonths(new Date(endDate), 1));
    newEndDate = endOfMonth(subMonths(new Date(endDate), 1));
  } else if (
    isSameMonth(new Date(startDate), new Date(endDate)) &&
    !isFirstDayOfMonth(new Date(startDate)) &&
    startDay <= getDaysInMonth(subMonths(new Date(startDate), 1)) &&
    endDay <= getDaysInMonth(subMonths(new Date(endDate), 1))
  ) {
    newStartDate = new Date(subMonths(new Date(startDate), 1).setDate(startDay));
    newEndDate = new Date(subMonths(new Date(endDate), 1).setDate(endDay));
  } else if (
    !isFirstDayOfMonth(new Date(startDate)) &&
    startDay <= getDaysInMonth(subMonths(new Date(startDate), 1)) &&
    endDay !== subMonths(new Date(endDate), 1).getDate() &&
    endDay > getDaysInMonth(subMonths(new Date(endDate), 1))
  ) {
    newStartDate = new Date(subMonths(new Date(startDate), 1).setDate(startDay));
    newEndDate = addDays(newStartDate, range);
  } else if (isFirstDayOfMonth(new Date(startDate)) && isLastDayOfMonth(new Date(endDate))) {
    newStartDate = startOfMonth(subMonths(new Date(startDate), 1));
    newEndDate = endOfMonth(subMonths(new Date(endDate), 1));
  } else if (
    isFirstDayOfMonth(new Date(startDate)) &&
    startDay <= getDaysInMonth(addMonths(new Date(startDate), 1)) &&
    endDay <= getDaysInMonth(addMonths(new Date(endDate), 1))
  ) {
    newStartDate = startOfMonth(subMonths(new Date(startDate), 1));
    newEndDate = addDays(newStartDate, range);
  }
  return { newStartDate, newEndDate };
};
/**
 * Get next month date range for scroll navigation
 * @param {*} startDate
 * @param {*} endDate
 * @returns { newStartDate, newEndDate }
 */
export const getNextMonthStayDate = (startDate, endDate) => {
  let newEndDate;
  let newStartDate;
  const range = differenceInDays(new Date(endDate), new Date(startDate));
  const startDay = new Date(startDate).getDate();
  const endDay = new Date(endDate).getDate();
  const nextMonthStartDate = addMonths(new Date(startDate), 1);
  const nextMonthEndDate = addMonths(new Date(endDate), 1);
  const daysInStartMonth = getDaysInMonth(nextMonthStartDate);
  const daysInEndMonth = getDaysInMonth(nextMonthEndDate);
  newStartDate = addDays(new Date(startDate), daysInStartMonth);
  newEndDate = addDays(new Date(endDate), daysInEndMonth);
  //
  if (
    isSameMonth(new Date(startDate), new Date(endDate)) &&
    isFirstDayOfMonth(new Date(startDate)) &&
    isLastDayOfMonth(new Date(endDate))
  ) {
    newStartDate = startOfMonth(addMonths(new Date(startDate), 1));
    newEndDate = endOfMonth(addMonths(new Date(endDate), 1));
  } else if (
    isSameMonth(new Date(startDate), new Date(endDate)) &&
    isFirstDayOfMonth(new Date(startDate)) &&
    endDay !== addMonths(new Date(endDate), 1).getDate() &&
    endDay > getDaysInMonth(addMonths(new Date(endDate), 1))
  ) {
    newStartDate = startOfMonth(addMonths(new Date(startDate), 1));
    newEndDate = endOfMonth(addMonths(new Date(endDate), 1));
  } else if (
    isFirstDayOfMonth(new Date(startDate)) &&
    startDay <= getDaysInMonth(addMonths(new Date(startDate), 1)) &&
    endDay <= getDaysInMonth(addMonths(new Date(endDate), 1))
  ) {
    newStartDate = startOfMonth(addMonths(new Date(startDate), 1));
    newEndDate = addDays(newStartDate, range);
  } else if (
    !isFirstDayOfMonth(new Date(startDate)) &&
    startDay <= getDaysInMonth(addMonths(new Date(startDate), 1)) &&
    endDay <= getDaysInMonth(addMonths(new Date(endDate), 1))
  ) {
    newStartDate = new Date(addMonths(new Date(startDate), 1).setDate(startDay));
    newEndDate = new Date(addMonths(new Date(endDate), 1).setDate(endDay));
  } else if (
    !isFirstDayOfMonth(new Date(startDate)) &&
    startDay !== addMonths(new Date(startDate), 1).getDate() &&
    startDay > getDaysInMonth(addMonths(new Date(startDate), 1))
  ) {
    newStartDate = endOfMonth(new Date(addMonths(new Date(startDate), 1)));
    newEndDate = addDays(newStartDate, range);
  } else if (
    !isFirstDayOfMonth(new Date(startDate)) &&
    endDay > getDaysInMonth(addMonths(new Date(endDate), 1))
  ) {
    newStartDate = new Date(addMonths(new Date(startDate), 1).setDate(startDay));
    newEndDate = addDays(newStartDate, range);
  } else if (isFirstDayOfMonth(new Date(startDate)) && isLastDayOfMonth(new Date(endDate))) {
    newStartDate = startOfMonth(addMonths(new Date(startDate), 1));
    newEndDate = endOfMonth(addMonths(new Date(endDate), 1));
  }
  return { newStartDate, newEndDate };
};
/**
 * Get previous period date range for scroll navigation
 * @param {*} startDate
 * @param {*} endDate
 * @returns { newStartDate, newEndDate }
 */
export const getPreviousPeriodStayDate = (startDate, endDate) => {
  const range = differenceInDays(new Date(endDate), new Date(startDate));
  const newEndDate = subDays(new Date(startDate), 1);
  const newStartDate = subDays(newEndDate, range);
  return { newStartDate, newEndDate };
};
/**
 * Get next period date range for scroll navigation
 * @param {*} startDate
 * @param {*} endDate
 * @returns { newStartDate, newEndDate }
 */
export const getNextPeriodStayDate = (startDate, endDate) => {
  const range = differenceInDays(new Date(endDate), new Date(startDate));
  const newStartDate = addDays(new Date(endDate), 1);
  const newEndDate = addDays(newStartDate, range);
  return { newStartDate, newEndDate };
};

/**
 * Getting visual data into the component
 * @param {*} index
 * @param {*} isCumulative
 * @param {*} weeklyViewToggle
 * @param {*} weeklyVisuals
 * @param {*} visuals
 * @param {*} perTabVisuals
 * @returns
 */
export const getVisual = (
  index,
  isCumulative,
  weeklyViewToggle,
  weeklyVisuals,
  visuals,
  cumulativeVisualsOrder = DEFAULT_CUMULATIVE_VISUAL_ORDER,
  perTabVisuals = DEFAULT_VISUALS_PER_PAGE
) => {
  let found;
  if (isCumulative) {
    found = weeklyViewToggle
      ? weeklyVisuals?.find(
          (visual) => visual?.tags?.includes(REPORT_TYPE.CUMULATIVE) && visual?.order === index
        )
      : visuals?.find(
          (visual) => visual?.tags?.includes(REPORT_TYPE.CUMULATIVE) && visual?.order === index
        );
  } else if (weeklyViewToggle) {
    const clonedArray = JSON.parse(JSON.stringify(weeklyVisuals));
    // why we get the unique, because same visuals there
    const uniqueVisuals = [
      ...new Map(clonedArray.map((element) => [element.order, element])).values(),
    ];

    const firstPart = uniqueVisuals.slice(0, perTabVisuals);
    const secondPart = uniqueVisuals.slice(perTabVisuals, perTabVisuals + uniqueVisuals.length);
    found = weeklyVisuals?.find((visual) => {
      if (
        visual?.order === firstPart[0]?.order ||
        visual?.order === secondPart[0]?.order ||
        visual?.order === firstPart[1]?.order ||
        visual?.order === secondPart[1]?.order ||
        visual?.order === firstPart[cumulativeVisualsOrder]?.order ||
        visual?.order === secondPart[cumulativeVisualsOrder]?.order
      ) {
        if (!visual?.tags?.includes(REPORT_TYPE.CUMULATIVE) && visual?.order === index) {
          return visual;
        }
      } else if (visual?.order === index) {
        return visual;
      }
      return null;
    });
  } else {
    const clonedArray = JSON.parse(JSON.stringify(visuals));
    // why we get the unique, because same visuals there
    const uniqueVisuals = [
      ...new Map(clonedArray.map((element) => [element.order, element])).values(),
    ];
    const firstPart = uniqueVisuals.slice(0, perTabVisuals);
    const secondPart = uniqueVisuals.slice(perTabVisuals, perTabVisuals + uniqueVisuals.length);
    found = visuals?.find((visual) => {
      if (
        visual?.order === firstPart[0]?.order ||
        visual?.order === secondPart[0]?.order ||
        visual?.order === firstPart[1]?.order ||
        visual?.order === secondPart[1]?.order ||
        visual?.order === firstPart[cumulativeVisualsOrder]?.order ||
        visual?.order === secondPart[cumulativeVisualsOrder]?.order
      ) {
        if (!visual?.tags?.includes(REPORT_TYPE.CUMULATIVE) && visual?.order === index) {
          return visual;
        }
      } else if (visual?.order === index) {
        return visual;
      }
      return null;
    });
  }
  //
  return found;
};
/**
 * Get selected breakdown based on isDetailed toggle and selected breakdown type
 * @param {Array} visualFilters
 * @param {String} chartBreakdown
 * @param {Boolean} isDetailed
 * @returns
 */
export const getSelectedBreakdown = (visualFilters, chartBreakdown, isDetailed) => {
  const breakdown = visualFilters?.find((filter) => filter?.id === chartBreakdown);
  //
  const type = breakdown?.type;
  const subType = breakdown?.values?.[0]?.value;
  //
  if (isDetailed) {
    if (breakdown?.tags?.includes(TAGS.DETAILED)) {
      return type;
    }
    return subType;
  }
  if (breakdown?.tags?.includes(TAGS.DETAILED)) {
    return subType;
  }
  return type;
};

/**
 * Get date based on time frame
 * @param {String} timeFrame - selected time frame
 * @param {String} date - date to adjust based on time frame
 * @returns
 */
export const getDateBasedOnTimeFrame = (timeFrame, date) => {
  let operator;
  //
  if (timeFrame) {
    if (timeFrame?.includes('year')) operator = 'years';
    if (timeFrame?.includes('month')) operator = 'months';
    //
    return sub(new Date(date), {
      [operator]: timeFrame.split(' ')[0],
    });
  }
  return date;
};
/**
 * To get Hotel List and focus on values
 * @param {Boolean} isDemoUser - Whether current user is a demo user
 * @param {Object} payload - Payload of getting hotels list action
 * @returns
 */
export const getHotelFocusOnValue = (isDemoUser, payload) => {
  let focusOnValues;
  let hotelDropdownList;
  if (!isDemoUser) {
    const hotel = payload?.results[0];
    hotelDropdownList = payload?.results?.map((data) => ({
      label: data?.name,
      id: data?.id,
      pmsDate: data?.pmsStartDate,
      altName: data?.displayName,
      databricksId: data?.databricksHotelId,
    }));
    focusOnValues = [
      {
        id: hotel?.id,
        label: hotel?.name,
        altName: hotel?.displayName,
        databricksId: hotel?.databricksHotelId,
      },
    ];
  } else {
    let hotelList = payload?.results?.sort(
      (element1, element2) =>
        element1.displayName &&
        element2.displayName &&
        Number(element1.displayName.split(' ')[1]) - Number(element2.displayName.split(' ')[1])
    );
    hotelList = hotelList?.filter((data) => data?.displayName);
    hotelDropdownList = hotelList?.map((data) => ({
      label: data?.displayName,
      id: data?.id,
      pmsDate: data?.pmsStartDate,
      altName: data?.name,
      databricksId: data?.databricksHotelId,
    }));
    //
    focusOnValues = [
      {
        id: hotelList[0]?.id,
        label: hotelList[0]?.displayName,
        altName: hotelList[0]?.name,
        databricksId: hotelList[0]?.databricksHotelId,
      },
    ];
  }
  return { focusOnValues, hotelDropdownList };
};
/**
 * To get visual name by visual order
 * @param {Array} visuals
 * @param {Number} order
 * @returns
 */
export const getVisualName = (visuals, order) =>
  visuals.find((visual) => visual?.order === order)?.name;

/**
 * To get Hotel List and focus on values
 * @param {Boolean} isDemoUser - Whether current user is a demo user
 * @param {Object} payload - Payload of getting hotels list action
 * @returns
 */
export const getHotelGroupFocusOnValue = (payload, hotelList) => {
  const hotelGroupDropdownList =
    payload?.results
      ?.filter((a) => a?.hotelGroups?.hotelQuery)
      ?.map((data) => ({
        id: data?.hotelGroups?.hotelGroupName,
        label: data?.hotelGroups?.hotelGroupName,
        hotels: getGroupSetValues(data?.hotelGroups?.hotelQuery?.toLowerCase(), hotelList),
      }))
      ?.sort(
        (element1, element2) =>
          element1.label && element2.label && element1.label.localeCompare(element2.label)
      ) ?? [];
  const focusOnValues = [
    {
      id: hotelGroupDropdownList?.[0]?.id,
      label: hotelGroupDropdownList?.[0]?.label,
      hotels: hotelGroupDropdownList?.[0]?.hotels,
    },
  ];
  //
  return { focusOnValues, hotelGroupDropdownList };
};
