import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectEmbedToken, selectTrendTargetDate } from 'modules/dashboard/selectors';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import { formatDate, getVisualName } from 'modules/dashboard/functions';
import VisualCard from 'modules/dashboard/components/tab-container/visual-card';
import {
  selectAdjustedEndDate,
  selectAdjustedStartDate,
  selectGranularity,
  selectTrendTimeFilterList,
  selectTrendTimeVisuals,
} from '../../../selectors';
import TrendTabularFilter from '../../filter-panel';
import TrendTimeTabularWidget from './trend-time-widget/tabular-visual-view';
import { GRANULARITY } from '../../../constants';

/**
 * Trend tabular widget implementation
 * @param {Number} value - Trend time widget tab value
 * @param {Boolean} toggleDisabled - PMS sync toggle disabled state
 * @param {Date} latestDate - Latest booking date of selected hotel
 * @param {String} hotelTimezone - Timezone of selected hotel
 * @param {String} reportType - Report type of active tab
 * @returns
 */
const Widget = ({ value, latestDate, reportType, hotelTimezone, toggleDisabled }) => {
  // States are obtained from redux store. Refer each selector function for details.
  const filterList = useSelector(selectTrendTimeFilterList);
  const tokenDetails = useSelector(selectEmbedToken);
  const visuals = useSelector(selectTrendTimeVisuals);
  const targetDate = useSelector(selectTrendTargetDate);
  const adjustedStartDate = useSelector(selectAdjustedStartDate);
  const adjustedEndDate = useSelector(selectAdjustedEndDate);
  const granularity = useSelector(selectGranularity);
  //
  const [label, setLabel] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  // Triggered when startDate, endDate, targetDate, weeklyViewToggle, isCumulative values change
  useEffect(() => {
    // Show adjusted stay dates based on granularity
    if (granularity !== GRANULARITY.DAILY) {
      setLabel(
        `Stay date selection adjusted.  New stay date range from: ${formatDate(
          adjustedStartDate
        )} to ${formatDate(adjustedEndDate)}`
      );
    } else {
      setLabel(null);
    }
  }, [targetDate, adjustedStartDate, adjustedEndDate, granularity]);

  // To get visuals based on the configuration
  const getWidget = (index, cssClassName) => {
    const found = visuals?.find((visual) => visual?.order === index);
    //
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={tokenDetails?.accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filterList}
        />
      )
    );
  };
  //
  return (
    <>
      <Grid item xs={2}>
        <TrendTabularFilter
          reportType={reportType}
          visual={getWidget(100, styles.symmetricComparisonWindow)}
          visualName={getVisualName(visuals, 100)}
          tabValue={value}
          toggleDisabled={toggleDisabled}
          latestDate={latestDate}
          hotelTimezone={hotelTimezone}
          isLoaded={isLoaded}
        />
      </Grid>
      <Grid container item xs={10} direction="column">
        <Grid item container justifyContent="space-around">
          {label && (
            <Typography paddingBottom={1} className={styles.weeklyLabel}>
              {label}
            </Typography>
          )}
        </Grid>
        <TrendTimeTabularWidget setIsTabularViewLoaded={setIsLoaded} />
      </Grid>
    </>
  );
};
//
export default Widget;
