import { isEmpty } from 'modules/common/helpers/object';
import * as yup from 'yup';
/**
 * Query box form validations define here
 */

const queryBoxValidation = yup.object().shape({
  setA: yup
    .string()
    .nullable()
    .matches(/^[!=]{1,2}/, 'Query should start with = or != operator')
    .matches(
      /^[/^[\w!%-='"\s]*$/,
      'Query cannot contain special characters other than ! =  % \' " '
    )
    .test('starting-operator', 'Query should start with = or != operator', (value) => {
      if (value && value.charAt(0) === '=') return true;
      if (value && value.charAt(0) === '!' && value.charAt(1) === '=') return true;
      return true;
    })
    .test('space-after-operator', 'Enter a space after the operator', (value) => {
      if (value && value.charAt(0) === '=') return value.charAt(1) === ' ';
      if (value && value.charAt(0) === '!') return value.charAt(2) === ' ';
      return true;
    })
    .matches(/^((?!\bOR\b\s*=).)*$/gi, 'OR operator cannot be followed by = != AND OR') // or =
    .matches(/^((?!\bOR\b\s*!=).)*$/gi, 'OR operator cannot be followed by = != AND OR') // or !=
    .matches(/^((?!\bOR\b\s*\s*\bAND\b).)*$/gi, 'OR operator cannot be followed by = != AND OR') // or and
    .matches(/^((?!\bOR\b\s*\s*\bOR\b).)*$/gi, 'OR operator cannot be followed by = != AND OR') // or or
    .matches(/^((?!\bAND\b\s*\s*AND).)*$/gi, 'AND operator cannot be followed by  AND OR') // and and
    .matches(/^((?!\bAND\b\s*\s*\bOR\b).)*$/gi, 'AND operator cannot be followed by AND OR') // and or
    .matches(/^((?!=\s*=).)*$/gi, '= operator cannot be followed by = != AND OR') // = =
    .matches(/^((?!=\s*\s*\bAND\b).)*$/gi, '= operator cannot be followed by  = != AND OR') // = and
    .matches(/^((?!=\s*\bOR\b).)*$/gi, '= operator cannot be followed by  = != AND OR') // = or
    .matches(/^((?!=\s*!=).)*$/gi, '= operator cannot be followed by  = != AND OR') // = !=
    .matches(/^((?!!=\s*\s*\bAND\b).)*$/gi, '!=  operator cannot be followed by  = != AND OR') // != and
    .matches(/^((?!!=\s*=).)*$/gi, '!=  operator cannot be followed by  = != AND OR') // != =
    .matches(/^((?!!=\s*!=).)*$/gi, '!=  operator cannot be followed by  = != AND OR') // != !=
    .matches(/^((?!!=\s*\bOR\b).)*$/gi, '!= operator cannot be followed by  = != AND OR') // != or
    .test('operator-after-and', 'AND operator must be followed by = != operators', (value) => {
      if (value) {
        const split = value
          .toLowerCase()
          .split(' ')
          .filter((filter) => filter !== '');
        if (!isEmpty(split)) {
          const indexes = split.reduce((a, e, i) => {
            if (e === 'and') a.push(i);
            return a;
          }, []);
          const result = [];
          if (!isEmpty(indexes)) {
            indexes.forEach((f) => result.push(split[f + 1] === '=' || split[f + 1] === '!='));
          }
          if (result.includes(false)) {
            return false;
          }
        }
        return true;
      }
      return true;
    })
    .test('and-before-operator', 'Invalid operator combination', (value) => {
      if (value) {
        const split = value
          .toLowerCase()
          .split(' ')
          .filter((filter) => filter !== '')
          .splice(1);
        if (!isEmpty(split)) {
          const indexes = split.reduce((a, e, i) => {
            if (e === '=' || e === '!=') a.push(i);
            return a;
          }, []);
          const result = [];
          if (!isEmpty(indexes)) {
            indexes.forEach((f) => result.push(split[f - 1] === 'and'));
          }
          if (result.includes(false)) {
            return false;
          }
        }
        return true;
      }
      return true;
    })
    .test('operator-after-word', 'Values must be followed by OR or AND operators', (value) => {
      if (value) {
        const split = value
          .toLowerCase()
          .match(/(?:[^\s"]+|"[^"]*")+/g)
          .filter((filter) => filter !== '');
        if (!isEmpty(split)) {
          let operatorItems = [];
          if (!isEmpty(split.filter((f) => f.includes('like')))) {
            let withoutLike = [];
            if (!isEmpty(split.filter((f) => f.includes('and')))) {
              withoutLike = split.filter((e) => e !== 'like' && e !== 'and');
            } else {
              withoutLike = split.filter((e) => e !== 'like');
            }
            operatorItems = withoutLike.filter((item, index) => index % 2 === 0);
          } else if (!isEmpty(split.filter((f) => f.includes('and')))) {
            const withoutAnd = split.filter((e) => e !== 'and');
            operatorItems = withoutAnd.filter((item, index) => index % 2 === 0);
          } else {
            operatorItems = split.filter((item, index) => index % 2 === 0);
          }
          if (operatorItems.every((elem) => ['=', '!=', 'or'].indexOf(elem) > -1)) {
            return true;
          }
          return false;
        }
        return true;
      }
      return true;
    })
    .test('operator-at-end', 'Invalid query, enter values after the operator', (value) => {
      if (value) {
        const split = value
          .toLowerCase()
          .split(' ')
          .filter((filter) => filter !== '');
        if (
          split[split.length - 1] === '=' ||
          split[split.length - 1] === '!=' ||
          split[split.length - 1] === 'or' ||
          split[split.length - 1] === 'and' ||
          split[split.length - 1] === 'like'
        ) {
          return false;
        }
        return true;
      }
      return true;
    }),
  //
  setB: yup
    .string()
    .nullable()
    .matches(/^[!=]{1,2}/, 'Query should start with = or != operator')
    .matches(
      /^[/^[\w!%-='"\s]*$/,
      'Query cannot contain special characters other than ! =  % \' " '
    )
    .test('starting-operator', 'Query should start with = or != operator', (value) => {
      if (value && value.charAt(0) === '=') return true;
      if (value && value.charAt(0) === '!' && value.charAt(1) === '=') return true;
      return true;
    })
    .test('space-after-operator', 'Enter a space after the operator', (value) => {
      if (value && value.charAt(0) === '=') return value.charAt(1) === ' ';
      if (value && value.charAt(0) === '!') return value.charAt(2) === ' ';
      return true;
    })
    .matches(/^((?!\bOR\b\s*=).)*$/gi, 'OR operator cannot be followed by = != AND OR') // or =
    .matches(/^((?!\bOR\b\s*!=).)*$/gi, 'OR operator cannot be followed by = != AND OR') // or !=
    .matches(/^((?!\bOR\b\s*\s*\bAND\b).)*$/gi, 'OR operator cannot be followed by = != AND OR') // or and
    .matches(/^((?!\bOR\b\s*\s*\bOR\b).)*$/gi, 'OR operator cannot be followed by = != AND OR') // or or
    .matches(/^((?!\bAND\b\s*\s*AND).)*$/gi, 'AND operator cannot be followed by  AND OR') // and and
    .matches(/^((?!\bAND\b\s*\s*\bOR\b).)*$/gi, 'AND operator cannot be followed by AND OR') // and or
    .matches(/^((?!=\s*=).)*$/gi, '= operator cannot be followed by = != AND OR') // = =
    .matches(/^((?!=\s*\s*\bAND\b).)*$/gi, '= operator cannot be followed by  = != AND OR') // = and
    .matches(/^((?!=\s*\bOR\b).)*$/gi, '= operator cannot be followed by  = != AND OR') // = or
    .matches(/^((?!=\s*!=).)*$/gi, '= operator cannot be followed by  = != AND OR') // = !=
    .matches(/^((?!!=\s*\s*\bAND\b).)*$/gi, '!=  operator cannot be followed by  = != AND OR') // != and
    .matches(/^((?!!=\s*=).)*$/gi, '!=  operator cannot be followed by  = != AND OR') // != =
    .matches(/^((?!!=\s*!=).)*$/gi, '!=  operator cannot be followed by  = != AND OR') // != !=
    .matches(/^((?!!=\s*\bOR\b).)*$/gi, '!= operator cannot be followed by  = != AND OR') // != or
    .test('operator-after-and', 'AND operator must be followed by = != operators', (value) => {
      if (value) {
        const split = value
          .toLowerCase()
          .split(' ')
          .filter((filter) => filter !== '');
        if (!isEmpty(split)) {
          const indexes = split.reduce((a, e, i) => {
            if (e === 'and') a.push(i);
            return a;
          }, []);
          const result = [];
          if (!isEmpty(indexes)) {
            indexes.forEach((f) => result.push(split[f + 1] === '=' || split[f + 1] === '!='));
          }
          if (result.includes(false)) {
            return false;
          }
        }
        return true;
      }
      return true;
    })
    .test('and-before-operator', 'Invalid operator combination', (value) => {
      if (value) {
        const split = value
          .toLowerCase()
          .split(' ')
          .filter((filter) => filter !== '')
          .splice(1);
        if (!isEmpty(split)) {
          const indexes = split.reduce((a, e, i) => {
            if (e === '=' || e === '!=') a.push(i);
            return a;
          }, []);
          const result = [];
          if (!isEmpty(indexes)) {
            indexes.forEach((f) => result.push(split[f - 1] === 'and'));
          }
          if (result.includes(false)) {
            return false;
          }
        }
        return true;
      }
      return true;
    })
    .test('operator-after-word', 'Values must be followed by OR or AND operators', (value) => {
      if (value) {
        const split = value
          .toLowerCase()
          .match(/(?:[^\s"]+|"[^"]*")+/g)
          .filter((filter) => filter !== '');
        if (!isEmpty(split)) {
          let operatorItems = [];
          if (!isEmpty(split.filter((f) => f.includes('like')))) {
            let withoutLike = [];
            if (!isEmpty(split.filter((f) => f.includes('and')))) {
              withoutLike = split.filter((e) => e !== 'like' && e !== 'and');
            } else {
              withoutLike = split.filter((e) => e !== 'like');
            }
            operatorItems = withoutLike.filter((item, index) => index % 2 === 0);
          } else if (!isEmpty(split.filter((f) => f.includes('and')))) {
            const withoutAnd = split.filter((e) => e !== 'and');
            operatorItems = withoutAnd.filter((item, index) => index % 2 === 0);
          } else {
            operatorItems = split.filter((item, index) => index % 2 === 0);
          }
          if (operatorItems.every((elem) => ['=', '!=', 'or'].indexOf(elem) > -1)) {
            return true;
          }
          return false;
        }
        return true;
      }
      return true;
    })
    .test('operator-at-end', 'Invalid query, enter values after the operator', (value) => {
      if (value) {
        const split = value
          .toLowerCase()
          .split(' ')
          .filter((filter) => filter !== '');
        if (
          split[split.length - 1] === '=' ||
          split[split.length - 1] === '!=' ||
          split[split.length - 1] === 'or' ||
          split[split.length - 1] === 'and' ||
          split[split.length - 1] === 'like'
        ) {
          return false;
        }
        return true;
      }
      return true;
    }),
});
//
export default queryBoxValidation;
