import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectVisualFilters,
  selectPmsSync,
  selectTargetDate,
  selectPaceHorizon,
  selectHotelName,
  selectSelectedHotelGroupName,
  selectEnableHotelGroups,
} from 'modules/dashboard/selectors';
import {
  selectPaceAlignmentToggle,
  selectPaceDateComparison,
  selectPaceSymmetricComparisonToggle,
  selectPercentage,
  selectIsPaceDetailed,
  selectPaceChartBreakdown,
  selectPaceFocusOn,
  selectPaceLagAligned,
  selectPaceCumulativeView,
  selectIsPrimary,
  selectSymmetricComparisonIsDisplay,
  selectIsForecast,
  selectRevenueIsDisplayPS,
  selectOccupiedRoomsIsDisplayPS,
  selectAdrIsDisplayPS,
  selectRevenueIsDisplayPT,
  selectOccupiedRoomsIsDisplayPT,
  selectAdrIsDisplayPT,
  selectCxlIsDisplayPT,
  selectCxlIsDisplayPS,
  selectPaceBookingPaceWindow,
  selectPaceBookingDate,
  selectHotelGroupFocusOn,
  selectEnableSecondaryHotelGroups,
  selectNormalizedView,
  selectHotelFocusOn,
  selectActivePaceTab,
  selectRevenueIsDisplayPH,
  selectOccupiedRoomsIsDisplayPH,
  selectAdrIsDisplayPH,
  selectCxlIsDisplayPH,
} from '../selectors';
import { paceActions } from '../slice';

import { generatePaceShareUrl } from '../../inner-filter/functions';

/**
 * Custom hook to generate share url in pace tab
 */
const usePaceShareUrl = () => {
  const dispatch = useDispatch();
  //
  const targetDate = useSelector(selectTargetDate);
  const hotelId = useSelector(selectHotelName);
  const visualFilters = useSelector(selectVisualFilters);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const paceHorizon = useSelector(selectPaceHorizon);
  const focusOn = useSelector(selectPaceFocusOn);
  const chartBreakdown = useSelector(selectPaceChartBreakdown);
  const isDetailed = useSelector(selectIsPaceDetailed);
  const lagAligned = useSelector(selectPaceLagAligned);
  const isCumulative = useSelector(selectPaceCumulativeView);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const hotelGroupId = useSelector(selectSelectedHotelGroupName);
  const dateComparison = useSelector(selectPaceDateComparison);
  const percentage = useSelector(selectPercentage);
  const symmetricComparisonToggle = useSelector(selectPaceSymmetricComparisonToggle);
  const alignmentToggle = useSelector(selectPaceAlignmentToggle);
  const compareWindowCollapseToggle = useSelector(selectSymmetricComparisonIsDisplay);
  const isPrimary = useSelector(selectIsPrimary);
  const isForecast = useSelector(selectIsForecast);
  const paceWindowSelection = useSelector(selectPaceBookingPaceWindow);
  const bookingDate = useSelector(selectPaceBookingDate);
  //
  const revenueIsDisplayPS = useSelector(selectRevenueIsDisplayPS);
  const occupiedRoomsIsDisplayPS = useSelector(selectOccupiedRoomsIsDisplayPS);
  const adrIsDisplayPS = useSelector(selectAdrIsDisplayPS);
  const cxlIsDisplayPS = useSelector(selectCxlIsDisplayPS);
  //
  const revenueIsDisplayPT = useSelector(selectRevenueIsDisplayPT);
  const occupiedRoomsIsDisplayPT = useSelector(selectOccupiedRoomsIsDisplayPT);
  const adrIsDisplayPT = useSelector(selectAdrIsDisplayPT);
  const cxlIsDisplayPT = useSelector(selectCxlIsDisplayPT);
  //
  const revenueIsDisplayPH = useSelector(selectRevenueIsDisplayPH);
  const occupiedRoomsIsDisplayPH = useSelector(selectOccupiedRoomsIsDisplayPH);
  const adrIsDisplayPH = useSelector(selectAdrIsDisplayPH);
  const cxlIsDisplayPH = useSelector(selectCxlIsDisplayPH);
  //
  const activeTab = useSelector(selectActivePaceTab);
  const hotelGroupFocusOn = useSelector(selectHotelGroupFocusOn);
  const hotelFocusOn = useSelector(selectHotelFocusOn);
  const enableSecondaryHotelGroups = useSelector(selectEnableSecondaryHotelGroups);
  const normalizedViewToggle = useSelector(selectNormalizedView);

  // Triggers when filter values dependencies changes
  useEffect(() => {
    if (!targetDate && !hotelId && !paceHorizon) return;
    // Generate pace widget filter url
    const paceWidgetUrl = generatePaceShareUrl(
      pmsSyncToggle,
      focusOn,
      lagAligned,
      isCumulative,
      visualFilters,
      targetDate,
      hotelId,
      paceHorizon,
      enableHotelGroups,
      hotelGroupId,
      percentage,
      alignmentToggle,
      symmetricComparisonToggle,
      dateComparison,
      chartBreakdown,
      isDetailed,
      compareWindowCollapseToggle,
      isPrimary,
      isForecast,
      revenueIsDisplayPS,
      occupiedRoomsIsDisplayPS,
      adrIsDisplayPS,
      cxlIsDisplayPS,
      revenueIsDisplayPT,
      occupiedRoomsIsDisplayPT,
      adrIsDisplayPT,
      cxlIsDisplayPT,
      paceWindowSelection,
      bookingDate,
      hotelGroupFocusOn,
      hotelFocusOn,
      enableSecondaryHotelGroups,
      normalizedViewToggle,
      activeTab,
      revenueIsDisplayPH,
      occupiedRoomsIsDisplayPH,
      adrIsDisplayPH,
      cxlIsDisplayPH
    );
    //
    dispatch(paceActions.setPaceWidgetShareUrl(paceWidgetUrl));
  }, [
    pmsSyncToggle,
    focusOn,
    lagAligned,
    isCumulative,
    visualFilters,
    targetDate,
    hotelId,
    paceHorizon,
    enableHotelGroups,
    hotelGroupId,
    percentage,
    alignmentToggle,
    symmetricComparisonToggle,
    dateComparison,
    paceHorizon,
    chartBreakdown,
    isDetailed,
    compareWindowCollapseToggle,
    isPrimary,
    isForecast,
    revenueIsDisplayPS,
    occupiedRoomsIsDisplayPS,
    adrIsDisplayPS,
    cxlIsDisplayPS,
    revenueIsDisplayPT,
    occupiedRoomsIsDisplayPT,
    adrIsDisplayPT,
    cxlIsDisplayPT,
    paceWindowSelection,
    bookingDate,
    hotelGroupFocusOn,
    hotelFocusOn,
    enableSecondaryHotelGroups,
    normalizedViewToggle,
    activeTab,
    revenueIsDisplayPH,
    occupiedRoomsIsDisplayPH,
    adrIsDisplayPH,
    cxlIsDisplayPH,
  ]);
};
//
export default usePaceShareUrl;
