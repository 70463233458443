import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { COLLAPSE_VISUALS, UiController, commands, events } from 'modules/common/ui-controller';
import { KEYS, RESET_BOOKING_DATE } from 'modules/dashboard/constants';
import { getHotelValuesFromList, getValuesFromList } from 'modules/common/utils/array';
import { selectWebClickHotelVisualFilter } from '../selectors';
import { webClickActions } from '../slice';
import { CLICK_STEP_LIST } from '../constants';
import { setCompareWindowCommandValue } from '../../inner-filter/functions';

/**
 * Custom hook to execute web click filter command flow
 * @param {Boolean} isLoaded
 * @param {Boolean} customBooking
 * @param {Array} paceList
 * @param {Function} handleToggleChange
 * @param {Function} handleComparisonChange
 * @param {Array} focusOnList
 * @param {Array} hotelList
 * @param {Object} selectedHotelName
 * @param {*} setCustomDatesRef
 * @param {String} dateComparison
 * @param {Object} selectHotelName
 * @param {Array} visualFilters
 * @param {String} alignment
 * @param {Array} dateComparisonList
 */
const useWebClickCommandFlow = (
  isLoaded,
  customBooking,
  paceList,
  handleToggleChange,
  handleComparisonChange,
  focusOnList,
  hotelList,
  selectedHotelName,
  setCustomDatesRef,
  dateComparison,
  selectHotelName,
  visualFilters,
  alignment,
  dateComparisonList,
  hotelGroupList,
  utmFocusOnList,
  utmBreakdownList,
  setScaleInputValue
) => {
  const dispatch = useDispatch();
  const filterList = useSelector(selectWebClickHotelVisualFilter);
  // Local states to set command flow values
  const [compareWindowValueCommand, setCompareWindowValueCommand] = useState(null);
  const [options, setOptions] = useState(null);
  const [hotelValue, setHotelValue] = useState(null);
  const [normalizeCommandValue, setNormalizedCommandValue] = useState(null);
  const [customDatesValueCommand, setCustomDatesValueCommand] = useState(null);
  const [stepsCommandValue, setStepsCommandValue] = useState(null);
  const [bookingDateValueCommand, setBookingDateValueCommand] = useState(null);
  const [paceHorizonCommandValue, setPaceHorizonCommandValue] = useState(null);
  const [hotelGroupCommand, setHotelGroupCommand] = useState(null);
  const [symmetricComparisonCollapseCommandValue, setSymmetricComparisonCollapseCommandValue] =
    useState(null);
  const [visualCollapseCommandValue, setVisualCollapseCommandValue] = useState(null);
  const [utmBreakdownValueCommand, setUtmBreakdownValueCommand] = useState(null);
  const [utmFocusOnValueCommand, setUtmFocusOnValueCommand] = useState(null);
  const [scaleValueCommand, setScaleValueCommand] = useState(null);

  // Listener function to set pace horizon command value
  const setWebClickPaceHorizonHandler = (value) => {
    setPaceHorizonCommandValue(value);
  };
  // Listener function to set booking date range command value
  const setWebClickCustomBookingHandler = (value) => {
    setBookingDateValueCommand(value);
  };
  // Triggers when symmetricComparisonCollapseCommandValue, isLoaded change
  useEffect(() => {
    if (symmetricComparisonCollapseCommandValue !== null && isLoaded) {
      dispatch(
        webClickActions.setSymmetricComparisonIsDisplay(
          symmetricComparisonCollapseCommandValue === 1
        )
      );
      setSymmetricComparisonCollapseCommandValue(null);
    }
  }, [symmetricComparisonCollapseCommandValue, isLoaded]);
  // Triggers when visualCollapseCommandValue change
  useEffect(() => {
    if (visualCollapseCommandValue !== null && isLoaded) {
      const visuals = visualCollapseCommandValue?.split(',');
      visuals?.forEach((visual) => {
        // get the visual collapse enable/disable status
        const status = parseInt(visual?.substring(2), 10);
        // get the type of visual view
        const visualName = visual?.substring(0, 2);
        if (visualName && status !== undefined) {
          switch (visualName) {
            case COLLAPSE_VISUALS.TT:
              dispatch(webClickActions.setTimeTrendIsDisplay(status === 1));
              break;
            case COLLAPSE_VISUALS.TP:
              dispatch(webClickActions.setTimePaceIsDisplay(status === 1));
              break;
            case COLLAPSE_VISUALS.ST:
              dispatch(webClickActions.setSegmentTrendIsDisplay(status === 1));
              break;
            case COLLAPSE_VISUALS.SP:
              dispatch(webClickActions.setSegmentPaceIsDisplay(status === 1));
              break;
            case COLLAPSE_VISUALS.HT:
              dispatch(webClickActions.setHotelTrendIsDisplay(status === 1));
              break;
            case COLLAPSE_VISUALS.HP:
              dispatch(webClickActions.setHotelPaceIsDisplay(status === 1));
              break;
            case COLLAPSE_VISUALS.UT:
              dispatch(webClickActions.setUtmTrendIsDisplay(status === 1));
              break;
            case COLLAPSE_VISUALS.UP:
              dispatch(webClickActions.setUtmPaceIsDisplay(status === 1));
              break;
            default:
              break;
          }
        }
      });
      setVisualCollapseCommandValue(null);
    }
  }, [visualCollapseCommandValue, isLoaded]);
  // Triggers when hotelGroupCommand, hotelGroupList, isLoaded values change
  useEffect(() => {
    if (hotelGroupCommand !== null && hotelGroupList?.length > 0 && isLoaded) {
      const hotelGroupsSelected = getHotelValuesFromList(hotelGroupCommand, hotelGroupList);
      if (hotelGroupsSelected?.length > 0) {
        dispatch(webClickActions.setEnableSecondaryHotelGroups(true));
        dispatch(webClickActions.setWebClickHotelGroupFocusOn(hotelGroupsSelected[0]));
      }
      setHotelGroupCommand(null);
    }
  }, [hotelGroupCommand, hotelGroupList?.length, isLoaded]);
  // Triggers when paceHorizonCommandValue, paceList, isLoaded values change
  useEffect(() => {
    if (paceHorizonCommandValue !== null && paceList?.length > 0 && isLoaded) {
      // get pace horizon value
      const paces = paceList?.filter((pace) => pace?.label[0].includes(paceHorizonCommandValue));
      if (paces?.length > 0) {
        dispatch(webClickActions.setWebClickPaceHorizon(paces[0]?.id));
      }
      setPaceHorizonCommandValue(null);
    }
  }, [paceHorizonCommandValue, paceList?.length, isLoaded]);
  // Triggers when customBooking, customBookingCommandValue, isLoaded values change
  useEffect(() => {
    if (
      bookingDateValueCommand !== null &&
      customBooking &&
      bookingDateValueCommand !== commands.OTB &&
      isLoaded
    ) {
      // emit event to set booking date range
      UiController.setBookingDateRange(bookingDateValueCommand);
      setBookingDateValueCommand(null);
    }
  }, [customBooking, bookingDateValueCommand, isLoaded]);
  // Triggers when customBookingCommandValue, isLoaded values change
  useEffect(() => {
    if (bookingDateValueCommand !== null && isLoaded) {
      // checks if booking date range value is 'otb'
      if (bookingDateValueCommand === commands.OTB) {
        dispatch(webClickActions.setWebClickOtb(false));
        dispatch(webClickActions.setWebClickBookingDate(RESET_BOOKING_DATE));
        setBookingDateValueCommand(null);
      } else {
        // open custom booing date picker
        dispatch(webClickActions.setWebClickOtb(true));
      }
    }
  }, [bookingDateValueCommand, isLoaded]);
  /**
   * Set alignment command value
   * @param {*} value - alignment command value
   */
  const setWebClickAlignmentToggleHandler = (value) => {
    handleToggleChange({
      target: {
        checked: value,
      },
    });
  };
  // Listener function to set web clicks step command value
  const setWebClickStepsHandler = (data) => {
    setStepsCommandValue(data);
  };
  // Triggers when stepsCommandValue, CLICK_STEP_LIST, isLoaded value change
  useEffect(() => {
    if (stepsCommandValue !== null && CLICK_STEP_LIST?.length > 0 && isLoaded) {
      // get web click steps options
      const optionsSelected = getValuesFromList(stepsCommandValue, CLICK_STEP_LIST);
      if (optionsSelected?.length > 0) {
        dispatch(webClickActions.setWebClickStep(optionsSelected));
      }
      setStepsCommandValue(null);
    }
  }, [stepsCommandValue, CLICK_STEP_LIST, isLoaded]);
  // execute setting compare window command value
  const setWebClickCompareWindowHandler = (value) => {
    // check if 'otb' value selected as compare window and custom booking is false
    setCompareWindowCommandValue(value, customBooking, handleComparisonChange);
    setCompareWindowValueCommand(null);
  };
  // Listener function to set compare window command value
  const setCompareWindowValue = (data) => {
    setCompareWindowValueCommand(data);
  };
  // Listener function to set focus on command value
  const setFocusOnValue = (data) => {
    setOptions(data);
  };
  // Listener function to set custom date range command value for custom compare window
  const setCustomDateRangeValue = (data) => {
    setCustomDatesValueCommand(data);
  };
  // Listener function to set  web click hotel command value
  const setHotelGroupsHandler = (data) => {
    setHotelGroupCommand(data);
  };
  // Listener function to set symmetric comparison collapse value
  const setSymmetricComparisonCollapseValue = (data) => {
    setSymmetricComparisonCollapseCommandValue(data);
  };
  // Listener function to set visual collapse value
  const setVisualCollapseValue = (data) => {
    setVisualCollapseCommandValue(data);
  };
  // Listener function to set visual collapse value
  const setWebClickUtmBreakdownHandler = (data) => {
    setUtmBreakdownValueCommand(data);
  };
  // Listener function to set visual collapse value
  const setUtmFocusOnValue = (data) => {
    setUtmFocusOnValueCommand(data);
  };
  // Triggers when paceHorizonCommandValue, paceList, isLoaded values change
  useEffect(() => {
    if (utmBreakdownValueCommand !== null && utmBreakdownList?.length > 0 && isLoaded) {
      dispatch(webClickActions.setSelectedUtmBreakdown(utmBreakdownValueCommand));
      dispatch(webClickActions.setUtmFocusOn([]));
      setPaceHorizonCommandValue(null);
    }
  }, [utmBreakdownValueCommand, utmBreakdownList?.length, isLoaded]);
  //
  const setUtmFocusOnHandler = (data) => {
    // get corresponding focus on value and execute dispatch function
    const optionsSelected = getValuesFromList(data, utmFocusOnList);
    if (optionsSelected?.length > 0) {
      dispatch(webClickActions.setUtmFocusOn(optionsSelected));
    } else {
      dispatch(webClickActions.setUtmFocusOn([]));
    }
    setUtmFocusOnValueCommand(null);
  };
  // Triggers when  utmFocusOnValueCommand,focusOnList, isLoaded values change
  useEffect(() => {
    if (utmFocusOnList?.length > 0 && utmFocusOnValueCommand && isLoaded) {
      setTimeout(() => {
        setUtmFocusOnHandler(utmFocusOnValueCommand);
      }, 2000);
    }
  }, [utmFocusOnValueCommand, utmFocusOnList, isLoaded]);
  // set focus on command value
  const setWebClickFocusOnHandler = (data) => {
    const optionsSelected = getValuesFromList(data, focusOnList);
    if (optionsSelected?.length > 0) {
      dispatch(webClickActions.setWebClickBreakdownFocusOn(optionsSelected));
    } else {
      dispatch(webClickActions.setWebClickBreakdownFocusOn([]));
    }
    setOptions(null);
  };
  // Listener function to set compare hotels command value
  const setCommandValue = (data) => {
    setHotelValue(data);
  };
  // set compare hotel command value
  const setCompareHotelHandler = (data) => {
    // get hotels list from command value
    const hotelsSelected = getHotelValuesFromList(data, hotelList);
    if (hotelsSelected?.length > 0) {
      // checks if selected hotel is in the list
      dispatch(webClickActions.setWebClickHotelFocusOn(hotelsSelected[0]));
    }
    //
    setHotelValue(null);
  };
  // Listener function to set normalized view command value
  const setNormalizedViewValue = (data) => {
    setNormalizedCommandValue(data);
  };
  // set scale factor command value
  const setWebClickScaleFactorHandler = (data) => {
    setScaleValueCommand(data);
  };
  //
  useEffect(() => {
    if (
      scaleValueCommand !== null &&
      parseInt(scaleValueCommand, 10) >= 0 &&
      parseInt(scaleValueCommand, 10) <= 100 &&
      isLoaded
    ) {
      setScaleInputValue(parseInt(scaleValueCommand, 10));
    }
  }, [scaleValueCommand, isLoaded]);
  // Reference function to set cutom date range when custom option is selected in compare window
  setCustomDatesRef.current = () => {
    setTimeout(() => {
      const datesExtracted = customDatesValueCommand?.split('-');
      // reset custom dates
      dispatch(webClickActions.setWebClickCustomDate(datesExtracted[0]));
      // emit event to set custom date range
      UiController.setCustomDateRange(customDatesValueCommand);
      setCustomDatesValueCommand(null);
    }, 2000);
  };
  // Triggers when customDatesValueCommand, dateComparison, isLoaded values change
  useEffect(() => {
    if (customDatesValueCommand !== null && isLoaded) {
      // checks if date comparison value is 'custom'
      if (dateComparison && dateComparison === KEYS.CUSTOM) {
        setCustomDatesRef?.current();
      }
    }
  }, [customDatesValueCommand, dateComparison, isLoaded]);
  // Triggers when compareWindowValueCommand, customBooking, dateComparison, isLoaded values change
  useEffect(() => {
    if (dateComparison && compareWindowValueCommand && isLoaded) {
      setWebClickCompareWindowHandler(compareWindowValueCommand);
    }
  }, [compareWindowValueCommand, customBooking, dateComparison, isLoaded]);
  // Triggers when hotelValue, hotelList, selectedHotelName, isLoaded values change
  useEffect(() => {
    if (hotelValue !== null && hotelList && selectHotelName && isLoaded) {
      setCompareHotelHandler(hotelValue);
    }
  }, [hotelValue, hotelList?.length, selectedHotelName, isLoaded]);
  // Triggers when  options,focusOnList, isLoaded values change
  useEffect(() => {
    if (focusOnList.length > 0 && options && isLoaded) {
      setWebClickFocusOnHandler(options);
    }
  }, [options, focusOnList?.length, isLoaded]);
  // Triggers when normalizeCommandValue, filterList, isLoaded values change
  useEffect(() => {
    if (normalizeCommandValue !== null && filterList?.length > 0 && isLoaded) {
      // execute dispatch function to set normalized view state
      dispatch(webClickActions.setWebClickNormalizedView(normalizeCommandValue === 1));
      setNormalizedCommandValue(null);
    }
  }, [normalizeCommandValue, filterList?.length, isLoaded]);

  // Add event listener function to set booking date range command value
  useEffect(() => {
    UiController.subscribe(events.SET_WEB_CLICK_CUSTOM_BOOKING, setWebClickCustomBookingHandler);
    return () => {
      UiController.unsubscribe(
        events.SET_WEB_CLICK_CUSTOM_BOOKING,
        setWebClickCustomBookingHandler
      );
    };
  }, []);
  // Add event listener function to set alignment command value
  useEffect(() => {
    UiController.subscribe(events.WEB_CLICK_ALIGNMENT, setWebClickAlignmentToggleHandler);
    return () => {
      UiController.unsubscribe(events.WEB_CLICK_ALIGNMENT, setWebClickAlignmentToggleHandler);
    };
  }, [visualFilters, alignment, dateComparisonList]);
  // Add event listener function to set web clicks steps commands step
  useEffect(() => {
    UiController.subscribe(events.SET_WEB_CLICK_STEPS, setWebClickStepsHandler);
    return () => {
      UiController.unsubscribe(events.SET_WEB_CLICK_STEPS, setWebClickStepsHandler);
    };
  }, []);
  // Add event listener function to set compare window command value
  useEffect(() => {
    UiController.subscribe(events.SET_WEB_CLICK_COMPARE_WINDOW, setCompareWindowValue);
    return () => {
      UiController.unsubscribe(events.SET_WEB_CLICK_COMPARE_WINDOW, setCompareWindowValue);
    };
  }, []);
  // Add event listener function to set pace horizon command value
  useEffect(() => {
    UiController.subscribe(events.SET_WEB_CLICK_PACE_HORIZON, setWebClickPaceHorizonHandler);
    return () => {
      UiController.unsubscribe(events.SET_WEB_CLICK_PACE_HORIZON, setWebClickPaceHorizonHandler);
    };
  }, []);
  // Add event listener function to set focus on command value
  useEffect(() => {
    UiController.subscribe(events.SET_WEB_CLICK_FOCUS_ON, setFocusOnValue);
    return () => {
      UiController.unsubscribe(events.SET_WEB_CLICK_FOCUS_ON, setFocusOnValue);
    };
  }, []);
  // Add event listener function to set compare hotels command value
  useEffect(() => {
    UiController.subscribe(events.SET_WEB_CLICK_COMPARE_HOTELS, setCommandValue);
    return () => {
      UiController.unsubscribe(events.SET_WEB_CLICK_COMPARE_HOTELS, setCommandValue);
    };
  }, []);
  // Add event listener function to set normalized view command value
  useEffect(() => {
    UiController.subscribe(events.SET_WEB_CLICK_NORMALIZED_VIEW, setNormalizedViewValue);
    return () => {
      UiController.unsubscribe(events.SET_WEB_CLICK_NORMALIZED_VIEW, setNormalizedViewValue);
    };
  }, []);
  // Add event listener function to set web click scale factor command value
  useEffect(() => {
    UiController.subscribe(events.SET_WEB_CLICK_SCALE, setWebClickScaleFactorHandler);
    return () => {
      UiController.unsubscribe(events.SET_WEB_CLICK_SCALE, setWebClickScaleFactorHandler);
    };
  }, []);
  // Add event listener function to set custom date range command value for custom compare window
  useEffect(() => {
    UiController.subscribe(events.SET_WEB_CLICK_CUSTOM_DATE_RANGE, setCustomDateRangeValue);
    return () => {
      UiController.unsubscribe(events.SET_WEB_CLICK_CUSTOM_DATE_RANGE, setCustomDateRangeValue);
    };
  }, []);
  // Add event listener function to set hotel groups command value
  useEffect(() => {
    UiController.subscribe(events.SET_WEB_CLICK_HOTEL_GROUP, setHotelGroupsHandler);
    return () => {
      UiController.unsubscribe(events.SET_WEB_CLICK_HOTEL_GROUP, setHotelGroupsHandler);
    };
  }, []);
  // Add Listener function to set symmetric comparison collapse
  useEffect(() => {
    UiController.subscribe(
      events.SET_WEB_CLICK_SYMMETRIC_COMPARISON_COLLAPSE,
      setSymmetricComparisonCollapseValue
    );
    return () => {
      UiController.unsubscribe(
        events.SET_WEB_CLICK_SYMMETRIC_COMPARISON_COLLAPSE,
        setSymmetricComparisonCollapseValue
      );
    };
  }, []);
  // Add Listener function to set visual collapse
  useEffect(() => {
    UiController.subscribe(events.SET_WEB_CLICK_VISUAL_COLLAPSE, setVisualCollapseValue);
    return () => {
      UiController.unsubscribe(events.SET_WEB_CLICK_VISUAL_COLLAPSE, setVisualCollapseValue);
    };
  }, []);
  // Add event listener function to set utm breakdown command value
  useEffect(() => {
    UiController.subscribe(events.SET_WEB_CLICK_UTM_BREAKDOWN, setWebClickUtmBreakdownHandler);
    return () => {
      UiController.unsubscribe(events.SET_WEB_CLICK_UTM_BREAKDOWN, setWebClickUtmBreakdownHandler);
    };
  }, []);
  // Add event listener function to set utm focus on command value
  useEffect(() => {
    UiController.subscribe(events.SET_WEB_CLICK_UTM_FOCUS_ON, setUtmFocusOnValue);
    return () => {
      UiController.unsubscribe(events.SET_WEB_CLICK_UTM_FOCUS_ON, setUtmFocusOnValue);
    };
  }, []);
};
//
export default useWebClickCommandFlow;
