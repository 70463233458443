import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectEnableHotelGroups,
  selectHotelName,
  selectSelectedHotelGroupName,
} from 'modules/dashboard/selectors';
import { REPORT_TYPE } from 'modules/dashboard/constants';
import {
  selectEnableSecondaryHotelGroups,
  selectIsPrimary,
  selectHotelFocusOn,
  selectHotelGroupFocusOn,
} from '../selectors';
import { paceActions } from '../slice';
// Custom hook to determine the displayed visual type based on hotel selection and date comparison
const UseVisualSwap = () => {
  const dispatch = useDispatch();
  const hotelFocusOn = useSelector(selectHotelFocusOn);
  const hotelGroupFocusOn = useSelector(selectHotelGroupFocusOn);
  const selectedHotel = useSelector(selectHotelName);
  const selectedHotelGroup = useSelector(selectSelectedHotelGroupName);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const enableSecondaryHotelGroups = useSelector(selectEnableSecondaryHotelGroups);
  const isPrimary = useSelector(selectIsPrimary);
  /**
   * Different hotels/ hotel groups selected in primary and secondary dropdowns -> Display Pace Hotel visuals
   * Same hotel/ hotel group selected in primary and secondary dropdowns AND  'PRIMARY' option selected on date comparison -> Display Pace Segment visuals
   * Else -> Display Pace Time visuals
   */
  useEffect(() => {
    if (
      !enableSecondaryHotelGroups &&
      !enableHotelGroups &&
      hotelFocusOn?.id !== selectedHotel?.id
    ) {
      dispatch(paceActions.setActiveTab(REPORT_TYPE.PACE_HOTEL_WIDGET));
    } else if (
      enableHotelGroups &&
      enableSecondaryHotelGroups &&
      hotelGroupFocusOn?.id !== selectedHotelGroup?.id
    ) {
      dispatch(paceActions.setActiveTab(REPORT_TYPE.PACE_HOTEL_WIDGET));
    } else if (enableSecondaryHotelGroups && !enableHotelGroups) {
      dispatch(paceActions.setActiveTab(REPORT_TYPE.PACE_HOTEL_WIDGET));
    } else if (!enableSecondaryHotelGroups && enableHotelGroups) {
      dispatch(paceActions.setActiveTab(REPORT_TYPE.PACE_HOTEL_WIDGET));
    } else if (isPrimary) {
      dispatch(paceActions.setActiveTab(REPORT_TYPE.PACE_SEGMENT_WIDGET));
    } else {
      dispatch(paceActions.setActiveTab(REPORT_TYPE.PACE_TIME_WIDGET));
    }
  }, [
    hotelFocusOn,
    selectedHotel,
    selectedHotelGroup,
    enableHotelGroups,
    hotelGroupFocusOn,
    enableSecondaryHotelGroups,
    isPrimary,
  ]);
};
//
export default UseVisualSwap;
