/**
 * Global routes in web application are defined  here
 */
const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  LOGIN_REDIRECT: '/login-callback',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  VERIFY_CALLBACK: '/verify-callback',
  SIGN_UP: '/sign-up',
  DASHBOARD: '/dashboard',
  FORECAST_UPLOAD: '/dashboard/forecast-upload',
  RATE_CALENDAR: '/rate-calendar',
  CONFIGURE: '/configure',
  USERS: '/configure/users',
  EVENT_CATEGORIES: '/configure/event-categories',
  CONFIGURE_HOTELS: '/configure/hotels',
  NOT_FOUND: '*',
};
//
export default ROUTES;
