import { useState, useEffect } from 'react';
import { Toolbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardActions } from 'modules/dashboard/slice';
import { summaryActions } from 'modules/dashboard/components/tab-container/summary-tab/slice';
import { selectOrganization } from 'modules/common/auth/selectors';
import {
  selectBreakdown,
  selectDateComparison,
  selectSummaryAlignmentToggle,
  selectSymmetricComparisonToggle,
  selectSummaryOtb,
  selectSummaryCustomDate,
  selectIsDetailed,
  selectSummaryEndDate,
  selectSummaryStartDate,
  selectSummaryVisualToggle,
  selectAlignment,
  selectSymmetricComparisonIsDisplay,
  selectEnableSecondaryHotelGroups,
  selectHotelFocusOn,
  selectHotelGroupFocusOn,
  selectNormalizedView,
  selectSummaryHotelComparison,
} from 'modules/dashboard/components/tab-container/summary-tab/selectors';
import {
  selectPaceHorizon,
  selectPaceVariable,
  selectTargetDate,
  selectVisualFilters,
  selectPmsSync,
  selectIsSignedIn,
  selectSummaryForecastFilter,
  selectHotelName,
  selectHotelGroupDropdownList,
  selectHotelDropdownList,
} from 'modules/dashboard/selectors';
import { getComparisonDateRange, shortcut } from 'modules/dashboard/functions';
import { CustomStack } from 'modules/common/components';
import { capitalizeFirst } from 'modules/common/helpers/strings';
import {
  SHORTCUT_KEY,
  TAB_VALUE,
  TAGS,
  OTB_VALUE,
  KEYS,
  STAY_DATE,
  RESET_BOOKING_DATE,
  ALIGNED,
} from 'modules/dashboard/constants';
import ActionButton from 'modules/common/components/action-button';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import { ENVIRONMENT } from 'config';
import DataUpdatedLabel from 'modules/dashboard/components/tab-container/data-updated-label';
import PmsToggle from 'modules/dashboard/components/tab-container/inner-filter/components/pms-toggle';
import BookingWindow from 'modules/dashboard/components/tab-container/inner-filter/components/booking-window';
import BreakdownWindow from 'modules/dashboard/components/tab-container/inner-filter/components/breakdown-window';
import VsComparisonWindow from 'modules/dashboard/components/tab-container/inner-filter/components/vs-comparison-window';
import BookingPaceWindow from 'modules/dashboard/components/tab-container/inner-filter/components/booking-pace-window';
import {
  getDateComparisonFilter,
  getSubAlignmentFilter,
  getSubBreakdownFilter,
  getSubPaceFilter,
  getSubPaceVariableFilter,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import useSummaryCommandFlow from 'modules/dashboard/components/tab-container/summary-tab/hooks/use-summary-command-flow';
import { CompareHotelsWindow } from '../../../inner-filter';
/**
 * Filters for Summary widget include PMS Sync, Booking Dates, Chart Breakdown,
 * Alignment, Date Comparison, Pace Horizon, pace variable and pace forecast
 * @param {Number} tabValue - Pace widget tab value
 * @param {Date} latestDate - Latest booking date of selected hotel
 * @param {Boolean} hotelTimezone - Timezone of selected hotel
 * @param {Number} toggleDisabled - PMS sync toggle disabled state
 * @param {Boolean} isLoaded - Visual is loaded state
 * @param {String} reportType - Report type of active tab
 * @param {Object} visual- Symmetric comparison visual object
 * @param {String} visualName - Display name for symmetric comparison visual
 * @returns
 */
const SummaryFilter = ({
  reportType,
  visual,
  visualName,
  tabValue,
  toggleDisabled,
  latestDate,
  hotelTimezone,
  isLoaded,
}) => {
  const dispatch = useDispatch();
  const organization = useSelector(selectOrganization);
  // Selected filter values are obtained from redux store. Refer each selector function for details.
  const chartBreakdown = useSelector(selectBreakdown);
  const alignment = useSelector(selectAlignment);
  const dateComparison = useSelector(selectDateComparison);
  const visualFilters = useSelector(selectVisualFilters);
  const targetDate = useSelector(selectTargetDate);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const paceHorizon = useSelector(selectPaceHorizon);
  const paceVariable = useSelector(selectPaceVariable);
  const alignmentToggle = useSelector(selectSummaryAlignmentToggle);
  const symmetricComparisonToggle = useSelector(selectSymmetricComparisonToggle);
  const isSignIn = useSelector(selectIsSignedIn);
  const customBooking = useSelector(selectSummaryOtb);
  const isDetailed = useSelector(selectIsDetailed);
  const customDate = useSelector(selectSummaryCustomDate);
  const startDate = useSelector(selectSummaryStartDate);
  const endDate = useSelector(selectSummaryEndDate);
  const visualToggle = useSelector(selectSummaryVisualToggle);
  const symmetricComparisonIsDisplay = useSelector(selectSymmetricComparisonIsDisplay);
  const summaryForecastFilter = useSelector(selectSummaryForecastFilter);
  const hotelList = useSelector(selectHotelDropdownList);
  const hotelFocusOn = useSelector(selectHotelFocusOn);
  const enableSecondaryHotelGroups = useSelector(selectEnableSecondaryHotelGroups);
  const hotelGroupList = useSelector(selectHotelGroupDropdownList);
  const hotelGroupFocusOn = useSelector(selectHotelGroupFocusOn);
  const normalizedViewToggle = useSelector(selectNormalizedView);
  const isSummaryHotelComparison = useSelector(selectSummaryHotelComparison);

  //
  const [dateComparisonList, setDateComparisonList] = useState([]);
  const [chartBreakdownList, setChartBreakdownList] = useState([]);
  const [paceList, setPaceList] = useState([]);
  const [paceVariableList, setPaceVariableList] = useState([]);
  // To select otb or custom booking window
  const handleOTBChange = (event) => {
    const { value } = event.target;
    dispatch(summaryActions.setSummaryOtb(value !== 'false'));
    dispatch(summaryActions.setSummaryBookingDate(RESET_BOOKING_DATE));
  };

  // Reset button function to set filter panel values to default
  const clearSearch = () => {
    dispatch(summaryActions.setNormalizedView(false));
    dispatch(summaryActions.setHotelFocusOn(hotelList[0]));
    dispatch(summaryActions.setEnableSecondaryHotelGroups(false));
    dispatch(summaryActions.setHotelGroupFocusOn(hotelGroupList[0]));
    dispatch(summaryActions.setSummaryOtb(false));
    dispatch(dashboardActions.setSummaryForecastFilter(true));
    dispatch(
      summaryActions.setBreakdown(
        visualFilters?.filter(
          (filter) => filter?.tags?.includes(TAGS.SEGMENT_BREAKDOWN) && filter?.isDefault === true
        )[0]?.id
      )
    );
    dispatch(
      dashboardActions.setPaceHorizon(
        visualFilters?.filter(
          (filter) => filter?.tags?.includes(TAGS.PACE_HORIZON) && filter?.isDefault === true
        )[0]?.id
      )
    );
    dispatch(
      dashboardActions.setPaceVariable(
        visualFilters?.filter(
          (filter) =>
            filter?.tags?.includes(TAGS.SUMMARY_PACE_VARIABLE) && filter?.isDefault === true
        )[0]?.values[0]
      )
    );
    dispatch(summaryActions.setSymmetricComparisonToggle(true));
    const subAlignmentFilter = getSubAlignmentFilter(visualFilters, false);
    dispatch(
      summaryActions.setDateComparison(
        subAlignmentFilter?.values?.filter((filter) => filter.isDefault === true)[0].value
      )
    );
    dispatch(summaryActions.setSummaryBookingDate(RESET_BOOKING_DATE));
    dispatch(dashboardActions.setPmsSync(false));
    dispatch(summaryActions.setSummaryAlignmentToggle(subAlignmentFilter?.type === ALIGNED));
    dispatch(summaryActions.setIsDetailed(false));
    dispatch(summaryActions.setSummaryStartDate(STAY_DATE.startDate));
    dispatch(summaryActions.setSummaryEndDate(STAY_DATE.endDate));
    dispatch(summaryActions.setSummaryCustomDate(KEYS.START_DATE));
    dispatch(summaryActions.setSummaryDateSelected(STAY_DATE.startDate));
  };
  // Triggered on change of hotel group list
  useEffect(() => {
    if (hotelGroupList?.length > 0 && !enableSecondaryHotelGroups) {
      dispatch(summaryActions.setHotelGroupFocusOn(hotelGroupList[0]));
    }
  }, [hotelGroupList]);

  // Triggered when visualFilters value changes
  useEffect(() => {
    // Set pace filter list and breakdown list
    const subBreakdownFilter = getSubBreakdownFilter(visualFilters);
    if (subBreakdownFilter) {
      setChartBreakdownList(subBreakdownFilter);
    }
    const subPaceFilter = getSubPaceFilter(visualFilters);
    if (subPaceFilter) {
      setPaceList(subPaceFilter);
    }
    const subPaceVariableFilter = getSubPaceVariableFilter(visualFilters);
    if (subPaceVariableFilter) {
      setPaceVariableList(subPaceVariableFilter);
    }
  }, [visualFilters]);

  // Triggered when alignment, visualFilters, alignmentToggle changes
  useEffect(() => {
    // Set date comparison list based on alignment
    if (alignment) {
      const dateComparisonFilter = getDateComparisonFilter(visualFilters, alignment?.id);
      setDateComparisonList(dateComparisonFilter);
      // persist value on browser refresh
      const serializedState = JSON.parse(
        localStorage.getItem(`persist:${ENVIRONMENT.PERSIST_KEY}`)
      );
      if (!isSignIn && serializedState) {
        return;
      }
      dispatch(
        summaryActions.setDateComparison(
          dateComparisonFilter?.filter((filter) => filter?.isDefault === true)[0].value
        )
      );
    }
  }, [alignment, visualFilters, alignmentToggle]);

  // Triggered when alignmentToggle or visualFilters value changes
  useEffect(() => {
    const subAlignmentFilter = getSubAlignmentFilter(visualFilters, alignmentToggle);
    dispatch(
      summaryActions.setAlignment({
        id: subAlignmentFilter?.id,
        label: capitalizeFirst(subAlignmentFilter?.type),
      })
    );
  }, [alignmentToggle, visualFilters]);

  // Alignment toggle change handler
  const handleToggleChange = (event) => {
    dispatch(summaryActions.setSummaryAlignmentToggle(event.target.checked));
    const dateComparisonFilter = getDateComparisonFilter(visualFilters, alignment?.id);
    dispatch(
      summaryActions.setDateComparison(
        dateComparisonFilter?.filter((filter) => filter?.isDefault === true)[0].value
      )
    );
  };

  // Shortcut function to set pms sync value
  shortcut(tabValue, TAB_VALUE.SUMMARY, SHORTCUT_KEY.PMS_TOGGLE, () =>
    dispatch(dashboardActions.setPmsSync(!pmsSyncToggle))
  );

  // Date comparison change handler
  const handleComparisonChange = (event) => {
    dispatch(summaryActions.setDateComparison(event.target.value));
    dispatch(summaryActions.setSummaryDateSelected(STAY_DATE.startDate));
    dispatch(summaryActions.setSummaryCustomDate(KEYS.START_DATE));
    //
    const dateRange = getComparisonDateRange(
      STAY_DATE.startDate,
      targetDate.startDate,
      targetDate.endDate,
      KEYS.START_DATE
    );
    //
    dispatch(summaryActions.setSummaryStartDate(dateRange?.comparisonStartDate.toISOString()));
    dispatch(summaryActions.setSummaryEndDate(dateRange?.comparisonEndDate.toISOString()));
  };

  // Triggered when customBooking, dateComparison, visualFilters, isLoaded change
  useEffect(() => {
    // Assign default value to date comparison when OTB is selected for booking window
    if (!customBooking && dateComparison === OTB_VALUE && isLoaded) {
      const subAlignmentFilter = getSubAlignmentFilter(visualFilters, true);
      dispatch(
        summaryActions.setDateComparison(
          subAlignmentFilter?.values?.filter((filter) => filter.isDefault === true)[0].value
        )
      );
    }
  }, [customBooking, dateComparison, visualFilters, isLoaded]);

  /**
   * set summary pace horizon value
   * @param {*} event - on change event
   */
  const handlePaceHorizonChange = (event) => {
    dispatch(dashboardActions.setPaceHorizon(event.target.value));
  };
  /**
   * set summary pace horizon value
   * @param {*} event - on change event
   */
  const handleForecastFilterChange = (event) => {
    dispatch(dashboardActions.setSummaryForecastFilter(event.target.checked));
  };
  /**
   * set summary pace variable value
   * @param {*} event - on change event
   */
  const handlePaceVariableChange = (event) => {
    dispatch(dashboardActions.setPaceVariable(event.target.value));
  };
  /**
   * set symmetric comparison value
   * @param {*} event - on change event
   */
  const handleSymmetricComparisonChange = (event) => {
    dispatch(summaryActions.setSymmetricComparisonToggle(event.target.checked));
  };
  /**
   * set custom dates value
   * @param {*} event - on change event
   */
  const handleCustomDates = (event) => {
    dispatch(summaryActions.setSummaryCustomDate(event.target.value));
  };
  /**
   * set breakdown value
   * @param {*} event - onChanged event
   */
  const handleBreakdownChange = (event) => {
    dispatch(summaryActions.setBreakdown(event.target.value));
  };
  /**
   * set isDetailed value
   * @param {*} event - onChanged event
   */
  const handleIsDetailedChange = (event) => {
    dispatch(summaryActions.setIsDetailed(event.target.checked));
  };
  // Summary filter command flow implementation
  useSummaryCommandFlow(
    isLoaded,
    customBooking,
    paceList,
    chartBreakdownList,
    paceVariableList,
    visualFilters,
    dateComparison,
    alignment,
    dateComparisonList,
    handleComparisonChange,
    handleToggleChange,
    organization,
    hotelList,
    hotelGroupList
  );
  //
  return (
    <>
      <PmsToggle
        disabled={toggleDisabled}
        value={pmsSyncToggle}
        onChange={(event) => dispatch(dashboardActions.setPmsSync(event.target.checked))}
      />
      <br />
      <BookingWindow
        vOTB={customBooking}
        customBooking={customBooking}
        handleOTBChange={handleOTBChange}
        reportType={reportType}
        latestDate={latestDate}
        otbValue={false}
      />
      <br />
      <BreakdownWindow
        isDetailed={isDetailed}
        chartBreakdown={chartBreakdown}
        chartBreakdownList={chartBreakdownList}
        handleIsDetailedChange={handleIsDetailedChange}
        handleBreakdownChange={handleBreakdownChange}
        isFocusOnAvailable={false}
      />
      <br />
      <CompareHotelsWindow
        selectedHotelName={selectHotelName}
        hotelList={hotelList}
        focusOn={hotelFocusOn}
        focusOnLabel={hotelFocusOn?.length ? 'Compared with' : 'Select Hotel'}
        handleCompareHotelsChange={(event, value) => {
          dispatch(summaryActions.setHotelFocusOn(value));
        }}
        normalizedViewToggle={normalizedViewToggle}
        handleTrendHotelNormalize={(event) =>
          dispatch(summaryActions.setNormalizedView(event.target.checked))
        }
        enableSecondaryHotelGroups={enableSecondaryHotelGroups}
        hotelGroupList={hotelGroupList}
        hotelGroupFocusOn={hotelGroupFocusOn}
        groupFocusOnLabel={hotelGroupFocusOn?.length ? 'Compared with' : 'Select Hotel Set'}
        handleCompareGroupHotelsChange={(event, value) => {
          dispatch(summaryActions.setHotelGroupFocusOn(value));
        }}
        handleEnableSecondaryHotelGroups={(event) =>
          dispatch(summaryActions.setEnableSecondaryHotelGroups(event.target.checked))
        }
      />
      <br />
      <VsComparisonWindow
        customBooking={customBooking}
        alignmentToggle={alignmentToggle ?? false}
        handleToggleChange={handleToggleChange}
        symmetricComparisonToggle={symmetricComparisonToggle}
        visual={visual}
        visualName={visualName}
        dateComparison={dateComparison}
        dateComparisonList={dateComparisonList}
        handleComparisonChange={handleComparisonChange}
        handleSymmetricComparisonChange={handleSymmetricComparisonChange}
        isCustomOptionAvailable
        customDate={customDate}
        reportType={reportType}
        startDate={startDate}
        endDate={endDate}
        targetDate={targetDate}
        handleCustomDates={handleCustomDates}
        isDisplay={symmetricComparisonIsDisplay}
        isDisplayOnClickAction={() => {
          dispatch(summaryActions.setSymmetricComparisonIsDisplay(!symmetricComparisonIsDisplay));
        }}
        isTimeComparisonDisabled={isSummaryHotelComparison}
      />
      <br />
      <BookingPaceWindow
        paceHorizon={paceHorizon}
        paceList={paceList}
        paceVariable={paceVariable}
        handlePaceHorizonChange={handlePaceHorizonChange}
        handlePaceVariableChange={handlePaceVariableChange}
        isPlot={false}
        paceVariableList={paceVariableList}
        visualToggle={visualToggle}
        handleVisualToggleChange={(event) =>
          dispatch(summaryActions.setSummaryVisualToggle(event.target.value))
        }
        handleForecastFilterChange={handleForecastFilterChange}
        summaryForecastFilter={summaryForecastFilter}
        enableForecastFilter={organization?.enableForecastFilter}
        showFilter
      />
      <br />
      <CustomStack cssClassName={styles.innerFilter}>
        <ActionButton onClick={clearSearch} label="RESET" />
      </CustomStack>
      <Toolbar />
      <DataUpdatedLabel latestDate={latestDate} hotelTimezone={hotelTimezone} />
    </>
  );
};
//
export default SummaryFilter;
