import { useSelector, useDispatch } from 'react-redux';
import { selectSignInError, selectSignInErrorCode, selectLoader } from 'modules/sign-in/selectors';
import { useEffect } from 'react';
import ROUTE from 'modules/common/constants/route';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useIsAuthenticated from 'modules/common/auth/hooks/use-is-authenticated';
import { selectUserRole } from 'modules/dashboard/selectors';
import { DEMO_ROLE, USER_ROLE } from 'modules/common/constants/roles';
import AuthLayout from 'modules/common/layouts/auth';
import { Box, CircularProgress } from '@mui/material';
import openAuthWindow from 'modules/common/utils/auth';
import { signInActions } from 'modules/sign-in/slice';
import { selectLandingPage } from 'modules/users/selectors';
import { isEmpty } from 'modules/common/helpers/object';

/**
 * Component that gets rendered on the callback URL
 * This function obtains the auth code or any error and sends that data back to the parent window,
 * and if this component was rendered as a parent (reset password flow), it re-initiates the auth flow and performs the sign-in
 */
const SignInAuthWindow = () => {
  const { isAuthenticated } = useIsAuthenticated();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signInError = useSelector(selectSignInError);

  //
  const errorCode = useSelector(selectSignInErrorCode);
  //
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const error = searchParams.get('error');
  /**
   * The codeVerifier here is only used for the reset password flow and not for the normal auth flow
   * Note: localStorage had to be used since the page reload cause by re-initiation causes
   * the verifier to be reset
   */
  const loginCodeVerifier = localStorage.getItem('loginCodeVerifier');
  const redirectPath = localStorage.getItem('redirect-to');

  const loading = useSelector(selectLoader);
  const userRole = useSelector(selectUserRole);
  const landingPage = useSelector(selectLandingPage);

  useEffect(() => {
    // Function to re-initiate the auth process after the reset password flow
    //
    const handleCodeExtract = async () => {
      // If this is a normal window (reset password flow) AND re-initialization of the auth process has already happenned once, trigger the login process
      if (code && loginCodeVerifier) {
        dispatch(signInActions.signIn({ code, codeVerifier: loginCodeVerifier }));
      }
    };
    handleCodeExtract();
  }, [error, code, loginCodeVerifier]);
  //
  useEffect(() => {
    // This is the same as the one in the welcome page component (for handling the re-direction after login)
    if (isAuthenticated() && !isEmpty(landingPage)) {
      // Reset localStorage
      localStorage.removeItem('loginCodeVerifier');
      if (redirectPath && redirectPath !== '') {
        const tab = atob(redirectPath)?.split('#')[1];
        if (tab === 'i-prefer' && (userRole === USER_ROLE || userRole === DEMO_ROLE)) {
          navigate(ROUTE.DASHBOARD);
        } else {
          navigate(atob(redirectPath));
        }
        localStorage.removeItem('redirect-to');
      } else {
        navigate(ROUTE.DASHBOARD);
      }
    }
  }, [isAuthenticated, errorCode, landingPage]);
  //
  useEffect(() => {
    if (
      signInError === 'Code not valid' ||
      signInError === 'PKCE verification failed' ||
      signInError === 'PKCE invalid code verifier'
    ) {
      openAuthWindow();
    }
  }, [signInError]);
  //
  return (
    // Renders a loader with the common background in the reset password flow
    <>
      {!window?.opener && (
        <AuthLayout>
          <Box sx={{ mb: 15, mt: 10, textAlign: 'center' }}>
            <CircularProgress />
          </Box>
        </AuthLayout>
      )}
      {loading && (
        <Box sx={{ mb: 15, mt: 10, textAlign: 'center' }}>
          <CircularProgress size="4rem" color="info" />
        </Box>
      )}
    </>
  );
};
//
export default SignInAuthWindow;
