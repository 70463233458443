import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Grid,
  FormControl,
  TextField,
  Button,
  Alert,
  Checkbox,
  FormControlLabel,
  Autocomplete,
} from '@mui/material';
import { selectNotification } from 'modules/common/notifications/selectors';
import { selectCommandFilterDropdownList } from 'modules/dashboard/selectors';
import ERROR_TYPES from 'modules/common/constants/error-types';
import { updateUserValidation } from 'modules/users/validation/add-user-form-validation';
import { dashboardActions } from 'modules/dashboard/slice';
import { usersActions } from 'modules/users/slice';
import { selectCurrentUser, selectLandingPage } from 'modules/users/selectors';
import { notificationActions } from 'modules/common/notifications/slice';
import OutlinedDiv from './outer-card';

/**
 * Update User implementation to update user preferences
 * @param {string} organizationId - organization id
 * @param {function} onClose - function to execute on close
 * @returns
 */
const UpdateUser = ({ organizationId, onClose }) => {
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const currentUser = useSelector(selectCurrentUser);
  const filterList = useSelector(selectCommandFilterDropdownList);
  const customLandingPage = useSelector(selectLandingPage);
  //
  const [filterDropdownList, setFilterDropdownList] = useState([]);
  const [initialValues, setInitialValues] = useState({
    email: '',
    username: '',
    firstName: '',
    lastName: '',
    isCustomLandingPageEnabled: false,
    landingPage: '',
  });
  // Triggers when currentUser changes
  useEffect(() => {
    // set initial values based on current user
    if (currentUser) {
      setInitialValues({
        email: currentUser?.email,
        username: currentUser?.username,
        firstName: currentUser?.firstName,
        lastName: currentUser?.lastName,
        isCustomLandingPageEnabled: Boolean(customLandingPage?.landingPage),
        landingPage: customLandingPage?.landingPage ?? '',
      });
    }
  }, [currentUser, customLandingPage]);
  // transform filter array
  useEffect(() => {
    if (filterList?.results?.length > 0) {
      const transformedList = filterList?.results?.map((type) => ({
        id: type?.commandFilterQuery?.filterName,
        label: type?.commandFilterQuery?.filterName,
        value: type?.commandFilterQuery?.query,
      }));
      setFilterDropdownList(transformedList);
    }
  }, [filterList]);
  // update user details in preference
  const onSubmitUpdateUser = async (values) => {
    const valueObj = {
      email: values?.email,
      firstName: values?.firstName,
      lastName: values?.lastName,
    };
    const updateLandingObj = {
      landingPage: values?.isCustomLandingPageEnabled ? values?.landingPage : '',
    };
    //
    if (!values.isCustomLandingPageEnabled) {
      dispatch(dashboardActions.resetLandingPage());
    }
    //
    dispatch(
      usersActions.updatePreference({
        ...valueObj,
        organizationId,
        userId: currentUser?.id,
      })
    );
    //
    dispatch(
      usersActions.updateLandingPage({
        ...updateLandingObj,
        organizationId,
        userId: currentUser?.id,
      })
    );
  };
  // reset notifications on first render
  useEffect(() => {
    dispatch(notificationActions.resetNotification());
  }, []);
  //
  return (
    <Box
      xs={12}
      ml={4}
      mt={2}
      component={Grid}
      container
      item
      display="flex"
      direction="column"
      sx={{ backgroundColor: 'white', flexShrink: 0 }}
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={updateUserValidation}
        onSubmit={onSubmitUpdateUser}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid alignItems="center" px={4} my={4} xs={12} item>
              {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
                <Alert mt={2} mb={3} severity={notification?.type}>
                  {notification?.message}
                </Alert>
              )}
            </Grid>
            <OutlinedDiv label="User Information">
              <Grid justifyContent="space-around" alignItems="center" px={4} my={4} xs={6} item>
                <FormControl fullWidth>
                  <TextField
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email ?? ''}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    label="Email"
                    variant="outlined"
                    size="small"
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid justifyContent="space-around" alignItems="center" px={4} my={4} xs={6} item>
                <FormControl fullWidth>
                  <TextField
                    name="username"
                    value={values.username ?? ''}
                    label="Username"
                    variant="outlined"
                    size="small"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid justifyContent="space-around" alignItems="center" px={4} my={4} xs={6} item>
                <FormControl fullWidth>
                  <TextField
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstName ?? ''}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    label="First name"
                    variant="outlined"
                    size="small"
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid justifyContent="space-around" alignItems="center" px={4} my={4} xs={6} item>
                <FormControl fullWidth>
                  <TextField
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lastName ?? ''}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                    label="Last name"
                    variant="outlined"
                    size="small"
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </FormControl>
              </Grid>
            </OutlinedDiv>
            <OutlinedDiv label="Landing Page">
              <Grid justifyContent="space-around" alignItems="center" px={4} my={4} xs={12} item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isCustomLandingPageEnabled"
                      checked={values.isCustomLandingPageEnabled}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  }
                  label="Select Custom Landing Page"
                />
              </Grid>
              <Grid justifyContent="space-around" alignItems="center" px={4} my={4} xs={6} item>
                <FormControl fullWidth>
                  <Autocomplete
                    options={filterDropdownList ?? []}
                    disabled={!values.isCustomLandingPageEnabled}
                    getOptionLabel={(option) => (option.label ? option.label : '')}
                    isOptionEqualToValue={(option, value) =>
                      option && value ? option.id === value.id : ''
                    }
                    filterSelectedOptions
                    onChange={(e, value) => {
                      setFieldValue('landingPage', value?.value);
                      if (values.isCustomLandingPageEnabled) {
                        dispatch(dashboardActions.setLandingPage(value));
                      }
                    }}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                    onBlur={handleBlur}
                    disableClearable
                    value={
                      filterDropdownList?.find((option) => option.value === values.landingPage) ||
                      ''
                    }
                    name="landingPage"
                    renderInput={(params) => (
                      <TextField
                        error={Boolean(touched.landingPage && errors.landingPage)}
                        helperText={touched.landingPage && errors.landingPage}
                        label="Landing Page"
                        size="medium"
                        {...params}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </OutlinedDiv>
            <Grid container direction="row" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={() => onClose()}
                sx={{ marginLeft: 2, marginRight: 4 }}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="success" disabled={isSubmitting}>
                Save
              </Button>
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  );
};
//
export default UpdateUser;
