/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEmbedToken } from 'modules/dashboard/selectors';
import {
  selectTrendHotelFilterList,
  selectIsCumulative,
  selectTrendHotelNormalizedFilterList,
  selectTrendTimeWeeklyView,
  selectAvgLosIsDisplayTH,
  selectAvgBookingWindowIsDisplayTH,
  selectAdrIsDisplayTH,
  selectCxlIsDisplayTH,
  selectOccupiedRoomsIsDisplayTH,
  selectRevenueIsDisplayTH,
  selectTrendHotelFocusOn,
  selectTrendHotelNormalizedView,
  selectTrendHotelTabularVisuals,
  selectTrendHotelGroupFocusOn,
  selectEnableSecondaryHotelGroups,
  selectTrendHotelWeeklyTabularVisuals,
  selectTrendHotelCumulativeTabularVisuals,
  selectTrendHotelPercentageTabularVisuals,
  selectIsPercentageChange,
  selectTrendHotelForecastOffPercentageTabularVisuals,
  selectTrendHotelForecastOffTabularVisuals,
  selectTrendHotelForecastOffCumulativeTabularVisuals,
  selectTrendHotelForecastOffWeeklyTabularVisuals,
  selectIsForecast,
} from 'modules/dashboard/components/tab-container/trend-tabs/selectors';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import VisualGrid from 'modules/dashboard/components/tab-container/visual-grid';
import { ExportVisualDataTS } from 'modules/dashboard/components/tab-container/export-visual-data';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import { REPORT_TYPE } from 'modules/dashboard/constants';
import { findVisual } from 'modules/dashboard/components/tab-container/trend-tabs/functions';
import VisualCard from 'modules/dashboard/components/tab-container/visual-card';
/**
 * Trend Hotel tabular report with filters
 */
const TrendHotelTabularWidget = () => {
  const dispatch = useDispatch();
  // States are obtained from redux store. Refer each selector function for details.
  const filterList = useSelector(selectTrendHotelFilterList);
  const weeklyViewToggle = useSelector(selectTrendTimeWeeklyView);
  const isCumulative = useSelector(selectIsCumulative);
  const normalizedFilterList = useSelector(selectTrendHotelNormalizedFilterList);
  const visuals = useSelector(selectTrendHotelTabularVisuals);
  const weeklyVisuals = useSelector(selectTrendHotelWeeklyTabularVisuals);
  const cumulativeVisuals = useSelector(selectTrendHotelCumulativeTabularVisuals);
  const tokenDetails = useSelector(selectEmbedToken);
  const revenueIsDisplay = useSelector(selectRevenueIsDisplayTH);
  const occupiedRoomsIsDisplay = useSelector(selectOccupiedRoomsIsDisplayTH);
  const adrIsDisplay = useSelector(selectAdrIsDisplayTH);
  const avgBookingWindowIsDisplay = useSelector(selectAvgBookingWindowIsDisplayTH);
  const avgLosIsDisplay = useSelector(selectAvgLosIsDisplayTH);
  const cxlIsDisplay = useSelector(selectCxlIsDisplayTH);
  const focusOn = useSelector(selectTrendHotelFocusOn);
  const hotelGroupFocusOn = useSelector(selectTrendHotelGroupFocusOn);
  const normalizedViewToggle = useSelector(selectTrendHotelNormalizedView);
  const enableSecondaryHotelGroups = useSelector(selectEnableSecondaryHotelGroups);
  const percentageVisuals = useSelector(selectTrendHotelPercentageTabularVisuals);
  const isPercentageChange = useSelector(selectIsPercentageChange);
  const forecastOffVisuals = useSelector(selectTrendHotelForecastOffTabularVisuals);
  const forecastOffCumulativeVisuals = useSelector(
    selectTrendHotelForecastOffCumulativeTabularVisuals
  );
  const forecastOffWeeklyVisuals = useSelector(selectTrendHotelForecastOffWeeklyTabularVisuals);
  const forecastOffPercentageVisuals = useSelector(
    selectTrendHotelForecastOffPercentageTabularVisuals
  );
  const isTrendForecast = useSelector(selectIsForecast);
  //
  const [totalRevenue, setTotalRevenue] = useState({});
  const [isTotalRevenueLoaded, setIsTotalRevenueLoaded] = useState(false);
  const [totalOccupiedRooms, setTotalOccupiedRooms] = useState({});
  const [isTotalOccupiedRoomsLoaded, setIsTotalOccupiedRoomsLoaded] = useState(false);
  const [adr, setAdr] = useState({});
  const [isAdrLoaded, setIsAdrLoaded] = useState(false);
  const [avgBookingWindow, setAvgBookingWindow] = useState({});
  const [isAvgBookingWindowLoaded, setIsAvgBookingWindowLoaded] = useState(false);
  const [avgLoS, setAvgLoS] = useState({});
  const [isAvgLoSLoaded, setIsAvgLoSLoaded] = useState(false);
  const [cancellations, setCancellations] = useState({});
  const [isCancellationsLoaded, setIsCancellationsLoaded] = useState(false);
  // To get visual details
  const getVisual = (index) => {
    let found;
    if (isCumulative) {
      found = findVisual(
        isTrendForecast ? cumulativeVisuals : forecastOffCumulativeVisuals,
        index,
        weeklyViewToggle,
        isTrendForecast
          ? [REPORT_TYPE.WEEKLY_TREND_HOTEL_TABULAR, REPORT_TYPE.TREND_HOTEL_TABULAR]
          : [
              REPORT_TYPE.WEEKLY_TREND_HOTEL_TABULAR_FORECAST_OFF,
              REPORT_TYPE.TREND_HOTEL_TABULAR_FORECAST_OFF,
            ]
      );
    } else if (weeklyViewToggle) {
      found = findVisual(
        isTrendForecast ? weeklyVisuals : forecastOffWeeklyVisuals,
        index,
        true,
        isTrendForecast
          ? [REPORT_TYPE.TREND_HOTEL_CUMULATIVE_TABULAR]
          : [REPORT_TYPE.TREND_HOTEL_CUMULATIVE_TABULAR_FORECAST_OFF]
      );
    } else if (isPercentageChange && !weeklyViewToggle && !isCumulative && !normalizedViewToggle) {
      found = findVisual(
        isTrendForecast ? percentageVisuals : forecastOffPercentageVisuals,
        index,
        true,
        isTrendForecast
          ? [REPORT_TYPE.TREND_HOTEL_PERCENTAGE_TABULAR]
          : [REPORT_TYPE.TREND_HOTEL_PERCENTAGE_TABULAR_FORECAST_OFF],
        true
      );
    } else {
      found = findVisual(
        isTrendForecast ? visuals : forecastOffVisuals,
        index,
        false,
        isTrendForecast
          ? [REPORT_TYPE.TREND_HOTEL_CUMULATIVE_TABULAR]
          : [REPORT_TYPE.TREND_HOTEL_CUMULATIVE_TABULAR_FORECAST_OFF]
      );
    }
    return found;
  };
  // To get PBI visual instance based on the configuration
  const getWidget = (
    index,
    cssClassName,
    setPBIReport = () => {},
    setIsLoaded = () => {},
    filter = filterList
  ) => {
    const found = getVisual(index);
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={tokenDetails?.accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filter}
          setPBIReport={(r) => setPBIReport(r)}
          setIsLoaded={(d) => setIsLoaded(d)}
        />
      )
    );
  };
  // To get visual names based on visual order
  const getVisualName = (order) => getVisual(order)?.name;
  //
  const trendFilters = enableSecondaryHotelGroups
    ? `Compared Groups: , "${hotelGroupFocusOn?.label}"\r\n`
    : `Compared Hotels: , "${focusOn?.label}"\r\n`;
  //
  return (
    <>
      <VisualGrid
        isDisplay={revenueIsDisplay}
        visualName={getVisualName(0)}
        visual={getWidget(
          0,
          styles.paceVisual,
          setTotalRevenue,
          setIsTotalRevenueLoaded,
          normalizedFilterList
        )}
        onClick={() => {
          dispatch(trendActions.setRevenueIsDisplayTH(!revenueIsDisplay));
        }}
        toggleFragment={
          <ExportVisualDataTS
            report={totalRevenue}
            isLoaded={isTotalRevenueLoaded}
            fileName={
              isPercentageChange
                ? 'TVH_Percentage_Total_Revenue'
                : normalizedViewToggle
                ? 'TVH_Normalized_Total_Revenue'
                : isCumulative
                ? 'TVH_Cumulative_Total_Revenue'
                : weeklyViewToggle
                ? 'TVH_Weekly_Total_Revenue'
                : 'TVH_Total_Revenue'
            }
            weeklyViewToggle={weeklyViewToggle}
            isCumulative={isCumulative}
            otherFilters={trendFilters}
          />
        }
      />
      <VisualGrid
        isDisplay={occupiedRoomsIsDisplay}
        visualName={getVisualName(1)}
        visual={getWidget(
          1,
          styles.paceVisual,
          setTotalOccupiedRooms,
          setIsTotalOccupiedRoomsLoaded,
          normalizedFilterList
        )}
        onClick={() => {
          dispatch(trendActions.setOccupiedRoomIsDisplayTH(!occupiedRoomsIsDisplay));
        }}
        toggleFragment={
          <ExportVisualDataTS
            report={totalOccupiedRooms}
            isLoaded={isTotalOccupiedRoomsLoaded}
            fileName={
              isPercentageChange
                ? 'TVH_Percentage_Total_Occupied_Rooms'
                : normalizedViewToggle
                ? 'TVH_Normalized_Total_Occupied_Rooms'
                : isCumulative
                ? 'TVH_Cumulative_Total_Occupied_Rooms'
                : weeklyViewToggle
                ? 'TVH_Weekly_Total_Occupied_Rooms'
                : 'TVH_Total_Occupied_Rooms'
            }
            weeklyViewToggle={weeklyViewToggle}
            isCumulative={isCumulative}
            otherFilters={trendFilters}
          />
        }
      />
      <VisualGrid
        isDisplay={adrIsDisplay}
        visualName={getVisualName(2)}
        visual={getWidget(2, styles.paceVisual, setAdr, setIsAdrLoaded)}
        onClick={() => {
          dispatch(trendActions.setAdrIsDisplayTH(!adrIsDisplay));
        }}
        toggleFragment={
          <ExportVisualDataTS
            report={adr}
            isLoaded={isAdrLoaded}
            fileName={
              isPercentageChange
                ? 'TVH_Percentage_Average_Daily_Rate'
                : weeklyViewToggle
                ? 'TVH_Weekly_Average_Daily_Rate'
                : 'TVH_Average_Daily_Rate'
            }
            weeklyViewToggle={weeklyViewToggle}
            isCumulative={isCumulative}
            otherFilters={trendFilters}
          />
        }
      />
      {getWidget(3, styles.paceVisual, setAvgLoS, setIsAvgLoSLoaded) && (
        <VisualGrid
          isDisplay={avgLosIsDisplay}
          visualName={getVisualName(3)}
          visual={getWidget(3, styles.paceVisual, setAvgLoS, setIsAvgLoSLoaded)}
          onClick={() => {
            dispatch(trendActions.setAvgLosIsDisplayTH(!avgLosIsDisplay));
          }}
          toggleFragment={
            <ExportVisualDataTS
              report={avgLoS}
              isLoaded={isAvgLoSLoaded}
              fileName={
                isPercentageChange
                  ? 'TVH_Percentage_Average_Booking_Window'
                  : weeklyViewToggle
                  ? 'TVH_Weekly_Average_Booking_Window'
                  : 'TVH_Average_Booking_Window'
              }
              weeklyViewToggle={weeklyViewToggle}
              isCumulative={isCumulative}
              otherFilters={trendFilters}
            />
          }
        />
      )}
      {getWidget(4, styles.paceVisual, setAvgBookingWindow, setIsAvgBookingWindowLoaded) && (
        <VisualGrid
          isDisplay={avgBookingWindowIsDisplay}
          visualName={getVisualName(4)}
          visual={getWidget(4, styles.paceVisual, setAvgBookingWindow, setIsAvgBookingWindowLoaded)}
          onClick={() => {
            dispatch(trendActions.setAvgBookingWindowIsDisplayTH(!avgBookingWindowIsDisplay));
          }}
          toggleFragment={
            <ExportVisualDataTS
              report={avgBookingWindow}
              isLoaded={isAvgBookingWindowLoaded}
              fileName={
                isPercentageChange
                  ? 'TVH_Percentage_Average_Length_of_Stay'
                  : weeklyViewToggle
                  ? 'TVH_Weekly_Average_Length_of_Stay'
                  : 'TVH_Average_Length_of_Stay'
              }
              weeklyViewToggle={weeklyViewToggle}
              isCumulative={isCumulative}
              otherFilters={trendFilters}
            />
          }
        />
      )}
      {getWidget(
        5,
        styles.paceVisual,
        setCancellations,
        setIsCancellationsLoaded,
        normalizedFilterList
      ) && (
        <VisualGrid
          isDisplay={cxlIsDisplay}
          visualName={getVisualName(5)}
          visual={getWidget(
            5,
            styles.paceVisual,
            setCancellations,
            setIsCancellationsLoaded,
            normalizedFilterList
          )}
          onClick={() => {
            dispatch(trendActions.setCxlIsDisplayTH(!cxlIsDisplay));
          }}
          toggleFragment={
            <ExportVisualDataTS
              report={cancellations}
              isLoaded={isCancellationsLoaded}
              fileName={
                isPercentageChange
                  ? 'TVH_Percentage_Number_of_Cancellations'
                  : normalizedViewToggle
                  ? 'TVH_Normalized_Number_of_Cancellations'
                  : isCumulative
                  ? 'TVH_Cumulative_Number_of_Cancellations'
                  : weeklyViewToggle
                  ? 'TVH_Weekly_Number_of_Cancellations'
                  : 'TVH_Number_of_Cancellations'
              }
              weeklyViewToggle={weeklyViewToggle}
              isCumulative={isCumulative}
              otherFilters={trendFilters}
            />
          }
        />
      )}
    </>
  );
};
//
export default TrendHotelTabularWidget;
