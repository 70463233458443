import { Autocomplete, Grid, IconButton, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from 'modules/common/auth/slice';
import { dashboardActions } from 'modules/dashboard/slice';
import { configureEventCategoriesActions } from 'modules/event-configuration/slice';
import {
  selectHotelDropdownList,
  selectHotelName,
  selectTargetDate,
  selectIsSignedIn,
  selectHotelGroupDropdownList,
  selectSelectedHotelGroupName,
  selectEnableHotelGroups,
} from 'modules/dashboard/selectors';
import { useEffect, useState } from 'react';
import { getHotelValuesFromList } from 'modules/common/utils/array';
import {
  selectAuthUser,
  selectOrganization,
  selectOrganizationId,
} from 'modules/common/auth/selectors';
import { PlusCircle } from 'react-feather';
import { UiController, events } from 'modules/common/ui-controller';
import PAGINATION from 'modules/common/constants/pagination-limits';
import { toDate, formatDate } from 'modules/dashboard/functions';
import ORDER_BY from 'modules/common/constants/order-by';
import { YEAR_MONTH_DAY_PATTERN } from 'modules/common/constants/date-range';
import { ENVIRONMENT } from 'config';
import AddHotelGroup from 'modules/common/components/add-hotel-group/components/add-hotel-group-view';
import { differenceInCalendarDays } from 'date-fns';
import CustomToggleSwitch from '../toggle-switch';
import { TextField } from './style';

/**
 * Navigation bar hotel dropdown common component that uses
 * across modules for display hotels dropdown and select hotels
 */
const NavbarHotelDropdown = () => {
  const dispatch = useDispatch();
  //
  const selectedHotelName = useSelector(selectHotelName);
  const selectedHotelGroupName = useSelector(selectSelectedHotelGroupName);
  const organizationId = useSelector(selectOrganizationId);
  const hotelList = useSelector(selectHotelDropdownList);
  const groupsList = useSelector(selectHotelGroupDropdownList);
  const targetDate = useSelector(selectTargetDate);
  const isSignIn = useSelector(selectIsSignedIn);
  const authUser = useSelector(selectAuthUser);
  const organization = useSelector(selectOrganization);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  //
  const [hotelCommand, setHotelCommand] = useState(null);
  const [hotelGroupCommand, setHotelGroupCommand] = useState(null);
  const [open, setOpen] = useState(false);
  // get hotel list
  useEffect(() => {
    const serializedState = JSON.parse(localStorage.getItem(`persist:${ENVIRONMENT.PERSIST_KEY}`));
    if (!isSignIn && serializedState) {
      return;
    }
    const query = `limit=${PAGINATION.HOTELS_PER_PAGE_COUNT}&sortBy=name:${ORDER_BY.ASCENDING}`;
    dispatch(
      dashboardActions.getHotelList({
        organizationId,
        query,
      })
    );
    dispatch(
      authActions.getOrganization({
        organizationId,
      })
    );
  }, []);
  //
  useEffect(() => {
    if (authUser && organizationId) {
      const query = `limit=${PAGINATION.HOTELS_PER_PAGE_COUNT}&page=0`;
      dispatch(
        dashboardActions.getHotelGroupList({
          organizationId,
          userId: authUser?.id,
          query,
        })
      );
      const query2 = `limit=${PAGINATION.AGGREGATION_FILTER_PER_PAGE}&page=0`;
      dispatch(
        dashboardActions.getLoadHotelGroupList({
          organizationId,
          userId: authUser?.id,
          query: query2,
        })
      );
      dispatch(
        dashboardActions.getCommandFilterDropDownList({
          organizationId,
          userId: authUser?.id,
          query: `limit=${PAGINATION.HOTELS_PER_PAGE_COUNT}&page=0`,
        })
      );
    }
  }, [authUser, organizationId]);
  // get selected hotel , set pms date and set selected hotel for configure event categories
  useEffect(() => {
    const hotelData = hotelList?.filter((hotel) => hotel?.id === selectedHotelName?.id);
    const hotelGroupData = groupsList?.find((group) => group?.id === selectedHotelGroupName?.id);
    if (!enableHotelGroups && hotelData && hotelData.length > 0 && hotelData[0]?.pmsDate) {
      dispatch(dashboardActions.setPmsDate(toDate(new Date(hotelData[0]?.pmsDate))));
    } else if (enableHotelGroups && hotelGroupData && hotelGroupData?.hotels?.length > 0) {
      const pmsDates = hotelGroupData?.hotels?.map((hotel) => {
        if (hotel?.pmsDate) {
          return toDate(new Date(hotel?.pmsDate));
        }
        return null;
      });
      dispatch(dashboardActions.setGroupPmsDates(pmsDates));
    } else {
      dispatch(dashboardActions.setPmsDate(null));
      dispatch(dashboardActions.setGroupPmsDates(null));
    }
    dispatch(configureEventCategoriesActions.setHotelId(selectedHotelName?.id));
  }, [selectedHotelName, hotelList, groupsList, enableHotelGroups, selectedHotelGroupName]);
  // checked difference in start and end date in stay date range and get forecast filters
  useEffect(() => {
    if (selectedHotelName) {
      const hotelId = selectedHotelName?.id;
      if (
        differenceInCalendarDays(new Date(targetDate.startDate), new Date(targetDate.endDate)) === 0
      ) {
        const query = `limit=${PAGINATION.FORECAST_DATA}&stayDate=${formatDate(
          targetDate.startDate,
          YEAR_MONTH_DAY_PATTERN
        )}`;
        dispatch(dashboardActions.getForecastFilters({ organizationId, hotelId, query }));
      } else {
        const query = `limit=${PAGINATION.FORECAST_DATA}&stayDate=${formatDate(
          targetDate.startDate,
          YEAR_MONTH_DAY_PATTERN
        )}&toStayDate=${formatDate(targetDate.endDate, YEAR_MONTH_DAY_PATTERN)}`;
        dispatch(dashboardActions.getForecastFilters({ organizationId, hotelId, query }));
      }
    } else {
      dispatch(dashboardActions.setForecastFilters([]));
    }
  }, [selectedHotelName, targetDate]);
  // execute set hotel ui controller command flow
  useEffect(() => {
    if (hotelCommand !== null && hotelList?.length > 0) {
      const hotelsSelected = getHotelValuesFromList(hotelCommand, hotelList);
      if (hotelsSelected?.length > 0) {
        dispatch(dashboardActions.setEnableHotelGroups(false));
        dispatch(dashboardActions.setHotelName(hotelsSelected[0]));
      }
      dispatch(dashboardActions.setHotelChange(true));
      setHotelCommand(null);
    }
  }, [hotelCommand, hotelList?.length]);
  // execute set hotel ui controller command flow
  useEffect(() => {
    if (hotelGroupCommand !== null && groupsList?.length > 0) {
      const hotelGroupsSelected = getHotelValuesFromList(hotelGroupCommand, groupsList);
      if (hotelGroupsSelected?.length > 0) {
        dispatch(dashboardActions.setEnableHotelGroups(true));
        dispatch(dashboardActions.setHotelGroupName(hotelGroupsSelected[0]));
      }
      dispatch(dashboardActions.setHotelChange(true));
      setHotelGroupCommand(null);
    }
  }, [hotelGroupCommand, groupsList?.length]);
  // get set hotel command value
  const setHotelHandler = (data) => {
    setHotelCommand(data);
  };
  // get set hotel command value
  const setHotelGroupHandler = (data) => {
    setHotelGroupCommand(data);
  };
  // subscribe ui controller events
  useEffect(() => {
    UiController.subscribe(events.SET_HOTEL, setHotelHandler);
    return () => {
      UiController.unsubscribe(events.SET_HOTEL, setHotelHandler);
    };
  }, []);
  //
  useEffect(() => {
    UiController.subscribe(events.SET_HOTEL_GROUP, setHotelGroupHandler);
    return () => {
      UiController.unsubscribe(events.SET_HOTEL_GROUP, setHotelGroupHandler);
    };
  }, []);
  //
  return (
    <Grid container flexDirection="row" justifyContent="flex-end" alignItems="center" wrap="nowrap">
      <CustomToggleSwitch
        label="Hotel Sets"
        sx={{
          color: '#ffffff',
          fontSize: { md: 13, lg: 15 },
          mt: 2.5,
        }}
        onChange={(event) => {
          dispatch(dashboardActions.setEnableHotelGroups(event.target.checked));
        }}
        checked={enableHotelGroups}
        disabled={!organization?.selectHotelGroups ?? true}
      />
      {enableHotelGroups ? (
        <Autocomplete
          id="tags-outlined"
          sx={{
            minWidth: 200,
            '& .MuiOutlinedInput-root': {
              color: '#e5e5e5',
            },
            '& .MuiSvgIcon-root': {
              color: '#e5e5e5',
              width: '2rem',
              height: '2rem',
              top: 0,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '& .MuiAutocomplete-input': {
              fontSize: { md: 13, lg: 15 },
              fontWeight: 'bold',
            },
          }}
          options={groupsList ?? []}
          getOptionLabel={(option) => (option.label ? option.label : '')}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          filterSelectedOptions
          onChange={(event, value) => {
            dispatch(dashboardActions.setHotelGroupName(value));
          }}
          disableClearable
          disabled={groupsList?.length < 1}
          value={selectedHotelGroupName}
          renderInput={(params) => <TextField {...params} size="small" color="primary" />}
        />
      ) : (
        <Autocomplete
          id="tags-outlined"
          sx={{
            minWidth: 200,
            '& .MuiOutlinedInput-root': {
              color: '#e5e5e5',
            },
            '& .MuiSvgIcon-root': {
              color: '#e5e5e5',
              width: '2rem',
              height: '2rem',
              top: 0,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '& .MuiAutocomplete-input': {
              fontSize: { md: 13, lg: 15 },
              fontWeight: 'bold',
            },
          }}
          options={hotelList ?? []}
          getOptionLabel={(option) => (option.label ? option.label : '')}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          filterSelectedOptions
          onChange={(event, value) => {
            dispatch(dashboardActions.setHotelName(value));
          }}
          disableClearable
          value={selectedHotelName}
          renderInput={(params) => <TextField {...params} size="small" color="primary" />}
        />
      )}
      <Tooltip title="Add Hotel Set">
        <span>
          <IconButton
            color="primary"
            variant="contained"
            onClick={() => setOpen(true)}
            disabled={!enableHotelGroups}
            sx={{
              '&.Mui-disabled': {
                background: 'gray',
                color: 'white',
              },
            }}
          >
            <PlusCircle size="15px" color="white" />
          </IconButton>
        </span>
      </Tooltip>

      <AddHotelGroup open={open} onClose={() => setOpen(false)} hotelList={hotelList ?? []} />
    </Grid>
  );
};
//
export default NavbarHotelDropdown;
