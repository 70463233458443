import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { REPORT_TYPE } from 'modules/dashboard/constants';
import { selectEmbedToken } from 'modules/dashboard/selectors';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import { getVisualName } from 'modules/dashboard/functions';
import VisualCard from 'modules/dashboard/components/tab-container/visual-card';
import PaceFilter from '../../filter-panel';
import {
  selectActivePaceTab,
  selectPaceTimeRevenueFilterList,
  selectPaceTimeVisuals,
} from '../../../selectors';
import PaceTimeWidget from './pace-time';
import PaceSegmentWidget from './pace-segment';
import PaceHotelWidget from './pace-hotel';
/**
 * Trend tab widget implementation
 * @param {Number} value - Trend time widget tab value
 * @param {Boolean} toggleDisabled - PMS sync toggle disabled state
 * @param {Date} latestDate - Latest booking date of selected hotel
 * @param {String} hotelTimezone - Timezone of selected hotel
 * @param {String} reportType - Report type of active tab
 * @returns
 */
const Widget = ({ value, latestDate, reportType, hotelTimezone, toggleDisabled }) => {
  // States are obtained from redux store. Refer each selector function for details.
  const filterList = useSelector(selectPaceTimeRevenueFilterList);
  const tokenDetails = useSelector(selectEmbedToken);
  const visuals = useSelector(selectPaceTimeVisuals);
  const activeTab = useSelector(selectActivePaceTab);
  //
  const [isLoaded, setIsLoaded] = useState(false);
  const [isTimeLoaded, setIsTimeLoaded] = useState(false);
  const [isSegmentLoaded, setIsSegmentLoaded] = useState(false);
  const [isHotelLoaded, setIsHotelLoaded] = useState(false);

  // To get visuals based on the configuration
  const getWidget = (index, cssClassName) => {
    const found = visuals?.find((visual) => visual?.order === index);
    //
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={tokenDetails?.accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filterList}
        />
      )
    );
  };
  // To determine is visual loaded state for pace tabs
  useEffect(() => {
    switch (activeTab) {
      case REPORT_TYPE.PACE_TIME_WIDGET:
        setIsLoaded(isTimeLoaded);
        break;
      case REPORT_TYPE.PACE_SEGMENT_WIDGET:
        setIsLoaded(isSegmentLoaded);
        break;
      case REPORT_TYPE.PACE_HOTEL_WIDGET:
        setIsLoaded(isHotelLoaded);
        break;
      default:
        break;
    }
  }, [isTimeLoaded, isSegmentLoaded, isHotelLoaded, activeTab]);
  //
  return (
    <>
      <Grid item xs={2}>
        <PaceFilter
          reportType={reportType}
          tabValue={value}
          toggleDisabled={toggleDisabled}
          latestDate={latestDate}
          hotelTimezone={hotelTimezone}
          isLoaded={isLoaded}
          visual={getWidget(100, styles.symmetricComparisonWindow)}
          visualName={getVisualName(visuals, 100)}
        />
      </Grid>
      <Grid container item xs={10} direction="column">
        {activeTab === REPORT_TYPE.PACE_TIME_WIDGET && (
          <PaceTimeWidget setIsTimeLoaded={setIsTimeLoaded} />
        )}
        {activeTab === REPORT_TYPE.PACE_SEGMENT_WIDGET && (
          <PaceSegmentWidget setIsSegmentLoaded={setIsSegmentLoaded} />
        )}
        {activeTab === REPORT_TYPE.PACE_HOTEL_WIDGET && (
          <PaceHotelWidget setIsHotelLoaded={setIsHotelLoaded} />
        )}
      </Grid>
    </>
  );
};
//
export default Widget;
