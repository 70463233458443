import { Box, Dialog, DialogContent, DialogTitle, Tab } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { selectAuthUser, selectOrganizationId } from 'modules/common/auth/selectors';
import { usersActions } from 'modules/users/slice';
import UpdateUser from './update-user';
import UpdatePassword from './update-password';

/**
 * Preferences component to update user preferences
 * @param {boolean} open - open / close state of modal
 * @param {function} onClose - function to close the modal
 * @returns
 */
const Preference = ({ open, onClose }) => {
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const authUser = useSelector(selectAuthUser);
  //
  const [tabValue, setTabValue] = useState('1');
  // get user by user id
  useEffect(() => {
    if (authUser && tabValue === '1') {
      dispatch(usersActions.getUserById({ organizationId, userId: authUser?.id }));
    }
  }, [tabValue]);
  //
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">User Preferences</DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={(e, val) => setTabValue(val)} aria-label="user preferences">
                <Tab label="General" value="1" />
                <Tab label="Security" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <UpdateUser
                organizationId={organizationId}
                userId={authUser?.id}
                onClose={() => onClose()}
                open={open}
              />
            </TabPanel>
            <TabPanel value="2">
              <UpdatePassword
                organizationId={organizationId}
                userId={authUser?.id}
                onClose={() => onClose()}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
//
export default Preference;
