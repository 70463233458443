import { useState, useEffect, useRef } from 'react';
import { Toolbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardActions } from 'modules/dashboard/slice';
import {
  selectVisualFilters,
  selectPmsSync,
  selectIsSignedIn,
  selectBreakdownDetails,
  selectHotelName,
  selectHotelDropdownList,
  selectEnableHotelGroups,
  selectHotelGroupDropdownList,
  selectSelectedHotelGroupName,
} from 'modules/dashboard/selectors';

import {
  getComparisonDateRange,
  getSelectedBreakdown,
  shortcut,
  timeout,
} from 'modules/dashboard/functions';
import { capitalizeFirst } from 'modules/common/helpers/strings';
import {
  TAB_VALUE,
  SHORTCUT_KEY,
  OTB_VALUE,
  KEYS,
  TREND_STAY_DATE,
  RESET_BOOKING_DATE,
  TAGS,
  REPORT_TYPE,
  ALIGNED,
} from 'modules/dashboard/constants';
import ActionButton from 'modules/common/components/action-button';
import { CustomStack } from 'modules/common/components';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import { ENVIRONMENT } from 'config';
import DataUpdatedLabel from 'modules/dashboard/components/tab-container/data-updated-label';
import PmsToggle from 'modules/dashboard/components/tab-container/inner-filter/components/pms-toggle';
import WeeklyCumulativeWindow from 'modules/dashboard/components/tab-container/inner-filter/components/weekly-cumulative-window';
import BookingWindow from 'modules/dashboard/components/tab-container/inner-filter/components/booking-window';
import TrendTimeVsComparisonWindow from 'modules/dashboard/components/tab-container/inner-filter/components/trend-time-vs-compare-window';
import {
  getDateComparisonFilter,
  getFocusOnFilter,
  getSubAlignmentFilter,
  getSubBreakdownFilter,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import {
  BreakdownWindow,
  CompareHotelsWindow,
} from 'modules/dashboard/components/tab-container/inner-filter';
import { selectOrganization } from 'modules/common/auth/selectors';
import { trendTabularTabActions } from '../../slice';
import {
  selectTrendOtb,
  selectTrendBookingStyle,
  selectActiveTrendTab,
  selectEnableSecondaryHotelGroups,
  selectIsCumulative,
  selectIsForecast,
  selectIsForecastDisabled,
  selectIsPercentageChange,
  selectIsPercentageChangeDisabled,
  selectIsPrimary,
  selectIsSegmentDetailed,
  selectSegmentSetAName,
  selectSegmentSetBName,
  selectSelectedFilterName,
  selectShowCustomGroupingFilterName,
  selectSymmetricComparisonIsDisplay,
  selectTrendHotelFocusOn,
  selectTrendHotelGroupFocusOn,
  selectTrendHotelNormalizedView,
  selectTrendSegmentChartBreakdown,
  selectTrendSegmentFocusOn,
  selectTrendSymmetricComparisonToggle,
  selectTrendTimeAlignment,
  selectTrendTimeAlignmentToggle,
  selectTrendTimeCustomDate,
  selectTrendTimeDateComparison,
  selectTrendTimeEndDate,
  selectTrendTimeStartDate,
  selectTrendTimeWeeklyView,
  selectTrendTargetDate,
} from '../../selectors';
import useTrendTabularCommandFlow from '../../hooks/use-trend-tabular-command-flow';

/**
 * Filter panel implementation for Trend Widget
 * @param {Number} tabValue - Pace widget tab value
 * @param {Date} latestDate - Latest booking date of selected hotel
 * @param {Boolean} hotelTimezone - Timezone of selected hotel
 * @param {Number} toggleDisabled - PMS sync toggle disabled state
 * @param {Boolean} isLoaded - Visual is loaded state
 * @param {String} reportType - Report type of active tab
 * @param {Object} visual- Symmetric comparison visual object
 * @param {String} visualName - Display name for symmetric comparison visual
 * @returns
 */
const TrendTabularFilter = ({
  reportType,
  visual,
  visualName,
  tabValue,
  toggleDisabled,
  latestDate,
  hotelTimezone,
  isLoaded,
}) => {
  const dispatch = useDispatch();
  const setCustomDatesRef = useRef(null);
  // Selected filter values are obtained from redux store. Refer each selector function for details.
  const organization = useSelector(selectOrganization);
  const customBooking = useSelector(selectTrendBookingStyle);
  const vOTB = useSelector(selectTrendOtb);
  const alignment = useSelector(selectTrendTimeAlignment);
  const dateComparison = useSelector(selectTrendTimeDateComparison);
  const visualFilters = useSelector(selectVisualFilters);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const weeklyViewToggle = useSelector(selectTrendTimeWeeklyView);
  const alignmentToggle = useSelector(selectTrendTimeAlignmentToggle);
  const targetDate = useSelector(selectTrendTargetDate);
  const symmetricComparisonToggle = useSelector(selectTrendSymmetricComparisonToggle);
  const isSignIn = useSelector(selectIsSignedIn);
  const isCumulative = useSelector(selectIsCumulative);
  const customDate = useSelector(selectTrendTimeCustomDate);
  const startDate = useSelector(selectTrendTimeStartDate);
  const endDate = useSelector(selectTrendTimeEndDate);
  const isTrendForecast = useSelector(selectIsForecast);
  const isDetailed = useSelector(selectIsSegmentDetailed);
  const focusOn = useSelector(selectTrendSegmentFocusOn);
  const chartBreakdown = useSelector(selectTrendSegmentChartBreakdown);
  const breakdownData = useSelector(selectBreakdownDetails);
  const selectedFilterName = useSelector(selectSelectedFilterName);
  const showFilterName = useSelector(selectShowCustomGroupingFilterName);
  const setAName = useSelector(selectSegmentSetAName);
  const setBName = useSelector(selectSegmentSetBName);
  const hotelFocusOn = useSelector(selectTrendHotelFocusOn);
  const hotelList = useSelector(selectHotelDropdownList);
  const normalizedViewToggle = useSelector(selectTrendHotelNormalizedView);
  const enableSecondaryHotelGroups = useSelector(selectEnableSecondaryHotelGroups);
  const hotelGroupList = useSelector(selectHotelGroupDropdownList);
  const selectedHotelGroupName = useSelector(selectSelectedHotelGroupName);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const hotelGroupFocusOn = useSelector(selectTrendHotelGroupFocusOn);
  const isForecastDisabled = useSelector(selectIsForecastDisabled);
  const activeTab = useSelector(selectActiveTrendTab);
  const symmetricComparisonIsDisplay = useSelector(selectSymmetricComparisonIsDisplay);
  const isPrimary = useSelector(selectIsPrimary);
  const isTrendPercentageChange = useSelector(selectIsPercentageChange);
  const isTrendPercentageDisabled = useSelector(selectIsPercentageChangeDisabled);
  //
  const [dateComparisonList, setDateComparisonList] = useState([]);
  const [chartBreakdownList, setChartBreakdownList] = useState([]);
  const [focusOnList, setFocusOnList] = useState([]);
  const [selectedBreakdown, setSelectedBreakdown] = useState();
  const [open, setOpen] = useState(false);
  // To select otb or custom booking window
  const handleOTBChange = (event) => {
    const { value } = event.target;
    dispatch(trendTabularTabActions.setTrendOtb(value));
    dispatch(trendTabularTabActions.setTrendBookingStyle(!customBooking));
    dispatch(trendTabularTabActions.setTrendBookingDate(RESET_BOOKING_DATE));
  };
  // Reset button function to set filter panel values to default
  const clearSearch = async () => {
    dispatch(trendTabularTabActions.setIsPrimary(false));
    dispatch(trendTabularTabActions.setTrendOtb(0));
    dispatch(trendTabularTabActions.setTrendBookingStyle(false));
    const subAlignmentFilter = getSubAlignmentFilter(visualFilters, false);
    dispatch(
      trendTabularTabActions.setTrendTimeDateComparison(
        subAlignmentFilter?.values?.filter((filter) => filter.isDefault === true)[0].value
      )
    );

    dispatch(
      trendTabularTabActions.setTrendTimeAlignmentToggle(subAlignmentFilter?.type === ALIGNED)
    );
    dispatch(trendTabularTabActions.setTrendBookingDate(RESET_BOOKING_DATE));
    dispatch(dashboardActions.setPmsSync(false));
    dispatch(trendTabularTabActions.setTrendTimeWeeklyView(false));
    dispatch(trendTabularTabActions.setTrendSymmetricComparisonToggle(true));
    await timeout(2000);
    dispatch(trendTabularTabActions.setIsCumulative(false));
    dispatch(trendTabularTabActions.setTrendTimeStartDate(TREND_STAY_DATE.startDate));
    dispatch(trendTabularTabActions.setTrendTimeEndDate(TREND_STAY_DATE.endDate));
    dispatch(trendTabularTabActions.setTrendTimeCustomDate(KEYS.START_DATE));
    dispatch(trendTabularTabActions.setTrendTimeDateSelected(TREND_STAY_DATE.startDate));
    dispatch(trendTabularTabActions.setTrendSegmentFocusOn([]));
    dispatch(trendTabularTabActions.setSegmentGroup1([]));
    dispatch(trendTabularTabActions.setSegmentGroup2([]));
    dispatch(
      trendTabularTabActions.setTrendSegmentChartBreakdown(
        visualFilters?.filter(
          (filter) => filter?.tags?.includes(TAGS.SEGMENT_BREAKDOWN) && filter?.isDefault === true
        )[0].id
      )
    );
    dispatch(trendTabularTabActions.setIsSegmentDetailed(false));
    dispatch(trendTabularTabActions.setTrendHotelFocusOn(hotelList[0]));
    dispatch(trendTabularTabActions.setEnableSecondaryHotelGroups(false));
    dispatch(trendTabularTabActions.setTrendHotelGroupFocusOn(hotelGroupList[0]));
    dispatch(trendTabularTabActions.setIsTrendForecast(false));
    dispatch(trendTabularTabActions.setTrendHotelNormalizedView(true));
    dispatch(
      trendTabularTabActions.setIsForecastDisabled(!organization?.enableForecastFilter ?? false)
    );
  };

  // set custom date comparison value
  const setArbitraryComparisonDate = () => {
    dispatch(trendTabularTabActions.setTrendTimeDateSelected(TREND_STAY_DATE.startDate));
    dispatch(trendTabularTabActions.setTrendTimeCustomDate(KEYS.START_DATE));
    const dateRange = getComparisonDateRange(
      TREND_STAY_DATE.startDate,
      targetDate.startDate,
      targetDate.endDate,
      KEYS.START_DATE
    );
    //
    dispatch(
      trendTabularTabActions.setTrendTimeStartDate(dateRange?.comparisonStartDate.toISOString())
    );
    dispatch(
      trendTabularTabActions.setTrendTimeEndDate(dateRange?.comparisonEndDate.toISOString())
    );
  };
  // Date comparison checkbox change handler
  const handleDateComparisonChange = (event) => {
    const { value } = event.target;
    dispatch(trendTabularTabActions.setTrendTimeDateComparison(value));
    if (value === KEYS.CUSTOM) {
      setArbitraryComparisonDate();
    }
  };
  // Triggered on change of hotel group list
  useEffect(() => {
    if (hotelGroupList?.length > 0 && !enableSecondaryHotelGroups) {
      dispatch(trendTabularTabActions.setTrendHotelGroupFocusOn(hotelGroupList[0]));
    }
  }, [hotelGroupList]);
  // Triggered when values of alignment, visualFilters, alignmentToggle change
  useEffect(() => {
    // Set date comparison list based on alignment
    if (alignment) {
      const dateComparisonFilter = getDateComparisonFilter(visualFilters, alignment?.id);
      setDateComparisonList(dateComparisonFilter);
      // Persist values on browser refresh
      const serializedState = JSON.parse(
        localStorage.getItem(`persist:${ENVIRONMENT.PERSIST_KEY}`)
      );
      if (!isSignIn && serializedState) {
        return;
      }
      dispatch(
        trendTabularTabActions.setTrendTimeDateComparison(
          dateComparisonFilter?.filter((filter) => filter?.isDefault === true)[0].value
        )
      );
    }
  }, [alignment, visualFilters, alignmentToggle]);
  // Triggered when alignmentToggle, visualFilters values change
  useEffect(() => {
    // Set alignment list
    const subAlignmentFilter = getSubAlignmentFilter(visualFilters, alignmentToggle);
    dispatch(
      trendTabularTabActions.setTrendTimeAlignment({
        id: subAlignmentFilter?.id,
        label: capitalizeFirst(subAlignmentFilter?.type),
      })
    );
  }, [alignmentToggle, visualFilters]);

  // Set alignment toggle on if weekly toggle is on
  useEffect(() => {
    if (weeklyViewToggle) dispatch(trendTabularTabActions.setTrendTimeAlignmentToggle(true));
  }, [weeklyViewToggle]);

  // Triggered when visualFilters value changes
  useEffect(() => {
    // Set breakdown list
    const subBreakdownFilter = getSubBreakdownFilter(visualFilters);
    if (subBreakdownFilter) {
      setChartBreakdownList(subBreakdownFilter);
    }
  }, [visualFilters]);

  // Triggered when the values of isDetailed, chartBreakdown or visualFilters change
  useEffect(() => {
    // Assign values to selected breakdown on chart breakdown or isDetails toggle change
    const breakdown = getSelectedBreakdown(visualFilters, chartBreakdown, isDetailed);
    setSelectedBreakdown(breakdown);
  }, [isDetailed, chartBreakdown, visualFilters]);

  useEffect(() => {
    // set breakdown focus on filter list
    if (chartBreakdown) {
      const focusOnFilter = getFocusOnFilter(breakdownData, selectedBreakdown);
      setFocusOnList(focusOnFilter);
    }
  }, [chartBreakdown, visualFilters, breakdownData, selectedBreakdown]);

  useEffect(() => {
    // Assign forecast disabled state on breakdown focus on change, percentage toggle and active tab change
    if (activeTab === REPORT_TYPE.TREND_SEGMENT || focusOn?.length) {
      dispatch(trendTabularTabActions.setIsForecastDisabled(true));
      dispatch(trendTabularTabActions.setIsTrendForecast(false));
    } else if (
      (activeTab === REPORT_TYPE.TREND_TIME || activeTab === REPORT_TYPE.TREND_HOTEL) &&
      isTrendPercentageChange
    ) {
      dispatch(trendTabularTabActions.setIsForecastDisabled(true));
      dispatch(trendTabularTabActions.setIsTrendForecast(false));
    } else
      dispatch(
        trendTabularTabActions.setIsForecastDisabled(!organization?.enableForecastFilter ?? false)
      );
  }, [isDetailed, chartBreakdown, focusOn, activeTab, organization, isTrendPercentageChange]);

  useEffect(() => {
    // Assign percentage disabled state on cumulative view, weekly view normalized view
    if (isCumulative || weeklyViewToggle || normalizedViewToggle) {
      dispatch(trendTabularTabActions.setIsTrendPercentageChange(false));
      dispatch(trendTabularTabActions.setIsTrendPercentageChangeDisabled(true));
    } else {
      dispatch(trendTabularTabActions.setIsTrendPercentageChangeDisabled(false));
    }
  }, [isCumulative, weeklyViewToggle, normalizedViewToggle, isTrendPercentageChange]);

  // Triggered when dateComparison value changes
  useEffect(() => {
    if (dateComparison) {
      if (!dateComparison.includes(KEYS.CUSTOM)) {
        setArbitraryComparisonDate();
      }
    }
  }, [dateComparison]);

  // Triggered when customBooking, dateComparison, visualFilters values change
  useEffect(() => {
    // Assign default value to date comparison when OTB is selected for booking window
    if (!customBooking && dateComparison === OTB_VALUE) {
      const subAlignmentFilter = getSubAlignmentFilter(visualFilters, true);
      dispatch(
        trendTabularTabActions.setTrendTimeDateComparison(
          subAlignmentFilter?.values?.find((filter) => filter.isDefault === true)[0].value
        )
      );
    }
  }, [customBooking, dateComparison, visualFilters]);
  // Shortcut function to weekly view toggle
  shortcut(tabValue, TAB_VALUE.TREND_TABULAR, SHORTCUT_KEY.WEEKLY_TOGGLE, () =>
    dispatch(trendTabularTabActions.setTrendTimeWeeklyView(!weeklyViewToggle))
  );
  // Shortcut function to set pms sync value
  shortcut(tabValue, TAB_VALUE.TREND_TABULAR, SHORTCUT_KEY.PMS_TOGGLE, () =>
    dispatch(dashboardActions.setPmsSync(!pmsSyncToggle))
  );
  // Alignment toggle change handler
  const handleAlignmentToggleChange = (event) => {
    dispatch(trendTabularTabActions.setTrendTimeAlignmentToggle(event.target.checked));
    const dateComparisonFilter = getDateComparisonFilter(visualFilters, alignment?.id);
    setDateComparisonList(dateComparisonFilter);
    dispatch(
      trendTabularTabActions.setTrendTimeDateComparison(
        dateComparisonFilter?.filter((filter) => filter?.isDefault === true)[0].value
      )
    );
  };
  // Reset focus on custom aggregation values
  const resetOptions = () => {
    dispatch(trendTabularTabActions.setTrendSegmentFocusOn([]));
    dispatch(trendTabularTabActions.setSegmentGroup1([]));
    dispatch(trendTabularTabActions.setSegmentGroup2([]));
  };
  // reset focus on option when hotel group option is selected
  useEffect(() => {
    resetOptions();
  }, [enableHotelGroups, selectedHotelGroupName]);
  /**
   * set breakdown value
   * @param {*} event - onChanged event
   */
  const handleBreakdownChange = (event) => {
    dispatch(trendTabularTabActions.setTrendSegmentChartBreakdown(event.target.value));
    dispatch(trendTabularTabActions.setTrendSegmentFocusOn([]));
    dispatch(trendTabularTabActions.setSegmentGroup1([]));
    dispatch(trendTabularTabActions.setSegmentGroup2([]));
    dispatch(trendTabularTabActions.setShowCustomGroupingFilterName(false));
  };
  /**
   * set isDetailed value
   * @param {*} event - onChanged event
   */
  const handleIsDetailedChange = (event) => {
    dispatch(trendTabularTabActions.setIsSegmentDetailed(event.target.checked));
    resetOptions();
  };
  //
  useTrendTabularCommandFlow(
    isLoaded,
    customBooking,
    chartBreakdownList,
    visualFilters,
    dateComparison,
    dateComparisonList,
    handleAlignmentToggleChange,
    focusOnList,
    setArbitraryComparisonDate,
    setCustomDatesRef
  );
  return (
    <>
      <PmsToggle
        disabled={toggleDisabled}
        value={pmsSyncToggle}
        onChange={(event) => dispatch(dashboardActions.setPmsSync(event.target.checked))}
      />
      {false && (
        <WeeklyCumulativeWindow
          isCumulative={isCumulative}
          weeklyViewToggle={weeklyViewToggle}
          handleIsCumulativeChange={(event) =>
            dispatch(trendTabularTabActions.setIsCumulative(event.target.checked))
          }
          handleWeeklyViewChange={(event) =>
            dispatch(trendTabularTabActions.setTrendTimeWeeklyView(event.target.checked))
          }
          isForecastToggle
          handleIsForecastChange={(event) =>
            dispatch(trendTabularTabActions.setIsTrendForecast(event.target.checked))
          }
          isTrendForecast={isTrendForecast}
          isForecastDisabled={isForecastDisabled}
          isPercentageToggle
          isTrendPercentageChange={isTrendPercentageChange}
          isTrendPercentageDisabled={isTrendPercentageDisabled}
          handleIsPercentageToggleChange={(event) =>
            dispatch(trendTabularTabActions.setIsTrendPercentageChange(event.target.checked))
          }
        />
      )}
      <br />
      <BookingWindow
        vOTB={vOTB}
        customBooking={customBooking}
        handleOTBChange={handleOTBChange}
        reportType={reportType}
        latestDate={latestDate}
        otbValue={0}
      />
      <br />
      <BreakdownWindow
        isDetailed={isDetailed}
        chartBreakdown={chartBreakdown}
        chartBreakdownList={chartBreakdownList}
        handleIsDetailedChange={handleIsDetailedChange}
        handleBreakdownChange={handleBreakdownChange}
        isFocusOnAvailable
        focusOnList={focusOnList}
        focusOn={focusOn}
        focusOnLabel={focusOn?.length ? 'Focused on' : 'Select Focus on'}
        handleFocusOnChange={(event, value) => {
          dispatch(trendTabularTabActions.setTrendSegmentFocusOn(value));
        }}
        isGroupingAvailable={false}
        onGroupClick={() => {
          setOpen(true);
          dispatch(trendTabularTabActions.setShowCustomGroupingFilterName(false));
        }}
        setOpen={setOpen}
        openGroupingDialog={open}
        showFilterName={showFilterName}
        setAName={setAName}
        setBName={setBName}
        selectedFilterName={selectedFilterName}
      />
      {false && (
        <CompareHotelsWindow
          selectedHotelName={selectHotelName}
          hotelList={hotelList}
          focusOn={hotelFocusOn}
          focusOnLabel={hotelFocusOn?.length ? 'Compared with' : 'Select Hotel'}
          handleCompareHotelsChange={(event, value) => {
            dispatch(trendTabularTabActions.setTrendHotelFocusOn(value));
          }}
          normalizedViewToggle={normalizedViewToggle}
          handleTrendHotelNormalize={(event) =>
            dispatch(trendTabularTabActions.setTrendHotelNormalizedView(event.target.checked))
          }
          enableSecondaryHotelGroups={enableSecondaryHotelGroups}
          hotelGroupList={hotelGroupList}
          hotelGroupFocusOn={hotelGroupFocusOn}
          groupFocusOnLabel={hotelGroupFocusOn?.length ? 'Compared with' : 'Select Hotel Set'}
          handleCompareGroupHotelsChange={(event, value) => {
            dispatch(trendTabularTabActions.setTrendHotelGroupFocusOn(value));
          }}
          handleEnableSecondaryHotelGroups={(event) =>
            dispatch(trendTabularTabActions.setEnableSecondaryHotelGroups(event.target.checked))
          }
          isTrendPercentageChange={isTrendPercentageChange}
        />
      )}
      <br />
      <TrendTimeVsComparisonWindow
        showBreakdown={false}
        customBooking={customBooking}
        alignmentToggle={alignmentToggle}
        handleToggleChange={handleAlignmentToggleChange}
        symmetricComparisonToggle={symmetricComparisonToggle}
        visual={visual}
        visualName={visualName}
        dateComparison={dateComparison}
        dateComparisonList={dateComparisonList}
        handleComparisonChange={handleDateComparisonChange}
        handleSymmetricComparisonChange={(event) =>
          dispatch(trendTabularTabActions.setTrendSymmetricComparisonToggle(event.target.checked))
        }
        weeklyViewToggle={weeklyViewToggle}
        customDate={customDate}
        reportType={reportType}
        startDate={startDate}
        endDate={endDate}
        targetDate={targetDate}
        handleCustomDates={(event) =>
          dispatch(trendTabularTabActions.setTrendTimeCustomDate(event.target.value))
        }
        isDisplay={symmetricComparisonIsDisplay}
        isDisplayOnClickAction={() =>
          dispatch(
            trendTabularTabActions.setSymmetricComparisonIsDisplay(!symmetricComparisonIsDisplay)
          )
        }
        activeTab={activeTab}
        isPrimary={isPrimary}
        handlePrimaryChange={(event) =>
          dispatch(trendTabularTabActions.setIsPrimary(event.target.checked))
        }
      />
      <br />
      <CustomStack cssClassName={styles.innerFilter}>
        <ActionButton onClick={clearSearch} label="RESET" />
      </CustomStack>
      <Toolbar />
      <DataUpdatedLabel latestDate={latestDate} hotelTimezone={hotelTimezone} />
    </>
  );
};
//
export default TrendTabularFilter;
