/**
 * Function to get relevant values from application list when provide command values
 * @param {*} array
 * @param {*} list
 * @returns
 */
export const getValuesFromList = (array, list) => {
  const optionsSelected = [];
  array?.forEach((option) => {
    const optionSelected = list?.filter((item) =>
      item?.label
        ?.trim()
        ?.replace(/ +/g, '')
        .toLowerCase()
        === option?.trim()?.replace(/ +/g, '').toLowerCase()
    );
    if (optionSelected?.length > 0) {
      optionsSelected.push(optionSelected[0]);
    }
  });
  //
  return optionsSelected;
};
/**
 * Function to get matched values from application list when provide command values
 * @param {*} array
 * @param {*} list
 * @returns
 */
export const getMatchedValuesFromList = (array, list) => {
  const optionsSelected = [];
  array?.forEach((option) => {
    const optionSelected = list?.filter((item) =>
      item
        ?.trim()
        ?.replace(/ +/g, '')
        .toLowerCase()
        .includes(option?.trim()?.replace(/ +/g, '').toLowerCase())
    );
    if (optionSelected?.length > 0) {
      optionsSelected.push(optionSelected[0]);
    }
  });
  //
  return optionsSelected;
};
/**
 * Function to get relevant hotel  values from application list when provide command values
 * @param {*} array
 * @param {*} list
 * @returns
 */
export const getHotelValuesFromList = (array, list) => {
  const optionsSelected = [];
  array?.forEach((option) => {
    const optionSelected = list?.filter(
      (item) =>
        item?.label
          ?.trim()
          ?.replace(/ +/g, '')
          .toLowerCase()
          .includes(option?.trim()?.replaceAll('%20', ' ')?.replace(/ +/g, '').toLowerCase()) ||
        item?.id === option ||
        item?.altName
          ?.trim()
          ?.replace(/ +/g, '')
          .toLowerCase()
          .includes(option?.trim()?.replace(/ +/g, '').toLowerCase())
    );
    if (optionSelected?.length > 0) {
      optionsSelected.push(optionSelected[0]);
    }
  });
  //
  return optionsSelected;
};
/**
 * Function to transform string into array
 * @param {*} value
 * @returns
 */
export const transformIntoArray = (value) => {
  let arr = [];
  if (value?.includes(',')) {
    arr = value?.split(',');
  } else {
    arr.push(value);
  }
  return arr;
};
