import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEmbedToken } from 'modules/dashboard/selectors';
import {
  selectTrendHotelFilterList,
  selectIsCumulative,
  selectTrendHotelNormalizedFilterList,
  selectTrendTimeWeeklyView,
  selectTrendHotelVisuals,
  selectWeeklyTrendHotelVisuals,
  selectAvgLosIsDisplayTH,
  selectAvgBookingWindowIsDisplayTH,
  selectAdrIsDisplayTH,
  selectCxlIsDisplayTH,
  selectOccupiedRoomsIsDisplayTH,
  selectRevenueIsDisplayTH,
  selectTrendHotelCumulativeVisuals,
  selectIsPercentageChange,
  selectTrendHotelNormalizedView,
  selectTrendHotelPercentageVisuals,
  selectIsForecast,
  selectTrendHotelForecastOffVisuals,
  selectTrendHotelForecastOffCumulativeVisuals,
  selectWeeklyTrendHotelForecastOffVisuals,
  selectTrendHotelForecastOffPercentageVisuals,
} from 'modules/dashboard/components/tab-container/trend-tabs/selectors';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import VisualGrid from 'modules/dashboard/components/tab-container/visual-grid';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import { REPORT_TYPE } from 'modules/dashboard/constants';
import { findVisual } from 'modules/dashboard/components/tab-container/trend-tabs/functions';
import VisualCard from 'modules/dashboard/components/tab-container/visual-card';
/**
 * Trend Hotel graphical report with filters
 * @param {Function} setIsHotelLoaded - set whether trend hotel graphical visuals are loaded
 * @returns
 */
const TrendHotelGraphicalWidget = ({ setIsHotelLoaded }) => {
  const dispatch = useDispatch();
  // States are obtained from redux store. Refer each selector function for details.
  const filterList = useSelector(selectTrendHotelFilterList);
  const weeklyViewToggle = useSelector(selectTrendTimeWeeklyView);
  const isCumulative = useSelector(selectIsCumulative);
  const normalizedFilterList = useSelector(selectTrendHotelNormalizedFilterList);
  const visuals = useSelector(selectTrendHotelVisuals);
  const cumulativeVisuals = useSelector(selectTrendHotelCumulativeVisuals);
  const weeklyVisuals = useSelector(selectWeeklyTrendHotelVisuals);
  const tokenDetails = useSelector(selectEmbedToken);
  const revenueIsDisplay = useSelector(selectRevenueIsDisplayTH);
  const occupiedRoomsIsDisplay = useSelector(selectOccupiedRoomsIsDisplayTH);
  const adrIsDisplay = useSelector(selectAdrIsDisplayTH);
  const avgBookingWindowIsDisplay = useSelector(selectAvgBookingWindowIsDisplayTH);
  const avgLosIsDisplay = useSelector(selectAvgLosIsDisplayTH);
  const cxlIsDisplay = useSelector(selectCxlIsDisplayTH);
  const percentageVisuals = useSelector(selectTrendHotelPercentageVisuals);
  const isPercentageChange = useSelector(selectIsPercentageChange);
  const normalizedViewToggle = useSelector(selectTrendHotelNormalizedView);
  const isTrendForecast = useSelector(selectIsForecast);
  //
  const forecastOffVisuals = useSelector(selectTrendHotelForecastOffVisuals);
  const forecastOffCumulativeVisuals = useSelector(selectTrendHotelForecastOffCumulativeVisuals);
  const forecastOffWeeklyVisuals = useSelector(selectWeeklyTrendHotelForecastOffVisuals);
  const forecastOffPercentageVisuals = useSelector(selectTrendHotelForecastOffPercentageVisuals);
  //
  const [isInitialTotalRevenueLoaded, setIsInitialTotalRevenueLoaded] = useState(false);
  const [isInitialTotalOccupiedRoomsLoaded, setIsInitialTotalOccupiedRoomsLoaded] = useState(false);
  const [isInitialAdrLoaded, setIsInitialAdrLoaded] = useState(false);
  const [isInitialAvgBookingWindowLoaded, setIsInitialAvgBookingWindowLoaded] = useState(false);
  const [isInitialCancellationsLoaded, setIsInitialCancellationsLoaded] = useState(false);
  const [isInitialAvgLoSLoaded, setIsInitialAvgLoSLoaded] = useState(false);
  // To get visual details
  const getVisual = (index) => {
    let found;
    if (isCumulative) {
      found = findVisual(
        isTrendForecast ? cumulativeVisuals : forecastOffCumulativeVisuals,
        index,
        weeklyViewToggle,
        isTrendForecast
          ? [REPORT_TYPE.WEEKLY_TREND_HOTEL, REPORT_TYPE.TREND_HOTEL]
          : [REPORT_TYPE.WEEKLY_TREND_HOTEL_FORECAST_OFF, REPORT_TYPE.TREND_HOTEL_FORECAST_OFF]
      );
    } else if (weeklyViewToggle) {
      found = findVisual(
        isTrendForecast ? weeklyVisuals : forecastOffWeeklyVisuals,
        index,
        true,
        isTrendForecast
          ? [REPORT_TYPE.TREND_HOTEL_CUMULATIVE]
          : [REPORT_TYPE.TREND_HOTEL_CUMULATIVE_FORECAST_OFF]
      );
    } else if (isPercentageChange && !weeklyViewToggle && !isCumulative && !normalizedViewToggle) {
      found = findVisual(
        isTrendForecast ? percentageVisuals : forecastOffPercentageVisuals,
        index,
        true,
        isTrendForecast
          ? [REPORT_TYPE.TREND_HOTEL_PERCENTAGE]
          : [REPORT_TYPE.TREND_HOTEL_PERCENTAGE_FORECAST_OFF],
        true
      );
    } else {
      found = findVisual(
        isTrendForecast ? visuals : forecastOffVisuals,
        index,
        false,
        isTrendForecast
          ? [REPORT_TYPE.TREND_HOTEL_CUMULATIVE]
          : [REPORT_TYPE.TREND_HOTEL_CUMULATIVE_FORECAST_OFF]
      );
    }
    return found;
  };
  useEffect(() => {
    // To set whether trend hotel graphical visuals are loaded
    setIsHotelLoaded(
      (isInitialTotalRevenueLoaded && isInitialTotalOccupiedRoomsLoaded && isInitialAdrLoaded) ||
        isInitialAvgBookingWindowLoaded ||
        isInitialAvgLoSLoaded ||
        isInitialCancellationsLoaded
    );
  }, [
    isInitialTotalRevenueLoaded,
    isInitialTotalOccupiedRoomsLoaded,
    isInitialAdrLoaded,
    isInitialAvgBookingWindowLoaded,
    isInitialAvgLoSLoaded,
    isInitialCancellationsLoaded,
  ]);
  // To get visuals based on the configuration
  const getWidget = (
    index,
    cssClassName,
    setPBIReport = () => {},
    setIsLoaded = () => {},
    filter = filterList
  ) => {
    const found = getVisual(index);
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={tokenDetails?.accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filter}
          setPBIReport={(r) => setPBIReport(r)}
          setIsLoaded={(d) => setIsLoaded(d)}
        />
      )
    );
  };
  // To get visual names based on visual order
  const getVisualName = (order) => getVisual(order)?.name;
  //
  return (
    <>
      <VisualGrid
        visualName={getVisualName(0)}
        isDisplay={revenueIsDisplay}
        visual={getWidget(
          0,
          styles.paceVisual,
          () => {},
          setIsInitialTotalRevenueLoaded,
          normalizedFilterList
        )}
        onClick={() => {
          dispatch(trendActions.setRevenueIsDisplayTH(!revenueIsDisplay));
        }}
      />
      <VisualGrid
        visualName={getVisualName(1)}
        isDisplay={occupiedRoomsIsDisplay}
        visual={getWidget(
          1,
          styles.paceVisual,
          () => {},
          setIsInitialTotalOccupiedRoomsLoaded,
          normalizedFilterList
        )}
        onClick={() => {
          dispatch(trendActions.setOccupiedRoomIsDisplayTH(!occupiedRoomsIsDisplay));
        }}
      />
      <VisualGrid
        visualName={getVisualName(2)}
        isDisplay={adrIsDisplay}
        visual={getWidget(2, styles.paceVisual, () => {}, setIsInitialAdrLoaded)}
        onClick={() => {
          dispatch(trendActions.setAdrIsDisplayTH(!adrIsDisplay));
        }}
      />
      {getWidget(3, styles.paceVisual, () => {}, setIsInitialAvgLoSLoaded) && (
        <VisualGrid
          visualName={getVisualName(3)}
          isDisplay={avgLosIsDisplay}
          visual={getWidget(3, styles.paceVisual, () => {}, setIsInitialAvgLoSLoaded)}
          onClick={() => {
            dispatch(trendActions.setAvgLosIsDisplayTH(!avgLosIsDisplay));
          }}
        />
      )}
      {getWidget(4, styles.paceVisual, () => {}, setIsInitialAvgBookingWindowLoaded) && (
        <VisualGrid
          visualName={getVisualName(4)}
          isDisplay={avgBookingWindowIsDisplay}
          visual={getWidget(4, styles.paceVisual, () => {}, setIsInitialAvgBookingWindowLoaded)}
          onClick={() => {
            dispatch(trendActions.setAvgBookingWindowIsDisplayTH(!avgBookingWindowIsDisplay));
          }}
        />
      )}
      {getWidget(
        5,
        styles.paceVisual,
        () => {},
        setIsInitialCancellationsLoaded,
        normalizedFilterList
      ) && (
        <VisualGrid
          visualName={getVisualName(5)}
          isDisplay={cxlIsDisplay}
          visual={getWidget(
            5,
            styles.paceVisual,
            () => {},
            setIsInitialCancellationsLoaded,
            normalizedFilterList
          )}
          onClick={() => {
            dispatch(trendActions.setCxlIsDisplayTH(!cxlIsDisplay));
          }}
        />
      )}
    </>
  );
};
//
export default TrendHotelGraphicalWidget;
