import { createSelector } from '@reduxjs/toolkit';
import { SLICE } from 'modules/dashboard/constants';
import { initialState } from 'modules/dashboard/components/tab-container/pace-time-tab/slice';
//
const selectDomain = (state) => state[SLICE.PACE_TIME] || initialState;
/**
 *  Getting pace widget pace forecast state to pace  time component
 */
export const selectPaceWidgetPaceForecast = createSelector(
  [selectDomain],
  (state) => state.paceWidgetForecast
);
/**
 * Getting pace load state for pace  time component
 */
export const selectPaceLoad = createSelector([selectDomain], (state) => state?.paceLoad);

/**
 * Getting pace widget segment visuals filter list state to pace  time component
 */
export const selectPaceTimeLagAligned = createSelector(
  [selectDomain],
  (state) => state.paceTimeLagAligned
);
/**
 * Getting pace cumulative state for pace  time component
 */
export const selectPaceTimeCumulativeView = createSelector(
  [selectDomain],
  (state) => state?.paceTimeCumulativeView
);
/**
 *  Getting is percentage forecast state to pace  time component
 */
export const selectPercentage = createSelector([selectDomain], (state) => state.percentage);
/**
 * Getting pace alignment pace  time component
 */
export const selectPaceAlignment = createSelector([selectDomain], (state) => state.paceAlignment);
/**
 *  Getting pace alignment toggle state to pace  time component
 */
export const selectPaceAlignmentToggle = createSelector(
  [selectDomain],
  (state) => state.paceAlignmentToggle
);
/**
 * Getting symmetric comparison toggle state for pace  time component
 */
export const selectPaceSymmetricComparisonToggle = createSelector(
  [selectDomain],
  (state) => state?.paceSymmetricComparisonToggle
);
/**
 * Getting date comparison state to pace  time component
 */
export const selectPaceDateComparison = createSelector(
  [selectDomain],
  (state) => state.datePaceComparison
);
/**
 *  Getting is trend hotel forecast state to pace  time component
 */
export const selectPaceTimeOccupiedRoomFilterList = createSelector(
  [selectDomain],
  (state) => state.paceTimeOccupiedRoomFilterList
);
/**
 *  Getting is trend hotel forecast state to pace  time component
 */
export const selectPaceTimeRevenueFilterList = createSelector(
  [selectDomain],
  (state) => state.paceTimeRevenueFilterList
);
/**
 * Getting pace widget filter list state to pace  time component
 */
export const selectPaceWidgetFilterList = createSelector(
  [selectDomain],
  (state) => state.paceWidgetFilterList
);
/**
 *  Getting generated pace time widget tab url
 */
export const selectPaceTimeWidgetUrl = createSelector(
  [selectDomain],
  (state) => state.paceTimeWidgetUrl
);
/**
 *  Getting  revenue is display state to the  component
 */
export const selectRevenueIsDisplay = createSelector(
  [selectDomain],
  (state) => state.revenueIsDisplay
);
/**
 *  Getting  Occupied Rooms is display state to the  component
 */
export const selectOccupiedRoomsIsDisplay = createSelector(
  [selectDomain],
  (state) => state.occupiedRoomsIsDisplay
);
/**
 *  Getting  Adr is display state to the  component
 */
export const selectAdrIsDisplay = createSelector([selectDomain], (state) => state.adrIsDisplay);
/**
 *  Getting  Cxl count is display state to the  component
 */
export const selectCxlIsDisplay = createSelector([selectDomain], (state) => state.cxlIsDisplay);
/**
 *  Getting  Symmetric Comparison is display state to the  component
 */
export const selectSymmetricComparisonIsDisplay = createSelector(
  [selectDomain],
  (state) => state.symmetricComparisonIsDisplay
);
