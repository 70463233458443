// Column names used for trend segment filter to be sent for TT visuals
export const TREND_TIME_SEGMENT_FILTER = {
  TABLE_NAME: 'Fact_Transactions',
  CHANNEL_RAW: 'channel',
  CHANNEL: 'AssignedChannel',
  MARKET_SEGMENT: 'AssignedMarketSegment',
  MARKET_SEGMENT_RAW: 'marketSegmentCode',
  RATE_CATEGORY: 'AssignedRateCategory',
  RATE_CATEGORY_RAW: 'rateCategoryCode',
  REGION: 'AssignedRegion',
  REGION_RAW: 'region_cleaned',
};
// Column names used for trend time segment filter to be sent for TxS visuals
export const TREND_TIME_X_SEGMENT_FILTER = {
  TABLE_NAME: 'Segment_Time_labels',
};
//
export const PRIMARY = 'Breakdown';

// Trend Segment feature related request structures are defined here
const API = {
  POST_SEGMENT_FILTER: {
    path: '/organizations/:organizationId/users/:userId/configurations/query-persist',
    method: 'POST',
  },
  GET_SEGMENT_FILTER: {
    path: '/organizations/:organizationId/users/:userId/configurations/query-persist?:query',
    method: 'GET',
  },
  DELETE_SEGMENT_FILTER: {
    path: '/organizations/:organizationId/users/:userId/configurations/query-persist?:query',
    method: 'DELETE',
  },
};
//
export default API;
