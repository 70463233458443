import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectVisualFilters,
  selectHotelName,
  selectPmsSync,
  selectTrendBookingDate,
  selectTrendTargetDate,
  selectWeeklyTrendEndDate,
  selectWeeklyTrendStartDate,
  // selectHotelDropdownList,
  selectIsDemoUser,
  selectSelectedHotelGroupName,
  selectEnableHotelGroups,
  // selectHotelGroupDropdownList,
  selectReportType,
} from 'modules/dashboard/selectors';
import {
  selectIsCumulative,
  selectTrendTimeWeeklyView,
  selectTrendSegmentFocusOn,
  selectTrendHotelNormalizedView,
  selectTrendHotelFocusOn,
  selectTrendHotelGroupFocusOn,
  selectEnableSecondaryHotelGroups,
  selectTrendSegmentChartBreakdown,
  selectIsSegmentDetailed,
  selectIsForecast,
  selectActiveTrendTab,
} from 'modules/dashboard/components/tab-container/trend-tabs/selectors';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import {
  getBookingDateSchema,
  getGroupASchema,
  getGroupHotelFilterSchema,
  getHotelGroupASchema,
  getHotelGroupOrderSchema,
  getHotelGroupSchema,
  getHotelIdSchema,
  getHotelNameOrderSchema,
  getIsForecastSchema,
  getNormalizedViewSchema,
  getPmsForecastSchema,
  getPmsSyncToggleSchema,
  getSegmentFocusOnSchema,
  getTargetDateSchema,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import { ENVIRONMENT } from 'config';
import { getSelectedBreakdown } from 'modules/dashboard/functions';
import { REPORT_TYPE } from 'modules/dashboard/constants';
import writeLog from 'modules/common/utils/filter-log';
/**
 * Custom hook to generate Trend Hotel widget filter schemas
 */
const useTrendHotel = () => {
  const dispatch = useDispatch();
  //
  const bookingDate = useSelector(selectTrendBookingDate);
  const targetDate = useSelector(selectTrendTargetDate);
  const hotelId = useSelector(selectHotelName);
  const visualFilters = useSelector(selectVisualFilters);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const weeklyViewToggle = useSelector(selectTrendTimeWeeklyView);
  const targetStartDate = useSelector(selectWeeklyTrendStartDate);
  const targetEndDate = useSelector(selectWeeklyTrendEndDate);
  const isCumulative = useSelector(selectIsCumulative);
  const focusOn = useSelector(selectTrendSegmentFocusOn);
  const normalizedViewToggle = useSelector(selectTrendHotelNormalizedView);
  // const hotelDropdownList = useSelector(selectHotelDropdownList);
  const isDemoUser = useSelector(selectIsDemoUser);
  const hotelFocusOn = useSelector(selectTrendHotelFocusOn);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const enableSecondaryHotelGroups = useSelector(selectEnableSecondaryHotelGroups);
  const hotelGroupId = useSelector(selectSelectedHotelGroupName);
  const hotelGroupFocusOn = useSelector(selectTrendHotelGroupFocusOn);
  const chartBreakdown = useSelector(selectTrendSegmentChartBreakdown);
  const isDetailed = useSelector(selectIsSegmentDetailed);
  const isTrendForecast = useSelector(selectIsForecast);
  const activeTab = useSelector(selectActiveTrendTab);
  const reportType = useSelector(selectReportType);
  //
  const [previousHotel, setPreviousHotel] = useState(null);
  const [previousHotelGroup, setPreviousHotelGroup] = useState(null);
  //
  const serializedState = JSON.parse(localStorage.getItem(`persist:${ENVIRONMENT.PERSIST_KEY}`));
  const persistValues = Object.values(serializedState)[1];
  const persistedHotelName = JSON.parse(persistValues).hotelName;
  const persistedHotelGroupName = JSON.parse(persistValues).selectedHotelGroupName;
  //
  useEffect(() => {
    // Persist hotel focus on value on browser refresh
    setPreviousHotel(persistedHotelName);
    if (previousHotel !== null) {
      // dispatch(trendActions.setTrendHotelFocusOn(hotelDropdownList[0]));
    }
  }, [hotelId]);

  useEffect(() => {
    // Persist hotel group focus on value on browser refresh
    setPreviousHotelGroup(persistedHotelGroupName);
    if (previousHotelGroup !== null) {
      // dispatch(trendActions.setTrendHotelGroupFocusOn(hotelGroupsDropdownList[0]));
    }
  }, [hotelGroupId]);

  // Generate filter list for trend hotel visuals
  useEffect(() => {
    if (
      reportType !== REPORT_TYPE.TREND_TIME ||
      !targetDate ||
      !bookingDate ||
      !visualFilters ||
      !hotelId ||
      !chartBreakdown
    ) {
      return;
    }
    const isForecastFilter = getIsForecastSchema(visualFilters, isTrendForecast);
    const pmsSyncForecastFilter = getPmsForecastSchema(visualFilters, pmsSyncToggle);
    let startDate;
    let endDate;
    if (isCumulative) {
      startDate = targetDate?.startDate;
      endDate = targetDate?.endDate;
    } else {
      startDate = weeklyViewToggle ? targetStartDate : targetDate?.startDate;
      endDate = weeklyViewToggle ? targetEndDate : targetDate?.endDate;
    }
    const targetDateFilter = getTargetDateSchema(visualFilters, startDate, endDate);
    const bookingDateFilter = getBookingDateSchema(
      visualFilters,
      bookingDate?.startDate,
      bookingDate?.endDate
    );
    const groupFocusOnIds = hotelGroupFocusOn?.hotels?.map((hotel) => hotel?.databricksId) ?? [];
    let hotelGroup1Filter;
    const hotelListIds = hotelGroupId?.hotels?.map((hotel) => hotel?.databricksId) ?? [];
    //
    const groupHotelFilter = getGroupHotelFilterSchema(visualFilters);
    if (enableHotelGroups) {
      hotelGroup1Filter = getHotelGroupSchema(hotelListIds, groupHotelFilter);
    } else {
      hotelGroup1Filter = getHotelGroupSchema([hotelId?.databricksId], groupHotelFilter);
    }
    //
    const groupAFilter = getGroupASchema(visualFilters);
    let hotelGroupAFilter;
    if (enableSecondaryHotelGroups) {
      hotelGroupAFilter = getHotelGroupASchema(groupFocusOnIds, groupAFilter);
    } else {
      hotelGroupAFilter = getHotelGroupASchema([hotelFocusOn?.databricksId], groupAFilter);
    }
    //
    let dimHotelIds = [];
    if (enableHotelGroups) {
      if (enableSecondaryHotelGroups) {
        dimHotelIds = new Set([...groupFocusOnIds, ...hotelListIds]);
      } else {
        dimHotelIds = new Set([hotelFocusOn?.databricksId, ...hotelListIds]);
      }
    } else if (enableSecondaryHotelGroups) {
      dimHotelIds = new Set([...groupFocusOnIds, hotelId?.databricksId]);
    } else {
      dimHotelIds = new Set([hotelFocusOn?.databricksId, hotelId?.databricksId]);
    }
    //
    const secondaryHotelIdFilter = getHotelIdSchema(Array.from(dimHotelIds), visualFilters);
    const hotelNameOrderFilter = getHotelNameOrderSchema(visualFilters, isDemoUser);
    const hotelGroupOrderFilter = getHotelGroupOrderSchema(visualFilters, isDemoUser);
    const pmsSyncFilter = getPmsSyncToggleSchema(visualFilters, pmsSyncToggle);
    const normalizedFilter = getNormalizedViewSchema(visualFilters, normalizedViewToggle);
    //
    const hotelFilters = [secondaryHotelIdFilter, hotelGroupAFilter, hotelGroup1Filter];
    const selectedBreakdown = getSelectedBreakdown(visualFilters, chartBreakdown, isDetailed);
    const segmentFocusOnFilter = getSegmentFocusOnSchema(
      selectedBreakdown,
      focusOn?.map((item) => item?.label)
    );
    //
    if (bookingDateFilter && targetDateFilter && hotelFilters) {
      const filters = [
        ...pmsSyncFilter,
        bookingDateFilter,
        targetDateFilter,
        hotelNameOrderFilter,
        ...pmsSyncForecastFilter,
        hotelGroupOrderFilter,
        ...hotelFilters,
      ];
      const finalFilters = focusOn?.length
        ? [...filters, segmentFocusOnFilter]
        : [...filters, isForecastFilter];
      if (activeTab === REPORT_TYPE.TREND_HOTEL) writeLog('Trend Hotel Filters', finalFilters);
      dispatch(trendActions.updateTrendHotelFilterList(finalFilters));
      if (normalizedFilter) {
        const finalNormalizedFilters = focusOn?.length
          ? [...filters, normalizedFilter, segmentFocusOnFilter]
          : [...filters, normalizedFilter, isForecastFilter];
        if (activeTab === REPORT_TYPE.TREND_HOTEL)
          writeLog('Trend Hotel Normalized Filters', finalNormalizedFilters);
        dispatch(trendActions.setTrendHotelNormalizedFilterList(finalNormalizedFilters));
      }
    }
  }, [
    bookingDate,
    visualFilters,
    targetDate,
    hotelId,
    pmsSyncToggle,
    hotelFocusOn,
    weeklyViewToggle,
    targetStartDate,
    targetEndDate,
    isCumulative,
    normalizedViewToggle,
    enableHotelGroups,
    hotelGroupId,
    hotelGroupFocusOn,
    enableSecondaryHotelGroups,
    chartBreakdown,
    isDetailed,
    focusOn,
    isTrendForecast,
    reportType,
    activeTab,
    previousHotel,
    previousHotelGroup,
  ]);
};
//
export default useTrendHotel;
