/* eslint-disable  import/prefer-default-export */
/**
 * Find visual function used in trend tabs widget
 * @param {Array} array - Visual array
 * @param {string} index - Order of the visual
 * @param {Boolean} includeTag - Whether to include the tags or exclude them from final result
 * @param {Array} tags - Tags list
 * @returns
 */
export const findVisual = (array, index, includeTag, tags, percentage = false) =>
  array?.find((visual) => {
    if (!percentage && (index === 0 || index === 1 || index === 5)) {
      return !visual?.tags?.includes(...tags) && visual?.order === index;
    }
    return includeTag
      ? visual?.tags?.includes(...tags) && visual?.order === index
      : visual?.order === index;
  });
