import { useEffect } from 'react';
import { Grid, Fade } from '@mui/material';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import { TAB_VALUE } from 'modules/dashboard/constants';
import Widget from './trend-widgets';
import useTrendTimeTabular from '../../hooks/use-trend-time';
/**
 * Trend widget parent component
 * @param {Number} value - Trend time widget tab value
 * @param {Boolean} active - whether trend time tab is active
 * @param {Boolean} toggleDisabled - PMS sync toggle disabled state
 * @param {Date} latestDate - Latest booking date of selected hotel
 * @param {String} hotelTimezone - Timezone of selected hotel
 * @param {String} reportType - Report type of active tab
 */
const TrendTabularWidget = ({
  active,
  value,
  latestDate,
  reportType,
  hotelTimezone,
  toggleDisabled,
}) => {
  useTrendTimeTabular();
  // Triggered when tab value or active tab change
  useEffect(() => {
    // Scroll up when the trend time tab is clicked
    if (value === TAB_VALUE.TREND_TABULAR) window.scrollTo(0, 0);
  }, [value, active]);
  //
  return (
    active && (
      <Fade in={active} {...{ timeout: 500 }}>
        <Grid
          container
          direction="row"
          sx={{
            display: value === TAB_VALUE.TREND_TABULAR ? '' : 'none',
          }}
          className={styles.mainGrid}
          alignItems="flex-start"
        >
          <Widget
            value={value}
            latestDate={latestDate}
            reportType={reportType}
            hotelTimezone={hotelTimezone}
            toggleDisabled={toggleDisabled}
          />
        </Grid>
      </Fade>
    )
  );
};
//
export default TrendTabularWidget;
