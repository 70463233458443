import { useState, useEffect } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Tooltip, Menu, MenuItem } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from 'modules/common/auth/slice';
import Preference from 'modules/users/components/preference';
import TOAST_TYPES from 'modules/common/constants/toast-types';
import ERROR_TYPES from 'modules/common/constants/error-types';
import MODULE from 'modules/common/constants/modules';
import { selectNotification } from 'modules/common/notifications/selectors';
import { notificationActions } from 'modules/common/notifications/slice';
import { toast } from 'react-toastify';
import { IconButton } from './style';
/**
 * Navigation bar user dropdown common component that
 * uses across modules for display user options
 */
const NavbarUserDropdown = () => {
  const dispatch = useDispatch();
  //
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [showPreference, setShowPreference] = useState(false);
  //
  const notification = useSelector(selectNotification);
  // open user menu
  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };
  // close user menu and reset notifications
  const closeMenu = () => {
    setAnchorMenu(null);
    setShowPreference(false);
    dispatch(notificationActions.resetNotification());
  };
  // sign out
  const handleSignOut = async () => {
    dispatch(authActions.signOut());
  };
  // checks for notification and toast notification and close menu
  useEffect(() => {
    if (
      notification?.type === ERROR_TYPES.SUCCESS &&
      notification?.message !== '' &&
      notification?.module === MODULE.preference
    ) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
      closeMenu();
    }
  }, [notification]);
  //
  return (
    <>
      <Tooltip title="Account">
        <IconButton
          aria-owns={anchorMenu ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          sx={{ color: 'white' }}
          size="large"
          id="auto-account"
        >
          <AccountCircleIcon />
        </IconButton>
      </Tooltip>
      <Menu id="menu-appbar" anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={closeMenu}>
        <MenuItem
          onClick={() => {
            setShowPreference(true);
          }}
          onClose={closeMenu}
        >
          Preferences
        </MenuItem>
        {showPreference && (
          <Preference
            open={showPreference}
            onClose={() => {
              setShowPreference(false);
              closeMenu();
            }}
          />
        )}
        <MenuItem onClick={handleSignOut} id="auto-sign-out">
          Sign out
        </MenuItem>
      </Menu>
    </>
  );
};
//
export default NavbarUserDropdown;
