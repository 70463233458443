/* eslint-disable import/prefer-default-export */
import { differenceInCalendarDays } from 'date-fns';
import { GRANULARITY } from './constants';

/**
 * Determines the granularity based on the difference in calendar days between
 * the start and end dates of a target date range.
 * @param {Object} targetDate - The target date range object with 'startDate' and 'endDate'.
 * @param {Date} targetDate.startDate - The start date of the target range.
 * @param {Date} targetDate.endDate - The end date of the target range.
 * @returns {string|undefined} - The calculated granularity, or undefined if not matched.
 */

export const getGranularity = (targetDate) => {
  let granularity;
  const difference =
    differenceInCalendarDays(new Date(targetDate.endDate), new Date(targetDate.startDate)) + 1;
  //
  if (difference <= 60) {
    granularity = GRANULARITY.DAILY;
  } else if (difference < 365) {
    granularity = GRANULARITY.WEEKLY;
  } else if (difference <= 1460) {
    granularity = GRANULARITY.MONTHLY;
  } else {
    granularity = GRANULARITY.YEARLY;
  }
  return granularity;
};
//
