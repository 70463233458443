/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import ERROR_TYPES from 'modules/common/constants/error-types';
import { forgotPasswordActions } from 'modules/forgot-password/slice';
import { rateCalendarUploadActions } from 'modules/rate-calendar-upload/slice';
import { resetPasswordActions } from 'modules/reset-password/slice';
import { signInActions } from 'modules/sign-in/slice';
import { verifyCallbackActions } from 'modules/verify-callback/slice';
import { rateCalenderActions } from 'modules/rate-calendar/slice';
import { configureEventCategoriesActions } from 'modules/event-configuration/slice';
import { usersActions } from 'modules/users/slice';
import { signUpActions } from 'modules/sign-up/slice';
import { dashboardActions } from 'modules/dashboard/slice';
import { SLICE } from 'modules/dashboard/constants';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import MODULE from 'modules/common/constants/modules';
/**
 * Initial state of notification is defined here
 */
export const initialState = {
  isEnabled: false,
  message: '',
  type: ERROR_TYPES.SUCCESS,
  module: '',
};
/**
 * All actions related to notification feature are defined here
 */
export const notificationSlice = createSlice({
  name: SLICE.NOTIFICATION,
  initialState,
  reducers: {
    resetNotification(state) {
      state.isEnabled = false;
      state.message = '';
      state.type = ERROR_TYPES.SUCCESS;
      state.module = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInActions.signIn, (state) => ({
        ...state,
        ...initialState,
      }))
      .addCase(signInActions.signInFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.WARNING,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(forgotPasswordActions.forgotPasswordSucceeded, (state, action) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: action?.payload,
        isEnabled: true,
      }))
      .addCase(verifyCallbackActions.verifyTokenFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(resetPasswordActions.resetPasswordSucceeded, (state, action) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: action?.payload,
        isEnabled: true,
      }))
      .addCase(resetPasswordActions.resetPasswordFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(rateCalendarUploadActions.rateCalendarUploadSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Successful',
        isEnabled: true,
      }))
      .addCase(rateCalendarUploadActions.rateCalendarUploadFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(rateCalendarUploadActions.rateCalendarForceUploadSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Successful',
        isEnabled: true,
      }))
      .addCase(rateCalenderActions.addEventDetailsSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Event successfully created',
        isEnabled: true,
      }))
      .addCase(rateCalenderActions.addEventDetailsFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(rateCalenderActions.deleteEventDetailsSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Event successfully deleted',
        isEnabled: true,
      }))
      .addCase(rateCalenderActions.deleteEventDetailsFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(rateCalenderActions.updateEventDetailsSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Event successfully updated',
        isEnabled: true,
      }))
      .addCase(rateCalenderActions.updateEventDetailsFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(configureEventCategoriesActions.configureEventCategoriesFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(configureEventCategoriesActions.addMinorEventCategoriesFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(
        configureEventCategoriesActions.updateMinorEventCategoriesFailed,
        (state, action) => ({
          ...state,
          type: ERROR_TYPES.ERROR,
          message: action?.payload?.message,
          isEnabled: true,
        })
      )
      .addCase(
        configureEventCategoriesActions.deleteMinorEventCategoriesFailed,
        (state, action) => ({
          ...state,
          type: ERROR_TYPES.ERROR,
          message: action?.payload?.message,
          isEnabled: true,
        })
      )
      .addCase(usersActions.createGeneralUsersFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(usersActions.createGeneralUsersSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'User successfully created',
        isEnabled: true,
      }))
      .addCase(usersActions.createAdminUsersFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(usersActions.createAdminUsersSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'User successfully created',
        isEnabled: true,
      }))
      .addCase(usersActions.updateUserFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(usersActions.updateUserSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'User successfully updated',
        isEnabled: true,
      }))
      .addCase(usersActions.deleteUserFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(usersActions.deleteUserSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'User successfully deleted',
        isEnabled: true,
      }))
      .addCase(signUpActions.signUpFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(signUpActions.signUpSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'User successfully created! Sign in to continue',
        isEnabled: true,
      }))
      .addCase(usersActions.changeUserPasswordSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Password Changed Successfully',
        isEnabled: true,
        module: MODULE.preference,
      }))
      .addCase(usersActions.changeUserPasswordFailed, (state) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: 'Password Change Failed!',
        isEnabled: true,
        module: MODULE.preference,
      }))
      .addCase(usersActions.updatePreferenceFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
        module: MODULE.preference,
      }))
      .addCase(usersActions.updatePreferenceSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Preferences successfully updated',
        isEnabled: true,
        module: MODULE.preference,
      }))
      .addCase(trendActions.saveSegmentFilterFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
        module: MODULE.preference,
      }))
      .addCase(trendActions.saveSegmentFilterSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Filter successfully saved',
        isEnabled: true,
        module: MODULE.preference,
      }))
      .addCase(trendActions.deleteAggregationFilterFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
        module: MODULE.preference,
      }))
      .addCase(trendActions.deleteAggregationFilterSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Filter successfully deleted',
        isEnabled: true,
        module: MODULE.preference,
      }))
      .addCase(usersActions.createUsersFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(usersActions.createUsersSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'User successfully created',
        isEnabled: true,
      }))
      .addCase(dashboardActions.saveCommandUrlFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(dashboardActions.saveCommandUrlSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Filter successfully saved',
        isEnabled: true,
        module: MODULE.preference,
      }))
      .addCase(dashboardActions.deleteCommandFilterFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(dashboardActions.deleteCommandFilterSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Filter successfully deleted',
        isEnabled: true,
        module: MODULE.preference,
      }))
      .addCase(dashboardActions.createHotelGroupFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(dashboardActions.createHotelGroupSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Hotel set successfully saved',
        isEnabled: true,
        module: MODULE.preference,
      }))
      .addCase(dashboardActions.deleteHotelGroupFailed, (state, action) => ({
        ...state,
        type: ERROR_TYPES.ERROR,
        message: action?.payload?.message,
        isEnabled: true,
      }))
      .addCase(dashboardActions.deleteHotelGroupSucceeded, (state) => ({
        ...state,
        type: ERROR_TYPES.SUCCESS,
        message: 'Hotel set successfully deleted',
        isEnabled: true,
        module: MODULE.preference,
      }));
  },
});
//
export const { actions: notificationActions } = notificationSlice;
