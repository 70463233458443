import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectTargetDate,
  selectVisualFilters,
  selectHotelName,
  selectSelectedHotelGroupName,
  selectEnableHotelGroups,
  selectPmsSync,
  selectReportType,
  selectIsDemoUser,
} from 'modules/dashboard/selectors';
import {
  generateWorkbookShareUrl,
  getAlignmentAndDateComparisonSchema,
  getBookingDateSchema,
  getCustomDateComparisonSchema,
  getHotelGroupOrderSchema,
  getHotelIdSchema,
  getPmsSyncSchema,
  getSymmetricComparisonSchema,
  getTargetDateSchema,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import { workbookActions } from 'modules/dashboard/components/tab-container/workbook-report/slice';
import {
  selectAlignment,
  selectDateComparison,
  selectWorkbookBookingDate,
  selectSymmetricComparisonToggle,
  selectWorkbookEndDate,
  selectWorkbookStartDate,
  selectWorkbookOtb,
  selectWorkbookAlignmentToggle,
  selectWorkbookCustomDate,
  selectD1SetIsDisplay,
  selectD2SetIsDisplay,
  selectD3SetIsDisplay,
  selectWorkbookFilterList,
} from 'modules/dashboard/components/tab-container/workbook-report/selectors';
import { selectSymmetricComparisonIsDisplay } from 'modules/dashboard/components/tab-container/summary-tab/selectors';
import { REPORT_TYPE, TAGS } from 'modules/dashboard/constants';
import writeLog from 'modules/common/utils/filter-log';
/**
 * Custom hook to generate summary widget filter schemas
 */
const useWorkbookWidget = () => {
  const dispatch = useDispatch();
  //
  const alignment = useSelector(selectAlignment);
  const dateComparison = useSelector(selectDateComparison);
  const bookingDate = useSelector(selectWorkbookBookingDate);
  const targetDate = useSelector(selectTargetDate);
  const hotelId = useSelector(selectHotelName);
  const visualFilters = useSelector(selectVisualFilters);
  const symmetricComparisonToggle = useSelector(selectSymmetricComparisonToggle);
  const hotelGroupId = useSelector(selectSelectedHotelGroupName);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const endDate = useSelector(selectWorkbookEndDate);
  const startDate = useSelector(selectWorkbookStartDate);
  const customBooking = useSelector(selectWorkbookOtb);
  const alignmentToggle = useSelector(selectWorkbookAlignmentToggle);
  const customDate = useSelector(selectWorkbookCustomDate);
  const symmetricComparisonIsDisplay = useSelector(selectSymmetricComparisonIsDisplay);
  const d1SetIsDisplay = useSelector(selectD1SetIsDisplay);
  const d2SetIsDisplay = useSelector(selectD2SetIsDisplay);
  const d3SetIsDisplay = useSelector(selectD3SetIsDisplay);
  const reportType = useSelector(selectReportType);
  const workbookFilterList = useSelector(selectWorkbookFilterList);
  const isDemoUser = useSelector(selectIsDemoUser);

  // Triggered when bookingDate,visualFilters,dateComparison,alignment,targetDate,hotelId,symmetricComparisonToggle value changes
  useEffect(() => {
    if (
      reportType !== REPORT_TYPE.WORKBOOK_REPORT ||
      (!dateComparison && !alignment && !bookingDate)
    )
      return;
    // Generate filter list for summary widget top 8 visuals
    const targetDateFilter = getTargetDateSchema(
      visualFilters,
      targetDate?.startDate,
      targetDate?.endDate
    );
    // const hotelIdFilter = getHotelIdSchema(hotelId?.databricksId, visualFilters);
    let hotelIdFilter = [];
    if (enableHotelGroups) {
      const hotelListIds = hotelGroupId?.hotels?.map((hotel) => hotel?.databricksId);
      hotelIdFilter = getHotelIdSchema(hotelListIds, visualFilters);
    } else {
      hotelIdFilter = getHotelIdSchema(hotelId?.databricksId, visualFilters);
    }
    const pmsSyncFilter = getPmsSyncSchema(visualFilters, pmsSyncToggle, TAGS.SUMMARY);
    const bookingDateFilter = getBookingDateSchema(
      visualFilters,
      bookingDate?.startDate,
      bookingDate?.endDate
    );
    const alignmentAndDateComparisonFilter = getAlignmentAndDateComparisonSchema(
      visualFilters,
      alignment?.id,
      dateComparison
    );
    const symmetricComparisonFilter = getSymmetricComparisonSchema(
      visualFilters,
      symmetricComparisonToggle
    );
    const isCustomDateComparisonFilter = getCustomDateComparisonSchema(
      visualFilters,
      dateComparison,
      startDate,
      endDate
    );
    const hotelGroupOrderFilter = getHotelGroupOrderSchema(visualFilters, isDemoUser);
    if (
      pmsSyncFilter &&
      bookingDateFilter &&
      alignmentAndDateComparisonFilter &&
      targetDateFilter &&
      hotelIdFilter &&
      symmetricComparisonFilter &&
      hotelGroupOrderFilter
    ) {
      const filters = [
        ...pmsSyncFilter,
        bookingDateFilter,
        ...alignmentAndDateComparisonFilter,
        targetDateFilter,
        hotelIdFilter,
        symmetricComparisonFilter,
        ...isCustomDateComparisonFilter,
        hotelGroupOrderFilter,
      ];

      if (JSON.stringify(filters) !== JSON.stringify(workbookFilterList)) {
        writeLog('Dashboard Visual Filters', filters);
        dispatch(workbookActions.updateWorkbookFilterList(filters));
      }
    } else dispatch(workbookActions.updateWorkbookFilterList([]));
  }, [
    bookingDate,
    pmsSyncToggle,
    visualFilters,
    dateComparison,
    alignment,
    targetDate,
    hotelId,
    symmetricComparisonToggle,
    enableHotelGroups,
    hotelGroupId,
    reportType,
    startDate,
    endDate,
    isDemoUser,
  ]);

  // Triggers when filter values dependencies changes
  useEffect(() => {
    if (!dateComparison && !bookingDate && !targetDate && (!hotelId || !hotelGroupId)) return;
    // Generate workbook filter url
    const workbookUrl = generateWorkbookShareUrl(
      pmsSyncToggle,
      hotelId,
      targetDate,
      dateComparison,
      startDate,
      endDate,
      customDate,
      customBooking,
      bookingDate,
      alignmentToggle,
      symmetricComparisonToggle,
      hotelGroupId,
      enableHotelGroups,
      symmetricComparisonIsDisplay,
      d1SetIsDisplay,
      d2SetIsDisplay,
      d3SetIsDisplay
    );
    //
    dispatch(workbookActions.setWorkbookUrl(workbookUrl));
  }, [
    pmsSyncToggle,
    hotelId,
    targetDate,
    dateComparison,
    startDate,
    endDate,
    customDate,
    customBooking,
    bookingDate,
    alignmentToggle,
    symmetricComparisonToggle,
    hotelGroupId,
    enableHotelGroups,
    symmetricComparisonIsDisplay,
    d1SetIsDisplay,
    d2SetIsDisplay,
    d3SetIsDisplay,
  ]);
};
//
export default useWorkbookWidget;
