/* eslint-disable no-nested-ternary */
import { CSVLink } from 'react-csv';
import { useState, useEffect, useRef } from 'react';
import { models } from 'powerbi-client';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/common/auth/selectors';
import {
  selectHotelName,
  selectPmsSync,
  selectTrendBookingDate,
  selectTrendTargetDate,
  selectWeeklyTrendStartDate,
  selectWeeklyTrendEndDate,
  selectReportType,
  selectEnableHotelGroups,
  selectSelectedHotelGroupName,
} from 'modules/dashboard/selectors';
import { isEmpty } from 'modules/common/helpers/object';
import { formatDate } from 'modules/dashboard/functions';
import { DATE_PATTERN, TIME_PATTERN } from 'modules/common/constants/date-range';
import { Grid, IconButton, Typography } from '@mui/material';
import { Loader } from 'modules/common/components';
import DownloadIcon from '@mui/icons-material/Download';
import { REPORT_TYPE, VISUAL_PAGE_NAMES } from 'modules/dashboard/constants';
import NoDataDialog from 'modules/dashboard/components/tab-container/export-visual-data/components/no-data';
import {
  selectIsCumulative,
  selectTrendTimeWeeklyView,
} from 'modules/dashboard/components/tab-container/trend-tabs/selectors';

/**
 * Export Data component to download visual data in csv format
 * @returns
 */
const ExportVisualData = ({
  report,
  isLoaded,
  fileName,
  weeklyViewToggle,
  isCumulative,
  otherFilters = '',
}) => {
  const csvLink = useRef();
  //
  const currentUser = useSelector(selectAuthUser);
  const selectedHotel = useSelector(selectHotelName);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const bookingDate = useSelector(selectTrendBookingDate);
  const targetDate = useSelector(selectTrendTargetDate);
  const startDate = useSelector(selectWeeklyTrendStartDate);
  const endDate = useSelector(selectWeeklyTrendEndDate);
  const reportType = useSelector(selectReportType);
  const weeklyViewToggleTH = useSelector(selectTrendTimeWeeklyView);
  const weeklyViewToggleTS = useSelector(selectTrendTimeWeeklyView);
  const trendSegmentIsCumulative = useSelector(selectIsCumulative);
  const trendHotelIsCumulative = useSelector(selectIsCumulative);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const hotelGroup = useSelector(selectSelectedHotelGroupName);
  //
  const [visualData, setVisualData] = useState([]);
  const [initiateDownload, setInitiateDownload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isDialog, setIsDialog] = useState(false);
  //
  useEffect(() => {
    if (visualData?.length) {
      setInitiateDownload(true);
    }
  }, [visualData]);
  //
  useEffect(() => {
    if (initiateDownload) {
      csvLink.current.link.click();
      setInitiateDownload(false);
    }
    setLoading(false);
  }, [initiateDownload, visualData]);
  //
  const resetInitData = () => {
    setInitiateDownload(false);
    setLoading(false);
    setIsDialog(true);
  };
  //
  const exportPbiData = async () => {
    setLoading(true);
    if (!isEmpty(report) && isLoaded) {
      //
      try {
        const pages = await report.getPages();
        const activePage = pages.filter((page) => page.isActive)[0];
        const visuals = await activePage.getVisuals();
        //
        let isWeekly = false;
        if (
          (reportType === REPORT_TYPE.TREND_TIME && weeklyViewToggleTS) ||
          (reportType === REPORT_TYPE.TREND_TIME && weeklyViewToggleTH)
        ) {
          isWeekly = true;
        }
        //
        const selectedVisual = visuals.filter((visual) => visual.name === visuals[0]?.name)[0];
        const selectedPage = selectedVisual?.page.name;
        if (
          ((selectedPage === VISUAL_PAGE_NAMES.TREND_HOTEL_TOTAL_REVENUE_C ||
            selectedPage === VISUAL_PAGE_NAMES.TREND_HOTEL_TOTAL_OCCUPIED_ROOMS_C) &&
            trendHotelIsCumulative) ||
          ((selectedPage === VISUAL_PAGE_NAMES.TREND_SEGMENT_TOTAL_REVENUE_C ||
            selectedPage === VISUAL_PAGE_NAMES.TREND_SEGMENT_TOTAL_OCCUPIED_ROOMS_C) &&
            trendSegmentIsCumulative)
        ) {
          isWeekly = false;
        }
        //
        const result = await selectedVisual.exportData(models.ExportDataType.Summarized);
        if (result?.data?.length > 0) {
          const column = isWeekly ? 'Day Name' : 'Date';

          const data = result.data
            .trim()
            .split('\n')
            .slice(1)
            .map((row) => {
              const [date, value, metric] = row.split(',');
              const formattedDate = date.split(' ')[0];
              const metricValue = parseFloat(metric.replace('$', ''));
              const hasDollarSign = metric.includes('$');
              return { [column]: formattedDate, value, metric: metricValue, hasDollarSign };
            });

          const isDollarFormat = data.some((item) => item.hasDollarSign);

          const uniqueDays = [...new Set(data.map((item) => item[column]))];
          const uniqueValues = [...new Set(data.map((item) => item.value))].sort(); // Sort unique values in ascending order

          const pivotTable = {};
          uniqueDays.forEach((day) => {
            pivotTable[day] = {};
            uniqueValues.forEach((value) => {
              pivotTable[day][value] = ''; // Keep it empty here
            });
          });

          data.forEach((item) => {
            const { [column]: day, value, metric } = item;
            pivotTable[day][value] = (pivotTable[day][value] || 0) + metric;
          });

          const groupedData = uniqueDays.map((day) => {
            const entry = { [column]: day };
            uniqueValues.forEach((value) => {
              const cellValue = pivotTable[day][value];
              entry[value] = isDollarFormat
                ? cellValue === ''
                  ? ''
                  : `$${cellValue.toFixed(2)}`
                : cellValue;
            });
            return entry;
          });

          const csvData = [
            Object.keys(groupedData[0]).join(','),
            ...groupedData.map((entry) => Object.values(entry).join(',')),
          ].join('\n');
          //
          if (isEmpty(result?.data)) {
            setLoading(false);
            setInitiateDownload(false);
            setMessage('No data available to export');
            setIsDialog(true);
          } else {
            const username = `Username : , ${currentUser?.username}\r\n`;
            const reportGenerationDate = `Report Generation Date : ,"${formatDate(
              new Date(),
              DATE_PATTERN
            )}, ${formatDate(new Date(), TIME_PATTERN)}"\r\n`;
            const hotel = enableHotelGroups
              ? `Hotel Set: , "${hotelGroup?.label}"\r\n`
              : `Hotel: , "${selectedHotel?.label}"\r\n`;
            const stayDateRange = `Stay Date Range :,${formatDate(
              weeklyViewToggle && !isCumulative ? startDate : targetDate.startDate,
              DATE_PATTERN
            )} to ${formatDate(
              weeklyViewToggle && !isCumulative ? endDate : targetDate.endDate,
              DATE_PATTERN
            )}\r\n`;
            const pmsSync = `PMS Sync : , ${pmsSyncToggle ? 'On' : 'Off'}\r\n`;
            const bookingDateRange = `Booking Date Range : ,${formatDate(
              bookingDate.startDate,
              DATE_PATTERN
            )} to ${formatDate(bookingDate.endDate, DATE_PATTERN)}\r\n`;
            setVisualData(
              username.concat(
                reportGenerationDate,
                hotel,
                stayDateRange,
                pmsSync,
                bookingDateRange,
                otherFilters,
                '\r\n',
                csvData
              )
            );
          }
        } else {
          setMessage('No data available to export', resetInitData());
        }
      } catch (error) {
        setMessage('Something went wrong', resetInitData());
      }
    } else {
      setMessage('Visual is still loading', resetInitData());
    }
  };
  //
  return (
    <Loader loading={loading}>
      <Grid container direction="row" alignItems="center">
        <Typography
          sx={{
            mr: 1,
          }}
        >
          Export Data
        </Typography>
        <IconButton
          size="small"
          onClick={() => exportPbiData()}
          sx={{
            '& .MuiSvgIcon-root': {
              width: '1.25rem',
              height: '1.25rem',
            },
          }}
        >
          <DownloadIcon />
        </IconButton>
      </Grid>
      <CSVLink
        asyncOnClick
        filename={`${fileName}_${formatDate(new Date(), DATE_PATTERN)}`}
        data={visualData}
        target="_self"
        ref={csvLink}
        sx={{ display: 'none' }}
      />
      <NoDataDialog open={isDialog} onClose={() => setIsDialog(false)} message={message} />
    </Loader>
  );
};
//
export default ExportVisualData;
