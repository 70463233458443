import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectVisualFilters,
  selectHotelName,
  selectPmsSync,
  selectWeeklyTrendEndDate,
  selectWeeklyTrendStartDate,
  selectSelectedHotelGroupName,
  selectEnableHotelGroups,
  selectReportType,
  selectIsDemoUser,
} from 'modules/dashboard/selectors';
import { KEYS, REPORT_TYPE } from 'modules/dashboard/constants';
import {
  generateTabularShareUrl,
  getBookingDateSchema,
  getCustomDateComparisonSchema,
  getGranularitySchema,
  getHotelGroupOrderSchema,
  getHotelIdSchema,
  getIsForecastSchema,
  getPmsSyncToggleSchema,
  getRevenueForecastSchema,
  getRoomForecastSchema,
  getSegmentFocusOnSchema,
  getSymmetricComparisonSchema,
  getTargetDateSchema,
  getTrendTimeAlignmentAndDateComparisonSchema,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import { getSelectedBreakdown } from 'modules/dashboard/functions';
import writeLog from 'modules/common/utils/filter-log';
import {
  endOfMonth,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isSameDay,
  isSaturday,
  isSunday,
  lastDayOfYear,
  nextSaturday,
  previousSunday,
  startOfMonth,
  startOfYear,
} from 'date-fns';
import { trendTabularTabActions } from '../slice';
import {
  selectActiveTrendTab,
  selectIsForecast,
  selectIsSegmentDetailed,
  selectTrendSegmentChartBreakdown,
  selectTrendSegmentFocusOn,
  selectTrendSymmetricComparisonToggle,
  selectTrendTimeAlignment,
  selectTrendTimeCustomDate,
  selectTrendTimeDateComparison,
  selectTrendTimeDateSelected,
  selectTrendTimeEndDate,
  selectTrendTimeFilterList,
  selectTrendBookingDate,
  selectTrendTimeStartDate,
  selectTrendTargetDate,
  selectAdjustedStartDate,
  selectAdjustedEndDate,
  selectGranularity,
  selectTrendTimeAlignmentToggle,
  selectTrendOtb,
  selectRevenueIsDisplayTT,
  selectSymmetricComparisonIsDisplay,
} from '../selectors';

import { getGranularity } from '../functions';
import { GRANULARITY } from '../constants';

/**
 * Custom hook to generate Trend Time widget filter schemas
 */
const useTrendTimeTabular = () => {
  const dispatch = useDispatch();
  //
  const alignment = useSelector(selectTrendTimeAlignment);
  const dateComparison = useSelector(selectTrendTimeDateComparison);
  const bookingDate = useSelector(selectTrendBookingDate);
  const targetDate = useSelector(selectTrendTargetDate);
  const hotelId = useSelector(selectHotelName);
  const visualFilters = useSelector(selectVisualFilters);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const targetStartDate = useSelector(selectWeeklyTrendStartDate);
  const targetEndDate = useSelector(selectWeeklyTrendEndDate);
  const symmetricComparisonToggle = useSelector(selectTrendSymmetricComparisonToggle);
  const startDateComparison = useSelector(selectTrendTimeStartDate);
  const endDateComparison = useSelector(selectTrendTimeEndDate);
  const customDate = useSelector(selectTrendTimeCustomDate);
  const selectedDateComparison = useSelector(selectTrendTimeDateSelected);
  const focusOn = useSelector(selectTrendSegmentFocusOn);
  const chartBreakdown = useSelector(selectTrendSegmentChartBreakdown);
  const isDetailed = useSelector(selectIsSegmentDetailed);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const hotelGroupId = useSelector(selectSelectedHotelGroupName);
  const isTrendForecast = useSelector(selectIsForecast);
  const filterList = useSelector(selectTrendTimeFilterList);
  const activeTab = useSelector(selectActiveTrendTab);
  const reportType = useSelector(selectReportType);
  const adjustedStartDate = useSelector(selectAdjustedStartDate);
  const adjustedEndDate = useSelector(selectAdjustedEndDate);
  const granularity = useSelector(selectGranularity);
  const isDemoUser = useSelector(selectIsDemoUser);
  const alignmentToggle = useSelector(selectTrendTimeAlignmentToggle);
  const vOTB = useSelector(selectTrendOtb);
  const revenueIsDisplayTT = useSelector(selectRevenueIsDisplayTT);
  const compareWindowCollapseToggle = useSelector(selectSymmetricComparisonIsDisplay);
  //
  useEffect(() => {
    if (
      reportType !== REPORT_TYPE.TREND_TABULAR ||
      (!dateComparison && !alignment && !bookingDate) ||
      (dateComparison && dateComparison[0] === KEYS.CUSTOM && endDateComparison === null)
    )
      return;
    const bookingDateFilter = getBookingDateSchema(
      visualFilters,
      bookingDate?.startDate,
      bookingDate?.endDate
    );
    const alignmentAndDateComparisonFilter = getTrendTimeAlignmentAndDateComparisonSchema(
      visualFilters,
      alignment?.id,
      dateComparison
    );
    const isForecastFilter = getIsForecastSchema(visualFilters, isTrendForecast);
    const granularityFilter = getGranularitySchema(visualFilters, granularity);
    //
    let startDate;
    let endDate;
    if (granularity === GRANULARITY.DAILY) {
      startDate = targetDate?.startDate;
      endDate = targetDate?.endDate;
    } else {
      startDate = adjustedStartDate;
      endDate = adjustedEndDate;
    }
    const targetDateFilter = getTargetDateSchema(visualFilters, startDate, endDate);
    let hotelIdFilter = [];
    if (enableHotelGroups) {
      const hotelListIds = hotelGroupId?.hotels?.map((hotel) => hotel?.databricksId);
      hotelIdFilter = getHotelIdSchema(hotelListIds, visualFilters);
    } else {
      hotelIdFilter = getHotelIdSchema(hotelId?.databricksId, visualFilters);
    }
    const pmsSyncFilter = getPmsSyncToggleSchema(visualFilters, pmsSyncToggle);
    const symmetricComparisonFilter = getSymmetricComparisonSchema(
      visualFilters,
      symmetricComparisonToggle
    );
    const roomForecastFilter = getRoomForecastSchema(visualFilters, pmsSyncToggle);
    const revenueForecastFilter = getRevenueForecastSchema(visualFilters, pmsSyncToggle);
    const isCustomDateComparisonFilter = getCustomDateComparisonSchema(
      visualFilters,
      dateComparison,
      startDateComparison,
      endDateComparison
    );
    const selectedBreakdown = getSelectedBreakdown(visualFilters, chartBreakdown, isDetailed);
    const segmentFocusOnFilter = getSegmentFocusOnSchema(
      selectedBreakdown,
      focusOn?.map((item) => item?.label)
    );
    const hotelGroupOrderFilter = getHotelGroupOrderSchema(visualFilters, isDemoUser);
    //
    if (
      bookingDateFilter &&
      alignmentAndDateComparisonFilter &&
      targetDateFilter &&
      hotelIdFilter &&
      roomForecastFilter &&
      revenueForecastFilter &&
      symmetricComparisonFilter &&
      granularityFilter &&
      hotelGroupOrderFilter
    ) {
      const filters = [
        ...pmsSyncFilter,
        bookingDateFilter,
        ...alignmentAndDateComparisonFilter,
        targetDateFilter,
        hotelIdFilter,
        symmetricComparisonFilter,
        roomForecastFilter,
        revenueForecastFilter,
        ...isCustomDateComparisonFilter,
        granularityFilter,
        hotelGroupOrderFilter,
      ];

      const finalFilters = focusOn?.length
        ? [...filters, segmentFocusOnFilter]
        : [...filters, isForecastFilter];
      if (JSON.stringify(finalFilters) !== JSON.stringify(filterList)) {
        writeLog('Trend Time Filters', filters);
        dispatch(trendTabularTabActions.updateTrendTimeFilterList(finalFilters));
      }
    }
  }, [
    bookingDate,
    visualFilters,
    dateComparison,
    alignment,
    targetDate,
    hotelId,
    pmsSyncToggle,
    symmetricComparisonToggle,
    targetStartDate,
    targetEndDate,
    startDateComparison,
    endDateComparison,
    selectedDateComparison,
    customDate,
    isDetailed,
    focusOn,
    enableHotelGroups,
    hotelGroupId,
    isTrendForecast,
    reportType,
    activeTab,
    adjustedStartDate,
    adjustedEndDate,
    granularity,
    isDemoUser,
  ]);
  // Triggered when targetDate value changes
  useEffect(() => {
    // Set stay dates based on granularity
    const trendGranularity = getGranularity(targetDate);
    dispatch(trendTabularTabActions.setGranularity(trendGranularity));
    if (trendGranularity === GRANULARITY.WEEKLY) {
      if (isSunday(new Date(targetDate.startDate))) {
        dispatch(
          trendTabularTabActions.setAdjustedStartDate(new Date(targetDate.startDate).toISOString())
        );
      } else {
        dispatch(
          trendTabularTabActions.setAdjustedStartDate(
            previousSunday(new Date(targetDate.startDate)).toISOString()
          )
        );
      }
      if (isSaturday(new Date(targetDate.endDate))) {
        dispatch(
          trendTabularTabActions.setAdjustedEndDate(new Date(targetDate.endDate).toISOString())
        );
      } else {
        dispatch(
          trendTabularTabActions.setAdjustedEndDate(
            nextSaturday(new Date(targetDate.endDate)).toISOString()
          )
        );
      }
    } else if (trendGranularity === GRANULARITY.MONTHLY) {
      if (isFirstDayOfMonth(new Date(targetDate.startDate))) {
        dispatch(
          trendTabularTabActions.setAdjustedStartDate(new Date(targetDate.startDate).toISOString())
        );
      } else {
        dispatch(
          trendTabularTabActions.setAdjustedStartDate(
            startOfMonth(new Date(targetDate.startDate)).toISOString()
          )
        );
      }
      if (isLastDayOfMonth(new Date(targetDate.endDate))) {
        dispatch(
          trendTabularTabActions.setAdjustedEndDate(new Date(targetDate.endDate).toISOString())
        );
      } else {
        dispatch(
          trendTabularTabActions.setAdjustedEndDate(
            endOfMonth(new Date(targetDate.endDate)).toISOString()
          )
        );
      }
    } else if (trendGranularity === GRANULARITY.YEARLY) {
      if (isSameDay(startOfYear(new Date(targetDate.startDate)), new Date(targetDate.startDate))) {
        dispatch(
          trendTabularTabActions.setAdjustedStartDate(new Date(targetDate.startDate).toISOString())
        );
      } else {
        dispatch(
          trendTabularTabActions.setAdjustedStartDate(
            startOfYear(new Date(targetDate.startDate)).toISOString()
          )
        );
      }
      if (isSameDay(lastDayOfYear(new Date(targetDate.endDate)), new Date(targetDate.endDate))) {
        dispatch(
          trendTabularTabActions.setAdjustedEndDate(new Date(targetDate.endDate).toISOString())
        );
      } else {
        dispatch(
          trendTabularTabActions.setAdjustedEndDate(
            lastDayOfYear(new Date(targetDate.endDate)).toISOString()
          )
        );
      }
    }
  }, [targetDate]);
  //
  // Triggers when filter values dependencies changes
  useEffect(() => {
    if (
      !dateComparison &&
      !chartBreakdown &&
      !bookingDate &&
      !targetDate &&
      (!hotelId || !hotelGroupId)
    )
      return;
    // Generate summary widget filter url
    const tabularUrl = generateTabularShareUrl(
      pmsSyncToggle,
      hotelId,
      targetDate,
      dateComparison,
      visualFilters,
      startDateComparison,
      endDateComparison,
      customDate,
      isDetailed,
      chartBreakdown,
      vOTB,
      bookingDate,
      alignmentToggle,
      symmetricComparisonToggle,
      hotelGroupId,
      enableHotelGroups,
      revenueIsDisplayTT,
      compareWindowCollapseToggle,
      focusOn
    );
    //
    dispatch(trendTabularTabActions.setTrendTabularUrl(tabularUrl));
  }, [
    pmsSyncToggle,
    hotelId,
    targetDate,
    dateComparison,
    visualFilters,
    startDateComparison,
    endDateComparison,
    customDate,
    isDetailed,
    chartBreakdown,
    vOTB,
    bookingDate,
    alignmentToggle,
    symmetricComparisonToggle,
    hotelGroupId,
    enableHotelGroups,
    revenueIsDisplayTT,
    compareWindowCollapseToggle,
    focusOn,
  ]);
};
//
export default useTrendTimeTabular;
