import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ADMIN_ROLE, SUPER_ADMIN_ROLE, PREFERRED_ROLE } from 'modules/common/constants/roles';
import {
  selectHotelDropdownList,
  selectHotelGroups,
  selectUserRole,
} from 'modules/dashboard/selectors';
import { dashboardActions } from 'modules/dashboard/slice';
import { getHotelGroupFocusOnValue } from '../functions';
/**
 * Custom hook to set tab accessibility based on user role
 */
const UseTabContainer = () => {
  const dispatch = useDispatch();
  const userRole = useSelector(selectUserRole);
  const hotelList = useSelector(selectHotelDropdownList);
  const hotelGroups = useSelector(selectHotelGroups);
  //
  useEffect(() => {
    // Set iPrefer access true for admin, super admin and preferred user roles
    dispatch(
      dashboardActions.setTabAccess({
        isIPrefer:
          userRole === PREFERRED_ROLE || userRole === ADMIN_ROLE || userRole === SUPER_ADMIN_ROLE,
      })
    );
  }, []);
  //
  useEffect(() => {
    if (hotelList?.length > 0 && hotelGroups) {
      const hotelGroup = getHotelGroupFocusOnValue(hotelGroups, hotelList);
      dispatch(dashboardActions.setHotelGroupDropdownList(hotelGroup?.hotelGroupDropdownList));
      dispatch(dashboardActions.setSelectedHotelGroup(hotelGroup));
    }
  }, [hotelList, hotelGroups]);
};
//
export default UseTabContainer;
