import { Grid, List } from '@mui/material';
import { ENVIRONMENT } from 'config';
import { ListItemButton, ListItemText, Wrapper } from './style';
/**
 * Footer common component that uses across modules as footer
 */
const Footer = () => (
  <Wrapper>
    <Grid container spacing={0}>
      <Grid
        sx={{
          display: { xs: 'none', md: 'block' },
        }}
        container
        item
        xs={12}
        md={6}
      >
        <List>
          <ListItemButton component="a" href="#">
            <ListItemText primary="Support" />
          </ListItemButton>
          <ListItemButton component="a" href="#">
            <ListItemText primary="Help Center" />
          </ListItemButton>
          <ListItemButton component="a" href="#">
            <ListItemText primary="Privacy" />
          </ListItemButton>
          <ListItemButton component="a" href="#">
            <ListItemText primary="Terms of Service" />
          </ListItemButton>
        </List>
      </Grid>
      <Grid container item xs={12} md={6} justifyContent="flex-end">
        <List>
          <ListItemText sx={{ mt: 3 }} primary={ENVIRONMENT.APP_VERSION} />
        </List>
        <List>
          <ListItemButton>
            <ListItemText primary={`© ${new Date().getFullYear()} ${ENVIRONMENT.APP_NAME}`} />
          </ListItemButton>
        </List>
      </Grid>
    </Grid>
  </Wrapper>
);
//
export default Footer;
