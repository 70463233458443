import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box, IconButton, Tooltip } from '@mui/material';
import { X } from 'react-feather';
import { AddCircle, Save } from '@mui/icons-material';
import ERROR_TYPES from 'modules/common/constants/error-types';
import { selectOrganizationId, selectAuthUser } from 'modules/common/auth/selectors';
import { selectNotification } from 'modules/common/notifications/selectors';
import {
  selectMinorEventCategories,
  selectMinorEventUpdateState,
} from 'modules/event-configuration/selectors';
import { configureEventCategoriesActions } from 'modules/event-configuration/slice';
import { ADMIN_ROLE, SUPER_ADMIN_ROLE } from 'modules/common/constants/roles';
import { Modal } from 'modules/common/components';
import { isEmpty } from 'modules/common/helpers/object';
import PAGINATION from 'modules/common/constants/pagination-limits';
import { Alert, Typography } from './style';
import AddMinorCategoryDialog from '../add-minor-category';
import MinorCategoriesEditingGrid from '../minor-categories-editing-grid';
/**
 * Drawer Item to display Minor event categories.
 * @param {*} show - state of show/hide state of the drawer
 * @param {*} isShow - set show/hide state of the drawer
 * @param {*} majorEventCategoryId - major event category id
 */
const MinorEventDataDrawerView = ({ show, isShow, majorEventCategoryId }) => {
  const dispatch = useDispatch();
  // global state selectors
  const organizationId = useSelector(selectOrganizationId);
  const user = useSelector(selectAuthUser);
  const notification = useSelector(selectNotification);
  const minorEventCategories = useSelector(selectMinorEventCategories);
  const updatedMinorEvent = useSelector(selectMinorEventUpdateState);
  //
  const [openAddMinorCategory, setOpenAddMinorCategory] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [rowModesModel, setRowModesModel] = useState({});
  const [editedModel, setEditedModel] = useState({});
  const [isEditable, setIsEditable] = useState(false);
  const [isDeletable, setIsDeletable] = useState(false);
  // triggers when updatedMinorEvent, majorEventCategoryId states change
  useEffect(() => {
    // get minor categories related to the major category
    if (majorEventCategoryId) {
      const query = `limit=${PAGINATION.MINOR_EVENT_CATEGORIES_PER_PAGE}`;
      dispatch(
        configureEventCategoriesActions.getMinorEventCategories({
          organizationId,
          majorEventCategoryId,
          query,
        })
      );
    }
  }, [updatedMinorEvent, majorEventCategoryId]);
  // triggers when notification state changes
  useEffect(() => {
    // close the drawer
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      isShow(false);
    }
  }, [notification]);
  //  Update multiple minor event categories functionality
  const handleSave = () => {
    const keys = Object.keys(rowModesModel);
    const editedMinorEventCategories = [];
    if (keys.length > 0) {
      // set edited event categories
      keys.forEach((key) => {
        const transformData = {
          id: key,
          name: editedModel[key]?.name,
          applicableHotels: editedModel[key]?.applicableHotels,
        };
        editedMinorEventCategories.push(transformData);
        setUpdate(false);
      });
    }
    // dispatch update minor categories
    dispatch(
      configureEventCategoriesActions.updateMinorEventCategories({
        organizationId,
        majorEventCategoryId,
        minor_events: editedMinorEventCategories,
      })
    );
    // empty rows model and edit model
    setRowModesModel({});
    setEditedModel({});
  };
  // triggers when user state changes
  useEffect(() => {
    // checks the user role and set availability  to edit and delete
    if (
      user &&
      (user?.roles[0]?.name === ADMIN_ROLE || user?.roles[0]?.name === SUPER_ADMIN_ROLE)
    ) {
      setIsEditable(true);
      setIsDeletable(true);
    }
  }, [user]);
  //
  return (
    <Box
      xs={12}
      md={4}
      ml={4}
      mt={2}
      component={Grid}
      container
      item
      display="flex"
      direction="column"
      sx={{
        backgroundColor: 'white',
        flexShrink: 0,
        display: show ? 'flex' : 'none',
      }}
    >
      <Grid container direction="row" justifyContent="space-between" px={2}>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: 300,
          }}
        >
          Minor categories for {minorEventCategories?.majorEventCategoryName}
        </Typography>
        <Grid>
          <Tooltip title="Add">
            <IconButton
              disabled={false}
              color="inherit"
              size="small"
              onClick={() => setOpenAddMinorCategory(true)}
            >
              <AddCircle style={{ fontSize: 'large' }} />
            </IconButton>
          </Tooltip>
          {!isEmpty(editedModel) && isEditable && (
            <Tooltip title="Update">
              <IconButton
                disabled={false}
                color="inherit"
                size="small"
                onClick={() => setUpdate(true)}
              >
                <Save style={{ fontSize: 'large' }} />
              </IconButton>
            </Tooltip>
          )}
          <IconButton color="inherit" size="small" onClick={() => isShow(false)}>
            <X />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="column" display="flex">
        {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
          <Alert mt={2} mb={3} severity={notification?.type}>
            {notification?.message}
          </Alert>
        )}
        {show && !isEmpty(minorEventCategories) && (
          <MinorCategoriesEditingGrid
            minorEventCategories={minorEventCategories}
            majorEventCategoryId={majorEventCategoryId}
            rowModesModel={rowModesModel}
            setRowModesModel={setRowModesModel}
            editedModel={editedModel}
            setEditedModel={setEditedModel}
            isEditable={isEditable}
            isDeletable={isDeletable}
          />
        )}
        <Modal
          open={isUpdate}
          handleClose={() => setUpdate(false)}
          title="Update Minor Category"
          content="Are you sure you want to update the minor event category ?"
          handleSuccess={handleSave}
          closeLabel="Cancel"
          successLabel="Update"
          variant="contained"
          color="primary"
        />
        <AddMinorCategoryDialog
          open={openAddMinorCategory}
          onClose={() => setOpenAddMinorCategory(false)}
          majorEventCategoryId={majorEventCategoryId}
        />
      </Grid>
    </Box>
  );
};
//
export default MinorEventDataDrawerView;
