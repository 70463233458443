import { createSlice } from '@reduxjs/toolkit';
import { signInActions } from 'modules/sign-in/slice';
import { dashboardActions } from 'modules/dashboard/slice';
import { capitalizeFirst } from 'modules/common/helpers/strings';
import { ALIGNED, SLICE, TAGS } from 'modules/dashboard/constants';

/**
 * Initial states of Pace Time widget functions are defined here
 */
export const initialState = {
  paceWidgetForecast: [],
  paceLoad: null,
  paceTimeLagAligned: false,
  paceTimeCumulativeView: false,
  percentage: null,
  paceAlignmentToggle: null,
  paceAlignment: null,
  paceSymmetricComparisonToggle: true,
  datePaceComparison: null,
  paceTimeOccupiedRoomFilterList: [],
  paceTimeRevenueFilterList: [],
  paceWidgetFilterList: [],
  paceTimeWidgetUrl: '',
  revenueIsDisplay: true,
  occupiedRoomsIsDisplay: true,
  symmetricComparisonIsDisplay: false,
  adrIsDisplay: true,
  cxlIsDisplay: false,
};
/**
 * All actions related to Pace Time widget feature are defined here
 */
export const paceTimeSlice = createSlice({
  name: SLICE.PACE_TIME,
  initialState,
  reducers: {
    setPaceWidgetForecast(state, action) {
      state.paceWidgetForecast = action?.payload;
    },
    setPaceLoad(state, action) {
      state.paceLoad = action?.payload;
    },

    setPaceTimeLagAligned(state, action) {
      state.paceTimeLagAligned = action?.payload;
    },
    setPaceTimeCumulativeView(state, action) {
      state.paceTimeCumulativeView = action?.payload;
    },
    setPercentage(state, action) {
      state.percentage = action?.payload;
    },
    setPaceAlignment(state, action) {
      state.paceAlignment = action?.payload;
    },
    setPaceAlignmentToggle(state, action) {
      state.paceAlignmentToggle = action?.payload;
    },
    setPaceSymmetricComparisonToggle(state, action) {
      state.paceSymmetricComparisonToggle = action?.payload;
    },
    setPaceDateComparison(state, action) {
      state.datePaceComparison = action?.payload;
    },
    setPaceTimeOccupiedFilterList(state, action) {
      state.paceTimeOccupiedRoomFilterList = action.payload;
    },
    setPaceTimeRevenueFilterList(state, action) {
      state.paceTimeRevenueFilterList = action.payload;
    },
    setPaceWidgetFilterList(state, action) {
      state.paceWidgetFilterList = action?.payload;
    },
    setPaceTimeWidgetUrl(state, action) {
      state.paceTimeWidgetUrl = action.payload;
    },
    setRevenueIsDisplay(state, action) {
      state.revenueIsDisplay = action?.payload;
    },
    setOccupiedRoomIsDisplay(state, action) {
      state.occupiedRoomsIsDisplay = action?.payload;
    },
    setAdrIsDisplay(state, action) {
      state.adrIsDisplay = action?.payload;
    },
    setCxlIsDisplay(state, action) {
      state.cxlIsDisplay = action?.payload;
    },
    setSymmetricComparisonIsDisplay(state, action) {
      state.symmetricComparisonIsDisplay = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInActions.signIn, (state) => ({
        ...state,
        ...initialState,
      }))
      .addCase(dashboardActions.getVisualFiltersSucceeded, (state, action) => {
        const dateComparisonFilter = action?.payload?.results?.find(
          (filter) => filter?.tags?.includes(TAGS.DATE_COMPARISON) && filter?.isDefault === true
        );
        return {
          ...state,
          percentage: action?.payload?.results?.find(
            (filter) => filter?.tags?.includes(TAGS.PERCENTAGE) && filter?.isDefault === true
          )?.id,
          paceAlignment: {
            id: dateComparisonFilter?.id,
            label: capitalizeFirst(dateComparisonFilter?.type),
          },
          paceAlignmentToggle: dateComparisonFilter?.type === ALIGNED,
          datePaceComparison: dateComparisonFilter?.values?.find(
            (filter) => filter.isDefault === true
          )?.value,
        };
      });
  },
});
//
export const { actions: paceTimeActions } = paceTimeSlice;
