import { startOfMonth, subDays, subMonths, endOfMonth } from 'date-fns';

/**
 * Dashboard feature related request structures are defined here
 */
export const API = {
  POST_TOKEN_INFO: {
    path: '/auth/introspect-tokens',
    method: 'POST',
  },
  GET_EMBED_TOKEN: {
    path: '/organizations/:organizationId/visuals-embed/token',
    method: 'GET',
  },
  GET_VISUALS: {
    path: '/organizations/:organizationId/visuals?:query',
    method: 'GET',
  },
  GET_VISUAL_FILTERS: {
    path: '/organizations/:organizationId/visual-filters?:query',
    method: 'GET',
  },
  GET_ALL_HOTELS: {
    path: '/organizations/:organizationId/hotels?:query',
    method: 'GET',
  },

  GET_ALL_BREAKDOWNS: {
    path: '/organizations/:organizationId/breakdowns?:query',
    method: 'GET',
  },
  GET_FORECAST_FILTERS: {
    path: '/organizations/:organizationId/hotels/:hotelId/forecast-filters?:query',
    method: 'GET',
  },
  POST_SQL_WAREHOUSE_PING: {
    path: '/sql-warehouse-ping',
    method: 'POST',
  },
  GET_SYSTEM_INFORMATION: {
    path: '/system-information',
    method: 'GET',
  },
  GET_ALL_UTM_BREAKDOWNS: {
    path: '/organizations/:organizationId/utm-breakdowns?:query',
    method: 'GET',
  },
  POST_COMMAND_FILTER: {
    path: '/organizations/:organizationId/users/:userId/configurations/command-filter',
    method: 'POST',
  },
  GET_COMMAND_FILTER: {
    path: '/organizations/:organizationId/users/:userId/configurations/command-filter?:query',
    method: 'GET',
  },
  DELETE_COMMAND_FILTER: {
    path: '/organizations/:organizationId/users/:userId/configurations/command-filter?:query',
    method: 'DELETE',
  },
  GET_ALL_HOTEL_GROUPS: {
    path: '/organizations/:organizationId/users/:userId/configurations/hotel-group?:query',
    method: 'GET',
  },
  POST_HOTEL_GROUP: {
    path: '/organizations/:organizationId/users/:userId/configurations/hotel-group',
    method: 'POST',
  },
  DELETE_HOTEL_GROUP: {
    path: '/organizations/:organizationId/users/:userId/configurations/hotel-group?:query',
    method: 'DELETE',
  },
  GET_NOTIFICATIONS: {
    path: '/organizations/:organizationId/users/:userId/notifications?:query',
    method: 'GET',
  },
  UPDATE_NOTIFICATION: {
    path: '/organizations/:organizationId/users/:userId/notifications/:notificationId',
    method: 'PATCH',
  },
};
/**
 * Tags for Visual Filter classification are defined here
 */
export const TAGS = {
  CHAIN_NAME: 'CHAIN_NAME',
  TARGET_DATE: 'TARGET_DATE',
  BOOKING_DATE: 'BOOKING_DATE',
  DATE_COMPARISON: 'DATE_COMPARISON',
  HOTEL_NAME: 'HOTEL_NAME',
  SEGMENT_BREAKDOWN: 'SEGMENT_BREAKDOWN',
  PMS_SYNC: 'PMS_SYNC',
  PACE_HORIZON: 'PACE_HORIZON',
  REGION: 'REGION',
  PACE_VARIABLE: 'PACE_VARIABLE',
  ROOM_FORECAST: 'ROOM_FORECAST',
  REVENUE_FORECAST: 'REVENUE_FORECAST',
  PMS_SYNC_FORECAST: 'PMS_SYNC_FORECAST',
  PACE_FORECAST: 'PACE_FORECAST',
  SUMMARY_PACE_VARIABLE: 'SUMMARY_PACE_VARIABLE',
  SYMMETRIC_COMPARISON: 'SYMMETRIC_COMPARISON',
  DETAILED: 'DETAILED',
  NORMALIZED: 'NORMALIZED',
  CUSTOM_DATE_COMPARISON: 'CUSTOM_DATE_COMPARISON',
  CUSTOM: 'CUSTOM',
  SEGMENT_GROUP_1: 'SEGMENT_GROUP_1',
  SEGMENT_GROUP_2: 'SEGMENT_GROUP_2',
  AGGREGATION: 'AGGREGATION',
  WEB_CLICK: 'WEB_CLICK',
  SCALING_FACTOR: 'SCALING_FACTOR',
  CLICK_STEPS: 'CLICK_STEPS',
  WEB_CLICK_CHANNEL: 'WEB_CLICK_CHANNEL',
  HOTEL_NAME_ORDER: 'HOTELNAME_ORDER',
  SUMMARY: 'SUMMARY',
  SUMMARY_SEGMENT_BREAKDOWN: 'SUMMARY_SEGMENT_BREAKDOWN',
  PACE_WIDGET: 'PACE_WIDGET',
  I_PREFER_SEGMENT_BREAKDOWN: 'I_PREFER_SEGMENT_BREAKDOWN',
  METRIC_SELECTION: 'METRIC_SELECTION',
  UTM_BREAKDOWN: 'UTM_BREAKDOWN',
  UTM_FOCUS_ON: 'UTM_FOCUS_ON',
  IS_FORECAST: 'IS_FORECAST',
  PERCENTAGE: 'PERCENTAGE',
  HOTEL_GROUP_1: 'HOTEL_GROUP_1',
  HOTEL_GROUP_2: 'HOTEL_GROUP_2',
  HOTEL_GROUP_ORDER: 'HOTEL_GROUP_ORDER',
  I_PREFER_TIME_FRAME: 'I_PREFER_TIME_FRAME',
  HOTEL_ID: 'HOTEL_ID',
  FAR_FIELD_PMS_SYNC: 'FAR_FIELD_PMS_SYNC',
  GRANULARITY: 'GRANULARITY',
  SEGMENT_WISE_BREAKDOWN: 'SEGMENT_WISE_BREAKDOWN',
};
/**
 * Operators to generate Power BI schema are defined here
 */
export const OPERATORS = {
  NOT_IN: 'NotIn',
  ALL: 'All',
  GREATER_OR_EQUAL: 'GreaterThanOrEqual',
  LESS_OR_EQUAL: 'LessThanOrEqual',
  AND: 'And',
  IN: 'In',
};
/**
 * Default booking date is defined here
 */
export const BOOKING_DATE = '2015/01/01';

/**
 * Tab values for each widget is defined here
 */
export const TAB_VALUE = {
  SUMMARY: 0,
  WORKBOOK_REPORT: 1,
  // PACE_TIME_WIDGET: 2,
  // PACE_SEGMENT_WIDGET: 3,
  TREND_TIME: 2,
  // PACE_3D: 3,
  PACE: 4,
  REGION_MAP: 5,
  WEB_CLICK: 6,
  TREND_TABULAR: 3,
  // I_PREFER: 8,
};
/**
 * Report type of each widget is defined here
 */
export const REPORT_TYPE = {
  SUMMARY: 'Summary',
  SUMMARY_HOTEL: 'Summary-Hotel',
  TREND_TIME: 'Trend-Time',

  TREND_SEGMENT: 'Trend-Segment',
  TREND_TIME_TABULAR: 'Trend-Time-Tabular',
  TREND_SEGMENT_TABULAR: 'Trend-Segment-Tabular',
  WEEKLY_TREND_TIME: 'Weekly-Trend-Time',
  WEEKLY_TREND_SEGMENT: 'Weekly-Trend-Segment',

  WEEKLY_TREND_SEGMENT_TABULAR: 'Weekly-Trend-Segment-Tabular',
  WEEKLY_TREND_TIME_TABULAR: 'Weekly-Trend-Time-Tabular',
  TREND_TIME_CUMULATIVE: 'Cumulative-Trend-Time',

  TREND_SEGMENT_CUMULATIVE: 'Cumulative-Trend-Segment',
  TREND_TIME_CUMULATIVE_TABULAR: 'Cumulative-Trend-Time-Tabular',

  TREND_SEGMENT_CUMULATIVE_TABULAR: 'Cumulative-Trend-Segment-Tabular',
  PACE_3D: '3d-pace',
  REGION_MAP: 'Region',
  PACE_TIME_WIDGET: 'Pace-Time-Widget',
  PACE_SEGMENT_WIDGET: 'Pace-Segment-Widget',
  PACE_HOTEL_WIDGET: 'Pace-Hotel-Widget',
  CUMULATIVE: 'Cumulative',
  WEB_CLICK: 'Web-Click',
  WEEKLY_WEB_CLICK: 'web-click-weekly',
  CUMULATIVE_WEB_CLICK: 'Web-Click-Cumulative',
  PACE_TIME_WIDGET_LAG_ALIGNED: 'Pace-Time-Widget-Lag-Aligned',
  PACE_SEGMENT_WIDGET_LAG_ALIGNED: 'Pace-Segment-Widget-Lag-Aligned',
  PACE_HOTEL_WIDGET_LAG_ALIGNED: 'Pace-Hotel-Widget-Lag-Aligned',
  PACE_TIME_WIDGET_ABSOLUTE_CUMULATIVE: 'Pace-Time-Widget-Absolute-Cumulative',
  PACE_SEGMENT_WIDGET_ABSOLUTE_CUMULATIVE: 'Pace-Segment-Widget-Absolute-Cumulative',
  PACE_HOTEL_WIDGET_ABSOLUTE_CUMULATIVE: 'Pace-Hotel-Widget-Absolute-Cumulative',
  PACE_TIME_WIDGET_LAG_ALIGNED_CUMULATIVE: 'Pace-Time-Widget-Lag-Aligned-Cumulative',
  PACE_SEGMENT_WIDGET_LAG_ALIGNED_CUMULATIVE: 'Pace-Segment-Widget-Lag-Aligned-Cumulative',
  PACE_HOTEL_WIDGET_LAG_ALIGNED_CUMULATIVE: 'Pace-Hotel-Widget-Lag-Aligned-Cumulative',
  I_PREFER: 'iPrefer',
  WORKBOOK_REPORT: 'Workbook-Report',
  PACE: 'Pace',
  TREND_TIME_PERCENTAGE: 'Trend-Time-Percentage',
  TREND_TIME_PERCENTAGE_TABULAR: 'Trend-Time-Percentage-Tabular',
  TREND_SEGMENT_PERCENTAGE: 'Trend-Segment-Percentage',
  TREND_SEGMENT_PERCENTAGE_TABULAR: 'Trend-Segment-Percentage-Tabular',

  TREND_TABULAR: 'Trend-Tabular',
  TREND_TIME_TABULAR_DAILY: 'Trend-Time-Tabular-Daily',
  TREND_TIME_SEGMENT: 'Trend-Time-Segment',
  TREND_TIME_SEGMENT_TABULAR: 'Trend-Time-Segment-Tabular',
  WEEKLY_TREND_TIME_SEGMENT: 'Weekly-Trend-Time-Segment',
  WEEKLY_TREND_TIME_SEGMENT_TABULAR: 'Weekly-Trend-Time-Segment-Tabular',
  TREND_TIME_SEGMENT_CUMULATIVE: 'Cumulative-Trend-Time-Segment',
  TREND_TIME_SEGMENT_CUMULATIVE_TABULAR: 'Cumulative-Trend-Time-Segment-Tabular',
  // Trend Hotel tags - forecast on
  TREND_HOTEL: 'Trend-Hotel',
  TREND_HOTEL_TABULAR: 'Trend-Hotel-Tabular',
  WEEKLY_TREND_HOTEL: 'Weekly-Trend-Hotel',
  WEEKLY_TREND_HOTEL_TABULAR: 'Weekly-Trend-Hotel-Tabular',
  TREND_HOTEL_CUMULATIVE: 'Cumulative-Trend-Hotel',
  TREND_HOTEL_CUMULATIVE_TABULAR: 'Cumulative-Trend-Hotel-Tabular',
  TREND_HOTEL_PERCENTAGE: 'Trend-Hotel-Percentage',
  TREND_HOTEL_PERCENTAGE_TABULAR: 'Trend-Hotel-Percentage-Tabular',
  // Trend Hotel tags - forecast off
  TREND_HOTEL_FORECAST_OFF: 'Trend-Hotel-Forecast-Off',
  TREND_HOTEL_TABULAR_FORECAST_OFF: 'Trend-Hotel-Tabular-Forecast-Off',
  WEEKLY_TREND_HOTEL_FORECAST_OFF: 'Weekly-Trend-Hotel-Forecast-Off',
  WEEKLY_TREND_HOTEL_TABULAR_FORECAST_OFF: 'Weekly-Trend-Hotel-Tabular-Forecast-Off',
  TREND_HOTEL_CUMULATIVE_FORECAST_OFF: 'Cumulative-Trend-Hotel-Forecast-Off',
  TREND_HOTEL_CUMULATIVE_TABULAR_FORECAST_OFF: 'Cumulative-Trend-Hotel-Tabular-Forecast-Off',
  TREND_HOTEL_PERCENTAGE_FORECAST_OFF: 'Trend-Hotel-Percentage-Forecast-Off',
  TREND_HOTEL_PERCENTAGE_TABULAR_FORECAST_OFF: 'Trend-Hotel-Percentage-Tabular-Forecast-Off',
};
/**
 * Short cut keys for the functionalities are defined here
 */
export const SHORTCUT_KEY = {
  PMS_TOGGLE: ['Alt', 'p'],
  WEEKLY_TOGGLE: ['Alt', 'w'],
  BACK_BY_MONTH: ['Control', 'ArrowLeft'],
  BACK_BY_MONTH_MAC: ['Meta', 'ArrowLeft'],
  BACK_BY_PERIOD: ['ArrowLeft'],
  FORWARD_BY_MONTH: ['Control', 'ArrowRight'],
  FORWARD_BY_MONTH_MAC: ['Meta', 'ArrowRight'],
  FORWARD_BY_PERIOD: ['ArrowRight'],
};
/**
 * Value of OTB date comparison defined here
 */
export const OTB_VALUE = 'vs OTB';
/**
 * Value of Custom date comparison define here
 */
export const CUSTOM_VALUE = 'Custom';
/**
 * Not applicable value for date comparison & alignment filter is defined here
 */
export const NA = 'NA';
/**
 * Filter values are defined here
 */
export const FILTER_VALUES = {
  RATE_CATEGORY: 'Rate Category',
  RATE_CATEGORY_RAW: 'Rate Category (raw)',
};
/**
 * Custom Keys are defined here
 */
export const KEYS = {
  LAST_UPDATED_DATE: 'syntheticPipelineTime',
  TIMEZONE: 'UTC',
  START_DATE: 'start',
  END_DATE: 'end',
  CUSTOM: 'Custom',
  PACE_FORECAST_LIMIT: 30,
  SET_A: 'Set A',
  SET_B: 'Set B',
};
/**
 * Summary stay date object is defined here
 */
export const STAY_DATE = {
  startDate: new Date(subDays(new Date(), 1)).toISOString(),
  endDate: new Date(subDays(new Date(), 1)).toISOString(),
  key: 'selection',
};
/**
 * Trend tab stay date object is defined here
 */
export const TREND_STAY_DATE = {
  startDate: new Date(startOfMonth(subMonths(new Date(), 1))).toISOString(),
  endDate: new Date(endOfMonth(subMonths(new Date(), 1))).toISOString(),
  key: 'selection',
};
/**
 * Reset booking date for dashboard component is defined here
 */
export const RESET_BOOKING_DATE = {
  startDate: new Date(BOOKING_DATE).toISOString(),
  endDate: new Date().toISOString(),
  key: 'selection',
};

/**
 * Per page default visual count
 */
export const DEFAULT_VISUALS_PER_PAGE = 6;
/**
 * Last Cumulative visual order in trend tabs
 */
export const DEFAULT_CUMULATIVE_VISUAL_ORDER = 5;

/**
 * Power BI report visual names
 */
export const VISUAL_PAGE_NAMES = {
  TREND_SEGMENT_TOTAL_REVENUE_C: 'ReportSectiona8da0be1a25f327cc2a1',
  TREND_SEGMENT_TOTAL_REVENUE_W: 'ReportSection3b74f8ac460241af62c3',
  TREND_SEGMENT_NO_OF_CANCELLATION_W: 'ReportSectiona231c0275716ab579ce3',
  TREND_SEGMENT_TOTAL_OCCUPIED_ROOMS_C: 'ReportSection1ffff9b588fc02fdbba1',
  TREND_SEGMENT_TOTAL_OCCUPIED_ROOMS_W: 'ReportSectionbaf46554acb641480818',
  TREND_HOTEL_TOTAL_REVENUE_C: 'ReportSectiond6705a204f72f18c9206',
  TREND_HOTEL_TOTAL_OCCUPIED_ROOMS_C: 'ReportSection87b8fd9d7cbcab7c48f3',
  TREND_HOTEL_NO_OF_CANCELLATION_W: 'ReportSectiona7025961dc3d386a06a3',
};
/**
 * Actual value for pace visual forecast is defined here
 */
export const ACTUAL_VALUE = 'Actual';

/**
 * Dashboard component slices are defined here
 */
export const SLICE = {
  BASE_AUTH: 'feature/base-auth',
  SIGN_IN: 'feature/sign-in',
  FORGOT_PASSWORD: 'feature/forgot-password',
  RESET_PASSWORD: 'feature/reset-password',
  NOTIFICATION: 'feature/notification',
  VERIFY_CALLBACK: 'feature/verify-callback',
  DASHBOARD: 'feature/dashboard',
  RATE_CALENDAR_UPLOAD: 'feature/rate-calendar-upload',
  USERS: 'feature/users',
  RATE_CALENDAR: 'feature/rate-calendar',
  CONFIGURE_EVENT_CATEGORIES: 'feature/configure-event-categories',
  SUMMARY: 'feature/summary',
  PACE_TIME: 'feature/pace-time',
  PACE_SEGMENT: 'feature/pace-segment',
  PACE: 'feature/pace',
  TREND: 'feature/trend',
  TREND_TIME: 'feature/trend-time',
  TREND_SEGMENT: 'feature/trend-segment',
  TREND_HOTEL: 'feature/trend-hotel',
  PLOT_PACE: 'feature/plot-pace',
  REGIONAL_MAP: 'feature/regional-map',
  WEB_CLICK: 'feature/web-click',
  I_PREFER: 'feature/i-prefer',
  WORKBOOK_REPORT: 'feature/workbook',
  TREND_TABULAR: 'feature/trend-tabular',
  TREND_TABULAR_TAB: 'feature/trend-tabular-tab',
};

/**
 * Alignment toggle value
 */
export const ALIGNED = 'ALIGNED';
//
export const PRIMARY = 'Breakdown';
//
export const PACE_HORIZON_SELECTION = {
  BOOKING_PACE_WINDOW: 'BPW',
  BOOKING_PACE_HORIZON: 'BPH',
};
