import { useCallback, useEffect } from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
  Paper,
  ListSubheader,
  InputAdornment,
  TextField,
} from '@mui/material';
import { dashboardActions } from 'modules/dashboard/slice';
import { selectIsDemoUser } from 'modules/dashboard/selectors';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { isEmpty } from 'modules/common/helpers/object';
import { useDispatch, useSelector } from 'react-redux';
import { generateQuery } from 'modules/common/components/add-hotel-group/functions';
import { Search } from 'react-feather';
import { LABELS } from '../../constants';

/**
 * Transfer list implementation for custom aggregation
 * @param {Array} focusOnList - List of focus on dropdown values
 * @param {Array} groupList - Hotel group list  values
 * @param {Function} setGroupList - Function to set Hotel group list values
 * @param {Boolean} isQueryEnabled - To enable/disable advanced querying feature
 */
const AggregationTransferList = ({
  focusOnList,
  groupList,
  setGroupList,
  isQueryEnabled,
  detailedBreakdown,
  setDetailedBreakdown,
  checked,
  setChecked,
  temGroupList,
  setTemGroupList,
}) => {
  //
  const dispatch = useDispatch();
  const isDemoUser = useSelector(selectIsDemoUser);

  // Helper functions for array operations
  const not = (array1, array2) => array1.filter((value) => array2.indexOf(value) === -1);
  const intersection = (array1, array2) => array1.filter((value) => array2.indexOf(value) !== -1);

  // Determine checked items in both lists
  const leftChecked = intersection(checked, groupList);
  const middleChecked = intersection(checked, detailedBreakdown);

  // Handle checkbox toggle
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  // Move items from detailed breakdown to group list
  const handleAllLeft = () => {
    dispatch(
      dashboardActions.setHotelGroupQuery(generateQuery(groupList.concat(detailedBreakdown)))
    );
    setGroupList(groupList.concat(detailedBreakdown));
    setTemGroupList(temGroupList.concat(detailedBreakdown));
    setDetailedBreakdown([]);
  };

  // Move selected items from detailed breakdown to group list
  const handleCheckedMiddleFromLeft = () => {
    dispatch(dashboardActions.setHotelGroupQuery(generateQuery(not(groupList, leftChecked))));
    setDetailedBreakdown(detailedBreakdown.concat(leftChecked));
    setGroupList(not(groupList, leftChecked));
    setTemGroupList(not(temGroupList, leftChecked));
    setChecked(not(checked, leftChecked));
  };
  // Move all items from group list to detailed breakdown
  const handleAllMiddleFromLeft = () => {
    dispatch(dashboardActions.setHotelGroupQuery(''));
    setDetailedBreakdown(detailedBreakdown.concat(groupList));
    setGroupList([]);
    setTemGroupList([]);
  };

  // Move selected items from detailed breakdown to group list
  const handleCheckedLeft = () => {
    dispatch(dashboardActions.setHotelGroupQuery(generateQuery(groupList.concat(middleChecked))));
    setGroupList(groupList.concat(middleChecked));
    setTemGroupList(temGroupList.concat(middleChecked));
    setDetailedBreakdown(not(detailedBreakdown, middleChecked));
    setChecked(not(checked, middleChecked));
  };

  // Update detailed breakdown when focusOnList or groupList changes
  useEffect(() => {
    if (isEmpty(groupList)) {
      setDetailedBreakdown(focusOnList);
    }
    if (groupList.length) {
      setDetailedBreakdown(not(detailedBreakdown, groupList));
    }
  }, [focusOnList, groupList]);

  // Function to filter hotel list based on search input
  // const filterHotelList = (event) => {
  //   const idsToRemove = new Set(temGroupList.map((item) => item.id));
  //   const filteredArray = hotelList.filter((item) => !idsToRemove.has(item.id));
  //   const { value } = event.target;
  //   if (!value) {
  //     setDetailedBreakdown(filteredArray);
  //     return;
  //   }
  //   const filtered = filteredArray.filter(
  //     (item) =>
  //       item.label.toLowerCase().includes(value.toLowerCase()) ||
  //       item.altName.toLowerCase().includes(value.toLowerCase())
  //   );
  //   setDetailedBreakdown(filtered);
  // };
  const filterHotelList = useCallback(
    (event) => {
      const idsToRemove = new Set(temGroupList.map((item) => item.id));
      const filteredArray = focusOnList.filter((item) => !idsToRemove.has(item.id));
      const { value } = event.target;
      if (!value) {
        setDetailedBreakdown(filteredArray);
        return;
      }
      const filtered = filteredArray.filter(
        (item) =>
          item.label.toLowerCase().includes(value.toLowerCase()) ||
          item.altName.toLowerCase().includes(value.toLowerCase())
      );
      setDetailedBreakdown(filtered);
    },
    [temGroupList, focusOnList]
  );

  // Function to filter group list based on search input
  // const filterHotelSets = (event) => {
  //   const { value } = event.target;
  //   if (!value) {
  //     setGroupList(temGroupList);
  //     return;
  //   }
  //   const filtered = temGroupList.filter(
  //     (item) =>
  //       item.label.toLowerCase().includes(value.toLowerCase()) ||
  //       item.altName.toLowerCase().includes(value.toLowerCase())
  //   );
  //   setGroupList(filtered);
  // };
  const filterHotelSets = useCallback(
    (event) => {
      const { value } = event.target;
      if (!value) {
        setGroupList(temGroupList);
        return;
      }
      const filtered = temGroupList.filter(
        (item) =>
          item.label.toLowerCase().includes(value.toLowerCase()) ||
          item.altName.toLowerCase().includes(value.toLowerCase())
      );
      setGroupList(filtered);
    },
    [temGroupList]
  );

  // Custom list component
  const customList = (items, header) => {
    const listItems = [...items];
    let sortArray = [];
    if (isDemoUser) {
      sortArray =
        !isEmpty(listItems) &&
        listItems?.sort(
          (element1, element2) =>
            element1.label &&
            element2.label &&
            Number(element1?.label?.split(' ')[1]) - Number(element2?.label?.split(' ')[1])
        );
    } else {
      sortArray =
        !isEmpty(listItems) &&
        listItems?.sort((element1, element2) => element1.label.localeCompare(element2.label));
    }

    return (
      <Paper
        sx={{ maxWidth: 250, minWidth: 250, height: 350, overflow: 'auto' }}
        variant="outlined"
      >
        <List
          dense
          component="div"
          role="list"
          subheader={<ListSubheader component="div">{header}</ListSubheader>}
        >
          {!isEmpty(sortArray) &&
            sortArray?.map((value) => (
              <ListItem
                key={value.id}
                role="listitem"
                onClick={handleToggle(value)}
                sx={{ padding: 0 }}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': value?.id,
                    }}
                    disabled={isQueryEnabled}
                  />
                </ListItemIcon>
                <ListItemText
                  id={value?.id}
                  primary={value?.label}
                  sx={{ width: 200, fontStyle: value?.pmsDate ? 'inherit' : 'italic' }}
                />
              </ListItem>
            ))}
        </List>
      </Paper>
    );
  };

  // Return the JSX for the component
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center" wrap="nowrap">
      <Grid item>
        <TextField
          placeholder="Search"
          fullWidth
          sx={{ mb: 2 }}
          size="small"
          onChange={(event) => filterHotelList(event)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Search size={16} />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
        {customList(detailedBreakdown, 'Hotels')}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={detailedBreakdown?.length === 0 || isQueryEnabled}
            aria-label="move all left"
          >
            <KeyboardDoubleArrowRightIcon fontSize="small" />
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={middleChecked?.length === 0 || isQueryEnabled}
            aria-label="move selected left"
          >
            <KeyboardArrowRightIcon fontSize="small" />
          </Button>
          <br />
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedMiddleFromLeft}
            disabled={leftChecked?.length === 0 || isQueryEnabled}
            aria-label="move selected left"
          >
            <KeyboardArrowLeftIcon fontSize="small" />
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllMiddleFromLeft}
            disabled={groupList?.length === 0 || isQueryEnabled}
            aria-label="move all left"
          >
            <KeyboardDoubleArrowLeftIcon fontSize="small" />
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <TextField
          placeholder="Search"
          fullWidth
          size="small"
          sx={{ mb: 2 }}
          onChange={(event) => filterHotelSets(event)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Search size={16} />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
        {customList(groupList, LABELS.GROUP_HOTEL)}
      </Grid>
    </Grid>
  );
};
//
export default AggregationTransferList;
