/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectVisualFilters, selectEmbedToken } from 'modules/dashboard/selectors';
import { getSelectedBreakdown } from 'modules/dashboard/functions';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import { ExportVisualDataTS } from 'modules/dashboard/components/tab-container/export-visual-data';
import {
  selectTrendSegmentFilterList,
  selectIsCumulative,
  selectIsSegmentDetailed,
  selectTrendSegmentChartBreakdown,
  selectTrendSegmentFocusOn,
  selectTrendTimeWeeklyView,
  selectCxlIsDisplayTS,
  selectAvgLosIsDisplayTS,
  selectAvgBookingWindowIsDisplayTS,
  selectOccupiedRoomsIsDisplayTS,
  selectRevenueIsDisplayTS,
  selectAdrIsDisplayTS,
  selectTrendSegmentTabularVisuals,
  selectTrendSegmentCumulativeTabularVisuals,
  selectTrendSegmentWeeklyTabularVisuals,
  selectTrendSegmentPercentageTabularVisuals,
  selectIsPercentageChange,
  selectTrendHotelNormalizedView,
} from 'modules/dashboard/components/tab-container/trend-tabs/selectors';
import VisualGrid from 'modules/dashboard/components/tab-container/visual-grid';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import { findVisual } from 'modules/dashboard/components/tab-container/trend-tabs/functions';
import { REPORT_TYPE } from 'modules/dashboard/constants';
import VisualCard from 'modules/dashboard/components/tab-container/visual-card';
/**
 * Trend Segment tabular report with filters
 */
const TrendSegmentTabularWidget = () => {
  const dispatch = useDispatch();
  // States are obtained from redux store. Refer each selector function for details.
  const filterList = useSelector(selectTrendSegmentFilterList);
  const weeklyViewToggle = useSelector(selectTrendTimeWeeklyView);
  const isCumulative = useSelector(selectIsCumulative);
  const focusOn = useSelector(selectTrendSegmentFocusOn);
  const chartBreakdown = useSelector(selectTrendSegmentChartBreakdown);
  const isDetailed = useSelector(selectIsSegmentDetailed);
  const visualFilters = useSelector(selectVisualFilters);
  const visuals = useSelector(selectTrendSegmentTabularVisuals);
  const weeklyVisuals = useSelector(selectTrendSegmentWeeklyTabularVisuals);
  const cumulativeVisuals = useSelector(selectTrendSegmentCumulativeTabularVisuals);
  const tokenDetails = useSelector(selectEmbedToken);
  const revenueIsDisplay = useSelector(selectRevenueIsDisplayTS);
  const occupiedRoomsIsDisplay = useSelector(selectOccupiedRoomsIsDisplayTS);
  const adrIsDisplay = useSelector(selectAdrIsDisplayTS);
  const avgBookingWindowIsDisplay = useSelector(selectAvgBookingWindowIsDisplayTS);
  const avgLosIsDisplay = useSelector(selectAvgLosIsDisplayTS);
  const cxlIsDisplay = useSelector(selectCxlIsDisplayTS);
  const percentageVisuals = useSelector(selectTrendSegmentPercentageTabularVisuals);
  const isPercentageChange = useSelector(selectIsPercentageChange);
  const normalizedViewToggle = useSelector(selectTrendHotelNormalizedView);
  //
  const [totalRevenue, setTotalRevenue] = useState({});
  const [isTotalRevenueLoaded, setIsTotalRevenueLoaded] = useState(false);
  const [totalOccupiedRooms, setTotalOccupiedRooms] = useState({});
  const [isTotalOccupiedRoomsLoaded, setIsTotalOccupiedRoomsLoaded] = useState(false);
  const [adr, setAdr] = useState({});
  const [isAdrLoaded, setIsAdrLoaded] = useState(false);
  const [avgBookingWindow, setAvgBookingWindow] = useState({});
  const [isAvgBookingWindowLoaded, setIsAvgBookingWindowLoaded] = useState(false);
  const [avgLoS, setAvgLoS] = useState({});
  const [isAvgLoSLoaded, setIsAvgLoSLoaded] = useState(false);
  const [cancellations, setCancellations] = useState({});
  const [isCancellationsLoaded, setIsCancellationsLoaded] = useState(false);
  // To get visual details
  const getVisual = (index) => {
    let found;
    if (isCumulative) {
      found = findVisual(cumulativeVisuals, index, weeklyViewToggle, [
        REPORT_TYPE.WEEKLY_TREND_SEGMENT_TABULAR,
        REPORT_TYPE.TREND_SEGMENT_TABULAR,
      ]);
    } else if (weeklyViewToggle) {
      found = findVisual(weeklyVisuals, index, true, [
        REPORT_TYPE.TREND_SEGMENT_CUMULATIVE_TABULAR,
      ]);
    } else if (isPercentageChange && !weeklyViewToggle && !isCumulative && !normalizedViewToggle) {
      found = findVisual(
        percentageVisuals,
        index,
        true,
        [REPORT_TYPE.TREND_SEGMENT_PERCENTAGE_TABULAR],
        true
      );
    } else {
      found = findVisual(visuals, index, false, [REPORT_TYPE.TREND_SEGMENT_CUMULATIVE_TABULAR]);
    }
    return found;
  };
  // To get visuals based on the configuration
  const getWidget = (
    index,
    cssClassName,
    setPBIReport = () => {},
    setIsLoaded = () => {},
    filter = filterList
  ) => {
    const found = getVisual(index);
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={tokenDetails?.accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filter}
          setPBIReport={(r) => setPBIReport(r)}
          setIsLoaded={(d) => setIsLoaded(d)}
        />
      )
    );
  };
  // To get visual names based on visual order
  const getVisualName = (order) => getVisual(order)?.name;
  //
  const selectedBreakdown = getSelectedBreakdown(visualFilters, chartBreakdown, isDetailed);
  const breakdown = `Breakdown : , ${selectedBreakdown}\r\n`;
  const focus = focusOn ? focusOn?.map((item) => item?.label).toString() : '';
  const focusedBreakdown = `Focused Breakdown Segments: , "${focus.toString()}"\r\n`;
  //
  return (
    <>
      <VisualGrid
        isDisplay={revenueIsDisplay}
        visualName={getVisualName(0)}
        visual={getWidget(0, styles.paceVisual, setTotalRevenue, setIsTotalRevenueLoaded)}
        onClick={() => {
          dispatch(trendActions.setRevenueIsDisplayTS(!revenueIsDisplay));
        }}
        toggleFragment={
          <ExportVisualDataTS
            report={totalRevenue}
            isLoaded={isTotalRevenueLoaded}
            fileName={
              isPercentageChange
                ? 'TVS_Percentage_Total_Revenue'
                : isCumulative
                ? 'TVS_Cumulative_Total_Revenue'
                : weeklyViewToggle
                ? 'TVS_Weekly_Total_Revenue'
                : 'TVS_Total_Revenue'
            }
            otherFilters={breakdown.concat(focusedBreakdown)}
          />
        }
      />
      <VisualGrid
        isDisplay={occupiedRoomsIsDisplay}
        visualName={getVisualName(1)}
        visual={getWidget(
          1,
          styles.paceVisual,
          setTotalOccupiedRooms,
          setIsTotalOccupiedRoomsLoaded
        )}
        onClick={() => {
          dispatch(trendActions.setOccupiedRoomIsDisplayTS(!occupiedRoomsIsDisplay));
        }}
        toggleFragment={
          <ExportVisualDataTS
            report={totalOccupiedRooms}
            isLoaded={isTotalOccupiedRoomsLoaded}
            fileName={
              isPercentageChange
                ? 'TVS_Percentage_Total_Occupied_Rooms'
                : isCumulative
                ? 'TVS_Cumulative_Total_Occupied_Rooms'
                : weeklyViewToggle
                ? 'TVS_Weekly_Total_Occupied_Rooms'
                : 'TVS_Total_Occupied_Rooms'
            }
            otherFilters={breakdown.concat(focusedBreakdown)}
          />
        }
      />
      <VisualGrid
        isDisplay={adrIsDisplay}
        visualName={getVisualName(2)}
        visual={getWidget(2, styles.paceVisual, setAdr, setIsAdrLoaded)}
        onClick={() => {
          dispatch(trendActions.setAdrIsDisplayTS(!adrIsDisplay));
        }}
        toggleFragment={
          <ExportVisualDataTS
            report={adr}
            isLoaded={isAdrLoaded}
            fileName={
              isPercentageChange
                ? 'TVS_Percentage_Average_Daily_Rate'
                : weeklyViewToggle
                ? 'TVS_Weekly_Average_Daily_Rate'
                : 'TVS_Average_Daily_Rate'
            }
            weeklyViewToggle={weeklyViewToggle}
            isCumulative={isCumulative}
            otherFilters={breakdown.concat(focusedBreakdown)}
          />
        }
      />
      {getWidget(4, styles.paceVisual, setAvgBookingWindow, setIsAvgBookingWindowLoaded) && (
        <VisualGrid
          isDisplay={avgBookingWindowIsDisplay}
          visualName={getVisualName(4)}
          visual={getWidget(4, styles.paceVisual, setAvgBookingWindow, setIsAvgBookingWindowLoaded)}
          onClick={() => {
            dispatch(trendActions.setAvgBookingWindowIsDisplayTS(!avgBookingWindowIsDisplay));
          }}
          toggleFragment={
            <ExportVisualDataTS
              report={avgBookingWindow}
              isLoaded={isAvgBookingWindowLoaded}
              fileName={
                isPercentageChange
                  ? 'TVS_Percentage_Average_Booking_Window'
                  : weeklyViewToggle
                  ? 'TVS_Weekly_Average_Booking_Window'
                  : 'TVS_Average_Booking_Window'
              }
              weeklyViewToggle={weeklyViewToggle}
              isCumulative={isCumulative}
              otherFilters={breakdown.concat(focusedBreakdown)}
            />
          }
        />
      )}
      {getWidget(3, styles.paceVisual, setAvgLoS, setIsAvgLoSLoaded) && (
        <VisualGrid
          isDisplay={avgLosIsDisplay}
          visualName={getVisualName(3)}
          visual={getWidget(3, styles.paceVisual, setAvgLoS, setIsAvgLoSLoaded)}
          onClick={() => {
            dispatch(trendActions.setAvgLosIsDisplayTS(!avgLosIsDisplay));
          }}
          toggleFragment={
            <ExportVisualDataTS
              report={avgLoS}
              isLoaded={isAvgLoSLoaded}
              fileName={
                isPercentageChange
                  ? 'TVS_Percentage_Average_Length_of_Stay'
                  : weeklyViewToggle
                  ? 'TVS_Weekly_Average_Length_of_Stay'
                  : 'TVS_Average_Length_of_Stay'
              }
              weeklyViewToggle={weeklyViewToggle}
              isCumulative={isCumulative}
              otherFilters={breakdown.concat(focusedBreakdown)}
            />
          }
        />
      )}
      {getWidget(5, styles.paceVisual, setCancellations, setIsCancellationsLoaded) && (
        <VisualGrid
          isDisplay={cxlIsDisplay}
          visualName={getVisualName(5)}
          visual={getWidget(5, styles.paceVisual, setCancellations, setIsCancellationsLoaded)}
          onClick={() => {
            dispatch(trendActions.setCxlIsDisplayTS(!cxlIsDisplay));
          }}
          toggleFragment={
            <ExportVisualDataTS
              report={cancellations}
              isLoaded={isCancellationsLoaded}
              fileName={
                isPercentageChange
                  ? 'TVS_Percentage_Number_of_Cancellations'
                  : isCumulative
                  ? 'TVS_Cumulative_Number_of_Cancellations'
                  : weeklyViewToggle
                  ? 'TVS_Weekly_Number_of_Cancellations'
                  : 'TVS_Number_of_Cancellations'
              }
              weeklyViewToggle={weeklyViewToggle}
              isCumulative={isCumulative}
              otherFilters={breakdown.concat(focusedBreakdown)}
            />
          }
        />
      )}
    </>
  );
};
//
export default TrendSegmentTabularWidget;
