/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectTrendTimeFilterList,
  selectIsCumulative,
  selectTrendTimeWeeklyView,
  selectTrendSymmetricComparisonToggle,
  selectTrendTimeAlignmentToggle,
  selectRevenueIsDisplayTT,
  selectOccupiedRoomsIsDisplayTT,
  selectAdrIsDisplayTT,
  selectAvgBookingWindowIsDisplayTT,
  selectAvgLosIsDisplayTT,
  selectCxlIsDisplayTT,
  selectTrendTimeTabularVisuals,
  selectTrendTimeCumulativeTabularVisuals,
  selectTrendTimeWeeklyTabularVisuals,
  selectIsPercentageChange,
  selectTrendTimePercentageTabularVisuals,
  selectTrendHotelNormalizedView,
} from 'modules/dashboard/components/tab-container/trend-tabs/selectors';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import { ExportVisualDataTT } from 'modules/dashboard/components/tab-container/export-visual-data';
import { selectEmbedToken } from 'modules/dashboard/selectors';
import VisualGrid from 'modules/dashboard/components/tab-container/visual-grid';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import { REPORT_TYPE } from 'modules/dashboard/constants';
import { findVisual } from 'modules/dashboard/components/tab-container/trend-tabs/functions';
import VisualCard from 'modules/dashboard/components/tab-container/visual-card';
/**
 * Trend Time tabular visual report with filters
 */
const TrendTimeTabularWidget = () => {
  const dispatch = useDispatch();
  // States are obtained from redux store. Refer each selector function for details.
  const filterList = useSelector(selectTrendTimeFilterList);
  const weeklyViewToggle = useSelector(selectTrendTimeWeeklyView);
  const isCumulative = useSelector(selectIsCumulative);
  const alignmentToggle = useSelector(selectTrendTimeAlignmentToggle);
  const symmetricComparisonToggle = useSelector(selectTrendSymmetricComparisonToggle);
  const tokenDetails = useSelector(selectEmbedToken);
  const visuals = useSelector(selectTrendTimeTabularVisuals);
  const weeklyVisuals = useSelector(selectTrendTimeWeeklyTabularVisuals);
  const cumulativeVisuals = useSelector(selectTrendTimeCumulativeTabularVisuals);
  const revenueIsDisplay = useSelector(selectRevenueIsDisplayTT);
  const occupiedRoomsIsDisplay = useSelector(selectOccupiedRoomsIsDisplayTT);
  const adrIsDisplay = useSelector(selectAdrIsDisplayTT);
  const avgBookingWindowIsDisplay = useSelector(selectAvgBookingWindowIsDisplayTT);
  const avgLosIsDisplay = useSelector(selectAvgLosIsDisplayTT);
  const cxlIsDisplay = useSelector(selectCxlIsDisplayTT);
  const percentageVisuals = useSelector(selectTrendTimePercentageTabularVisuals);
  const isPercentageChange = useSelector(selectIsPercentageChange);
  const normalizedViewToggle = useSelector(selectTrendHotelNormalizedView);
  //
  const [totalRevenue, setTotalRevenue] = useState({});
  const [isTotalRevenueLoaded, setIsTotalRevenueLoaded] = useState(false);
  const [totalOccupiedRooms, setTotalOccupiedRooms] = useState({});
  const [isTotalOccupiedRoomsLoaded, setIsTotalOccupiedRoomsLoaded] = useState(false);
  const [adr, setAdr] = useState({});
  const [isAdrLoaded, setIsAdrLoaded] = useState(false);
  const [avgBookingWindow, setAvgBookingWindow] = useState({});
  const [isAvgBookingWindowLoaded, setIsAvgBookingWindowLoaded] = useState(false);
  const [cancellations, setCancellations] = useState({});
  const [isCancellationsLoaded, setIsCancellationsLoaded] = useState(false);
  const [avgLoS, setAvgLoS] = useState({});
  const [isAvgLoSLoaded, setIsAvgLoSLoaded] = useState(false);
  //
  const trendFilters = `Alignment : , ${
    alignmentToggle ? 'On' : 'Off'
  }\r\nSymmetric Comparison : , ${symmetricComparisonToggle ? 'On' : 'Off'}\r\n`;
  // To get visual details
  const getVisual = (index) => {
    let found;
    if (isCumulative) {
      found = findVisual(cumulativeVisuals, index, weeklyViewToggle, [
        REPORT_TYPE.WEEKLY_TREND_TIME_TABULAR,
        REPORT_TYPE.TREND_TIME_TABULAR,
      ]);
    } else if (weeklyViewToggle) {
      found = findVisual(weeklyVisuals, index, true, [REPORT_TYPE.TREND_TIME_CUMULATIVE_TABULAR]);
    } else if (isPercentageChange && !weeklyViewToggle && !isCumulative && !normalizedViewToggle) {
      found = findVisual(
        percentageVisuals,
        index,
        true,
        [REPORT_TYPE.TREND_TIME_PERCENTAGE_TABULAR],
        true
      );
    } else {
      found = findVisual(visuals, index, false, [REPORT_TYPE.TREND_TIME_CUMULATIVE_TABULAR]);
    }
    return found;
  };
  // To get visuals based on the configuration
  const getWidget = (
    index,
    cssClassName,
    setPBIReport = () => {},
    setIsLoaded = () => {},
    filter = filterList
  ) => {
    const found = getVisual(index);
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={tokenDetails?.accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filter}
          setPBIReport={(r) => setPBIReport(r)}
          setIsLoaded={(d) => setIsLoaded(d)}
        />
      )
    );
  };
  // To get visual names based on visual order
  const getVisualName = (order) => getVisual(order)?.name;
  //
  return (
    <>
      <VisualGrid
        isDisplay={revenueIsDisplay}
        visualName={getVisualName(0)}
        visual={getWidget(0, styles.paceVisual, setTotalRevenue, setIsTotalRevenueLoaded)}
        onClick={() => {
          dispatch(trendActions.setRevenueIsDisplayTT(!revenueIsDisplay));
        }}
        toggleFragment={
          <ExportVisualDataTT
            report={totalRevenue}
            isLoaded={isTotalRevenueLoaded}
            fileName={
              isPercentageChange
                ? 'TVT_Percentage_Total_Revenue'
                : isCumulative
                ? 'TVT_Cumulative_Total_Revenue'
                : weeklyViewToggle
                ? 'TVT_Weekly_Total_Revenue'
                : 'TVT_Total_Revenue'
            }
            weeklyViewToggle={weeklyViewToggle}
            isCumulative={isCumulative}
            otherFilters={trendFilters}
          />
        }
      />
      <VisualGrid
        isDisplay={occupiedRoomsIsDisplay}
        visualName={getVisualName(1)}
        visual={getWidget(
          1,
          styles.paceVisual,
          setTotalOccupiedRooms,
          setIsTotalOccupiedRoomsLoaded
        )}
        onClick={() => {
          dispatch(trendActions.setOccupiedRoomIsDisplayTT(!occupiedRoomsIsDisplay));
        }}
        toggleFragment={
          <ExportVisualDataTT
            report={totalOccupiedRooms}
            isLoaded={isTotalOccupiedRoomsLoaded}
            fileName={
              isPercentageChange
                ? 'TVT_Percentage_Total_Occupied_Rooms'
                : isCumulative
                ? 'TVT_Cumulative_Total_Occupied_Rooms'
                : weeklyViewToggle
                ? 'TVT_Weekly_Total_Occupied_Rooms'
                : 'TVT_Total_Occupied_Rooms'
            }
            weeklyViewToggle={weeklyViewToggle}
            isCumulative={isCumulative}
            otherFilters={trendFilters}
          />
        }
      />
      <VisualGrid
        isDisplay={adrIsDisplay}
        visualName={getVisualName(2)}
        visual={getWidget(2, styles.paceVisual, setAdr, setIsAdrLoaded)}
        onClick={() => {
          dispatch(trendActions.setAdrIsDisplayTT(!adrIsDisplay));
        }}
        toggleFragment={
          <ExportVisualDataTT
            report={adr}
            isLoaded={isAdrLoaded}
            fileName={
              isPercentageChange
                ? 'TVT_Percentage_Average_Daily_Rate'
                : weeklyViewToggle
                ? 'TVT_Weekly_Average_Daily_Rate'
                : 'TVT_Average_Daily_Rate'
            }
            weeklyViewToggle={weeklyViewToggle}
            isCumulative={isCumulative}
            otherFilters={trendFilters}
          />
        }
      />
      {getWidget(3, styles.paceVisual, setAvgBookingWindow, setIsAvgBookingWindowLoaded) && (
        <VisualGrid
          isDisplay={avgBookingWindowIsDisplay}
          visualName={getVisualName(3)}
          visual={getWidget(3, styles.paceVisual, setAvgBookingWindow, setIsAvgBookingWindowLoaded)}
          onClick={() => {
            dispatch(trendActions.setAvgBookingWindowIsDisplayTT(!avgBookingWindowIsDisplay));
          }}
          toggleFragment={
            <ExportVisualDataTT
              report={avgBookingWindow}
              isLoaded={isAvgBookingWindowLoaded}
              fileName={
                isPercentageChange
                  ? 'TVT_Percentage_Average_Booking_Window'
                  : weeklyViewToggle
                  ? 'TVT_Weekly_Average_Booking_Window'
                  : 'TVT_Average_Booking_Window'
              }
              weeklyViewToggle={weeklyViewToggle}
              isCumulative={isCumulative}
              otherFilters={trendFilters}
            />
          }
        />
      )}
      {getWidget(4, styles.paceVisual, setAvgLoS, setIsAvgLoSLoaded) && (
        <VisualGrid
          isDisplay={avgLosIsDisplay}
          visualName={getVisualName(4)}
          visual={getWidget(4, styles.paceVisual, setAvgLoS, setIsAvgLoSLoaded)}
          onClick={() => {
            dispatch(trendActions.setAvgLosIsDisplayTT(!avgLosIsDisplay));
          }}
          toggleFragment={
            <ExportVisualDataTT
              report={avgLoS}
              isLoaded={isAvgLoSLoaded}
              fileName={
                isPercentageChange
                  ? 'TVT_Percentage_Average_Length_of_Stay'
                  : weeklyViewToggle
                  ? 'TVT_Weekly_Average_Length_of_Stay'
                  : 'TVT_Average_Length_of_Stay'
              }
              weeklyViewToggle={weeklyViewToggle}
              isCumulative={isCumulative}
              otherFilters={trendFilters}
            />
          }
        />
      )}
      {getWidget(5, styles.paceVisual, setCancellations, setIsCancellationsLoaded) && (
        <VisualGrid
          isDisplay={cxlIsDisplay}
          visualName={getVisualName(5)}
          visual={getWidget(5, styles.paceVisual, setCancellations, setIsCancellationsLoaded)}
          onClick={() => {
            dispatch(trendActions.setCxlIsDisplayTT(!cxlIsDisplay));
          }}
          toggleFragment={
            <ExportVisualDataTT
              report={cancellations}
              isLoaded={isCancellationsLoaded}
              fileName={
                isPercentageChange
                  ? 'TVT_Percentage_Number_of_Cancellations'
                  : isCumulative
                  ? 'TVT_Cumulative_Number_of_Cancellations'
                  : weeklyViewToggle
                  ? 'TVT_Weekly_Number_of_Cancellations'
                  : 'TVT_Number_of_Cancellations'
              }
              weeklyViewToggle={weeklyViewToggle}
              isCumulative={isCumulative}
              otherFilters={trendFilters}
            />
          }
        />
      )}
    </>
  );
};
//
export default TrendTimeTabularWidget;
