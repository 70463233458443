import { useEffect, useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { TAB_VALUE } from 'modules/dashboard/constants';
import styles from 'modules/dashboard/components/tab-container/workbook-report/workbook-report.module.scss';
import VisualCard from 'modules/dashboard/components/tab-container/visual-card';
import GetAppIcon from '@mui/icons-material/GetApp';
import { getVisualName } from 'modules/dashboard/functions';
import { selectOrganizationId } from 'modules/common/auth/selectors';
import { Loader } from 'modules/common/components';
import { isEmpty } from 'modules/common/helpers/object';
import generateFilterString from 'modules/dashboard/utils/filters-transformation';
import { toast } from 'react-toastify';
import TOAST_TYPES from 'modules/common/constants/toast-types';
import WorkBookFilter from '../filter-panel';
import useWorkbookWidget from '../../hooks/use-workbook-widget';
import {
  selectPdfExportedObject,
  selectWorkbookFilterList,
  selectLoader,
  selectD1SetIsDisplay,
  selectD2SetIsDisplay,
  selectD3SetIsDisplay,
} from '../../selectors';
import { workbookActions } from '../../slice';
import VisualGridWorkbook from '../../../visual-grid-workbook';
import { NotificationWindow } from './components';
/**
 * Workbook widget report with filters
 * @param {Number} value - Workbook widget tab value
 * @param {String} accessToken - Power BI access token
 * @param {Object} visuals - Workbook tab visual details
 * @param {Boolean} active - whether Workbook tab is active
 * @param {Date} latestDate - Latest booking date of selected hotel
 * @param {String} hotelTimezone - Timezone of selected hotel
 * @param {String} reportType - Report type of active tab
 * @returns
 */

const WorkbookReport = ({
  value,
  accessToken,
  visuals,
  active,
  latestDate,
  reportType,
  hotelTimezone,
  toggleDisabled,
}) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(selectOrganizationId);
  const exportPdfDetails = useSelector(selectPdfExportedObject);
  const loading = useSelector(selectLoader);
  //
  useWorkbookWidget();
  // States are obtained from redux store. Refer each selector function for details.
  const workbookFilterList = useSelector(selectWorkbookFilterList);
  const d1SetIsDisplay = useSelector(selectD1SetIsDisplay);
  const d2SetIsDisplay = useSelector(selectD2SetIsDisplay);
  const d3SetIsDisplay = useSelector(selectD3SetIsDisplay);

  // Local states to set Workbook visual loading states
  const [isD1Loaded, setIsD1Loaded] = useState(false);
  const [isD2Loaded, setIsD2Loaded] = useState(false);
  const [isD3Loaded, setIsD3Loaded] = useState(false);
  const [d1, setD1] = useState({});
  // To get visuals based on the configuration
  const getWidget = (
    index,
    cssClassName,
    setPBIReport = () => {},
    setIsLoaded = () => {},
    filter = workbookFilterList
  ) => {
    const found = visuals?.find((visual) => visual?.order === index);
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filter}
          setPBIReport={(r) => setPBIReport(r)}
          setIsLoaded={(d) => setIsLoaded(d)}
        />
      )
    );
  };
  //
  const exportReport = async () => {
    // Export the captured bookmark to PDF
    const capturedBookmark = await d1.bookmarksManager.capture();
    //
    const settings = {
      format: 'PDF',
      powerBIReportConfiguration: {
        defaultBookmark: {
          state: capturedBookmark.state,
        },
        pages: [{ pageName: 'ReportSection3a09013add0a30042130' }],
        reportLevelFilters: [
          {
            filter: generateFilterString(workbookFilterList),
          },
        ],
      },
    };
    //
    dispatch(
      workbookActions.getWorkbookPdfExportUrl({
        settings,
        organizationId,
        reportId: d1.config.id,
        groupId: d1.config.groupId,
      })
    );
  };
  //
  useEffect(() => {
    if (exportPdfDetails && !isEmpty(d1)) {
      toast(exportPdfDetails?.message, { type: TOAST_TYPES.SUCCESS });
    }
  }, [exportPdfDetails]);
  //
  return (
    active && (
      <Loader loading={loading}>
        <Grid
          container
          direction="row"
          className={styles.mainGrid}
          sx={{
            display: value === TAB_VALUE.WORKBOOK_REPORT ? '' : 'none',
          }}
        >
          <Grid item xs={2}>
            <WorkBookFilter
              reportType={reportType}
              visual={getWidget(100, styles.symmetricComparisonWindow)}
              visualName={getVisualName(visuals, 100)}
              latestDate={latestDate}
              hotelTimezone={hotelTimezone}
              isLoaded={isD1Loaded && isD2Loaded && isD3Loaded}
              toggleDisabled={toggleDisabled}
            />
          </Grid>
          <Grid
            container
            item
            xs={10}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid mb={2} container item justifyContent="flex-end" alignItems="center">
              {isD1Loaded && isD2Loaded && isD3Loaded && (
                <IconButton
                  size="small"
                  onClick={exportReport}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      width: '1.5rem',
                      height: '1.25rem',
                      marginRight: 2,
                    },
                  }}
                >
                  <GetAppIcon />
                </IconButton>
              )}
              <NotificationWindow />
              {/*  */}
            </Grid>
            <Box sx={{ flexGrow: 1 }}>
              <VisualGridWorkbook
                isDisplay={d1SetIsDisplay}
                order={0}
                visualsList={visuals}
                visual={getWidget(0, styles.workbookReportD1, setD1, setIsD1Loaded)}
                onClick={() => {
                  dispatch(workbookActions.setD1SetIsDisplay(!d1SetIsDisplay));
                }}
              />
              <VisualGridWorkbook
                isDisplay={d3SetIsDisplay}
                order={2}
                visualsList={visuals}
                visual={getWidget(2, styles.workbookReportD3, () => {}, setIsD3Loaded)}
                onClick={() => {
                  dispatch(workbookActions.setD3SetIsDisplay(!d3SetIsDisplay));
                }}
              />
              <VisualGridWorkbook
                isDisplay={d2SetIsDisplay}
                order={1}
                visualsList={visuals}
                visual={getWidget(1, styles.workbookReportD2, () => {}, setIsD2Loaded)}
                onClick={() => {
                  dispatch(workbookActions.setD2SetIsDisplay(!d2SetIsDisplay));
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Loader>
    )
  );
};
//
export default WorkbookReport;
