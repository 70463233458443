import { createSlice } from '@reduxjs/toolkit';
import { signInActions } from 'modules/sign-in/slice';
import { ALIGNED, BOOKING_DATE, SLICE, TAGS } from 'modules/dashboard/constants';
import { dashboardActions } from 'modules/dashboard/slice';
import { capitalizeFirst } from 'modules/common/helpers/strings';
//
const bookingStartDate = new Date(BOOKING_DATE).toISOString();
const stayDate = {
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
  key: 'selection',
};
/**
 * Initial states of Workbook widget functions are defined here
 */
export const initialState = {
  loading: false,
  workbookBookingDate: {
    startDate: new Date(bookingStartDate).toISOString(),
    endDate: new Date().toISOString(),
    key: 'selection',
  },
  dateComparison: null,
  workbookFilterList: [],
  workbookAlignmentToggle: null,
  workbookOtb: false,
  workbookCustomDate: 'start',
  workbookStartDate: stayDate.startDate,
  workbookEndDate: stayDate.endDate,
  workbookDateSelected: stayDate.startDate,
  workbookVisualToggle: false,
  workbookUrl: '',
  symmetricComparisonToggle: true,
  alignment: null,
  exportData: null,
  symmetricComparisonIsDisplay: false,
  d1SetIsDisplay: true,
  d2SetIsDisplay: true,
  d3SetIsDisplay: true,
};
/**
 * All actions related to summary widget feature are defined here
 */
export const workbookSlice = createSlice({
  name: SLICE.WORKBOOK_REPORT,
  initialState,
  reducers: {
    setWorkbookBookingDate(state, action) {
      state.workbookBookingDate = action?.payload;
    },
    setBreakdown(state, action) {
      state.chartBreakdown = action?.payload;
    },
    setDateComparison(state, action) {
      state.dateComparison = action?.payload;
    },
    updateWorkbookFilterList(state, action) {
      state.workbookFilterList = action?.payload;
    },
    setWorkbookAlignmentToggle(state, action) {
      state.workbookAlignmentToggle = action?.payload;
    },
    setWorkbookOtb(state, action) {
      state.workbookOtb = action?.payload;
    },
    setWorkbookCustomDate(state, action) {
      state.workbookCustomDate = action?.payload;
    },
    setWorkbookStartDate(state, action) {
      state.workbookStartDate = action?.payload;
    },
    setWorkbookEndDate(state, action) {
      state.workbookEndDate = action?.payload;
    },
    setWorkbookDateSelected(state, action) {
      state.workbookDateSelected = action?.payload;
    },
    setWorkbookVisualToggle(state, action) {
      state.workbookVisualToggle = action.payload;
    },
    setSymmetricComparisonToggle(state, action) {
      state.symmetricComparisonToggle = action?.payload;
    },
    setIsDetailed(state, action) {
      state.isDetailed = action?.payload;
    },
    setPaceVisualFilterList(state, action) {
      state.paceVisualFilterList = action?.payload;
    },
    setAlignment(state, action) {
      state.alignment = action?.payload;
    },
    getWorkbookPdfExportUrl(state) {
      state.loading = true;
    },
    getWorkbookPdfExportUrlSucceeded(state, action) {
      state.exportData = action?.payload;
      state.loading = false;
    },
    getWorkbookPdfExportUrlFailed(state) {
      state.loading = false;
    },
    setWorkbookUrl(state, action) {
      state.workbookUrl = action?.payload;
    },
    setPdfContent(state, action) {
      state.pdf = action.payload;
      state.workbookUrl = action?.payload;
    },
    setSymmetricComparisonIsDisplay(state, action) {
      state.symmetricComparisonIsDisplay = action?.payload;
    },
    setD1SetIsDisplay(state, action) {
      state.d1SetIsDisplay = action?.payload;
    },
    setD2SetIsDisplay(state, action) {
      state.d2SetIsDisplay = action?.payload;
    },
    setD3SetIsDisplay(state, action) {
      state.d3SetIsDisplay = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInActions.signIn, (state) => ({
        ...state,
        ...initialState,
      }))
      .addCase(dashboardActions.getVisualFiltersSucceeded, (state, action) => {
        const dateComparisonFilter = action?.payload?.results?.filter(
          (filter) => filter?.tags?.includes(TAGS.DATE_COMPARISON) && filter?.isDefault === true
        )[0];
        return {
          ...state,
          dateComparison: dateComparisonFilter?.values?.find((filter) => filter.isDefault === true)
            ?.value,
          alignment: {
            id: dateComparisonFilter?.id,
            label: capitalizeFirst(dateComparisonFilter?.type),
          },
          workbookAlignmentToggle: dateComparisonFilter?.type === ALIGNED,
        };
      });
  },
});
//
export const { actions: workbookActions } = workbookSlice;
