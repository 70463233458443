import { Stack, IconButton, Grid, Tooltip } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardActions } from 'modules/dashboard/slice';
import { plotPaceActions } from 'modules/dashboard/components/tab-container/3d-pace-tab/slice';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import { summaryActions } from 'modules/dashboard/components/tab-container/summary-tab/slice';
import { ChevronsLeft, ChevronsRight, ChevronLeft, ChevronRight } from 'react-feather';
import { StayDateRangePicker } from 'modules/common/components';
import { ENVIRONMENT } from 'config';
import { selectTargetDate, selectTrendTargetDate } from 'modules/dashboard/selectors';
import {
  selectTrendTargetDate as selectTrendTabularTargetDate,
  selectTrendTimeDateSelected as selectTabularDateSelected,
  selectTrendTimeCustomDate as selectTabularCustomDate,
  selectTrendTimeStartDate as selectTabularStartDate,
  selectTrendTimeEndDate as selectTabularEndDate,
} from 'modules/dashboard/components/tab-container/trend-tabular-tab/selectors';
import {
  selectSummaryDateSelected,
  selectSummaryCustomDate,
  selectSummaryStartDate,
  selectSummaryEndDate,
} from 'modules/dashboard/components/tab-container/summary-tab/selectors';
import {
  selectTrendTimeDateSelected,
  selectTrendTimeCustomDate,
  selectTrendTimeStartDate,
  selectTrendTimeEndDate,
} from 'modules/dashboard/components/tab-container/trend-tabs/selectors';
import { selectPlotTargetDate } from 'modules/dashboard/components/tab-container/3d-pace-tab/selectors';
import {
  selectWebClickTargetDate,
  selectWebClickStartDate,
  selectWebClickEndDate,
  selectWebClickDateSelected,
  selectWebClickCustomDate,
} from 'modules/dashboard/components/tab-container/web-click-tab/selectors';
import {
  selectIPreferCustomDate,
  selectIPreferStartDate,
  selectIPreferEndDate,
  selectIPreferDateSelected,
  selectIPreferTargetDate,
} from 'modules/dashboard/components/tab-container/i-prefer-tab/selectors';
import {
  formatDateLabel,
  getComparisonDateRange,
  getNextMonthStayDate,
  getNextPeriodStayDate,
  getPreviousMonthStayDate,
  getPreviousPeriodStayDate,
  shortcut,
} from 'modules/dashboard/functions';
import {
  differenceInDays,
  addDays,
  subDays,
  addSeconds,
  differenceInMonths,
  parse,
  startOfWeek,
  endOfWeek,
  endOfDay,
  startOfYear,
  endOfYear,
  subYears,
  startOfMonth,
  endOfMonth,
  subMonths,
  isValid,
  startOfDay,
} from 'date-fns';
import { STAY_DATE_END_DATE, STAY_DATE_START_DATE } from 'modules/common/constants/date-range';
import { REPORT_TYPE, SHORTCUT_KEY } from 'modules/dashboard/constants';
import { UiController, commands, events } from 'modules/common/ui-controller';
import styles from 'modules/dashboard/components/tab-container/inner-filter/inner-filter.module.scss';
import { webClickActions } from 'modules/dashboard/components/tab-container/web-click-tab/slice';
import { iPreferActions } from 'modules/dashboard/components/tab-container/i-prefer-tab/slice';
import { workbookActions } from '../tab-container/workbook-report/slice';
import {
  selectWorkbookCustomDate,
  selectWorkbookDateSelected,
} from '../tab-container/workbook-report/selectors';
import { trendTabularTabActions } from '../tab-container/trend-tabular-tab/slice';

/**
 * Stay date-range picker for each widget with scrollable date navigation
 * @param {String} reportType - Report type of  active tab
 * @param {String} adornment - Text to add as start adornment in date picker textfield
 * @param {String} label - Label for in date picker textfield
 * @returns
 */
const DashboardHeader = ({ reportType, adornment, label }) => {
  const dispatch = useDispatch();

  // Current date values for each tab is selected here. Refer each selector function for details
  const targetDate = useSelector(selectTargetDate);
  const trendTargetDate = useSelector(selectTrendTargetDate);
  const trendTabularTargetDate = useSelector(selectTrendTabularTargetDate);
  const plotTargetDate = useSelector(selectPlotTargetDate);
  const summarySelectedDate = useSelector(selectSummaryDateSelected);
  const summaryCustomDate = useSelector(selectSummaryCustomDate);
  const workbookSelectedDate = useSelector(selectWorkbookDateSelected);
  const workbookCustomDate = useSelector(selectWorkbookCustomDate);
  const trendTimeSelectedDate = useSelector(selectTrendTimeDateSelected);
  const trendTimeCustomDate = useSelector(selectTrendTimeCustomDate);
  const summaryStartDate = useSelector(selectSummaryStartDate);
  const summaryEndDate = useSelector(selectSummaryEndDate);
  const trendTimeStartDate = useSelector(selectTrendTimeStartDate);
  const trendTimeEndDate = useSelector(selectTrendTimeEndDate);
  const webClickTargetDate = useSelector(selectWebClickTargetDate);
  const webClickStartDate = useSelector(selectWebClickStartDate);
  const webClickEndDate = useSelector(selectWebClickEndDate);
  const webClickSelectedDate = useSelector(selectWebClickDateSelected);
  const webClickCustomDate = useSelector(selectWebClickCustomDate);
  const iPreferTargetDate = useSelector(selectIPreferTargetDate);
  const iPreferDateSelected = useSelector(selectIPreferDateSelected);
  const iPreferCustomDate = useSelector(selectIPreferCustomDate);
  const iPreferStartDate = useSelector(selectIPreferStartDate);
  const iPreferEndDate = useSelector(selectIPreferEndDate);
  const trendTimeTabularSelectedDate = useSelector(selectTabularDateSelected);
  const trendTimeTabularCustomDate = useSelector(selectTabularCustomDate);
  const trendTimeTabularStartDate = useSelector(selectTabularStartDate);
  const trendTimeTabularEndDate = useSelector(selectTabularEndDate);

  // References for scroll navigation buttons to execute ui control flow
  const prevRangeRef = useRef(null);
  const prevMonthRef = useRef(null);
  const nextRangeRef = useRef(null);
  const nextMonthRef = useRef(null);

  // Local states to maintain selected date values and to disable scroll navigation icons are defined here
  const [dates, setDates] = useState(targetDate);
  const [triggerTime, setTriggerTime] = useState();
  const [disableGetPreviousMonth, setDisableGetPreviousMonth] = useState(false);
  const [disableGetNextMonth, setDisableGetNextMonth] = useState(false);
  const [disableGetPreviousPeriod, setDisableGetPreviousPeriod] = useState(false);
  const [disableGetNextPeriod, setDisableGetNextPeriod] = useState(false);
  //
  const triggerTimeRef = useRef(triggerTime);
  const datesRef = useRef(dates);
  triggerTimeRef.current = triggerTime;
  datesRef.current = dates;

  // Triggered when selected report type changes
  useEffect(() => {
    // Set local state of selected stay date based on the report type.
    // Eg: 'targetDate' is set as  'dates' for summary tab
    switch (reportType) {
      case REPORT_TYPE.SUMMARY:
      case REPORT_TYPE.WORKBOOK_REPORT:
        setDates(targetDate);
        break;
      case REPORT_TYPE.TREND_TIME:
        setDates(trendTargetDate);
        break;
      case REPORT_TYPE.TREND_TABULAR:
        setDates(trendTabularTargetDate);
        break;
      case REPORT_TYPE.PACE_3D:
        setDates(plotTargetDate);
        break;
      case REPORT_TYPE.REGION_MAP:
        setDates(targetDate);
        break;
      case REPORT_TYPE.PACE:
      case REPORT_TYPE.PACE_TIME_WIDGET:
      case REPORT_TYPE.PACE_SEGMENT_WIDGET:
        setDates(targetDate);
        break;
      case REPORT_TYPE.WEB_CLICK:
        setDates(webClickTargetDate);
        break;
      case REPORT_TYPE.I_PREFER:
        setDates(iPreferTargetDate);
        break;
      default:
        break;
    }
  }, [reportType]);

  // Update global states of stay dates and comparison dates based on the report type to trigger Power BI filter
  const updateStore = (item, comparisonStartDate = null, comparisonEndDate = null) => {
    const stayDate = {
      key: 'selection',
      startDate: new Date(item?.selection.startDate).toISOString(),
      endDate: new Date(item?.selection.endDate).toISOString(),
    };
    // Dates are set using respective dashboard actions based on the active report type.
    // Eg: setTargetDate, setSummaryStartDate and setSummaryEndDate are used to update date values for summary tab
    switch (reportType) {
      case REPORT_TYPE.SUMMARY:
        dispatch(dashboardActions.setTargetDate(stayDate));
        dispatch(summaryActions.setSummaryStartDate(comparisonStartDate.toISOString()));
        dispatch(summaryActions.setSummaryEndDate(comparisonEndDate.toISOString()));
        break;
      case REPORT_TYPE.WORKBOOK_REPORT:
        dispatch(dashboardActions.setTargetDate(stayDate));
        dispatch(workbookActions.setWorkbookStartDate(comparisonStartDate.toISOString()));
        dispatch(workbookActions.setWorkbookEndDate(comparisonEndDate.toISOString()));
        break;
      case REPORT_TYPE.TREND_TIME:
        dispatch(dashboardActions.setTrendTargetDate(stayDate));
        dispatch(trendActions.setTrendTimeStartDate(comparisonStartDate.toISOString()));
        dispatch(trendActions.setTrendTimeEndDate(comparisonEndDate.toISOString()));
        break;
      case REPORT_TYPE.TREND_TABULAR:
        dispatch(trendTabularTabActions.setTrendTargetDate(stayDate));
        dispatch(trendTabularTabActions.setTrendTimeStartDate(comparisonStartDate.toISOString()));
        dispatch(trendTabularTabActions.setTrendTimeEndDate(comparisonEndDate.toISOString()));
        break;
      case REPORT_TYPE.PACE_3D:
        dispatch(plotPaceActions.setPlotTargetDate(stayDate));
        break;
      case REPORT_TYPE.REGION_MAP:
        dispatch(dashboardActions.setTargetDate(stayDate));
        break;
      case REPORT_TYPE.PACE:
      case REPORT_TYPE.PACE_TIME_WIDGET:
      case REPORT_TYPE.PACE_SEGMENT_WIDGET:
        dispatch(dashboardActions.setTargetDate(stayDate));
        break;
      case REPORT_TYPE.WEB_CLICK:
        dispatch(webClickActions.setWebClickTargetDate(stayDate));
        dispatch(webClickActions.setWebClickStartDate(comparisonStartDate.toISOString()));
        dispatch(webClickActions.setWebClickEndDate(comparisonEndDate.toISOString()));
        break;
      case REPORT_TYPE.I_PREFER:
        dispatch(iPreferActions.setIPreferTargetDate(stayDate));
        dispatch(iPreferActions.setIPreferStartDate(comparisonStartDate.toISOString()));
        dispatch(iPreferActions.setIPreferEndDate(comparisonEndDate.toISOString()));
        break;
      default:
        break;
    }
  };
  //
  const handleChange = (item, fromDatePicker = true, comparisonItem = null) => {
    setDates((prev) => ({
      ...prev,
      startDate: item?.selection.startDate,
      endDate: item?.selection.endDate,
    }));
    // Apply power bi filters directly if the change triggered from the date picker
    if (fromDatePicker) {
      let selectedDate;
      let customDate;
      if (reportType === REPORT_TYPE.SUMMARY) {
        selectedDate = summarySelectedDate;
        customDate = summaryCustomDate;
      } else if (reportType === REPORT_TYPE.WORKBOOK_REPORT) {
        selectedDate = workbookSelectedDate;
        customDate = workbookCustomDate;
      } else if (reportType === REPORT_TYPE.TREND_TIME) {
        selectedDate = trendTimeSelectedDate;
        customDate = trendTimeCustomDate;
      } else if (reportType === REPORT_TYPE.TREND_TABULAR) {
        selectedDate = trendTimeTabularSelectedDate;
        customDate = trendTimeTabularCustomDate;
      } else if (reportType === REPORT_TYPE.WEB_CLICK) {
        selectedDate = webClickSelectedDate;
        customDate = webClickCustomDate;
      } else if (reportType === REPORT_TYPE.I_PREFER) {
        selectedDate = iPreferDateSelected;
        customDate = iPreferCustomDate;
      }
      // Calculate comparison dates based on selected stay date gap
      const dateRange = getComparisonDateRange(
        selectedDate,
        item?.selection.startDate,
        item?.selection.endDate,
        customDate
      );
      updateStore(item, dateRange?.comparisonStartDate, dateRange?.comparisonEndDate);
    } else {
      // Implemented 2s delay in applying Power BI filter for the date change triggered through scroll navigation
      setTimeout(() => {
        const currentTime = new Date();
        if (currentTime.getTime() - triggerTimeRef.current.getTime() > 999) {
          updateStore(
            {
              selection: {
                startDate: datesRef.current?.startDate,
                endDate: datesRef.current?.endDate,
                key: 'selection',
              },
            },
            comparisonItem?.startDate,
            comparisonItem?.endDate
          );
        }
      }, Number(ENVIRONMENT.TIMEOUT_DELAY));
    }
  };
  // Get date range based on report type
  const dateRange = () => {
    switch (reportType) {
      case REPORT_TYPE.SUMMARY:
      case REPORT_TYPE.WORKBOOK_REPORT:
        return [targetDate];
      case REPORT_TYPE.TREND_TIME:
        return [trendTargetDate];
      case REPORT_TYPE.TREND_TABULAR:
        return [trendTabularTargetDate];
      case REPORT_TYPE.PACE_3D:
        return [plotTargetDate];
      case REPORT_TYPE.REGION_MAP:
        return [targetDate];
      case REPORT_TYPE.PACE:
      case REPORT_TYPE.PACE_TIME_WIDGET:
      case REPORT_TYPE.PACE_SEGMENT_WIDGET:
        return [targetDate];
      case REPORT_TYPE.WEB_CLICK:
        return [webClickTargetDate];
      case REPORT_TYPE.I_PREFER:
        return [iPreferTargetDate];
      default:
        return null;
    }
  };

  // Move back by 1 month scroll navigation implementation
  const getPreviousMonth = () => {
    const newDates = getPreviousMonthStayDate(dates.startDate, dates.endDate);
    let newComparisonDates;
    // Date values are set depending on the active report type
    if (reportType === REPORT_TYPE.SUMMARY) {
      newComparisonDates = getPreviousMonthStayDate(summaryStartDate, summaryEndDate);
    } else if (reportType === REPORT_TYPE.TREND_TIME) {
      newComparisonDates = getPreviousMonthStayDate(trendTimeStartDate, trendTimeEndDate);
    } else if (reportType === REPORT_TYPE.TREND_TABULAR) {
      newComparisonDates = getPreviousMonthStayDate(
        trendTimeTabularStartDate,
        trendTimeTabularEndDate
      );
    } else if (reportType === REPORT_TYPE.WEB_CLICK) {
      newComparisonDates = getPreviousMonthStayDate(webClickStartDate, webClickEndDate);
    } else if (reportType === REPORT_TYPE.I_PREFER) {
      newComparisonDates = getPreviousMonthStayDate(iPreferStartDate, iPreferEndDate);
    }
    //
    setDates((prev) => ({
      ...prev,
      startDate: newDates.newStartDate,
      endDate: newDates.newEndDate,
    }));
    const item = {
      selection: {
        startDate: newDates.newStartDate,
        endDate: newDates.newEndDate,
        key: 'selection',
      },
    };
    // Set comparison date for summary, trend time and web click tabs
    let comparisonItem = null;
    if (
      reportType === REPORT_TYPE.SUMMARY ||
      reportType === REPORT_TYPE.TREND_TIME ||
      reportType === REPORT_TYPE.TREND_TABULAR ||
      reportType === REPORT_TYPE.WEB_CLICK ||
      reportType === REPORT_TYPE.I_PREFER
    ) {
      comparisonItem = {
        startDate: newComparisonDates.newStartDate,
        endDate: newComparisonDates.newEndDate,
      };
    }
    //
    setTriggerTime(addSeconds(new Date().getTime(), 0));
    handleChange(item, false, comparisonItem);
  };

  // Move forward by 1 month scroll navigation implementation
  const getNextMonth = () => {
    const newDates = getNextMonthStayDate(dates.startDate, dates.endDate);
    let newComparisonDates;
    // Date values are set depending on the active report type
    if (reportType === REPORT_TYPE.SUMMARY) {
      newComparisonDates = getNextMonthStayDate(summaryStartDate, summaryEndDate);
    } else if (reportType === REPORT_TYPE.TREND_TIME) {
      newComparisonDates = getNextMonthStayDate(trendTimeStartDate, trendTimeEndDate);
    } else if (reportType === REPORT_TYPE.TREND_TABULAR) {
      newComparisonDates = getNextMonthStayDate(trendTimeTabularStartDate, trendTimeTabularEndDate);
    } else if (reportType === REPORT_TYPE.WEB_CLICK) {
      newComparisonDates = getNextMonthStayDate(webClickStartDate, webClickEndDate);
    } else if (reportType === REPORT_TYPE.I_PREFER) {
      newComparisonDates = getNextMonthStayDate(iPreferStartDate, iPreferEndDate);
    }
    //
    setDates((prev) => ({
      ...prev,
      startDate: newDates.newStartDate,
      endDate: newDates.newEndDate,
    }));
    const item = {
      selection: {
        startDate: newDates.newStartDate,
        endDate: newDates.newEndDate,
        key: 'selection',
      },
    };
    // Set comparison date for summary, trend time and web click tabs
    let comparisonItem = null;
    if (
      reportType === REPORT_TYPE.SUMMARY ||
      reportType === REPORT_TYPE.TREND_TIME ||
      reportType === REPORT_TYPE.TREND_TABULAR ||
      reportType === REPORT_TYPE.WEB_CLICK ||
      reportType === REPORT_TYPE.I_PREFER
    ) {
      comparisonItem = {
        startDate: newComparisonDates.newStartDate,
        endDate: newComparisonDates.newEndDate,
      };
    }
    //
    setTriggerTime(addSeconds(new Date().getTime(), 0));
    handleChange(item, false, comparisonItem);
  };

  // Move back by 1 period scroll navigation implementation
  const getPreviousDate = () => {
    const newDates = getPreviousPeriodStayDate(dates.startDate, dates.endDate);
    let newComparisonDates;
    // Date values are set depending on the active report type
    if (reportType === REPORT_TYPE.SUMMARY) {
      newComparisonDates = getPreviousPeriodStayDate(summaryStartDate, summaryEndDate);
    } else if (reportType === REPORT_TYPE.TREND_TIME) {
      newComparisonDates = getPreviousPeriodStayDate(trendTimeStartDate, trendTimeEndDate);
    } else if (reportType === REPORT_TYPE.TREND_TABULAR) {
      newComparisonDates = getPreviousPeriodStayDate(
        trendTimeTabularStartDate,
        trendTimeTabularEndDate
      );
    } else if (reportType === REPORT_TYPE.WEB_CLICK) {
      newComparisonDates = getPreviousPeriodStayDate(webClickStartDate, webClickEndDate);
    } else if (reportType === REPORT_TYPE.I_PREFER) {
      newComparisonDates = getPreviousPeriodStayDate(iPreferStartDate, iPreferEndDate);
    }
    setDates((prev) => ({
      ...prev,
      startDate: newDates.newStartDate,
      endDate: newDates.newEndDate,
    }));
    const item = {
      selection: {
        startDate: newDates.newStartDate,
        endDate: newDates.newEndDate,
        key: 'selection',
      },
    };
    // Set comparison date for summary, trend time and web click tabs
    let comparisonItem = null;
    if (
      reportType === REPORT_TYPE.SUMMARY ||
      reportType === REPORT_TYPE.TREND_TIME ||
      reportType === REPORT_TYPE.TREND_TABULAR ||
      reportType === REPORT_TYPE.WEB_CLICK ||
      reportType === REPORT_TYPE.I_PREFER
    ) {
      comparisonItem = {
        startDate: newComparisonDates.newStartDate,
        endDate: newComparisonDates.newEndDate,
      };
    }
    //
    setTriggerTime(addSeconds(new Date().getTime(), 0));
    handleChange(item, false, comparisonItem);
  };

  // Move forward by 1 period scroll navigation implementation
  const getNextDate = () => {
    const newDates = getNextPeriodStayDate(dates.startDate, dates.endDate);
    let newComparisonDates;
    // Date values are set depending on the active report type
    if (reportType === REPORT_TYPE.SUMMARY) {
      newComparisonDates = getNextPeriodStayDate(summaryStartDate, summaryEndDate);
    } else if (reportType === REPORT_TYPE.TREND_TIME) {
      newComparisonDates = getNextPeriodStayDate(trendTimeStartDate, trendTimeEndDate);
    } else if (reportType === REPORT_TYPE.TREND_TABULAR) {
      newComparisonDates = getNextPeriodStayDate(
        trendTimeTabularStartDate,
        trendTimeTabularEndDate
      );
    } else if (reportType === REPORT_TYPE.WEB_CLICK) {
      newComparisonDates = getNextPeriodStayDate(webClickStartDate, webClickEndDate);
    } else if (reportType === REPORT_TYPE.I_PREFER) {
      newComparisonDates = getNextPeriodStayDate(iPreferStartDate, iPreferEndDate);
    }
    setDates((prev) => ({
      ...prev,
      startDate: newDates.newStartDate,
      endDate: newDates.newEndDate,
    }));
    const item = {
      selection: {
        startDate: newDates.newStartDate,
        endDate: newDates.newEndDate,
        key: 'selection',
      },
    };
    // Set comparison date for summary, trend time and web click tabs
    let comparisonItem = null;
    if (
      reportType === REPORT_TYPE.SUMMARY ||
      reportType === REPORT_TYPE.TREND_TIME ||
      reportType === REPORT_TYPE.TREND_TABULAR ||
      reportType === REPORT_TYPE.WEB_CLICK ||
      reportType === REPORT_TYPE.I_PREFER
    ) {
      comparisonItem = {
        startDate: newComparisonDates.newStartDate,
        endDate: newComparisonDates.newEndDate,
      };
    }
    //
    setTriggerTime(addSeconds(new Date().getTime(), 0));
    handleChange(item, false, comparisonItem);
  };

  // Short cut implementation for scroll navigation functions
  shortcut(1, 1, SHORTCUT_KEY.BACK_BY_MONTH_MAC, () => getPreviousMonth());
  shortcut(1, 1, SHORTCUT_KEY.FORWARD_BY_MONTH_MAC, () => getNextMonth());
  shortcut(1, 1, SHORTCUT_KEY.BACK_BY_MONTH, () => getPreviousMonth());
  shortcut(1, 1, SHORTCUT_KEY.FORWARD_BY_MONTH, () => getNextMonth());
  shortcut(1, 1, SHORTCUT_KEY.BACK_BY_PERIOD, () => getPreviousDate());
  shortcut(1, 1, SHORTCUT_KEY.FORWARD_BY_PERIOD, () => getNextDate());

  // Listener function to set stay date change command flow : sty
  const setStayDateHandler = (data) => {
    let obj = {
      startDate: new Date().toString(),
      endDate: new Date().toString(),
    };
    // Checks whether command value includes "-" to ensure to have two dates
    if (data?.includes('-')) {
      // extract start and end date ranges and check validation
      const datesExtracted = data?.split('-');
      const parsedDate1 = parse(datesExtracted[0].trim(), 'yyyyMMdd', new Date());
      const parsedDate2 = parse(datesExtracted[1].trim(), 'yyyyMMdd', new Date());
      if (isValid(parsedDate1) && isValid(parsedDate2)) {
        obj = {
          startDate: parsedDate1,
          endDate: parsedDate2,
        };
      }
    } else if (/^\d/.test(data) && parse(data.trim(), 'yyyyMMdd', new Date())) {
      // Checks for single date and validation
      const parsedDate = parse(data.trim(), 'yyyyMMdd', new Date());
      if (isValid(parsedDate)) {
        obj = {
          startDate: parsedDate,
          endDate: parsedDate,
        };
      }
    } else if (data === commands.TODAY) {
      obj = {
        startDate: startOfDay(new Date()),
        endDate: endOfDay(new Date()),
      };
    } else if (data === commands.FORWARD_7_DAYS) {
      obj = {
        startDate: addDays(startOfDay(new Date()), 0),
        endDate: addDays(endOfDay(new Date()), 6),
      };
    } else if (data === commands.LAST_WEEK) {
      obj = {
        startDate: startOfWeek(subDays(new Date(), 7)),
        endDate: endOfWeek(subDays(new Date(), 7)),
      };
    } else if (data === commands.THIS_WEEK) {
      obj = {
        startDate: startOfWeek(new Date()),
        endDate: endOfWeek(new Date()),
      };
    } else if (data === commands.WEEK_TO_DATE) {
      obj = {
        startDate: startOfWeek(new Date()),
        endDate: subDays(endOfDay(new Date()), 1),
      };
    } else if (data === commands.LAST_MONTH) {
      obj = {
        startDate: startOfMonth(subMonths(new Date(), 1)),
        endDate: endOfMonth(subMonths(new Date(), 1)),
      };
    } else if (data === commands.THIS_MONTH) {
      obj = {
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date(), 1),
      };
    } else if (data === commands.MONTH_TO_DATE) {
      obj = {
        startDate: startOfMonth(new Date()),
        endDate: endOfDay(new Date()),
      };
    } else if (data === commands.LAST_YEAR) {
      obj = {
        startDate: startOfYear(subYears(new Date(), 1)),
        endDate: endOfYear(subYears(new Date(), 1)),
      };
    } else if (data === commands.YEAR_TO_DATE) {
      obj = {
        startDate: startOfYear(new Date()),
        endDate: endOfDay(new Date()),
      };
    }
    // execute function to set stay date range with command values
    handleChange({ selection: { ...obj, key: 'selection' } });
  };
  // function to handle multiple clicks in scroll navigation
  const simulateClicks = (ref, times) => {
    for (let i = 0; i < times; i += 1) {
      setTimeout(() => {
        ref?.current?.click();
      }, 100 * i);
    }
  };
  // Listener function to trigger < button
  const setStayBackwardPeriodHandler = (value) => {
    simulateClicks(prevRangeRef, value);
  };
  // Listener function to trigger << button
  const setStayBackwardMonthHandler = (value) => {
    simulateClicks(prevMonthRef, value);
  };
  // Listener function to trigger > button
  const setStayForwardPeriodHandler = (value) => {
    simulateClicks(nextRangeRef, value);
  };
  // Listener function to trigger >> button
  const setStayForwardMonthHandler = (value) => {
    simulateClicks(nextMonthRef, value);
  };
  // Add event listener function to set stay date range
  useEffect(() => {
    UiController.subscribe(events.STAY_DATE, setStayDateHandler);
    return () => {
      UiController.unsubscribe(events.STAY_DATE, setStayDateHandler);
    };
  }, []);
  // Triggers when dates state changes
  useEffect(() => {
    // set scroll icon disabled state
    setDisableGetPreviousMonth(
      differenceInMonths(new Date(dates?.startDate), new Date(STAY_DATE_START_DATE)) === 0
    );
    setDisableGetPreviousPeriod(
      differenceInDays(new Date(dates?.startDate), new Date(STAY_DATE_START_DATE)) === 0 ||
        differenceInDays(
          subDays(
            subDays(new Date(dates?.startDate), 1),
            differenceInDays(new Date(dates?.endDate), new Date(dates?.startDate))
          ),
          new Date(STAY_DATE_START_DATE)
        ) < 0
    );
    setDisableGetNextPeriod(
      differenceInDays(new Date(dates?.endDate), new Date(STAY_DATE_END_DATE)) === 0 ||
        differenceInDays(
          addDays(
            addDays(new Date(dates?.endDate), 1),
            differenceInDays(new Date(dates?.endDate), new Date(dates?.startDate))
          ),
          new Date(STAY_DATE_END_DATE)
        ) > 0
    );
    setDisableGetNextMonth(
      differenceInMonths(new Date(dates?.endDate), new Date(STAY_DATE_END_DATE)) === 0
    );

    // Add event listener functions to simulate scroll navigation buttons
    UiController.subscribe(events.SET_PREVIOUS_DAY, setStayBackwardPeriodHandler);
    UiController.subscribe(events.SET_PREVIOUS_MONTH, setStayBackwardMonthHandler);
    UiController.subscribe(events.SET_NEXT_DAY, setStayForwardPeriodHandler);
    UiController.subscribe(events.SET_NEXT_MONTH, setStayForwardMonthHandler);
    return () => {
      UiController.unsubscribe(events.SET_PREVIOUS_DAY, setStayBackwardPeriodHandler);
      UiController.unsubscribe(events.SET_PREVIOUS_MONTH, setStayBackwardMonthHandler);
      UiController.unsubscribe(events.SET_NEXT_DAY, setStayForwardPeriodHandler);
      UiController.unsubscribe(events.SET_NEXT_MONTH, setStayForwardMonthHandler);
    };
  }, [dates]);
  //
  return (
    <Grid
      paddingBottom={3}
      paddingRight={10}
      container
      direction="row"
      justifyContent="flex-end"
      mx={2}
    >
      <Stack direction="row" spacing={1} xs={12} paddingTop={1}>
        <Tooltip title="Move back by 1 month." placement="top">
          <IconButton
            ref={prevMonthRef}
            onClick={getPreviousMonth}
            disabled={disableGetPreviousMonth}
          >
            <ChevronsLeft fontSize="large" strokeWidth={4} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Move back by 1 period." placement="top">
          <IconButton
            ref={prevRangeRef}
            onClick={getPreviousDate}
            disabled={disableGetPreviousPeriod}
          >
            <ChevronLeft fontSize="large" strokeWidth={4} />
          </IconButton>
        </Tooltip>
        <StayDateRangePicker
          handleChange={handleChange}
          ranges={dateRange()}
          label={label}
          dateValue={formatDateLabel(dates)}
          adornment={adornment}
          cssClassName={styles.calendarElement}
        />
        <Tooltip title="Move forward by 1 period." placement="top">
          <IconButton ref={nextRangeRef} onClick={getNextDate} disabled={disableGetNextPeriod}>
            <ChevronRight fontSize="large" strokeWidth={4} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Move forward by 1 month." placement="top">
          <IconButton ref={nextMonthRef} onClick={getNextMonth} disabled={disableGetNextMonth}>
            <ChevronsRight fontSize="large" strokeWidth={4} />
          </IconButton>
        </Tooltip>
      </Stack>
    </Grid>
  );
};
//
export default DashboardHeader;
