import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAlignment,
  selectBreakdown,
  selectDateComparison,
  selectSummaryAlignmentToggle,
  selectSymmetricComparisonToggle,
  selectSummaryOtb,
  selectSummaryCustomDate,
  selectIsDetailed,
  selectSummaryEndDate,
  selectSummaryBookingDate,
  selectSummaryStartDate,
  selectSummaryVisualToggle,
  selectPaceVisualIsDisplay,
  selectRevenueSetIsDisplay,
  selectCxlSetIsDisplay,
  selectSymmetricComparisonIsDisplay,
  selectSummaryFilterList,
  selectPaceVisualFilterList,
  selectHotelGroupFocusOn,
  selectEnableSecondaryHotelGroups,
  selectHotelFocusOn,
  selectNormalizedView,
} from 'modules/dashboard/components/tab-container/summary-tab/selectors';
import {
  selectPaceHorizon,
  selectPaceVariable,
  selectTargetDate,
  selectVisualFilters,
  selectHotelName,
  selectPmsSync,
  selectEnableHotelGroups,
  selectSelectedHotelGroupName,
  selectSummaryForecastFilter,
  selectReportType,
  selectHotelGroupDropdownList,
  selectHotelDropdownList,
} from 'modules/dashboard/selectors';
import { summaryActions } from 'modules/dashboard/components/tab-container/summary-tab/slice';
import { KEYS, REPORT_TYPE, TAGS } from 'modules/dashboard/constants';
import {
  generateSummaryShareUrl,
  getAlignmentAndDateComparisonSchema,
  getBookingDateSchema,
  getChartBreakdownFilterList,
  getCustomDateComparisonSchema,
  getFarFieldPmsSyncSchema,
  getGroupASchema,
  getGroupHotelFilterSchema,
  getHotelGroupASchema,
  getHotelGroupSchema,
  getHotelIdSchema,
  getIsForecastSchema,
  getNormalizedViewSchema,
  getPaceHorizonSchema,
  getPaceVariableSchema,
  getPmsSyncSchema,
  getRevenueForecastSchema,
  getRoomForecastSchema,
  getSymmetricComparisonSchema,
  getTargetDateSchema,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import writeLog from 'modules/common/utils/filter-log';
import { ENVIRONMENT } from 'config';
import { isEmpty } from 'modules/common/helpers/object';

/**
 * Custom hook to generate summary widget filter schemas
 */
const useSummaryWidget = () => {
  const dispatch = useDispatch();
  //
  const summaryForecastFilter = useSelector(selectSummaryForecastFilter);
  const chartBreakdown = useSelector(selectBreakdown);
  const alignment = useSelector(selectAlignment);
  const dateComparison = useSelector(selectDateComparison);
  const bookingDate = useSelector(selectSummaryBookingDate);
  const targetDate = useSelector(selectTargetDate);
  const hotelId = useSelector(selectHotelName);
  const hotelGroupId = useSelector(selectSelectedHotelGroupName);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const visualFilters = useSelector(selectVisualFilters);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const paceHorizon = useSelector(selectPaceHorizon);
  const paceVariable = useSelector(selectPaceVariable);
  const symmetricComparisonToggle = useSelector(selectSymmetricComparisonToggle);
  const isDetailed = useSelector(selectIsDetailed);
  const endDate = useSelector(selectSummaryEndDate);
  const startDate = useSelector(selectSummaryStartDate);
  const customBooking = useSelector(selectSummaryOtb);
  const alignmentToggle = useSelector(selectSummaryAlignmentToggle);
  const customDate = useSelector(selectSummaryCustomDate);
  const visualToggle = useSelector(selectSummaryVisualToggle);
  const paceVisualIsDisplay = useSelector(selectPaceVisualIsDisplay);
  const revenueSetIsDisplay = useSelector(selectRevenueSetIsDisplay);
  const cxlSetIsDisplay = useSelector(selectCxlSetIsDisplay);
  const symmetricComparisonIsDisplay = useSelector(selectSymmetricComparisonIsDisplay);
  const forecastToggle = useSelector(selectSummaryForecastFilter);
  const reportType = useSelector(selectReportType);
  const summaryFilterList = useSelector(selectSummaryFilterList);
  const paceVisualFilterList = useSelector(selectPaceVisualFilterList);
  const hotelGroupsDropdownList = useSelector(selectHotelGroupDropdownList);
  const hotelGroupFocusOn = useSelector(selectHotelGroupFocusOn);
  const hotelFocusOn = useSelector(selectHotelFocusOn);
  const enableSecondaryHotelGroups = useSelector(selectEnableSecondaryHotelGroups);
  const hotelDropdownList = useSelector(selectHotelDropdownList);
  const normalizedViewToggle = useSelector(selectNormalizedView);
  //
  const [previousHotel, setPreviousHotel] = useState(null);
  const [previousHotelGroup, setPreviousHotelGroup] = useState(null);
  //
  const serializedState = JSON.parse(localStorage.getItem(`persist:${ENVIRONMENT.PERSIST_KEY}`));
  const persistValues = Object.values(serializedState)[1];
  const persistedHotelName = JSON.parse(persistValues).hotelName;
  const persistedHotelGroupName = JSON.parse(persistValues).selectedHotelGroupName;
  //
  useEffect(() => {
    // Persist hotel focus on value on browser refresh
    setPreviousHotel(persistedHotelName);
    if (previousHotel !== null) {
      // dispatch(summaryActions.setHotelFocusOn(hotelDropdownList[0]));
    }
  }, [hotelId]);
  //
  useEffect(() => {
    // Persist hotel group focus on value on browser refresh
    setPreviousHotelGroup(persistedHotelGroupName);
    if (previousHotelGroup !== null) {
      // dispatch(summaryActions.setHotelGroupFocusOn(hotelGroupsDropdownList[0]));
    }
  }, [hotelGroupId]);
  // Triggered when pmsSyncToggle,chartBreakdown,bookingDate,visualFilters,dateComparison,alignment,targetDate,hotelId,symmetricComparisonToggle,isDetailed,endDate,startDate value changes
  useEffect(() => {
    if (
      reportType !== REPORT_TYPE.SUMMARY ||
      !dateComparison ||
      !chartBreakdown ||
      !alignment ||
      !bookingDate ||
      !targetDate ||
      !visualFilters ||
      !hotelId ||
      (dateComparison && dateComparison === KEYS.CUSTOM && endDate === null)
    )
      return;
    // Generate filter list for summary widget top 8 visuals
    const targetDateFilter = getTargetDateSchema(
      visualFilters,
      targetDate?.startDate,
      targetDate?.endDate
    );
    //
    const groupFocusOnIds =
      hotelGroupFocusOn?.hotels?.map((hotel) => hotel?.databricksId) ??
      hotelDropdownList?.map((filter) => filter?.databricksId);
    let hotelGroup1Filter;
    const hotelListIds =
      hotelGroupId?.hotels?.map((hotel) => hotel?.databricksId) ??
      hotelDropdownList?.map((filter) => filter?.databricksId);
    //
    if (enableHotelGroups) {
      hotelGroup1Filter = getHotelGroupSchema(hotelListIds);
    } else {
      hotelGroup1Filter = getHotelGroupSchema([hotelId?.databricksId]);
    }
    //
    let hotelGroupAFilter;
    if (enableSecondaryHotelGroups) {
      hotelGroupAFilter = getHotelGroupASchema(groupFocusOnIds);
    } else {
      hotelGroupAFilter = getHotelGroupASchema([hotelFocusOn?.databricksId]);
    }
    //
    let dimHotelIds = [];
    if (enableHotelGroups) {
      if (enableSecondaryHotelGroups) {
        dimHotelIds = new Set([...groupFocusOnIds, ...hotelListIds]);
      } else {
        dimHotelIds = new Set([hotelFocusOn?.databricksId, ...hotelListIds]);
      }
    } else if (enableSecondaryHotelGroups) {
      dimHotelIds = new Set([...groupFocusOnIds, hotelId?.databricksId]);
    } else {
      dimHotelIds = new Set([hotelFocusOn?.databricksId, hotelId?.databricksId]);
    }
    if ((enableSecondaryHotelGroups || enableHotelGroups) && isEmpty(dimHotelIds)) {
      dimHotelIds = new Set(hotelDropdownList?.map((filter) => filter?.databricksId));
    }
    //
    const secondaryHotelIdFilter = getHotelIdSchema(Array.from(dimHotelIds), visualFilters);
    const hotelFilters = [secondaryHotelIdFilter, hotelGroupAFilter, hotelGroup1Filter];
    //
    const bookingDateFilter = getBookingDateSchema(
      visualFilters,
      bookingDate?.startDate,
      bookingDate?.endDate
    );
    const alignmentAndDateComparisonFilter = getAlignmentAndDateComparisonSchema(
      visualFilters,
      alignment?.id,
      dateComparison
    );
    const symmetricComparisonFilter = getSymmetricComparisonSchema(
      visualFilters,
      symmetricComparisonToggle
    );
    const isCustomDateComparisonFilter = getCustomDateComparisonSchema(
      visualFilters,
      dateComparison,
      startDate,
      endDate
    );
    const chartBreakdownFilter = getChartBreakdownFilterList(
      visualFilters,
      chartBreakdown,
      isDetailed
    );
    const pmsSyncFilter = getPmsSyncSchema(visualFilters, pmsSyncToggle, TAGS.SUMMARY);
    const normalizedFilter = getNormalizedViewSchema(visualFilters, normalizedViewToggle);
    if (
      chartBreakdownFilter &&
      bookingDateFilter &&
      alignmentAndDateComparisonFilter &&
      targetDateFilter &&
      symmetricComparisonFilter &&
      normalizedFilter
    ) {
      const filters = [
        ...pmsSyncFilter,
        chartBreakdownFilter,
        bookingDateFilter,
        ...alignmentAndDateComparisonFilter,
        targetDateFilter,
        symmetricComparisonFilter,
        ...hotelFilters,
        ...isCustomDateComparisonFilter,
        normalizedFilter,
      ];
      //
      if (
        JSON.stringify(filters) !== JSON.stringify(summaryFilterList) &&
        reportType === REPORT_TYPE.SUMMARY
      ) {
        writeLog('Summary Filter', filters);
        dispatch(summaryActions.updateSummaryFilterList(filters));
      }
    } else dispatch(summaryActions.updateSummaryFilterList([]));
  }, [
    pmsSyncToggle,
    chartBreakdown,
    bookingDate,
    visualFilters,
    dateComparison,
    alignment,
    targetDate,
    hotelId,
    symmetricComparisonToggle,
    isDetailed,
    endDate,
    startDate,
    enableHotelGroups,
    hotelGroupId,
    reportType,
    hotelGroupFocusOn,
    enableSecondaryHotelGroups,
    hotelFocusOn,
    hotelGroupsDropdownList,
    normalizedViewToggle,
  ]);

  // Triggered when pmsSyncToggle,visualFilters,dateComparison,alignment,targetDate,hotelId,paceHorizon,paceVariable,endDate,startDate value changes
  useEffect(() => {
    if (
      reportType !== REPORT_TYPE.SUMMARY ||
      !dateComparison ||
      !alignment ||
      !targetDate ||
      !visualFilters ||
      !hotelId ||
      !paceHorizon ||
      !paceVariable ||
      (dateComparison && dateComparison === KEYS.CUSTOM && endDate === null)
    )
      return;
    // Generate filter list for summary widget pace visual
    const targetDateFilter = getTargetDateSchema(
      visualFilters,
      targetDate?.startDate,
      targetDate?.endDate
    );
    const groupFocusOnIds =
      hotelGroupFocusOn?.hotels?.map((hotel) => hotel?.databricksId) ??
      hotelDropdownList?.map((filter) => filter?.databricksId);
    let hotelGroup1Filter;
    const hotelListIds =
      hotelGroupId?.hotels?.map((hotel) => hotel?.databricksId) ??
      hotelDropdownList?.map((filter) => filter?.databricksId);
    //
    const groupHotelFilter = getGroupHotelFilterSchema(visualFilters);
    if (enableHotelGroups) {
      hotelGroup1Filter = getHotelGroupSchema(hotelListIds, groupHotelFilter);
    } else {
      hotelGroup1Filter = getHotelGroupSchema([hotelId?.databricksId], groupHotelFilter);
    }
    //
    const groupAFilter = getGroupASchema(visualFilters);
    let hotelGroupAFilter;
    if (enableSecondaryHotelGroups) {
      hotelGroupAFilter = getHotelGroupASchema(groupFocusOnIds, groupAFilter);
    } else {
      hotelGroupAFilter = getHotelGroupASchema([hotelFocusOn?.databricksId], groupAFilter);
    }
    //
    let dimHotelIds = [];
    if (enableHotelGroups) {
      if (enableSecondaryHotelGroups) {
        dimHotelIds = new Set([...groupFocusOnIds, ...hotelListIds]);
      } else {
        dimHotelIds = new Set([hotelFocusOn?.databricksId, ...hotelListIds]);
      }
    } else if (enableSecondaryHotelGroups) {
      dimHotelIds = new Set([...groupFocusOnIds, hotelId?.databricksId]);
    } else {
      dimHotelIds = new Set([hotelFocusOn?.databricksId, hotelId?.databricksId]);
    }
    if ((enableSecondaryHotelGroups || enableHotelGroups) && isEmpty(dimHotelIds)) {
      dimHotelIds = new Set(hotelDropdownList?.map((filter) => filter?.databricksId));
    }
    //
    const secondaryHotelIdFilter = getHotelIdSchema(Array.from(dimHotelIds), visualFilters);
    const hotelFilters = [secondaryHotelIdFilter, hotelGroupAFilter, hotelGroup1Filter];
    //
    const isCustomDateComparisonFilter = getCustomDateComparisonSchema(
      visualFilters,
      dateComparison,
      startDate,
      endDate
    );
    const alignmentAndDateComparisonFilter = getAlignmentAndDateComparisonSchema(
      visualFilters,
      alignment?.id,
      dateComparison
    );
    const pmsSyncFilter = getPmsSyncSchema(visualFilters, pmsSyncToggle, TAGS.SUMMARY);
    const paceHorizonFilter = getPaceHorizonSchema(visualFilters, paceHorizon);
    const paceVariableFilter = getPaceVariableSchema(
      visualFilters,
      paceVariable,
      TAGS.SUMMARY_PACE_VARIABLE
    );
    const isForecastFilter = getIsForecastSchema(visualFilters, summaryForecastFilter);
    const roomForecastFilter = getRoomForecastSchema(visualFilters, pmsSyncToggle);
    const revenueForecastFilter = getRevenueForecastSchema(visualFilters, pmsSyncToggle);
    const farFieldPmsFilter = getFarFieldPmsSyncSchema(visualFilters, pmsSyncToggle);
    const normalizedFilter = getNormalizedViewSchema(visualFilters, normalizedViewToggle);
    //
    if (
      targetDateFilter &&
      paceHorizonFilter &&
      paceVariableFilter &&
      roomForecastFilter &&
      revenueForecastFilter &&
      alignmentAndDateComparisonFilter &&
      farFieldPmsFilter &&
      isForecastFilter &&
      normalizedFilter
    ) {
      const filters = [
        ...pmsSyncFilter,
        targetDateFilter,
        paceHorizonFilter,
        paceVariableFilter,
        roomForecastFilter,
        revenueForecastFilter,
        ...alignmentAndDateComparisonFilter,
        ...isCustomDateComparisonFilter,
        ...farFieldPmsFilter,
        isForecastFilter,
        ...hotelFilters,
        normalizedFilter,
      ];

      if (JSON.stringify(filters) !== JSON.stringify(paceVisualFilterList)) {
        writeLog('Pace Visuals Filter', filters);
        dispatch(summaryActions.setPaceVisualFilterList(filters));
      }
    }
  }, [
    pmsSyncToggle,
    visualFilters,
    dateComparison,
    alignment,
    targetDate,
    hotelId,
    paceHorizon,
    paceVariable,
    endDate,
    startDate,
    visualToggle,
    enableHotelGroups,
    hotelGroupId,
    summaryForecastFilter,
    reportType,
    hotelGroupFocusOn,
    enableSecondaryHotelGroups,
    hotelFocusOn,
    hotelGroupsDropdownList,
    normalizedViewToggle,
  ]);

  // Triggers when filter values dependencies changes
  useEffect(() => {
    if (
      !dateComparison &&
      !chartBreakdown &&
      !bookingDate &&
      !targetDate &&
      (!hotelId || !hotelGroupId) &&
      !paceHorizon &&
      !paceVariable
    )
      return;
    // Generate summary widget filter url
    const summaryUrl = generateSummaryShareUrl(
      pmsSyncToggle,
      hotelId,
      targetDate,
      dateComparison,
      paceVariable,
      visualFilters,
      paceHorizon,
      startDate,
      endDate,
      customDate,
      isDetailed,
      chartBreakdown,
      customBooking,
      bookingDate,
      alignmentToggle,
      symmetricComparisonToggle,
      hotelGroupId,
      enableHotelGroups,
      visualToggle,
      paceVisualIsDisplay,
      revenueSetIsDisplay,
      cxlSetIsDisplay,
      symmetricComparisonIsDisplay,
      forecastToggle,
      enableSecondaryHotelGroups,
      hotelFocusOn,
      hotelGroupFocusOn,
      normalizedViewToggle
    );
    //
    dispatch(summaryActions.setSummaryUrl(summaryUrl));
  }, [
    pmsSyncToggle,
    chartBreakdown,
    bookingDate,
    visualFilters,
    dateComparison,
    alignmentToggle,
    targetDate,
    hotelId,
    paceHorizon,
    paceVariable,
    symmetricComparisonToggle,
    isDetailed,
    customBooking,
    startDate,
    endDate,
    customDate,
    hotelGroupId,
    enableHotelGroups,
    visualToggle,
    paceVisualIsDisplay,
    revenueSetIsDisplay,
    cxlSetIsDisplay,
    symmetricComparisonIsDisplay,
    forecastToggle,
    enableSecondaryHotelGroups,
    hotelFocusOn,
    hotelGroupFocusOn,
    normalizedViewToggle,
  ]);
};
//
export default useSummaryWidget;
