// Column names used for trend segment filter to be sent for TT visuals
export const TREND_TIME_SEGMENT_FILTER = {
  TABLE_NAME: 'Fact_Transactions',
  CHANNEL_RAW: 'channel',
  CHANNEL: 'AssignedChannel',
  MARKET_SEGMENT: 'AssignedMarketSegment',
  MARKET_SEGMENT_RAW: 'marketSegmentCode',
  RATE_CATEGORY: 'AssignedRateCategory',
  RATE_CATEGORY_RAW: 'rateCategoryCode',
  REGION: 'AssignedRegion',
  REGION_RAW: 'region_cleaned',
};
//
export const PRIMARY = 'Breakdown';
//
export const GRANULARITY = {
  DAILY: 'Date',
  WEEKLY: 'Week of Year',
  MONTHLY: 'Month',
  YEARLY: 'Year',
};
