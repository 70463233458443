import { transformIntoArray } from 'modules/common/utils/array';
import { UiController } from '..';
import { EXECUTION_TYPE, consoleCommands } from './constants';

/**
 * Get data value based on execution type that get called form different places
 * @param {*} commandData - element that contains command url, it changes depends where it get executed
 * @param {*} type - type is defined as where execution function get called ex: chat, command, url
 * @returns
 */
export const setDataAndSuccessResponse = (commandData, type) => {
  let data = '';
  switch (type) {
    case EXECUTION_TYPE.COMMAND:
      data = commandData.value;
      break;
    case EXECUTION_TYPE.CONVERSATION:
      data = commandData;
      break;
    case EXECUTION_TYPE.URL:
      data = commandData.substring(1);
      break;
    default:
      break;
  }
  //
  return data;
};
/**
 * Set tab of dashboard view from the value retrieved from the command url
 * @param {*} commandData - element that contains command url, it changes depends where it get executed
 * @param {*} type - type is defined as where execution function get called ex: chat, command, url
 * @returns
 */
export const setTabCommand = (commandData, type) => {
  const data = setDataAndSuccessResponse(commandData, type);
  // breaks down command url
  const commands = data?.split('&');
  // checked whether tab command exists or not in command url
  const tab = commands?.filter((com) => com.includes(consoleCommands.TAB));
  // if there is tab command emit event to set tab
  if (tab?.length > 0) {
    UiController.setTab(tab[0]?.split('=')[1]);
  }
};
/**
 * Set hotel of dashboard view from the value retrieved from the command url
 * @param {*} commandData - element that contains command url, it changes depends where it get executed
 * @param {*} type - type is defined as where execution function get called ex: chat, command, url
 * @returns
 */
export const setHotelCommand = (commandData, type) => {
  const data = setDataAndSuccessResponse(commandData, type);
  // breaks down command url
  const commands = data?.split('&');
  // checked whether hotel command exists or not in command url
  const hotel = commands?.filter((com) => com.includes(consoleCommands.HOTEL));
  const hotelGroup = commands?.filter((com) => com.includes(consoleCommands.HOTEL_GROUP));
  // if there is hotel command emit event to set hotel
  if (hotel?.length > 0) {
    const values = transformIntoArray(hotel[0]?.split('=')[1]);
    UiController.setHotelName(values);
  } else if (hotelGroup?.length > 0) {
    const groupValues = transformIntoArray(hotelGroup[0]?.split('=')[1]);
    UiController.setHotelGroupName(groupValues);
  }
};
/**
 * Execute single commands
 * This where ui controller single commands are executed.
 * @param {*} commandData - element that contains command url, it changes depends where it get executed
 * @param {*} type - type is defined as where execution function get called ex: chat, command, url
 * @returns
 */
export const executeSingleCommands = (commandData, type) => {
  const data = setDataAndSuccessResponse(commandData, type);
  // get command schema value
  const command = data?.split('=')[0];
  // check the command and emit related events with passed down values
  switch (command) {
    case consoleCommands.STAY_DATE:
      UiController.setDateRange(data?.split('=')[1]);
      break;
    case consoleCommands.HOTEL: {
      const values = transformIntoArray(data?.split('=')[1]);
      UiController.setHotelName(values);
      break;
    }
    case consoleCommands.HOTEL_GROUP: {
      const values = transformIntoArray(data?.split('=')[1]);
      UiController.setHotelGroupName(values);
      break;
    }
    case consoleCommands.PMS:
      UiController.setPmsSync(parseInt(data?.split('=')[1], 10));
      break;
    case consoleCommands.TAB:
      UiController.setTab(data?.split('=')[1]);
      break;
    case consoleCommands.BOOKING_DATE:
      UiController.setBookingDate(data?.split('=')[1]);
      break;
    case consoleCommands.WEEKLY_VIEW:
      UiController.setWeeklyView(parseInt(data?.split('=')[1], 10));
      break;
    case consoleCommands.ALIGNMENT:
      UiController.setAlignment(parseInt(data?.split('=')[1], 10));
      break;
    case consoleCommands.SYMMETRIC:
      UiController.setSymmetricComparison(parseInt(data?.split('=')[1], 10));
      break;
    case consoleCommands.CUMULATIVE_VIEW:
      UiController.setCumulativeView(parseInt(data?.split('=')[1], 10));
      break;
    case consoleCommands.COMPARE_WINDOW:
      UiController.setCompareWIndow(data?.split('=')[1]);
      break;
    case consoleCommands.PACE_HORIZON:
      UiController.setPaceHorizon(data?.split('=')[1]);
      break;
    case consoleCommands.PACE_VARIABLE:
      UiController.setPaceVariable(data?.split('=')[1]);
      break;
    case consoleCommands.SEGMENT:
      UiController.setBreakdown(data?.split('=')[1]);
      break;
    case consoleCommands.COMPARE_HOTEL: {
      const values = transformIntoArray(data?.split('=')[1]);
      UiController.setCompareHotels(values);
      break;
    }
    case consoleCommands.FOCUS: {
      const values = transformIntoArray(data?.split('=')[1]);
      UiController.setFocusOn(values);
      break;
    }
    case consoleCommands.STAY_BACKWARD_PERIOD:
      UiController.setStayBackwardPeriod(parseInt(data?.split('=')[1], 10));
      break;
    case consoleCommands.STAY_BACKWARD_MONTH:
      UiController.setStayBackwardMonth(parseInt(data?.split('=')[1], 10));
      break;
    case consoleCommands.STAY_FORWARD_PERIOD:
      UiController.setStayForwardPeriod(parseInt(data?.split('=')[1], 10));
      break;
    case consoleCommands.STAY_FORWARD_MONTH:
      UiController.setStayForwardMonth(parseInt(data?.split('=')[1], 10));
      break;
    case consoleCommands.NORMALIZED_VIEW:
      UiController.setNormalizedView(parseInt(data?.split('=')[1], 10));
      break;
    case consoleCommands.WEB_CLICK_STEPS: {
      const values = transformIntoArray(data?.split('=')[1]);
      UiController.setWebClickSteps(values);
      break;
    }
    case consoleCommands.WEB_CLICK_SCALE:
      UiController.setWebClickScaleFactor(parseInt(data?.split('=')[1], 10));
      break;
    case consoleCommands.CUSTOM_DATES:
      UiController.setCustomDateRangeValue(data?.split('=')[1]);
      break;
    case consoleCommands.SET_A: {
      const values = transformIntoArray(data?.split('=')[1]);
      UiController.setCustomAggregationA(values);
      break;
    }
    case consoleCommands.SET_B: {
      const values = transformIntoArray(data?.split('=')[1]);
      UiController.setCustomAggregationB(values);
      break;
    }
    case consoleCommands.TABULAR_VIEW:
      UiController.setTabularView(data?.split('=')[1]);
      break;
    case consoleCommands.METRIC_SELECTION:
      UiController.setMetricSelection(data?.split('=')[1]);
      break;
    case consoleCommands.TIME_FRAME:
      UiController.setTimeFrame(data?.split('=')[1]);
      break;
    case consoleCommands.LAG_ALIGN:
      UiController.setLagAligned(parseInt(data?.split('=')[1], 10));
      break;
    case consoleCommands.PERCENTAGE:
      UiController.setPercentage(data?.split('=')[1]);
      break;
    case consoleCommands.BOOKING_FORECAST: {
      const values = transformIntoArray(data?.split('=')[1]);
      UiController.setBookingForecast(values);
      break;
    }
    case consoleCommands.EXTENDING_FORECAST:
      UiController.setExtendingForecast(parseInt(data?.split('=')[1], 10));
      break;
    case consoleCommands.SYMMETRIC_COMPARISON_COLLAPSE:
      UiController.setSymmetricComparisonCollapse(parseInt(data?.split('=')[1], 10));
      break;
    case consoleCommands.VISUAL_LOADING:
      UiController.setVisualCollapse(data?.split('=')[1]);
      break;
    case consoleCommands.FORECAST:
      UiController.setForecastToggle(parseInt(data?.split('=')[1], 10));
      break;
    case consoleCommands.COMPARE_HOTEL_GROUP: {
      const values = transformIntoArray(data?.split('=')[1]);
      UiController.setSecondaryHotelGroup(values);
      break;
    }
    case consoleCommands.ACTIVE_TREND_TAB:
      UiController.setActiveTab(data?.split('=')[1]);
      break;
    case consoleCommands.BREAKDOWN_TOGGLE:
      UiController.setBreakdownToggle(parseInt(data?.split('=')[1], 10));
      break;
    case consoleCommands.PERCENTAGE_TOGGLE:
      UiController.setPercentageChangeToggle(parseInt(data?.split('=')[1], 10));
      break;
    case consoleCommands.SEGMENT_BREAKDOWN:
      UiController.setSegmentBreakdownToggle(parseInt(data?.split('=')[1], 10));
      break;
    case consoleCommands.UTM_BREAKDOWN:
      UiController.setUTMBreakdown(data?.split('=')[1]);
      break;
    case consoleCommands.UTM_FOCUS_ON: {
      const values = transformIntoArray(data?.split('=')[1]);
      UiController.setUTMFocusOn(values);
      break;
    }
    case consoleCommands.BOOKING_PACE_WINDOW:
      UiController.setBookingPaceWindow(data?.split('=')[1]);
      break;
    default:
      break;
  }
};
/**
 * Execute multiple commands
 * This where ui controller multiple commands are executed.
 * @param {*} commandData - element that contains command url, it changes depends where it get executed
 * @param {*} type - type is defined as where execution function get called ex: chat, command, url
 * @returns
 */
export const executeMultipleCommands = (commandData, type) => {
  const data = setDataAndSuccessResponse(commandData, type);
  // breaks down command url
  const commands = data?.split('&');
  // check the commands and emit related events with passed down values
  commands?.forEach((command) => {
    if (command.includes(consoleCommands.STAY_DATE)) {
      UiController.setDateRange(command?.split('=')[1]);
    }
    if (command.includes(consoleCommands.HOTEL)) {
      const values = transformIntoArray(command?.split('=')[1]);
      UiController.setHotelName(values);
    }
    if (command.includes(consoleCommands.HOTEL_GROUP)) {
      const values = transformIntoArray(command?.split('=')[1]);
      UiController.setHotelGroupName(values);
    }
    if (command.includes(consoleCommands.PMS)) {
      UiController.setPmsSync(parseInt(command?.split('=')[1], 10));
    }
    if (command.includes(consoleCommands.BOOKING_DATE)) {
      UiController.setBookingDate(command?.split('=')[1]);
    }
    if (command.includes(consoleCommands.WEEKLY_VIEW)) {
      UiController.setWeeklyView(parseInt(command?.split('=')[1], 10));
    }
    if (command.includes(consoleCommands.ALIGNMENT)) {
      UiController.setAlignment(parseInt(command?.split('=')[1], 10));
    }
    if (command.includes(consoleCommands.SYMMETRIC)) {
      UiController.setSymmetricComparison(parseInt(command?.split('=')[1], 10));
    }
    if (command.includes(consoleCommands.CUMULATIVE_VIEW)) {
      UiController.setCumulativeView(parseInt(command?.split('=')[1], 10));
    }
    if (command.includes(consoleCommands.COMPARE_WINDOW)) {
      UiController.setCompareWIndow(command?.split('=')[1]);
    }
    if (command.includes(consoleCommands.PACE_HORIZON)) {
      UiController.setPaceHorizon(command?.split('=')[1]);
    }
    if (command.includes(consoleCommands.PACE_VARIABLE)) {
      UiController.setPaceVariable(command?.split('=')[1]);
    }
    if (command.includes(consoleCommands.SEGMENT)) {
      UiController.setBreakdown(command?.split('=')[1]);
    }
    if (command.includes(consoleCommands.COMPARE_HOTEL)) {
      const values = transformIntoArray(command?.split('=')[1]);
      UiController.setCompareHotels(values);
    }
    if (command.includes(consoleCommands.FOCUS)) {
      const values = transformIntoArray(command?.split('=')[1]);
      UiController.setFocusOn(values);
    }
    if (command.includes(consoleCommands.STAY_BACKWARD_PERIOD)) {
      UiController.setStayBackwardPeriod(parseInt(command?.split('=')[1], 10));
    }
    if (command.includes(consoleCommands.STAY_BACKWARD_MONTH)) {
      UiController.setStayBackwardMonth(parseInt(command?.split('=')[1], 10));
    }
    if (command.includes(consoleCommands.STAY_FORWARD_PERIOD)) {
      UiController.setStayForwardPeriod(parseInt(command?.split('=')[1], 10));
    }
    if (command.includes(consoleCommands.STAY_FORWARD_MONTH)) {
      UiController.setStayForwardMonth(parseInt(command?.split('=')[1], 10));
    }
    if (command.includes(consoleCommands.NORMALIZED_VIEW)) {
      UiController.setNormalizedView(parseInt(command?.split('=')[1], 10));
    }
    if (command.includes(consoleCommands.WEB_CLICK_STEPS)) {
      const values = transformIntoArray(command?.split('=')[1]);
      UiController.setWebClickSteps(values);
    }
    if (command.includes(consoleCommands.WEB_CLICK_SCALE)) {
      UiController.setWebClickScaleFactor(parseInt(command?.split('=')[1], 10));
    }
    if (command.includes(consoleCommands.CUSTOM_DATES)) {
      UiController.setCustomDateRangeValue(command?.split('=')[1]);
    }
    if (command.includes(consoleCommands.SET_A)) {
      const values = transformIntoArray(command?.split('=')[1]);
      UiController.setCustomAggregationA(values);
    }
    if (command.includes(consoleCommands.SET_B)) {
      const values = transformIntoArray(command?.split('=')[1]);
      UiController.setCustomAggregationB(values);
    }
    if (command.includes(consoleCommands.TABULAR_VIEW)) {
      UiController.setTabularView(command?.split('=')[1]);
    }
    if (command.includes(consoleCommands.METRIC_SELECTION)) {
      UiController.setMetricSelection(command?.split('=')[1]);
    }
    if (command.includes(consoleCommands.TIME_FRAME)) {
      UiController.setTimeFrame(command?.split('=')[1]);
    }
    if (command.includes(consoleCommands.LAG_ALIGN)) {
      UiController.setLagAligned(parseInt(command?.split('=')[1], 10));
    }
    if (command.includes(consoleCommands.PERCENTAGE)) {
      UiController.setPercentage(command?.split('=')[1]);
    }
    if (command.includes(consoleCommands.BOOKING_FORECAST)) {
      const values = transformIntoArray(command?.split('=')[1]);
      UiController.setBookingForecast(values);
    }
    if (command.includes(consoleCommands.EXTENDING_FORECAST)) {
      UiController.setExtendingForecast(parseInt(command?.split('=')[1], 10));
    }
    if (command.includes(consoleCommands.SYMMETRIC_COMPARISON_COLLAPSE)) {
      UiController.setSymmetricComparisonCollapse(parseInt(command?.split('=')[1], 10));
    }
    if (command.includes(consoleCommands.VISUAL_LOADING)) {
      UiController.setVisualCollapse(command?.split('=')[1]);
    }
    if (command.includes(consoleCommands.FORECAST)) {
      UiController.setForecastToggle(parseInt(command?.split('=')[1], 10));
    }
    if (command.includes(consoleCommands.COMPARE_HOTEL_GROUP)) {
      const values = transformIntoArray(command?.split('=')[1]);
      UiController.setSecondaryHotelGroup(values);
    }
    if (command.includes(consoleCommands.ACTIVE_TREND_TAB)) {
      UiController.setActiveTab(command?.split('=')[1]);
    }
    if (command.includes(consoleCommands.BREAKDOWN_TOGGLE)) {
      UiController.setBreakdownToggle(parseInt(command?.split('=')[1], 10));
    }
    if (command.includes(consoleCommands.PERCENTAGE_TOGGLE)) {
      UiController.setPercentageChangeToggle(parseInt(command?.split('=')[1], 10));
    }
    if (command.includes(consoleCommands.SEGMENT_BREAKDOWN)) {
      UiController.setSegmentBreakdownToggle(parseInt(command?.split('=')[1], 10));
    }
    if (command.includes(consoleCommands.UTM_BREAKDOWN)) {
      UiController.setUTMBreakdown(command?.split('=')[1]);
    }
    if (command.includes(consoleCommands.UTM_FOCUS_ON)) {
      const values = transformIntoArray(command?.split('=')[1]);
      UiController.setUTMFocusOn(values);
    }
    if (command.includes(consoleCommands.BOOKING_PACE_WINDOW)) {
      UiController.setBookingPaceWindow(command?.split('=')[1]);
    }
  });
};
