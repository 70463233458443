import { useState, useEffect, useRef } from 'react';
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  FormControl,
  Toolbar,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsSignedIn,
  selectVisualFilters,
  selectBreakdownDetails,
  selectSelectedHotelGroupName,
  selectEnableHotelGroups,
} from 'modules/dashboard/selectors';
import {
  selectIPreferFocusOn,
  selectIPreferMetricSelection,
  selectIPreferOtb,
  selectIPreferTimeFrame,
  selectIPreferCustomDate,
  selectIPreferStartDate,
  selectIPreferEndDate,
  selectIPreferTargetDate,
  selectIPreferChartBreakdown,
  selectIPreferAlignment,
  selectIPreferDateComparison,
  selectIPreferAlignmentToggle,
  selectIPreferSymmetricComparisonToggle,
  selectIPreferIsDetailed,
} from 'modules/dashboard/components/tab-container/i-prefer-tab/selectors';
import { getComparisonDateRange, getSelectedBreakdown } from 'modules/dashboard/functions';
import { isEmpty } from 'modules/common/helpers/object';
import { capitalizeFirst } from 'modules/common/helpers/strings';
import {
  TAGS,
  KEYS,
  STAY_DATE,
  TREND_STAY_DATE,
  RESET_BOOKING_DATE,
  ALIGNED,
} from 'modules/dashboard/constants';
import ActionButton from 'modules/common/components/action-button';
import { CustomStack } from 'modules/common/components';
import { ENVIRONMENT } from 'config';
import DataUpdatedLabel from 'modules/dashboard/components/tab-container/data-updated-label';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import BookingWindow from 'modules/dashboard/components/tab-container/inner-filter/components/booking-window';
import BreakdownWindow from 'modules/dashboard/components/tab-container/inner-filter/components/breakdown-window';
import VsComparisonWindow from 'modules/dashboard/components/tab-container/inner-filter/components/vs-comparison-window';
import {
  getDateComparisonFilter,
  getFocusOnFilter,
  getSubAlignmentFilter,
  getSubBreakdownFilter,
  getSubSelectionMetricFilter,
  getSubTimeFrameFilter,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import useIPreferCommandFlow from '../../hooks/use-iprefer-command-flow';
import { iPreferActions } from '../../slice';
/**
 * Filters for Summary widget include PMS Sync, Booking Dates, Chart Breakdown,
 * Alignment, Date Comparison, Pace Horizon, pace variable and pace forecast
 * @param {String} reportType - Report type of active tab
 * @param {Object} visual- Symmetric comparison visual object
 * @param {Date} latestDate - Latest booking date of selected hotel
 * @param {String} hotelTimezone - Timezone of selected hotel
 * @param {Boolean} isLoaded - state of visuals are loaded
 * @returns
 */
const IPreferFilter = ({ reportType, visual, latestDate, hotelTimezone, isLoaded }) => {
  const dispatch = useDispatch();
  // Selected filter values are obtained from redux store. Refer each selector function for details.
  const visualFilters = useSelector(selectVisualFilters);
  const targetDate = useSelector(selectIPreferTargetDate);
  const customDate = useSelector(selectIPreferCustomDate);
  const startDate = useSelector(selectIPreferStartDate);
  const endDate = useSelector(selectIPreferEndDate);
  const customBooking = useSelector(selectIPreferOtb);
  const chartBreakdown = useSelector(selectIPreferChartBreakdown);
  const alignment = useSelector(selectIPreferAlignment);
  const dateComparison = useSelector(selectIPreferDateComparison);
  const alignmentToggle = useSelector(selectIPreferAlignmentToggle);
  const symmetricComparisonToggle = useSelector(selectIPreferSymmetricComparisonToggle);
  const isSignIn = useSelector(selectIsSignedIn);
  const isDetailed = useSelector(selectIPreferIsDetailed);
  const breakdownData = useSelector(selectBreakdownDetails);
  const focusOn = useSelector(selectIPreferFocusOn);
  const metricSelection = useSelector(selectIPreferMetricSelection);
  const timeFrame = useSelector(selectIPreferTimeFrame);
  const selectedHotelGroupName = useSelector(selectSelectedHotelGroupName);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  //
  const setCustomDatesRef = useRef(null);
  //
  const [dateComparisonList, setDateComparisonList] = useState([]);
  const [chartBreakdownList, setChartBreakdownList] = useState([]);
  const [focusOnList, setFocusOnList] = useState([]);
  const [selectedBreakdown, setSelectedBreakdown] = useState();
  const [metricSelectionList, setMetricSelectionList] = useState([]);
  const [timeFrameList, setTimeFrameList] = useState([]);

  // To select otb or custom booking window
  const handleOTBChange = (event) => {
    const { value } = event.target;
    dispatch(iPreferActions.setIPreferOtb(value !== 'false'));
    dispatch(iPreferActions.setIPreferBookingDate(RESET_BOOKING_DATE));
  };

  // Reset button function to set filter panel values to default
  const clearSearch = () => {
    dispatch(iPreferActions.setIPreferSymmetricComparisonToggle(true));
    dispatch(iPreferActions.setIPreferIsDetailed(false));
    dispatch(iPreferActions.setIPreferBookingDate(RESET_BOOKING_DATE));
    dispatch(iPreferActions.setIPreferStartDate(STAY_DATE.startDate));
    dispatch(iPreferActions.setIPreferEndDate(STAY_DATE.endDate));
    dispatch(iPreferActions.setIPreferCustomDate(KEYS.START_DATE));
    dispatch(iPreferActions.setIPreferDateSelected(STAY_DATE.startDate));
    dispatch(iPreferActions.setIPreferOtb(false));
    dispatch(iPreferActions.setIPreferFocusOn([]));
    dispatch(
      iPreferActions.setIPreferChartBreakdown(
        visualFilters?.filter(
          (filter) => filter?.tags?.includes(TAGS.SEGMENT_BREAKDOWN) && filter?.isDefault === true
        )[0]?.id
      )
    );
    const subAlignmentFilter = visualFilters?.filter(
      (filter) => filter?.tags?.includes(TAGS.DATE_COMPARISON) && filter?.isDefault === true
    )[0];
    dispatch(
      iPreferActions.setIPreferDateComparison(
        subAlignmentFilter?.values?.filter((filter) => filter.isDefault === true)[0].value
      )
    );
    dispatch(iPreferActions.setIPreferAlignmentToggle(subAlignmentFilter?.type === ALIGNED));
    dispatch(
      iPreferActions.setIPreferMetricSelection(
        visualFilters?.find(
          (filter) => filter?.tags?.includes(TAGS.METRIC_SELECTION) && filter?.isDefault === true
        )?.values[0]
      )
    );
    dispatch(
      iPreferActions.setIPreferTimeFrame(
        visualFilters.find(
          (filter) => filter?.tags?.includes(TAGS.I_PREFER_TIME_FRAME) && filter?.isDefault === true
        )?.id
      )
    );
  };
  // Triggered when visualFilters value changes
  useEffect(() => {
    // Set breakdown list, time frame list and metric selection list
    const subBreakdownFilter = getSubBreakdownFilter(visualFilters);
    if (subBreakdownFilter) {
      setChartBreakdownList(subBreakdownFilter);
    }
    const subSelectionMetricFilter = getSubSelectionMetricFilter(visualFilters);
    if (subSelectionMetricFilter) {
      setMetricSelectionList(subSelectionMetricFilter);
    }
    const subTimeFrameFilter = getSubTimeFrameFilter(visualFilters);
    if (subTimeFrameFilter) {
      setTimeFrameList(subTimeFrameFilter);
    }
  }, [visualFilters]);

  // Triggered when alignment, visualFilters, alignmentToggle changes
  useEffect(() => {
    // Set date comparison list based on alignment
    if (alignment) {
      const dateComparisonFilter = getDateComparisonFilter(visualFilters, alignment?.id);
      setDateComparisonList(dateComparisonFilter);
      // persist value on browser refresh
      const serializedState = JSON.parse(
        localStorage.getItem(`persist:${ENVIRONMENT.PERSIST_KEY}`)
      );
      if (!isSignIn && serializedState) {
        return;
      }
      dispatch(
        iPreferActions.setIPreferDateComparison(
          dateComparisonFilter?.filter((filter) => filter?.isDefault === true)[0].value
        )
      );
    }
  }, [alignment, visualFilters, alignmentToggle]);

  // Triggered when alignmentToggle or visualFilters value changes
  useEffect(() => {
    // Set alignment list
    const subAlignmentFilter = getSubAlignmentFilter(visualFilters, alignmentToggle);
    dispatch(
      iPreferActions.setIPreferAlignment({
        id: subAlignmentFilter?.id,
        label: capitalizeFirst(subAlignmentFilter?.type),
      })
    );
  }, [alignmentToggle, visualFilters]);

  // Alignment toggle change handler
  const handleToggleChange = (event) => {
    dispatch(iPreferActions.setIPreferAlignmentToggle(event.target.checked));
    const dateComparisonFilter = getDateComparisonFilter(visualFilters, alignment?.id);
    //
    dispatch(
      iPreferActions.setIPreferDateComparison(
        dateComparisonFilter?.filter((filter) => filter?.isDefault === true)[0].value
      )
    );
  };

  // Date comparison change handler
  const handleComparisonChange = (event) => {
    dispatch(iPreferActions.setIPreferDateComparison(event.target.value));
    dispatch(iPreferActions.setIPreferDateSelected(TREND_STAY_DATE.startDate));
    dispatch(iPreferActions.setIPreferCustomDate(KEYS.START_DATE));
    //
    const dateRange = getComparisonDateRange(
      TREND_STAY_DATE.startDate,
      targetDate.startDate,
      targetDate.endDate,
      KEYS.START_DATE
    );
    //
    dispatch(iPreferActions.setIPreferStartDate(dateRange?.comparisonStartDate.toISOString()));
    dispatch(iPreferActions.setIPreferEndDate(dateRange?.comparisonEndDate.toISOString()));
  };

  // Triggered on change of chartBreakdown, visualFilters, breakdownData or selectedBreakdown
  useEffect(() => {
    // Set focus on list relevant for selected chart breakdown
    if (chartBreakdown) {
      const focusOnFilter = getFocusOnFilter(breakdownData, selectedBreakdown);
      setFocusOnList(focusOnFilter);
    }
  }, [chartBreakdown, visualFilters, breakdownData, selectedBreakdown]);

  // Triggered on visualFilters, chartBreakdown or isDetails toggle change
  useEffect(() => {
    // Assign values to selected breakdown
    const breakdown = getSelectedBreakdown(visualFilters, chartBreakdown, isDetailed);
    setSelectedBreakdown(breakdown);
  }, [isDetailed, chartBreakdown, visualFilters]);
  //
  const focusOnLabel = focusOn?.length ? 'Focused on' : 'Select Focus on';
  //
  useEffect(() => {
    dispatch(iPreferActions.setIPreferFocusOn([]));
  }, [enableHotelGroups, selectedHotelGroupName]);
  //
  // I Prefer command flow implementation
  useIPreferCommandFlow(
    customBooking,
    handleComparisonChange,
    setCustomDatesRef,
    isLoaded,
    dateComparison,
    chartBreakdownList,
    focusOnList,
    visualFilters,
    timeFrameList,
    handleToggleChange,
    metricSelectionList
  );
  //
  return (
    <>
      <BookingWindow
        vOTB={customBooking}
        customBooking={customBooking}
        handleOTBChange={handleOTBChange}
        reportType={reportType}
        latestDate={latestDate}
        otbValue={false}
      />
      <br />
      <BreakdownWindow
        isDetailed={isDetailed}
        chartBreakdown={chartBreakdown}
        chartBreakdownList={chartBreakdownList}
        handleIsDetailedChange={(event) => {
          dispatch(iPreferActions.setIPreferIsDetailed(event.target.checked));
          dispatch(iPreferActions.setIPreferFocusOn([]));
        }}
        handleBreakdownChange={(event) => {
          dispatch(iPreferActions.setIPreferChartBreakdown(event.target.value));
          dispatch(iPreferActions.setIPreferFocusOn([]));
        }}
        isFocusOnAvailable
        focusOnList={focusOnList}
        focusOn={focusOn}
        focusOnLabel={focusOnLabel}
        handleFocusOnChange={(event, value) => dispatch(iPreferActions.setIPreferFocusOn(value))}
      />
      <br />
      <VsComparisonWindow
        customBooking={customBooking}
        alignmentToggle={alignmentToggle}
        handleToggleChange={handleToggleChange}
        symmetricComparisonToggle={symmetricComparisonToggle}
        visual={visual}
        dateComparison={dateComparison}
        dateComparisonList={dateComparisonList}
        handleComparisonChange={handleComparisonChange}
        handleSymmetricComparisonChange={(event) =>
          dispatch(iPreferActions.setIPreferSymmetricComparisonToggle(event.target.checked))
        }
        isCustomOptionAvailable
        customDate={customDate}
        reportType={reportType}
        startDate={startDate}
        endDate={endDate}
        targetDate={targetDate}
        handleCustomDates={(event) => {
          dispatch(iPreferActions.setIPreferCustomDate(event.target.value));
        }}
      />
      <br />
      <CustomStack cssClassName={styles.innerFilter}>
        {!isEmpty(metricSelectionList) && metricSelection && (
          <FormControl>
            <FormLabel>Metric Selection</FormLabel>
            <RadioGroup
              value={metricSelection}
              onChange={(event) =>
                dispatch(iPreferActions.setIPreferMetricSelection(event.target.value))
              }
            >
              {metricSelectionList?.map((item) => (
                <FormControlLabel
                  key={item?.id}
                  value={item?.label}
                  control={<Radio />}
                  label={item?.label}
                  sx={{
                    my: -1,
                  }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      </CustomStack>
      <br />
      <CustomStack cssClassName={styles.innerFilter}>
        {!isEmpty(timeFrameList) && timeFrame && (
          <FormControl>
            <FormLabel>Time Frame</FormLabel>
            <RadioGroup
              value={timeFrame}
              onChange={(event) => dispatch(iPreferActions.setIPreferTimeFrame(event.target.value))}
            >
              {timeFrameList?.map((item) => (
                <FormControlLabel
                  key={item?.id}
                  value={item?.id}
                  control={<Radio />}
                  label={item?.label}
                  sx={{
                    my: -1,
                  }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      </CustomStack>
      <br />
      <CustomStack cssClassName={styles.innerFilter}>
        <ActionButton onClick={clearSearch} label="RESET" />
      </CustomStack>
      <Toolbar />
      <DataUpdatedLabel latestDate={latestDate} hotelTimezone={hotelTimezone} />
    </>
  );
};
//
export default IPreferFilter;
