import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectVisualFilters,
  selectHotelName,
  selectPmsSync,
  selectTrendBookingDate,
  selectTrendTargetDate,
  selectWeeklyTrendEndDate,
  selectWeeklyTrendStartDate,
  selectSelectedHotelGroupName,
  selectEnableHotelGroups,
  selectReportType,
} from 'modules/dashboard/selectors';
import {
  selectTrendTimeDateSelected,
  selectIsCumulative,
  selectTrendTimeCustomDate,
  selectTrendTimeEndDate,
  selectTrendTimeStartDate,
  selectTrendTimeWeeklyView,
  selectTrendSymmetricComparisonToggle,
  selectTrendTimeAlignment,
  selectTrendTimeDateComparison,
  selectIsSegmentDetailed,
  selectTrendSegmentChartBreakdown,
  selectTrendSegmentFocusOn,
  selectIsForecast,
  selectTrendTimeFilterList,
  selectActiveTrendTab,
  selectIsPercentageChange,
} from 'modules/dashboard/components/tab-container/trend-tabs/selectors';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import { KEYS, REPORT_TYPE } from 'modules/dashboard/constants';
import {
  getBookingDateSchema,
  getCustomDateComparisonSchema,
  getHotelIdSchema,
  getIsForecastSchema,
  getPmsSyncToggleSchema,
  getRevenueForecastSchema,
  getRoomForecastSchema,
  getSegmentFocusOnSchema,
  getSymmetricComparisonSchema,
  getTargetDateSchema,
  getTimeSegmentBreakdownAndFocusOnSchema,
  getTrendTimeAlignmentAndDateComparisonSchema,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import { getSelectedBreakdown } from 'modules/dashboard/functions';
import writeLog from 'modules/common/utils/filter-log';
/**
 * Custom hook to generate Trend Time widget filter schemas
 */
const useTrendTime = () => {
  const dispatch = useDispatch();
  //
  const alignment = useSelector(selectTrendTimeAlignment);
  const dateComparison = useSelector(selectTrendTimeDateComparison);
  const bookingDate = useSelector(selectTrendBookingDate);
  const targetDate = useSelector(selectTrendTargetDate);
  const hotelId = useSelector(selectHotelName);
  const visualFilters = useSelector(selectVisualFilters);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const weeklyViewToggle = useSelector(selectTrendTimeWeeklyView);
  const targetStartDate = useSelector(selectWeeklyTrendStartDate);
  const targetEndDate = useSelector(selectWeeklyTrendEndDate);
  const symmetricComparisonToggle = useSelector(selectTrendSymmetricComparisonToggle);
  const isCumulative = useSelector(selectIsCumulative);
  const startDateComparison = useSelector(selectTrendTimeStartDate);
  const endDateComparison = useSelector(selectTrendTimeEndDate);
  const customDate = useSelector(selectTrendTimeCustomDate);
  const selectedDateComparison = useSelector(selectTrendTimeDateSelected);
  const focusOn = useSelector(selectTrendSegmentFocusOn);
  const chartBreakdown = useSelector(selectTrendSegmentChartBreakdown);
  const isDetailed = useSelector(selectIsSegmentDetailed);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const hotelGroupId = useSelector(selectSelectedHotelGroupName);
  const isTrendForecast = useSelector(selectIsForecast);
  const filterList = useSelector(selectTrendTimeFilterList);
  const activeTab = useSelector(selectActiveTrendTab);
  const reportType = useSelector(selectReportType);
  const isPercentageChange = useSelector(selectIsPercentageChange);
  //
  useEffect(() => {
    if (
      reportType !== REPORT_TYPE.TREND_TIME ||
      activeTab !== REPORT_TYPE.TREND_TIME ||
      (!dateComparison && !alignment && !bookingDate) ||
      (dateComparison && dateComparison[0] === KEYS.CUSTOM && endDateComparison === null)
    )
      return;
    const bookingDateFilter = getBookingDateSchema(
      visualFilters,
      bookingDate?.startDate,
      bookingDate?.endDate
    );
    const alignmentAndDateComparisonFilter = getTrendTimeAlignmentAndDateComparisonSchema(
      visualFilters,
      alignment?.id,
      dateComparison
    );
    const isForecastFilter = getIsForecastSchema(visualFilters, isTrendForecast);
    let startDate;
    let endDate;
    if (isCumulative) {
      startDate = targetDate?.startDate;
      endDate = targetDate?.endDate;
    } else {
      startDate = weeklyViewToggle ? targetStartDate : targetDate?.startDate;
      endDate = weeklyViewToggle ? targetEndDate : targetDate?.endDate;
    }
    const targetDateFilter = getTargetDateSchema(visualFilters, startDate, endDate);
    let hotelIdFilter = [];
    if (enableHotelGroups) {
      const hotelListIds = hotelGroupId?.hotels?.map((hotel) => hotel?.databricksId);
      hotelIdFilter = getHotelIdSchema(hotelListIds, visualFilters);
    } else {
      hotelIdFilter = getHotelIdSchema(hotelId?.databricksId, visualFilters);
    }
    const pmsSyncFilter = getPmsSyncToggleSchema(visualFilters, pmsSyncToggle);
    const symmetricComparisonFilter = getSymmetricComparisonSchema(
      visualFilters,
      symmetricComparisonToggle
    );
    const roomForecastFilter = getRoomForecastSchema(visualFilters, pmsSyncToggle);
    const revenueForecastFilter = getRevenueForecastSchema(visualFilters, pmsSyncToggle);
    const isCustomDateComparisonFilter = getCustomDateComparisonSchema(
      visualFilters,
      dateComparison,
      startDateComparison,
      endDateComparison
    );
    const selectedBreakdown = getSelectedBreakdown(visualFilters, chartBreakdown, isDetailed);
    const deAggregateSegmentAndFocusOnFilter = getTimeSegmentBreakdownAndFocusOnSchema(
      visualFilters,
      chartBreakdown,
      isDetailed,
      focusOn
    );
    const segmentFocusOnFilter = getSegmentFocusOnSchema(
      selectedBreakdown,
      focusOn?.map((item) => item?.label)
    );
    //
    if (
      bookingDateFilter &&
      alignmentAndDateComparisonFilter &&
      targetDateFilter &&
      hotelIdFilter &&
      roomForecastFilter &&
      revenueForecastFilter &&
      symmetricComparisonFilter
    ) {
      let filters = [
        ...pmsSyncFilter,
        bookingDateFilter,
        ...alignmentAndDateComparisonFilter,
        targetDateFilter,
        hotelIdFilter,
        symmetricComparisonFilter,
        roomForecastFilter,
        revenueForecastFilter,
        ...isCustomDateComparisonFilter,
      ];
      if (!isPercentageChange) {
        filters = [...filters, ...deAggregateSegmentAndFocusOnFilter];
      }
      const finalFilters = focusOn?.length
        ? [...filters, segmentFocusOnFilter]
        : [...filters, isForecastFilter];
      if (JSON.stringify(finalFilters) !== JSON.stringify(filterList)) {
        writeLog('Trend Time Filters', filters);
        dispatch(trendActions.updateTrendTimeFilterList(finalFilters));
      }
    }
  }, [
    bookingDate,
    visualFilters,
    dateComparison,
    alignment,
    targetDate,
    hotelId,
    pmsSyncToggle,
    weeklyViewToggle,
    symmetricComparisonToggle,
    targetStartDate,
    targetEndDate,
    isCumulative,
    startDateComparison,
    endDateComparison,
    selectedDateComparison,
    customDate,
    isDetailed,
    focusOn,
    enableHotelGroups,
    hotelGroupId,
    isTrendForecast,
    reportType,
    activeTab,
    isPercentageChange,
  ]);
};
//
export default useTrendTime;
