/* eslint-disable import/no-cycle */
import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { isEmpty } from 'modules/common/helpers/object';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, useFormikContext } from 'formik';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { selectNotification } from 'modules/common/notifications/selectors';
import { selectOrganizationId, selectAuthUser } from 'modules/common/auth/selectors';
import ActionButton from 'modules/common/components/action-button';
import { dashboardActions } from 'modules/dashboard/slice';
import { notificationActions } from 'modules/common/notifications/slice';
import { Modal } from 'modules/common/components';
import ERROR_TYPES from 'modules/common/constants/error-types';
import { Alert, TextField } from 'modules/common/components/add-hotel-group/style';
import addFilterFormValidation from 'modules/common/components/add-hotel-group/validation/add-filter-form-validation';
import {
  selectExistedHotelGroup,
  selectHotelGroupName,
  selectHotelGroupQuery,
  selectIsUserConfigurationNotExist,
} from 'modules/dashboard/selectors';

/**
 * used to inject formik context in-order to control form field from outside of formik context
 * @param {String} selectedFilterName - Name of the loaded hotel group
 * @constructor
 */
const FormikContextInjector = ({ selectedFilterName }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  useEffect(() => {
    if (selectedFilterName) {
      setFieldValue('filterName', selectedFilterName);
      setFieldTouched('filterName', false);
    } else {
      setFieldValue('filterName', '');
      setFieldTouched('filterName', false);
    }
  }, [selectedFilterName]);
};

/** Implementation to save hotel group
 * @param {Boolean} open - To open/ close dialog box
 * @param {Function} onClose - Function to trigger on close event
 * @returns
 */
const SaveFilterDialog = ({
  groupList,
  onClose,
  setShowLoadFilter,
  setGroupList,
  setTemGroupList,
}) => {
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const notification = useSelector(selectNotification);
  const currentUser = useSelector(selectAuthUser);
  const selectedHotelName = useSelector(selectHotelGroupName);
  const existedFilter = useSelector(selectExistedHotelGroup);
  const isUserConfigurationNotExist = useSelector(selectIsUserConfigurationNotExist);
  const hotelQuery = useSelector(selectHotelGroupQuery);
  //
  const [openOverwriteModal, setOpenOverwriteModal] = useState(false);
  const [saveValueObj, setSaveValueObj] = useState(null);

  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      dispatch(notificationActions.resetNotification());
      // setTemGroupList([]);
      // setGroupList([]);
      // dispatch(dashboardActions.setHotelGroupQuery(''));
      // dispatch(dashboardActions.setSelectedHotelGroupName(''));
      // onClose();
    }
  }, [notification]);
  // create save filter object and dispatch validate filter name
  const createFilterAndValidateFilterName = async (values) => {
    dispatch(dashboardActions.resetHotelGroupNameValidationStatus());
    const valueObj = {
      userId: currentUser?.id,
      organizationId,
      hotelGroups: [
        {
          hotelGroupName: values?.filterName.trim(),
          hotelQuery,
        },
      ],
    };
    //
    setSaveValueObj(valueObj);

    const query = `hotelGroupName=${values.filterName.trim()}`;
    dispatch(
      dashboardActions.getHotelGroupNameValidation({
        organizationId,
        userId: currentUser?.id,
        query,
      })
    );
  };
  // dispatch save hotel group , reset save object, filter validation state and close update warning modal
  const saveSubmit = (filterObject) => {
    dispatch(dashboardActions.createHotelGroup(filterObject));
    setSaveValueObj(null);
    dispatch(dashboardActions.resetHotelGroupNameValidationStatus());
    dispatch(dashboardActions.resetUserConfigurationStatus());
    setOpenOverwriteModal(false);
  };
  // checks hotel group name already exists and open update warning modal
  useEffect(() => {
    if (saveValueObj !== null && existedFilter !== null && !isUserConfigurationNotExist) {
      if (existedFilter) {
        setOpenOverwriteModal(true);
      } else {
        saveSubmit(saveValueObj);
      }
    }
    // if user configuration is not exist, save the hotel group
    if (isUserConfigurationNotExist && saveValueObj !== null && existedFilter === null) {
      saveSubmit(saveValueObj);
    }
  }, [saveValueObj, existedFilter, isUserConfigurationNotExist]);
  //
  useEffect(() => {
    dispatch(notificationActions.resetNotification());
  }, []);
  //
  return (
    <Formik
      initialValues={{
        filterName: selectedHotelName ?? '',
      }}
      validationSchema={addFilterFormValidation}
      onSubmit={(values) => createFilterAndValidateFilterName(values)}
    >
      {({ errors, handleBlur, setFieldValue, handleSubmit, values, touched, isSubmitting }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={4} paddingRight={1}>
              <TextField
                name="filterName"
                value={values.filterName}
                InputLabelProps={{ shrink: true }}
                error={Boolean(touched.filterName && errors.filterName)}
                fullWidth
                onBlur={handleBlur}
                onChange={(event) => {
                  setFieldValue('filterName', event.target.value);
                  dispatch(dashboardActions.setSelectedHotelGroupName(event.target.value));
                }}
                label="Hotel Set Name"
                size="small"
              />
              <span>
                <Typography
                  sx={{ whiteSpace: 'pre-line', fontSize: 11, color: 'red', minHeight: 15 }}
                >
                  {(touched.filterName && errors.filterName) ?? ' '}
                </Typography>
              </span>
            </Grid>
            <Grid item container flexDirection="row" xs={6} justifyContent="flex-end">
              <Grid item paddingRight={1}>
                <ActionButton
                  label="Load"
                  variant="contained"
                  onClick={(event) => {
                    event.preventDefault();
                    setShowLoadFilter(true);
                  }}
                />
              </Grid>
              <Grid item paddingRight={1}>
                <ActionButton
                  label="Cancel"
                  onClick={() => {
                    setGroupList([]);
                    setTemGroupList([]);
                    dispatch(dashboardActions.setHotelGroupQuery(''));
                    dispatch(dashboardActions.setSelectedHotelGroupName(''));
                    onClose();
                  }}
                  sx={{ maxWidth: 50 }}
                />
              </Grid>
              <Grid item>
                <ActionButton
                  label="Save"
                  variant="contained"
                  color="success"
                  onClick={() => {}}
                  disabled={isSubmitting || isEmpty(groupList)}
                />
              </Grid>
            </Grid>

            <FormikContextInjector selectedFilterName={selectedHotelName} />
          </Grid>
          <Grid>
            {notification?.isEnabled && notification?.type === ERROR_TYPES.ERROR && (
              <Alert severity={notification?.type}>{notification?.message}</Alert>
            )}
          </Grid>
          <Modal
            open={openOverwriteModal}
            handleClose={() => {
              dispatch(dashboardActions.resetHotelGroupNameValidationStatus());
              setOpenOverwriteModal(false);
            }}
            title="Update Hotel Set"
            content="Hotel Set Name already exists. Are you sure you want to overwrite the hotel set?"
            handleSuccess={() => saveSubmit(saveValueObj)}
            closeLabel="Cancel"
            successLabel="Update"
            variant="contained"
            color="error"
          />
        </form>
      )}
    </Formik>
  );
};
//
export default SaveFilterDialog;
