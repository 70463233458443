import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectHotelName,
  selectTargetDate,
  selectVisualFilters,
  selectPmsSync,
  selectPaceHorizon,
  selectEnableHotelGroups,
  selectSelectedHotelGroupName,
  selectReportType,
} from 'modules/dashboard/selectors';
import { PACE_HORIZON_SELECTION, REPORT_TYPE, TAGS } from 'modules/dashboard/constants';
import {
  getBookingDateSchema,
  getBreakdownAndFocusOnSchema,
  getHotelIdSchema,
  getPaceWidgetPaceHorizonSchema,
  getPmsSyncSchema,
  getTargetDateSchema,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import { paceActions } from 'modules/dashboard/components/tab-container/pace-tabs/slice';
import {
  selectIsPaceDetailed,
  selectIsPrimary,
  selectPaceBookingDate,
  selectPaceBookingPaceWindow,
  selectPaceChartBreakdown,
  selectPaceFocusOn,
  selectPaceWidgetSegmentFilterList,
} from 'modules/dashboard/components/tab-container/pace-tabs/selectors';
import writeLog from 'modules/common/utils/filter-log';

/**
 * Custom hook to generate summary widget filter schemas
 */
const usePaceSegmentWidget = () => {
  const dispatch = useDispatch();
  //
  const targetDate = useSelector(selectTargetDate);
  const hotelId = useSelector(selectHotelName);
  const visualFilters = useSelector(selectVisualFilters);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const paceHorizon = useSelector(selectPaceHorizon);
  const focusOn = useSelector(selectPaceFocusOn);
  const chartBreakdown = useSelector(selectPaceChartBreakdown);
  const isDetailed = useSelector(selectIsPaceDetailed);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const hotelGroupId = useSelector(selectSelectedHotelGroupName);
  const paceSegmentVisualFilterList = useSelector(selectPaceWidgetSegmentFilterList);
  const isSegment = useSelector(selectIsPrimary);
  const reportType = useSelector(selectReportType);
  const bookingDate = useSelector(selectPaceBookingDate);
  const bookingPaceWindow = useSelector(selectPaceBookingPaceWindow);

  // Generate filter list for pace segment visuals
  useEffect(() => {
    if (
      reportType !== REPORT_TYPE.PACE ||
      !chartBreakdown ||
      !paceHorizon ||
      !hotelId ||
      !visualFilters
    )
      return;
    const targetDateFilter = getTargetDateSchema(
      visualFilters,
      targetDate?.startDate,
      targetDate?.endDate
    );
    const bookingDateFilter = getBookingDateSchema(
      visualFilters,
      bookingDate?.startDate,
      bookingDate?.endDate
    );
    let hotelIdFilter = [];
    if (enableHotelGroups) {
      const hotelListIds = hotelGroupId?.hotels?.map((hotel) => hotel?.databricksId);
      hotelIdFilter = getHotelIdSchema(hotelListIds, visualFilters);
    } else {
      hotelIdFilter = getHotelIdSchema(hotelId?.databricksId, visualFilters);
    }
    const paceHorizonFilter = getPaceWidgetPaceHorizonSchema(visualFilters, paceHorizon, bookingPaceWindow === PACE_HORIZON_SELECTION.BOOKING_PACE_HORIZON);
    const segmentAndFocusOnFilter = getBreakdownAndFocusOnSchema(
      visualFilters,
      chartBreakdown,
      isDetailed,
      focusOn
    );
    const pmsSyncFilter = getPmsSyncSchema(visualFilters, pmsSyncToggle, TAGS.PACE_WIDGET);

    if (
      targetDateFilter &&
      hotelIdFilter &&
      paceHorizonFilter &&
      pmsSyncFilter &&
      segmentAndFocusOnFilter &&
      bookingDateFilter
    ) {
      const filterList = [
        targetDateFilter,
        hotelIdFilter,
        paceHorizonFilter,
        ...segmentAndFocusOnFilter,
        ...pmsSyncFilter,
        bookingDateFilter
      ];
      if (JSON.stringify(paceSegmentVisualFilterList) !== JSON.stringify(filterList)) {
        writeLog('Pace Segment Filters', filterList);
        dispatch(paceActions.setPaceWidgetSegmentFilterList(filterList));
      }
    }
  }, [
    pmsSyncToggle,
    visualFilters,
    targetDate,
    hotelId,
    paceHorizon,
    focusOn,
    chartBreakdown,
    isDetailed,
    enableHotelGroups,
    hotelGroupId,
    reportType,
    isSegment,
    bookingDate,
    bookingPaceWindow
  ]);
};
//
export default usePaceSegmentWidget;
