import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectHotelName,
  selectVisualFilters,
  selectIsDemoUser,
  selectPmsSync,
  selectSelectedHotelGroupName,
  selectEnableHotelGroups,
  selectReportType,
  // selectHotelGroupDropdownList,
  // selectHotelDropdownList,
} from 'modules/dashboard/selectors';
import {
  selectWebClickAlignmentToggle,
  selectWebClickCustomDate,
  selectWebClickOtb,
  selectWebClickAlignment,
  selectWebClickDateComparison,
  selectUtmFocusOn,
  selectUtmBreakdownSelection,
  selectWebClickBookingDate,
  selectWebClickTargetDate,
  selectWebClickPaceHorizon,
  selectWebClickSymmetricComparisonToggle,
  selectWebClickEndDate,
  selectWebClickStartDate,
  selectWebClickStep,
  selectWebClickHotelFocusOn,
  selectWebClickFocusOn,
  selectWebClickScalingFactor,
  selectWebClickNormalizedView,
  selectWebClickCumulativeView,
  selectWeeklyWebClickEndDate,
  selectWeeklyWebClickStartDate,
  selectWebClickWeeklyView,
  selectWebClickTimeVisualFilter,
  selectWebClickTimePaceVisualFilter,
  selectWebClickHotelVisualFilter,
  selectWebClickHotelPaceVisualFilter,
  selectWebClickSegmentVisualFilter,
  selectWebClickSegmentPaceVisualFilter,
  selectUtmFilterList,
  selectWebClickHotelGroupFocusOn,
  selectWebClickEnableSecondaryHotelGroups,
  selectTimeTrendIsDisplay,
  selectTimePaceIsDisplay,
  selectSegmentTrendIsDisplay,
  selectSegmentPaceIsDisplay,
  selectHotelTrendIsDisplay,
  selectHotelPaceIsDisplay,
  selectUtmTrendIsDisplay,
  selectUtmPaceIsDisplay,
} from 'modules/dashboard/components/tab-container/web-click-tab/selectors';
import { KEYS, REPORT_TYPE } from 'modules/dashboard/constants';
import {
  generateWebClicksShareUrl,
  getAlignmentAndDateComparisonSchema,
  getBookingDateSchema,
  getChannelFocusOnSchema,
  getCustomDateComparisonSchema,
  getGroupASchema,
  getGroupHotelFilterSchema,
  getHotelGroupASchema,
  getHotelGroupSchema,
  getHotelIdSchema,
  getHotelNameOrderSchema,
  getNormalizedViewSchema,
  getPaceHorizonSchema,
  getScaleFactorSchema,
  getSymmetricComparisonSchema,
  getTargetDateSchema,
  getUtmBreakdownSchema,
  getUtmFocusOnSchema,
  getWebClicksStepsSchema,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import { webClickActions } from 'modules/dashboard/components/tab-container/web-click-tab/slice';
import writeLog from 'modules/common/utils/filter-log';
import { ENVIRONMENT } from 'config';
import { selectSymmetricComparisonIsDisplay } from '../../summary-tab/selectors';

/**
 * Custom hook to generate web click view filter schemas
 */
const useWebClickView = () => {
  const dispatch = useDispatch();
  //
  const alignment = useSelector(selectWebClickAlignment);
  const dateComparison = useSelector(selectWebClickDateComparison);
  const bookingDate = useSelector(selectWebClickBookingDate);
  const targetDate = useSelector(selectWebClickTargetDate);
  const hotelId = useSelector(selectHotelName);
  const visualFilters = useSelector(selectVisualFilters);
  const paceHorizon = useSelector(selectWebClickPaceHorizon);
  const symmetricComparisonToggle = useSelector(selectWebClickSymmetricComparisonToggle);
  const endDate = useSelector(selectWebClickEndDate);
  const startDate = useSelector(selectWebClickStartDate);
  const focusOn = useSelector(selectWebClickFocusOn);
  const webClickStep = useSelector(selectWebClickStep);
  const scalingFactor = useSelector(selectWebClickScalingFactor);
  const normalizedViewToggle = useSelector(selectWebClickNormalizedView);
  const weeklyViewToggle = useSelector(selectWebClickWeeklyView);
  const targetStartDate = useSelector(selectWeeklyWebClickStartDate);
  const targetEndDate = useSelector(selectWeeklyWebClickEndDate);
  const isCumulative = useSelector(selectWebClickCumulativeView);
  const isDemoUser = useSelector(selectIsDemoUser);
  const vOTB = useSelector(selectWebClickOtb);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const alignmentToggle = useSelector(selectWebClickAlignmentToggle);
  const customDate = useSelector(selectWebClickCustomDate);
  const utmBreakdown = useSelector(selectUtmBreakdownSelection);
  const utmFocusOn = useSelector(selectUtmFocusOn);
  const hotelFocusOn = useSelector(selectWebClickHotelFocusOn);
  const hotelGroupId = useSelector(selectSelectedHotelGroupName);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const hotelGroupFocusOn = useSelector(selectWebClickHotelGroupFocusOn);
  const enableSecondaryHotelGroups = useSelector(selectWebClickEnableSecondaryHotelGroups);
  const reportType = useSelector(selectReportType);
  const timeVisualFilterList = useSelector(selectWebClickTimeVisualFilter);
  const timePaceVisualFilterList = useSelector(selectWebClickTimePaceVisualFilter);
  const hotelVisualFilterList = useSelector(selectWebClickHotelVisualFilter);
  const hotelPaceVisualFilterList = useSelector(selectWebClickHotelPaceVisualFilter);
  const segmentVisualFilterList = useSelector(selectWebClickSegmentVisualFilter);
  const segmentPaceVisualFilterList = useSelector(selectWebClickSegmentPaceVisualFilter);
  const utmVisualFilterList = useSelector(selectUtmFilterList);
  // const hotelGroupsDropdownList = useSelector(selectHotelGroupDropdownList);
  // const hotelDropdownList = useSelector(selectHotelDropdownList);
  const compareWindowCollapseToggle = useSelector(selectSymmetricComparisonIsDisplay);
  const timeTrendIsDisplay = useSelector(selectTimeTrendIsDisplay);
  const timePaceIsDisplay = useSelector(selectTimePaceIsDisplay);
  const segmentTrendIsDisplay = useSelector(selectSegmentTrendIsDisplay);
  const segmentPaceIsDisplay = useSelector(selectSegmentPaceIsDisplay);
  const hotelTrendIsDisplay = useSelector(selectHotelTrendIsDisplay);
  const hotelPaceIsDisplay = useSelector(selectHotelPaceIsDisplay);
  const utmTrendIsDisplay = useSelector(selectUtmTrendIsDisplay);
  const utmPaceIsDisplay = useSelector(selectUtmPaceIsDisplay);

  //
  const [previousHotel, setPreviousHotel] = useState(null);
  const [previousHotelGroup, setPreviousHotelGroup] = useState(null);
  //
  const serializedState = JSON.parse(localStorage.getItem(`persist:${ENVIRONMENT.PERSIST_KEY}`));
  const persistValues = Object.values(serializedState)[1];
  const persistedHotelName = JSON.parse(persistValues).hotelName;
  const persistedHotelGroupName = JSON.parse(persistValues).selectedHotelGroupName;
  //
  useEffect(() => {
    // Persist hotel focus on value on browser refresh
    setPreviousHotel(persistedHotelName);
    if (previousHotel !== null) {
      // dispatch(webClickActions.setWebClickHotelFocusOn(hotelDropdownList[0]));
    }
  }, [hotelId]);

  useEffect(() => {
    // Persist hotel group focus on value on browser refresh
    setPreviousHotelGroup(persistedHotelGroupName);
    if (previousHotelGroup !== null) {
      // dispatch(webClickActions.setWebClickHotelGroupFocusOn(hotelGroupsDropdownList[0]));
    }
  }, [hotelGroupId]);
  // Generate filter list for web clicks visuals
  useEffect(() => {
    if (
      reportType !== REPORT_TYPE.WEB_CLICK ||
      (!dateComparison && !alignment && !bookingDate) ||
      (dateComparison && dateComparison === KEYS.CUSTOM && endDate === null)
    )
      return;
    const bookingDateFilter = getBookingDateSchema(
      visualFilters,
      bookingDate?.startDate,
      bookingDate?.endDate
    );
    const alignmentAndDateComparisonFilter = getAlignmentAndDateComparisonSchema(
      visualFilters,
      alignment?.id,
      dateComparison
    );
    //
    let newStartDate;
    let newEndDate;
    if (isCumulative) {
      newStartDate = targetDate?.startDate;
      newEndDate = targetDate?.endDate;
    } else {
      newStartDate = weeklyViewToggle ? targetStartDate : targetDate?.startDate;
      newEndDate = weeklyViewToggle ? targetEndDate : targetDate?.endDate;
    }
    const targetDateFilter = getTargetDateSchema(visualFilters, newStartDate, newEndDate);
    //
    let hotelIdFilter = [];
    if (enableHotelGroups) {
      const hotelListIds = hotelGroupId?.hotels?.map((hotel) => hotel?.databricksId);
      hotelIdFilter = getHotelIdSchema(hotelListIds, visualFilters);
    } else {
      hotelIdFilter = getHotelIdSchema(hotelId?.databricksId, visualFilters);
    }
    const paceHorizonFilter = getPaceHorizonSchema(visualFilters, paceHorizon);
    const symmetricComparisonFilter = getSymmetricComparisonSchema(
      visualFilters,
      symmetricComparisonToggle
    );
    const isCustomDateComparisonFilter = getCustomDateComparisonSchema(
      visualFilters,
      dateComparison,
      startDate,
      endDate
    );
    const webClickStepsFilter = getWebClicksStepsSchema(visualFilters, webClickStep);
    //
    if (
      bookingDateFilter &&
      alignmentAndDateComparisonFilter &&
      targetDateFilter &&
      hotelIdFilter &&
      paceHorizonFilter &&
      symmetricComparisonFilter &&
      webClickStepsFilter
    ) {
      const filters = [
        bookingDateFilter,
        ...alignmentAndDateComparisonFilter,
        targetDateFilter,
        hotelIdFilter,
        symmetricComparisonFilter,
        ...isCustomDateComparisonFilter,
        webClickStepsFilter,
        paceHorizonFilter,
      ];

      // trend time filter list
      if (JSON.stringify(filters) !== JSON.stringify(timeVisualFilterList)) {
        writeLog('Web clicks Time Visual Filter', filters);
        dispatch(webClickActions.setWebClickTimeVisualFilter(filters));
      }
    }
    if (
      alignmentAndDateComparisonFilter &&
      targetDateFilter &&
      hotelIdFilter &&
      paceHorizonFilter &&
      webClickStepsFilter
    ) {
      const filters = [
        ...alignmentAndDateComparisonFilter,
        targetDateFilter,
        hotelIdFilter,
        ...isCustomDateComparisonFilter,
        webClickStepsFilter,
        paceHorizonFilter,
      ];

      // time pace filter list
      if (JSON.stringify(filters) !== JSON.stringify(timePaceVisualFilterList)) {
        writeLog('Web clicks Time Pace Visual Filter', filters);
        dispatch(webClickActions.setWebClickTimePaceVisualFilter(filters));
      }
    }
  }, [
    bookingDate,
    visualFilters,
    targetDate,
    hotelId,
    paceHorizon,
    endDate,
    startDate,
    webClickStep,
    targetStartDate,
    targetEndDate,
    isCumulative,
    weeklyViewToggle,
    hotelGroupId,
    enableHotelGroups,
    reportType,
    alignment,
    symmetricComparisonToggle,
    dateComparison,
  ]);

  // Generate filter list for segment visuals
  useEffect(() => {
    if (reportType !== REPORT_TYPE.WEB_CLICK) return;
    const bookingDateFilter = getBookingDateSchema(
      visualFilters,
      bookingDate?.startDate,
      bookingDate?.endDate
    );
    let newStartDate;
    let newEndDate;
    if (isCumulative) {
      newStartDate = targetDate?.startDate;
      newEndDate = targetDate?.endDate;
    } else {
      newStartDate = weeklyViewToggle ? targetStartDate : targetDate?.startDate;
      newEndDate = weeklyViewToggle ? targetEndDate : targetDate?.endDate;
    }
    const targetDateFilter = getTargetDateSchema(visualFilters, newStartDate, newEndDate);
    //
    let hotelIdFilter = [];
    if (enableHotelGroups) {
      const hotelListIds = hotelGroupId?.hotels?.map((hotel) => hotel?.databricksId);
      hotelIdFilter = getHotelIdSchema(hotelListIds, visualFilters);
    } else {
      hotelIdFilter = getHotelIdSchema(hotelId?.databricksId, visualFilters);
    }
    const paceHorizonFilter = getPaceHorizonSchema(visualFilters, paceHorizon);
    const webClickStepsFilter = getWebClicksStepsSchema(visualFilters, webClickStep);
    const ChannelFocusOnFilter = getChannelFocusOnSchema(visualFilters, focusOn);
    const scaleFactorFilter = getScaleFactorSchema(visualFilters, scalingFactor);
    // trend segment filter list
    if (
      bookingDateFilter &&
      targetDateFilter &&
      hotelIdFilter &&
      webClickStepsFilter &&
      ChannelFocusOnFilter &&
      scaleFactorFilter
    ) {
      const filters = [
        bookingDateFilter,
        targetDateFilter,
        hotelIdFilter,
        webClickStepsFilter,
        ChannelFocusOnFilter,
        scaleFactorFilter,
      ];

      if (JSON.stringify(filters) !== JSON.stringify(segmentVisualFilterList)) {
        writeLog('Web clicks Segment Visual Filter', filters);
        dispatch(webClickActions.setWebClickSegmentVisualFilter(filters));
      }
    }
    // segment pace filter list
    if (
      targetDateFilter &&
      hotelIdFilter &&
      paceHorizonFilter &&
      webClickStepsFilter &&
      ChannelFocusOnFilter &&
      scaleFactorFilter
    ) {
      const filters = [
        targetDateFilter,
        hotelIdFilter,
        webClickStepsFilter,
        paceHorizonFilter,
        ChannelFocusOnFilter,
        scaleFactorFilter,
      ];

      if (JSON.stringify(filters) !== JSON.stringify(segmentPaceVisualFilterList)) {
        writeLog('Web Clicks Segment Pace Visual Filter', filters);
        dispatch(webClickActions.setWebClickSegmentPaceVisualFilter(filters));
      }
    }
  }, [
    bookingDate,
    visualFilters,
    targetDate,
    hotelId,
    paceHorizon,
    webClickStep,
    focusOn,
    scalingFactor,
    targetStartDate,
    targetEndDate,
    isCumulative,
    weeklyViewToggle,
    hotelGroupId,
    enableHotelGroups,
    reportType,
  ]);

  // Generate filter list for web clicks hotel visuals
  useEffect(() => {
    if (reportType !== REPORT_TYPE.WEB_CLICK) return;
    const bookingDateFilter = getBookingDateSchema(
      visualFilters,
      bookingDate?.startDate,
      bookingDate?.endDate
    );
    let newStartDate;
    let newEndDate;
    if (isCumulative) {
      newStartDate = targetDate?.startDate;
      newEndDate = targetDate?.endDate;
    } else {
      newStartDate = weeklyViewToggle ? targetStartDate : targetDate?.startDate;
      newEndDate = weeklyViewToggle ? targetEndDate : targetDate?.endDate;
    }
    const targetDateFilter = getTargetDateSchema(visualFilters, newStartDate, newEndDate);
    const groupFocusOnIds = hotelGroupFocusOn?.hotels?.map((hotel) => hotel?.databricksId) ?? [];
    let hotelGroup1Filter;
    const hotelListIds = hotelGroupId?.hotels?.map((hotel) => hotel?.databricksId) ?? [];
    //
    const groupHotel1Filter = getGroupHotelFilterSchema(visualFilters);
    if (enableHotelGroups) {
      hotelGroup1Filter = getHotelGroupSchema(hotelListIds, groupHotel1Filter);
    } else {
      hotelGroup1Filter = getHotelGroupSchema([hotelId?.databricksId], groupHotel1Filter);
    }
    //
    const groupHotel2Filter = getGroupASchema(visualFilters);
    let hotelGroup2Filter;
    if (enableSecondaryHotelGroups) {
      hotelGroup2Filter = getHotelGroupASchema(groupFocusOnIds, groupHotel2Filter);
    } else {
      hotelGroup2Filter = getHotelGroupASchema([hotelFocusOn?.databricksId], groupHotel2Filter);
    }
    //
    let dimHotelIds = [];
    if (enableHotelGroups) {
      if (enableSecondaryHotelGroups) {
        dimHotelIds = new Set([...groupFocusOnIds, ...hotelListIds]);
      } else {
        dimHotelIds = new Set([hotelFocusOn?.databricksId, ...hotelListIds]);
      }
    } else if (enableSecondaryHotelGroups) {
      dimHotelIds = new Set([...groupFocusOnIds, hotelId?.databricksId]);
    } else {
      dimHotelIds = new Set([hotelFocusOn?.databricksId, hotelId?.databricksId]);
    }
    //
    const secondaryHotelIdFilter = getHotelIdSchema(Array.from(dimHotelIds), visualFilters);
    const hotelFilters = [secondaryHotelIdFilter, hotelGroup2Filter, hotelGroup1Filter];
    const hotelNameOrderFilter = getHotelNameOrderSchema(visualFilters, isDemoUser);
    const paceHorizonFilter = getPaceHorizonSchema(visualFilters, paceHorizon);
    const webClickStepsFilter = getWebClicksStepsSchema(visualFilters, webClickStep);
    const normalizedFilter = getNormalizedViewSchema(visualFilters, normalizedViewToggle);
    // trend hotel filter list
    if (
      bookingDateFilter &&
      targetDateFilter &&
      hotelFilters &&
      webClickStepsFilter &&
      normalizedFilter &&
      hotelNameOrderFilter
    ) {
      const filters = [
        bookingDateFilter,
        targetDateFilter,
        ...hotelFilters,
        webClickStepsFilter,
        normalizedFilter,
        hotelNameOrderFilter,
      ];

      if (JSON.stringify(filters) !== JSON.stringify(hotelVisualFilterList)) {
        writeLog('Web Click Hotel Visual Filter', filters);
        dispatch(webClickActions.setWebClickHotelVisualFilter(filters));
      }
    }
    // hotel pace filter list
    if (
      targetDateFilter &&
      hotelFilters &&
      paceHorizonFilter &&
      webClickStepsFilter &&
      hotelNameOrderFilter &&
      normalizedFilter
    ) {
      const filters = [
        targetDateFilter,
        ...hotelFilters,
        webClickStepsFilter,
        hotelNameOrderFilter,
        paceHorizonFilter,
        normalizedFilter,
      ];

      if (JSON.stringify(filters) !== JSON.stringify(hotelPaceVisualFilterList)) {
        writeLog('Web Click Hotel Pace Visual Filter', filters);
        dispatch(webClickActions.setWebClickHotelPaceVisualFilter(filters));
      }
    }
  }, [
    bookingDate,
    visualFilters,
    targetDate,
    hotelId,
    paceHorizon,
    webClickStep,
    normalizedViewToggle,
    hotelFocusOn,
    targetStartDate,
    targetEndDate,
    isCumulative,
    weeklyViewToggle,
    hotelGroupId,
    enableHotelGroups,
    reportType,
    enableSecondaryHotelGroups,
    hotelGroupFocusOn,
  ]);

  // Generate utm visuals filter list
  useEffect(() => {
    if (reportType !== REPORT_TYPE.WEB_CLICK) return;
    const bookingDateFilter = getBookingDateSchema(
      visualFilters,
      bookingDate?.startDate,
      bookingDate?.endDate
    );
    let newStartDate;
    let newEndDate;
    if (isCumulative) {
      newStartDate = targetDate?.startDate;
      newEndDate = targetDate?.endDate;
    } else {
      newStartDate = weeklyViewToggle ? targetStartDate : targetDate?.startDate;
      newEndDate = weeklyViewToggle ? targetEndDate : targetDate?.endDate;
    }
    const targetDateFilter = getTargetDateSchema(visualFilters, newStartDate, newEndDate);
    //
    let hotelIdFilter = [];
    if (enableHotelGroups) {
      const hotelListIds = hotelGroupId?.hotels?.map((hotel) => hotel?.databricksId);
      hotelIdFilter = getHotelIdSchema(hotelListIds, visualFilters);
    } else {
      hotelIdFilter = getHotelIdSchema(hotelId?.databricksId, visualFilters);
    }
    const paceHorizonFilter = getPaceHorizonSchema(visualFilters, paceHorizon);
    const webClickStepsFilter = getWebClicksStepsSchema(visualFilters, webClickStep);
    const utmBreakdownFilter = getUtmBreakdownSchema(visualFilters, utmBreakdown);
    const UtmFocusOnFilter = getUtmFocusOnSchema(visualFilters, utmFocusOn);
    //
    if (
      bookingDateFilter &&
      targetDateFilter &&
      hotelIdFilter &&
      webClickStepsFilter &&
      utmBreakdownFilter &&
      UtmFocusOnFilter &&
      paceHorizonFilter
    ) {
      const filters = [
        bookingDateFilter,
        targetDateFilter,
        paceHorizonFilter,
        hotelIdFilter,
        webClickStepsFilter,
        utmBreakdownFilter,
        UtmFocusOnFilter,
      ];

      if (JSON.stringify(filters) !== JSON.stringify(utmVisualFilterList)) {
        writeLog('UTM Filter', filters);
        dispatch(webClickActions.setUtmFilterList(filters));
      }
    }
  }, [
    bookingDate,
    visualFilters,
    targetDate,
    hotelId,
    paceHorizon,
    endDate,
    startDate,
    webClickStep,
    targetStartDate,
    targetEndDate,
    isCumulative,
    weeklyViewToggle,
    utmFocusOn,
    utmBreakdown,
    hotelGroupId,
    enableHotelGroups,
    reportType,
  ]);

  // Triggers when filter values dependencies changes
  useEffect(() => {
    if (!bookingDate && !targetDate && !hotelId && !paceHorizon && !dateComparison) return;
    // Generate web clicks filter url
    const webClickUrl = generateWebClicksShareUrl(
      pmsSyncToggle,
      hotelId,
      targetDate,
      dateComparison,
      vOTB,
      visualFilters,
      paceHorizon,
      startDate,
      endDate,
      customDate,
      weeklyViewToggle,
      isCumulative,
      normalizedViewToggle,
      webClickStep,
      scalingFactor,
      hotelFocusOn,
      focusOn,
      bookingDate,
      alignmentToggle,
      symmetricComparisonToggle,
      enableHotelGroups,
      hotelGroupId,
      enableSecondaryHotelGroups,
      hotelGroupFocusOn,
      compareWindowCollapseToggle,
      utmFocusOn,
      utmBreakdown,
      timeTrendIsDisplay,
      timePaceIsDisplay,
      segmentTrendIsDisplay,
      segmentPaceIsDisplay,
      hotelTrendIsDisplay,
      hotelPaceIsDisplay,
      utmTrendIsDisplay,
      utmPaceIsDisplay
    );
    //
    dispatch(webClickActions.setWebClickUrl(webClickUrl));
  }, [
    pmsSyncToggle,
    bookingDate,
    visualFilters,
    alignmentToggle,
    targetDate,
    hotelId,
    paceHorizon,
    symmetricComparisonToggle,
    vOTB,
    weeklyViewToggle,
    isCumulative,
    normalizedViewToggle,
    focusOn,
    hotelFocusOn,
    scalingFactor,
    webClickStep,
    startDate,
    endDate,
    customDate,
    enableHotelGroups,
    hotelGroupId,
    enableSecondaryHotelGroups,
    hotelGroupFocusOn,
    utmFocusOn,
    utmBreakdown,
    timeTrendIsDisplay,
    timePaceIsDisplay,
    segmentTrendIsDisplay,
    segmentPaceIsDisplay,
    hotelTrendIsDisplay,
    hotelPaceIsDisplay,
    utmTrendIsDisplay,
    utmPaceIsDisplay,
    compareWindowCollapseToggle,
  ]);
};
//
export default useWebClickView;
