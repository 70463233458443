/**
 * Custom aggregation feature related constant are defined here
 */
export const OPERATORS = {
  EQUAL: '=',
  NOT_EQUAL: '!=',
  AND: 'and',
  OR: ' or ',
  LIKE: 'like',
  SPACE: ' ',
};
//
export const LABELS = {
  GROUP_HOTEL: 'Hotel Set',
};
//
export const SPLIT_AND_REGEX =
  /(?:[^"']*"[^"']*"[^"']*)*([^"']*and[^"']*"[^"']*"[^"']*)*(.*?)(?:[^"']*"[^"']*"[^"']*)*$/;
