import { ENVIRONMENT } from 'config';
import * as Sentry from '@sentry/react';
import store from 'store/index';
import { SLICE } from 'modules/dashboard/constants';

let sessionId;
let user;

const writeLog = (filterName, filters) => {
  if (!sessionId) {
    store?.subscribe(() => {
      sessionId = store?.getState()[SLICE.BASE_AUTH]?.sessionId;
      user = store?.getState()[SLICE.BASE_AUTH]?.user;
    });
    sessionId = store?.getState()[SLICE.BASE_AUTH]?.sessionId;
    user = store?.getState()[SLICE.BASE_AUTH]?.user;
  }

  if (ENVIRONMENT.PBI_EMBED_DEBUG_MODE?.toLowerCase() === 'true') {
    let filterLog = `${filterName} : ${new Date().toISOString()} - `;

    filters.forEach((filter) => {
      if (filter.$schema.includes('basic')) {
        filterLog = filterLog.concat(
          `{table: '${filter?.target?.table}', column : '${filter?.target?.column}'} '${
            filter?.operator
          }' [${filter?.values.join(',')}] | `
        );
      } else {
        let advancedCondition = '';
        filter?.conditions.forEach((con) => {
          advancedCondition = advancedCondition.concat(
            `{operator: '${con.operator}', value: '${con.value}'} `
          );
        });
        filterLog = filterLog.concat(
          `{table: '${filter?.target?.table}', column : '${filter?.target?.column}'} '${filter?.logicalOperator}' [${advancedCondition}] | `
        );
      }
    });

    // eslint-disable-next-line no-console
    console.log(filterLog);
    if (ENVIRONMENT.ENABLE_SENTRY?.toLowerCase() === 'true') {
      Sentry.captureEvent({
        message: filterLog,
        level: 'info',
        user: { username: user?.username || 'undefined' },
        tags: {
          sessionId,
          visualtype: 'filter',
        },
      });
    }
  }
};

export default writeLog;
