import { Grid, FormControl, FormControlLabel, Switch, Tooltip } from '@mui/material';
/**
 * Custom toggle switch implementation
 * @param {String} label - label of the switch component
 * @param {Function} onChange - trigger the function when switch change
 * @param {String} title - title for the tooltip
 * @param {Boolean} checked - selected status of the toggle switch
 * @param {String} sx - custom style for entire component
 * @param {String} placement - element placement of the component
 * @param {String} size - size of toggle switch
 * @param {String} labelPlacement - placement of toggle label
 * @returns
 */
const CustomToggleSwitch = ({
  label,
  onChange,
  title = '',
  checked = false,
  sx = {},
  placement = 'top',
  disabled = false,
  size = 'medium',
  labelPlacement = 'end',
  secondaryLabel = null,
}) => (
  <FormControl sx={{ paddingBottom: 3 }}>
    <Tooltip title={title} placement={placement}>
      <Grid container direction="row" alignContent="center">
        <Grid item>
          <FormControlLabel
            control={
              <Switch checked={checked} onChange={onChange} disabled={disabled} size={size} />
            }
            label={!secondaryLabel && label}
            labelPlacement={labelPlacement}
            sx={{
              width: 'fit-content',
              height: 5,
              ...sx,
            }}
          />
        </Grid>
        {secondaryLabel && (
          <Grid item marginLeft={-4} sx={{ color: disabled ? 'gray' : 'inherit' }}>
            {label}
            <span style={{ fontSize: 11.5, color: 'gray' }}> {secondaryLabel}</span>
          </Grid>
        )}
      </Grid>
    </Tooltip>
  </FormControl>
);
//
export default CustomToggleSwitch;
