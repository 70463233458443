import {
  COLLAPSE_VISUALS,
  UiController,
  breakdownList,
  commands,
  compareWindows,
  events,
} from 'modules/common/ui-controller';
import { KEYS, RESET_BOOKING_DATE } from 'modules/dashboard/constants';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getValuesFromList } from 'modules/common/utils/array';
import { trendTabularTabActions } from '../slice';

/**
 * Custom hook to execute tabular widget filter command flow
 * @param {*} isLoaded 
 * @param {*} customBooking 
 * @param {*} chartBreakdownList 
 * @param {*} visualFilters 
 * @param {*} dateComparison 
 * @param {*} dateComparisonList 
 * @param {*} handleAlignmentToggleChange 
 * @param {*} focusOnList 
 * @param {*} setArbitraryComparisonDate 
 * @param {*} setCustomDatesRef 
 */
const useTrendTabularCommandFlow = (
  isLoaded,
  customBooking,
  chartBreakdownList,
  visualFilters,
  dateComparison,
  dateComparisonList,
  handleAlignmentToggleChange,
  focusOnList,
  setArbitraryComparisonDate,
  setCustomDatesRef
) => {
  const dispatch = useDispatch();
  const [compareWindowValueCommand, setCompareWindowValueCommand] = useState(null);
  const [customDatesValueCommand, setCustomDatesValueCommand] = useState(null);
  const [breakdownCommandValue, setBreakdownCommandValue] = useState(null);
  const [customBookingCommandValue, setCustomBookingCommandValue] = useState(null);
  const [symmetricComparisonCollapseCommandValue, setSymmetricComparisonCollapseCommandValue] =
    useState(null);
  const [alignmentCommandValue, setAlignmentCommandValue] = useState(null);
  const [visualCollapseCommandValue, setVisualCollapseCommandValue] = useState(null);
  const [options, setOptions] = useState(null);

  // set focus on command value
  const setTrendSegmentFocusOnHandler = (data) => {
    // get corresponding focus on value and execute dispatch function
    const optionsSelected = getValuesFromList(data, focusOnList);
    if (optionsSelected?.length > 0) {
      dispatch(trendTabularTabActions.setTrendSegmentFocusOn(optionsSelected));
    } else {
      dispatch(trendTabularTabActions.setTrendSegmentFocusOn([]));
    }
    setOptions(null);
  };
  // Triggers when options, focusOnList, isLoaded values change
  useEffect(() => {
    if (focusOnList.length > 0 && options && isLoaded) {
      setTimeout(() => {
        setTrendSegmentFocusOnHandler(options);
      }, 2000);
    }
  }, [options, focusOnList?.length, isLoaded]);
  useEffect(() => {
    if (alignmentCommandValue !== null && isLoaded && visualFilters) {
      handleAlignmentToggleChange({
        target: {
          checked: alignmentCommandValue,
        },
      });
    }
  }, [alignmentCommandValue, isLoaded, visualFilters]);
  // set alignment toggle command value
  const setSummaryAlignmentToggleHandler = (value) => {
    setAlignmentCommandValue(value);
  };
  //
  const compareWindowCommandHandler = (value, comparison) => {
    if (comparison) {
      // get compare window value
      const windowSelected = compareWindows[value];
      if (windowSelected !== undefined) {
        const index = comparison.indexOf(windowSelected);
        // checks if option is already selected
        if (index === -1) {
          dispatch(trendTabularTabActions.setTrendTimeDateComparison(windowSelected));
        }
        if (windowSelected === KEYS.CUSTOM) {
          setArbitraryComparisonDate();
        }
      }
    }
  };

  // Triggers when compareWindowValueCommand, date comparison and isLoaded value change
  useEffect(() => {
    if (compareWindowValueCommand !== null && dateComparison?.length > 0 && isLoaded) {
      compareWindowCommandHandler(compareWindowValueCommand, dateComparison);
      setCompareWindowValueCommand(null);
    }
  }, [compareWindowValueCommand, dateComparison?.length, isLoaded]);
  // reference function to set custom compare window
  setCustomDatesRef.current = () => {
    setTimeout(() => {
      const datesExtracted = customDatesValueCommand?.split('-');
      dispatch(trendTabularTabActions.setTrendTimeCustomDate(datesExtracted[0]));
      UiController.setCustomDateRange(customDatesValueCommand);
      setCustomDatesValueCommand(null);
    }, 2000);
  };
  // Triggers when customDatesValueCommand, dateComparison, isLoaded values change
  useEffect(() => {
    if (customDatesValueCommand !== null && isLoaded) {
      // check date comparison value is custom
      if (dateComparison && dateComparison?.includes(KEYS.CUSTOM)) {
        setCustomDatesRef?.current();
      }
    }
  }, [customDatesValueCommand, dateComparison, isLoaded]);
  // Triggers when customBookingCommandValue, isLoaded change
  useEffect(() => {
    if (customBookingCommandValue !== null && isLoaded) {
      if (customBookingCommandValue === commands.OTB) {
        dispatch(trendTabularTabActions.setTrendOtb(false));
        dispatch(trendTabularTabActions.setTrendBookingStyle(false));
        dispatch(trendTabularTabActions.setTrendBookingDate(RESET_BOOKING_DATE));
        setCustomBookingCommandValue(null);
      } else {
        dispatch(trendTabularTabActions.setTrendOtb(true));
        dispatch(trendTabularTabActions.setTrendBookingStyle(true));
      }
    }
  }, [customBookingCommandValue, isLoaded]);
  // Triggers when customBookingCommandValue,customBooking, isLoaded change
  useEffect(() => {
    if (
      customBookingCommandValue !== null &&
      customBookingCommandValue !== commands.OTB &&
      customBooking &&
      isLoaded
    ) {
      // emit set custom booking date range event
      UiController.setBookingDateRange(customBookingCommandValue);
      setCustomBookingCommandValue(null);
    }
  }, [customBookingCommandValue, customBooking, isLoaded]);

  // Triggers when breakdownCommandValue,chartBreakdownList, isLoaded change
  useEffect(() => {
    if (breakdownCommandValue !== null && chartBreakdownList?.length > 0 && isLoaded) {
      // get breakdown option value
      const breakdownOption = breakdownList[breakdownCommandValue?.substring(0, 2)];
      // get isDetailed state from command
      const detailsStatus = parseInt(breakdownCommandValue?.substring(2, 4), 10);
      if (breakdownOption !== undefined) {
        const option = chartBreakdownList?.filter((val) => val?.label?.includes(breakdownOption));
        if (option?.length > 0) {
          dispatch(trendTabularTabActions.setTrendSegmentChartBreakdown(option[0]?.id));
        }
        // set isDetailed  state
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(detailsStatus)) {
          dispatch(trendTabularTabActions.setIsSegmentDetailed(detailsStatus === 1));
        }
      }
      setBreakdownCommandValue(null);
    }
  }, [breakdownCommandValue, chartBreakdownList?.length, isLoaded]);

  // Triggers when customDatesValueCommand and dateComparison values change
  useEffect(() => {
    if (customDatesValueCommand !== null) {
      const datesExtracted = customDatesValueCommand?.split('-');
      // emit event to set custom date range
      if (dateComparison && dateComparison === KEYS.CUSTOM) {
        dispatch(trendTabularTabActions.setTrendTimeCustomDate(datesExtracted[0]));
        UiController.setCustomDateRange(customDatesValueCommand);
        setCustomDatesValueCommand(null);
      }
    }
  }, [customDatesValueCommand, dateComparison]);
  // Triggers when symmetricComparisonCollapseCommandValue, isLoaded change
  useEffect(() => {
    if (symmetricComparisonCollapseCommandValue !== null && isLoaded) {
      dispatch(
        trendTabularTabActions.setSymmetricComparisonIsDisplay(
          symmetricComparisonCollapseCommandValue === 1
        )
      );
      setSymmetricComparisonCollapseCommandValue(null);
    }
  }, [symmetricComparisonCollapseCommandValue, isLoaded]);
  // Triggers when visualCollapseCommandValue change
  useEffect(() => {
    if (visualCollapseCommandValue !== null && isLoaded) {
      const visuals = visualCollapseCommandValue?.split(',');
      visuals?.forEach((visual) => {
        // get the visual collapse enable/disable status
        const status = parseInt(visual?.substring(2), 10);
        // get the type of visual view
        const visualName = visual?.substring(0, 2);
        if (visualName && status !== undefined) {
          switch (visualName) {
            case COLLAPSE_VISUALS.TABULAR:
              dispatch(trendTabularTabActions.setRevenueIsDisplayTT(status === 1));
              break;
            default:
              break;
          }
        }
      });
      setVisualCollapseCommandValue(null);
    }
  }, [visualCollapseCommandValue, isLoaded]);

  // Listener function to set compare window value
  const setCompareWindowValue = (data) => {
    setCompareWindowValueCommand(data);
  };
  // Listener function to set custom date range value
  const setCustomDateRangeValue = (data) => {
    setCustomDatesValueCommand(data);
  };
  // Listener function to set breakdown command value
  const setBreakdownHandler = (value) => {
    setBreakdownCommandValue(value);
  };
  // Listener function to set custom booking date range command
  const setCustomBookingHandler = (value) => {
    setCustomBookingCommandValue(value);
  };
  // Listener function to set symmetric comparison collapse value
  const setSymmetricComparisonCollapseValue = (data) => {
    setSymmetricComparisonCollapseCommandValue(data);
  };
  // Listener function to set visual collapse value
  const setVisualCollapseValue = (data) => {
    setVisualCollapseCommandValue(data);
  };
  // Listener function to set focus on value
  const setFocusOnValue = (data) => {
    setOptions(data);
  };
  // Add Listener function to set custom booking command value
  useEffect(() => {
    UiController.subscribe(events.SET_TABULAR_CUSTOM_BOOKING, setCustomBookingHandler);
    return () => {
      UiController.unsubscribe(events.SET_TABULAR_CUSTOM_BOOKING, setCustomBookingHandler);
    };
  }, []);
  // Add Listener function to set alignment command value
  useEffect(() => {
    UiController.subscribe(events.TABULAR_ALIGNMENT, setSummaryAlignmentToggleHandler);
    return () => {
      UiController.unsubscribe(events.TABULAR_ALIGNMENT, setSummaryAlignmentToggleHandler);
    };
  }, []);
  // Add Listener function to set breakdown command value
  useEffect(() => {
    UiController.subscribe(events.SET_TABULAR_BREAKDOWN, setBreakdownHandler);
    return () => {
      UiController.unsubscribe(events.SET_TABULAR_BREAKDOWN, setBreakdownHandler);
    };
  }, []);
  // Add Listener function to set compare window command value
  useEffect(() => {
    UiController.subscribe(events.SET_TABULAR_COMPARE_WINDOW, setCompareWindowValue);
    return () => {
      UiController.unsubscribe(events.SET_TABULAR_COMPARE_WINDOW, setCompareWindowValue);
    };
  }, [dateComparison, customBooking]);
  // Add Listener function to set custom booking date range command value
  useEffect(() => {
    UiController.subscribe(events.SET_TABULAR_CUSTOM_DATE_RANGE, setCustomDateRangeValue);
    return () => {
      UiController.unsubscribe(events.SET_TABULAR_CUSTOM_DATE_RANGE, setCustomDateRangeValue);
    };
  }, []);
  // Add Listener function to set symmetric comparison collapse
  useEffect(() => {
    UiController.subscribe(
      events.SET_TABULAR_SYMMETRIC_COMPARISON_COLLAPSE,
      setSymmetricComparisonCollapseValue
    );
    return () => {
      UiController.unsubscribe(
        events.SET_TABULAR_SYMMETRIC_COMPARISON_COLLAPSE,
        setSymmetricComparisonCollapseValue
      );
    };
  }, []);
  // Add Listener function to set visual collapse
  useEffect(() => {
    UiController.subscribe(events.SET_TABULAR_VISUAL_COLLAPSE, setVisualCollapseValue);
    return () => {
      UiController.unsubscribe(events.SET_TABULAR_VISUAL_COLLAPSE, setVisualCollapseValue);
    };
  }, []);

  // Add event listener function to set focus on command value
  useEffect(() => {
    UiController.subscribe(events.SET_TABULAR_FOCUS_ON, setFocusOnValue);
    return () => {
      UiController.unsubscribe(events.SET_TABULAR_FOCUS_ON, setFocusOnValue);
    };
  }, []);
};

export default useTrendTabularCommandFlow;
