import { CustomStack, CustomToggleSwitch } from 'modules/common/components';
import styles from 'modules/dashboard/components/tab-container/inner-filter/inner-filter.module.scss';

/**
 * Weekly cumulative window component common for the dashboard component
 * @param {Boolean} isCumulative - State of Cumulative toggle
 * @param {Boolean} weeklyViewToggle - State of Weekly view toggle
 * @param {Function} handleIsCumulativeChange - Function to set cumulative toggle value
 * @param {Function} handleWeeklyViewChange - Function to set weekly toggle value
 * @returns
 */
const WeeklyCumulativeWindow = ({
  isCumulative,
  weeklyViewToggle,
  handleIsCumulativeChange,
  handleWeeklyViewChange,
  isForecastToggle = false,
  handleIsForecastChange = () => {},
  isTrendForecast = false,
  isForecastDisabled = false,
  isPercentageToggle = false,
  isTrendPercentageChange = false,
  isTrendPercentageDisabled = false,
  handleIsPercentageToggleChange = () => {},
}) => (
  <CustomStack cssClassName={styles.innerFilter}>
    <CustomToggleSwitch
      label="Cumulative View"
      title="Change ‘Total Occupied Rooms’ and ‘Total Revenue’ graphs to display the cumulated booking pace for each statistic."
      onChange={handleIsCumulativeChange}
      checked={isCumulative ?? false}
    />
    <CustomToggleSwitch
      label="Weekly View"
      title="Change X-axis to ‘days of the week’ to view results aggregated to the relevant weekday."
      onChange={handleWeeklyViewChange}
      checked={weeklyViewToggle ?? false}
    />

    {isForecastToggle && (
      <CustomToggleSwitch
        label="Forecasts"
        title="Enable forecasts on the ‘Total Revenue’ and ‘Total Occupied Rooms’ graphs"
        onChange={handleIsForecastChange}
        checked={isTrendForecast ?? false}
        disabled={isForecastDisabled}
      />
    )}

    {isPercentageToggle && (
      <CustomToggleSwitch
        label="Percentage"
        title="Enable percentage change on the ‘Total Revenue’ , ‘Total Occupied Rooms’ and 'Total Average Daily Rate' graphs"
        onChange={handleIsPercentageToggleChange}
        checked={isTrendPercentageChange ?? false}
        disabled={isTrendPercentageDisabled}
      />
    )}
  </CustomStack>
);
//
export default WeeklyCumulativeWindow;
