import { useEffect } from 'react';
import { Grid, Fade } from '@mui/material';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import { TAB_VALUE } from 'modules/dashboard/constants';
import Widget from './pace-widgets';
import usePaceTimeWidget from '../../hooks/use-pace-time-widget';
import usePaceSegmentWidget from '../../hooks/use-pace-segment-widget';
import usePaceShareUrl from '../../hooks/use-pace-share-url';
import UseVisualSwap from '../../hooks/use-visual-swap';
import usePaceHotelWidget from '../../hooks/use-pace-hotel-widget';
/**
 * Pace widget parent component
 * @param {Number} value - Pace widget tab value
 * @param {Boolean} active - whether pace tab is active
 */
const PaceWidget = ({ active, value, latestDate, reportType, hotelTimezone, toggleDisabled }) => {
  usePaceTimeWidget();
  usePaceSegmentWidget();
  usePaceShareUrl();
  UseVisualSwap();
  usePaceHotelWidget();
  // Triggered when tab value or active tab change
  useEffect(() => {
    // Scroll up when the pace tab is clicked
    if (value === TAB_VALUE.PACE) window.scrollTo(0, 0);
  }, [value, active]);
  //
  return (
    active && (
      <Fade in={active} {...{ timeout: 500 }}>
        <Grid
          container
          direction="row"
          sx={{
            display: value === TAB_VALUE.PACE ? '' : 'none',
          }}
          className={styles.mainGrid}
          alignItems="flex-start"
        >
          <Widget
            value={value}
            latestDate={latestDate}
            reportType={reportType}
            hotelTimezone={hotelTimezone}
            toggleDisabled={toggleDisabled}
          />
        </Grid>
      </Fade>
    )
  );
};
//
export default PaceWidget;
